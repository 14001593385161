import {
  useState,
  type ChangeEvent,
  type FC,
  type MouseEvent,
  useCallback,
} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  SvgIcon,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { Scrollbar } from "src/components/scrollbar";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import EditOutlined from "@mui/icons-material/EditOutlined";
import { useDispatch } from "src/store";
import { thunks } from "src/thunks/admin/organization-users";
import { toast } from "react-hot-toast";
import { UpdateUserDrawer } from "./update-user-drawer";
import { TableLoadingRow } from "src/sections/common/table-loading-row";
import {
  OrganizationUser,
  OrganizationUserRole,
} from "src/types/admin/organization-users";

interface UserListTableProps {
  organizationId: string;
  count: number | null;
  items: OrganizationUser[] | [];
  onPageChange: (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  onRowsPerPageChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onReset: () => void;
  onUpdate: () => void;
  page: number;
  top: number;
  isLoading: boolean;
  hasError: boolean;
}

export const UserListTable: FC<UserListTableProps> = (props) => {
  const { t } = useTranslation();
  const {
    count = 0,
    organizationId,
    items = null,
    onPageChange = () => {},
    onRowsPerPageChange,
    onReset = () => {},
    onUpdate = () => {},
    page = 0,
    top = 10,
    isLoading,
    hasError,
  } = props;
  const showPagination = !!page && !!top && !!count;

  const dispatch = useDispatch();

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openSendInvitationDialog, setOpenSendInvitationDialog] =
    useState<boolean>(false);

  const [user, setUser] = useState<OrganizationUser | undefined>(undefined);
  const [showUpdateUserDrawer, setShowUpdateUserDrawer] =
    useState<boolean>(false);

  const handleDeleteClose = useCallback(() => {
    setUser(undefined);
    setOpenDeleteDialog(false);
  }, []);

  const handleOpenDeleteDialog = useCallback((user: OrganizationUser) => {
    setUser(user);
    setOpenDeleteDialog(true);
  }, []);

  const handleSendInvitationClose = useCallback(() => {
    setUser(undefined);
    setOpenSendInvitationDialog(false);
  }, []);

  const deleteUser = useCallback(async () => {
    try {
      if (user) {
        handleDeleteClose();
        await dispatch(thunks.deleteOrganizationUser(organizationId, user.id));
        onReset();
      }
    } catch (err) {
      toast.error(t(tokens.general.formError));
    }
  }, [dispatch, handleDeleteClose, onReset, organizationId, t, user]);

  const getRoleName = useCallback((role: OrganizationUserRole) => {
    switch (role) {
      case OrganizationUserRole.admin:
        return t(tokens.general.userRoles.admin);
      case OrganizationUserRole.user:
        return t(tokens.general.userRoles.user);
      default:
        return t(tokens.general.userRoles.none);
    }
  }, []);

  return (
    <Box sx={{ position: "relative" }}>
      <Scrollbar>
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              <TableCell>
                {
                  t(
                    tokens.admin.organization.details.users.table.name
                  ) as string
                }
              </TableCell>
              <TableCell>
                {
                  t(
                    tokens.admin.organization.details.users.table.email
                  ) as string
                }
              </TableCell>
              <TableCell>
                {
                  t(
                    tokens.admin.organization.details.users.table.role
                  ) as string
                }
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  minWidth: 60,
                  pl: 3,
                }}
              >
                {
                  t(
                    tokens.admin.organization.details.users.table.actions
                  ) as string
                }
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableLoadingRow
              colSpan={4}
              isLoading={isLoading}
              hasError={hasError}
              itemsCount={items?.length ?? 0}
              noItemsText={
                t(
                  tokens.admin.organization.details.users.table.noUsersFound
                ) as string
              }
              errorLoadingText={
                t(
                  tokens.admin.organization.details.users.table
                    .errorLoadingUsers
                ) as string
              }
            />
            {items?.map((user) => {
              return (
                <TableRow
                  hover
                  key={user.id}
                >
                  <TableCell>
                    {user.firstName} {user.lastName}
                  </TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{getRoleName(user.role)}</TableCell>
                  <TableCell align="right">
                    <Stack
                      direction="row"
                      spacing={1}
                      justifyContent="flex-end"
                    >
                      {/* <IconButton
                        onClick={() => {
                          setUser(user);
                          setOpenSendInvitationDialog(true);
                        }}
                      >
                        <SvgIcon>
                          <ForwardToInboxIcon />
                        </SvgIcon>
                      </IconButton> */}
                      <IconButton
                        onClick={() => {
                          setUser(user);
                          setShowUpdateUserDrawer(true);
                        }}
                      >
                        <SvgIcon>
                          <EditOutlined />
                        </SvgIcon>
                      </IconButton>

                      <IconButton onClick={() => handleOpenDeleteDialog(user)}>
                        <SvgIcon>
                          <DeleteOutlineOutlinedIcon color="error" />
                        </SvgIcon>
                      </IconButton>
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
      {showPagination && (
        <TablePagination
          labelRowsPerPage={t(tokens.general.paginationRowsPerPage)}
          component="div"
          count={count}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          page={page - 1}
          rowsPerPage={top}
          rowsPerPageOptions={[5, 10, 25]}
        />
      )}

      <UpdateUserDrawer
        isOpen={showUpdateUserDrawer}
        onClose={() => setShowUpdateUserDrawer(false)}
        onUpdate={() => {
          setShowUpdateUserDrawer(false);
          onUpdate();
        }}
        user={user}
        organizationId={organizationId}
      />
      <Dialog
        open={openDeleteDialog}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t(tokens.admin.organization.details.users.deleteUserDialogTitle)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t(
              tokens.admin.organization.details.users
                .deleteUserDialogDescription
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={deleteUser}
            color="error"
          >
            {t(tokens.general.buttons.delete)}
          </Button>
          <Button onClick={handleDeleteClose}>
            {t(tokens.general.buttons.cancel)}
          </Button>
        </DialogActions>
      </Dialog>

      {/* <Dialog
        open={openSendInvitationDialog}
        onClose={handleSendInvitationClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t(tokens.admin.organization.details.users.sendInvitationDialogTitle)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t(tokens.admin.organization.details.users.sendInvitationDialogDescription)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={sendInvitation}
            color="primary">
            {t(tokens.general.buttons.send)}
          </Button>
          <Button onClick={handleSendInvitationClose}>{t(tokens.general.buttons.cancel)}</Button>
        </DialogActions>
      </Dialog> */}
    </Box>
  );
};
