import { tokens } from "../tokens";

export const en = {
  [tokens.landing.nav.products]: "Products",
  [tokens.landing.nav.singIn]: "Sign in",
  [tokens.landing.nav.singUp]: "Sign up",
  [tokens.landing.nav.privacyPolicy]: "Privacy policy",
  [tokens.landing.nav.termsOfUse]: "Terms of use",

  [tokens.nav.dashboard]: "Dashboard",
  [tokens.nav.host]: "Host",
  [tokens.nav.profile]: "My profile",

  [tokens.admin.products.list.pageTitle]: "Products",
  [tokens.admin.products.list.pageHeader]: "Products",
  [tokens.admin.products.list.table.title]: "Title",
  [tokens.admin.products.list.table.accessMonthsCount]: "Access",
  [tokens.admin.products.list.table.months]: "month(s)",
  [tokens.admin.products.list.table.start]: "Start",
  [tokens.admin.products.list.table.usersCount]: "Users count",
  [tokens.admin.products.list.table.actions]: "Actions",
  [tokens.admin.products.list.archiveProductDialogTitle]: "Archive product",
  [tokens.admin.products.list.archiveProductDialogDescription]:
    "Are you sure you want to archive this product?",
  [tokens.admin.products.list.deleteProductDialogTitle]: "Delete product",
  [tokens.admin.products.list.deleteProductDialogDescription]:
    "Are you sure you want to delete this product?",
  [tokens.admin.products.list.buttons.add]: "New product",
  [tokens.admin.products.list.noProductsFound]: "No products found",
  [tokens.admin.products.list.errorLoadingProducts]: "Could not load products",

  [tokens.admin.products.create.pageTitle]: "New product",
  [tokens.admin.products.create.pageHeader]: "New product",
  [tokens.admin.products.create.backButton]: "Back to products",
  [tokens.admin.products.create.form.title]: "Title",
  [tokens.admin.products.create.form.description]: "Description",
  [tokens.admin.products.create.form.startDate]: "Start date",
  [tokens.admin.products.create.form.endDate]: "End date",
  [tokens.admin.products.create.form.isStandalone]: "Standalone product",
  [tokens.admin.products.create.form.backGroundImage.header]:
    "Background image",
  [tokens.admin.products.create.form.backGroundImage.description]:
    "Size recommendation: 1120 (width) * 160 (height)",
  [tokens.admin.products.create.form.successMessage]:
    "product has been successfully created.",

  [tokens.admin.products.details.pageTitle]: "Product details",
  [tokens.admin.products.details.pageHeader]: "Product details",
  [tokens.admin.products.details.backButton]: "Back to products",
  [tokens.admin.products.details.noModulesFound]: "No modules found",
  [tokens.admin.products.details.errorLoadingModules]: "Could not load modules",
  [tokens.admin.products.details.buttons.addModule]: "New module",
  [tokens.admin.products.details.menu.preview]: "Preview product",
  [tokens.admin.products.details.table.title]: "Title",
  [tokens.admin.products.details.table.active]: "Active",
  [tokens.admin.products.details.table.productPlans]: "Product plans",
  [tokens.admin.products.details.table.actions]: "Actions",
  [tokens.admin.products.details.deleteModuleDialogTitle]: "Delete module",
  [tokens.admin.products.details.deleteModuleDialogDescription]:
    "Are you sure you want to delete this module?",
  [tokens.admin.products.details.tabs.content]: "Content",
  [tokens.admin.products.details.tabs.modules]: "Modules",
  [tokens.admin.products.details.tabs.details]: "Details",
  [tokens.admin.products.details.tabs.users]: "Users",
  [tokens.admin.products.details.tabs.payments]: "Payments",
  [tokens.admin.products.details.tabs.settings]: "Settings",
  [tokens.admin.products.details.tabs.productPlans]: "Pricing Plans",
  [tokens.admin.products.details.form.title]: "Title",
  [tokens.admin.products.details.form.description]: "Description",
  [tokens.admin.products.details.form.startDate]: "Start date",
  [tokens.admin.products.details.form.maxAccessMonthsCount]: "Access (months)",
  [tokens.admin.products.details.form.content.editor]: "Editor",
  [tokens.admin.products.details.form.content.preview]: "Content preview",
  [tokens.admin.products.details.form.content.noPreviewContent]:
    "No content to preview",
  [tokens.admin.products.details.form.backGroundImage.header]:
    "Background image",
  [tokens.admin.products.details.form.backGroundImage.description]:
    "Size recommendation: 1120 (width) * 160 (height)",
  [tokens.admin.products.details.form.successMessage]:
    "Product has been successfully updated.",
  [tokens.admin.products.details.productPlans.buttons.newProductPlan]:
    "New plan",
  [tokens.admin.products.details.productPlans.table.name]: "Name",
  [tokens.admin.products.details.productPlans.table.price]: "Price",
  [tokens.admin.products.details.productPlans.table.currency]: "Currency",
  [tokens.admin.products.details.productPlans.table.actions]: "Actions",
  [tokens.admin.products.details.productPlans.table.noPaymentPlansFound]:
    "No product plans found",
  [tokens.admin.products.details.productPlans.table.errorLoadingProductPlans]:
    "Could not load product plans",
  [tokens.admin.products.details.productPlans.deleteProductPlanDialogTitle]:
    "Delete product plan",
  [tokens.admin.products.details.productPlans
    .deleteProductPlanDialogDescription]:
    "Are you sure you want to delete this product plan?",
  [tokens.admin.products.details.productPlans.form.name]: "Name",
  [tokens.admin.products.details.productPlans.form.url]: "Url",
  [tokens.admin.products.details.productPlans.form.price]: "Price",
  [tokens.admin.products.details.productPlans.form.currency]: "Currency",
  [tokens.admin.products.details.productPlans.form.params]: "Params",
  [tokens.admin.products.details.productPlans.form.successMessage]:
    "Product plan has been successfully saved.",
  [tokens.admin.products.details.productPlans.form.updateTitle]: "Update plan",
  [tokens.admin.products.details.productPlans.form.createTitle]: "New plan",
  [tokens.admin.products.details.productPlans.form.settingsTitle]: "Settings",

  [tokens.admin.products.details.users.table.name]: "Name",
  [tokens.admin.products.details.users.table.phone]: "Phone",
  [tokens.admin.products.details.users.table.date]: "Date",
  [tokens.admin.products.details.users.table.endDate]: "End date",
  [tokens.admin.products.details.users.table.paymentStatus]: "Payment status",
  [tokens.admin.products.details.users.table.actions]: "Actions",
  [tokens.admin.products.details.users.table.type]: "Type",
  [tokens.admin.products.details.users.table.productPlan]: "Product plan",
  [tokens.admin.products.details.users.table.manualType]: "Manual",
  [tokens.admin.products.details.users.table.autoType]: "Auto",
  [tokens.admin.products.details.users.table.noUsersFound]: "No users found",
  [tokens.admin.products.details.users.table.errorLoadingUsers]:
    "Could not load users",
  [tokens.admin.products.details.users.buttons.newUser]: "New user",
  [tokens.admin.products.details.users.form.firstName]: "First name",
  [tokens.admin.products.details.users.form.lastName]: "Last name",
  [tokens.admin.products.details.users.form.email]: "Email",
  [tokens.admin.products.details.users.form.phone]: "Phone",
  [tokens.admin.products.details.users.form.productPlan]: "Product plan",
  [tokens.admin.products.details.users.form.endDate]: "End date",
  [tokens.admin.products.details.users.form.createTitle]: "Create user",
  [tokens.admin.products.details.users.form.updateTitle]: "New user",
  [tokens.admin.products.details.users.form.successMessage]:
    "User has been successfully added.",
  [tokens.admin.products.details.users.form.errors.userAlreadyExists]:
    "User already exists",
  [tokens.admin.products.details.users.deleteUserDialogTitle]: "Delete user",
  [tokens.admin.products.details.users.deleteUserDialogDescription]:
    "Are you sure you want to delete this user?",
  [tokens.admin.products.details.users.sendInvitationDialogTitle]:
    "Send invitation",
  [tokens.admin.products.details.users.sendInvitationDialogDescription]:
    "Are you sure you want to send an invitation to this user?",
  [tokens.admin.products.details.users.invitationSentSuccessMessage]:
    "Invitation has been successfully sent",
  [tokens.admin.products.details.payments.table.orderReference]:
    "Order reference",
  [tokens.admin.products.details.payments.table.user]: "User",
  [tokens.admin.products.details.payments.table.amount]: "Amount",
  [tokens.admin.products.details.payments.table.productPlan]: "Product plan",
  [tokens.admin.products.details.payments.table.date]: "Date",
  [tokens.admin.products.details.payments.table.paymentStatus]:
    "Payment status",
  [tokens.admin.products.details.payments.table.noPaymentsFound]:
    "No payments found",
  [tokens.admin.products.details.payments.table.errorLoadingPayments]:
    "Could not load payments",
  [tokens.admin.products.details.settings.successMessage]:
    "Settings have been successfully updated.",
  [tokens.admin.products.details.settings.errorLoadingSettings]:
    "Could not load settings",
  [tokens.admin.products.details.settings.wayForPay.paymentButton.header]:
    "WayForPay Payment Button",
  [tokens.admin.products.details.settings.wayForPay.paymentButton.serviceUrl]:
    "Service URL",
  [tokens.admin.products.details.settings.wayForPay.paymentButton.returnUrl]:
    "Return URL",
  [tokens.admin.products.details.settings.webHooks.header]: "Webhooks",
  [tokens.admin.products.details.settings.webHooks.form.productInvitationHook]:
    "Product invitation webhook",
  [tokens.admin.products.details.settings.webHooks.form.params]: "Params:",
  [tokens.admin.products.details.settings.webHooks.form.url]: "URL",
  [tokens.admin.products.details.settings.emailTemplate.header]:
    "Product invitation email template",
  [tokens.admin.products.details.settings.emailTemplate
    .editProductInvitationEmail]: "Edit template",
  [tokens.admin.products.details.settings.productPlans.header]: "Product plans",
  [tokens.admin.products.details.settings.productPlans.form.name]: "Name",
  [tokens.admin.products.details.settings.productPlans.noPlans]: "No plans",
  [tokens.admin.products.details.settings.productPlans.form.successMessage]:
    "Product plans have been successfully saved.",

  [tokens.admin.modules.create.pageTitle]: "New module",
  [tokens.admin.modules.create.pageHeader]: "New module",
  [tokens.admin.modules.create.backButton]: "Back to product",
  [tokens.admin.modules.create.form.title]: "Title",
  [tokens.admin.modules.create.form.description]: "Description",
  [tokens.admin.modules.create.form.active]: "Active",
  [tokens.admin.modules.create.form.link.title]: "Link title",
  [tokens.admin.modules.create.form.link.url]: "Link URL",
  [tokens.admin.modules.create.form.backGroundImage.header]: "Image",
  [tokens.admin.modules.create.form.backGroundImage.description]:
    "Size recommendation: 1120 (width) * 160 (height)",
  [tokens.admin.modules.create.form.content]: "Content",
  [tokens.admin.modules.create.form.successMessage]:
    "Module has been successfully created.",
  [tokens.admin.modules.create.linkHeader]: "Links",
  [tokens.admin.modules.create.noLinks]: "No links",

  [tokens.admin.modules.details.pageTitle]: "Edit module",
  [tokens.admin.modules.details.pageHeader]: "Edit module",
  [tokens.admin.modules.details.backButton]: "Back to modules",
  [tokens.admin.modules.details.menu.preview]: "Preview module",
  [tokens.admin.modules.details.form.title]: "Title",
  [tokens.admin.modules.details.form.description]: "Description",
  [tokens.admin.modules.details.form.active]: "Active",
  [tokens.admin.modules.details.form.startDate]: "Start date",
  [tokens.admin.modules.details.form.link.title]: "Link title",
  [tokens.admin.modules.details.form.link.url]: "Link URL",
  [tokens.admin.modules.details.form.productPlans.header]:
    "Available in product plans",
  [tokens.admin.modules.details.form.productPlans.name]: "Name",
  [tokens.admin.modules.details.form.image.header]: "Image",
  [tokens.admin.modules.details.form.image.description]:
    "Size recommendation: 1120 (width) * 160 (height)",
  [tokens.admin.modules.details.form.content.editor]: "Editor",
  [tokens.admin.modules.details.form.content.preview]: "Content preview",
  [tokens.admin.modules.details.form.content.noPreviewContent]:
    "No content to preview",
  [tokens.admin.modules.details.form.successMessage]:
    "Module has been successfully updated.",
  [tokens.admin.modules.details.linkHeader]: "Links",
  [tokens.admin.modules.details.noLinks]: "No links added",
  [tokens.admin.modules.details.tabs.details]: "Details",
  [tokens.admin.modules.details.tabs.content]: "Content",

  [tokens.admin.organization.details.pageTitle]: "Organization details",
  [tokens.admin.organization.details.pageHeader]: "Organization details",
  [tokens.admin.organization.details.tabs.details]: "Details",
  [tokens.admin.organization.details.tabs.users]: "Users",
  [tokens.admin.organization.details.tabs.settings]: "Settings",
  [tokens.admin.organization.details.form.domain]: "Domain",
  [tokens.admin.organization.details.form.name]: "Name",
  [tokens.admin.organization.details.form.logoImage.header]: "Logo",
  [tokens.admin.organization.details.form.logoImage.description]:
    "Size recommendation: 200 (width) * 200 (height)",
  [tokens.admin.organization.details.form.successMessage]:
    "Organization has been successfully updated.",
  [tokens.admin.organization.details.form.domainAlreadyUsed]:
    "The domain is already used.",
  [tokens.admin.organization.details.users.table.name]: "Name",
  [tokens.admin.organization.details.users.table.email]: "Email",
  [tokens.admin.organization.details.users.table.role]: "Role",
  [tokens.admin.organization.details.users.table.actions]: "Actions",
  [tokens.admin.organization.details.users.table.errorLoadingUsers]:
    "Could not load users",
  [tokens.admin.organization.details.users.table.noUsersFound]:
    "No users found",
  [tokens.admin.organization.details.users.buttons.newUser]: "New user",
  [tokens.admin.organization.details.users.form.firstName]: "First name",
  [tokens.admin.organization.details.users.form.lastName]: "Last name",
  [tokens.admin.organization.details.users.form.role]: "Role",
  [tokens.admin.organization.details.users.form.email]: "Email",
  [tokens.admin.organization.details.users.form.createTitle]: "Create user",
  [tokens.admin.organization.details.users.form.updateTitle]: "Update user",
  [tokens.admin.organization.details.users.form.updatedSuccessMessage]:
    "User has been successfully updated.",
  [tokens.admin.organization.details.users.form.createdSuccessMessage]:
    "User has been successfully added.",
  [tokens.admin.organization.details.users.form.errors.userAlreadyExists]:
    "User already exists",
  [tokens.admin.organization.details.users.deleteUserDialogTitle]:
    "Delete user",
  [tokens.admin.organization.details.users.deleteUserDialogDescription]:
    "Are you sure you want to delete this user?",
  [tokens.admin.organization.details.settings.errorLoadingSettings]:
    "Could not load settings",
  [tokens.admin.organization.details.settings.successMessage]:
    "Settings have been successfully updated.",
  [tokens.admin.organization.details.settings.colors.header]: "Colors",
  [tokens.admin.organization.details.settings.colors.form.mainColor]:
    "Main color",
  [tokens.admin.organization.details.settings.colors.form.darkColor]:
    "Dark color",
  [tokens.admin.organization.details.settings.colors.form.darkestColor]:
    "Darkest color",
  [tokens.admin.organization.details.settings.colors.form.lightColor]:
    "Light color",
  [tokens.admin.organization.details.settings.colors.form.lightestColor]:
    "Lightest color",
  [tokens.admin.organization.details.settings.colors.form.contrastTextColor]:
    "Contrast text color",

  [tokens.admin.organization.details.settings.email.header]:
    "Email sender settings",
  [tokens.admin.organization.details.settings.email.form.isEnabled]: "Enabled",
  [tokens.admin.organization.details.settings.email.form.email]: "From email",
  [tokens.admin.organization.details.settings.email.form.name]: "From name",
  [tokens.admin.organization.details.settings.email.form.host]: "Host",
  [tokens.admin.organization.details.settings.email.form.port]: "Port",
  [tokens.admin.organization.details.settings.email.form.username]: "Username",
  [tokens.admin.organization.details.settings.email.form.password]: "Password",
  [tokens.admin.organization.details.settings.emailTemplates.header]:
    "Email templates",
  [tokens.admin.organization.details.settings.emailTemplates.signInEmail]:
    "Edit sign in email",
  [tokens.admin.organization.details.settings.payment.wayForPay.header]:
    "WayForPay Configuration",
  [tokens.admin.organization.details.settings.payment.wayForPay.form.secretKey]:
    "Secret key",
  [tokens.admin.organization.details.settings.payment.wayForPay.form
    .merchantAccount]: "Merchant account",
  [tokens.admin.organization.details.settings.payment.wayForPay.form
    .merchantDomain]: "Merchant domain",

  [tokens.panel.products.details.backButton]: "Back to products",
  [tokens.panel.products.details.menu.edit]: "Edit product",
  [tokens.panel.products.details.errorProductLoading]: "Could not load product",
  [tokens.panel.products.details.errorModulesLoading]: "Could not load modules",
  [tokens.panel.products.details.noModulesFound]: "No modules found",
  [tokens.panel.products.details.startDate]: "Open date",

  [tokens.panel.products.list.pageTitle]: "Products",
  [tokens.panel.products.list.errorProductsLoading]: "Could not load products",
  [tokens.panel.products.list.noProductsFound]: "No products found",
  [tokens.panel.products.list.status.open]: "Open",
  [tokens.panel.products.list.status.closed]: "Closed",

  [tokens.panel.modules.details.backButton]: "Back to modules",
  [tokens.panel.modules.details.linkHeader]: "Additional materials",
  [tokens.panel.modules.details.noLinks]: "No additional materials",
  [tokens.panel.modules.details.menu.edit]: "Edit module",
  [tokens.panel.modules.details.loadingError]: "Could not load module",

  [tokens.account.signUp.title]: "Create account",
  [tokens.account.signUp.header]: "Create account",
  [tokens.account.signUp.form.firstName]: "First name *",
  [tokens.account.signUp.form.lastName]: "Last name *",
  [tokens.account.signUp.form.phone]: "Phone number *",
  [tokens.account.signUp.form.email]: "Email address *",
  [tokens.account.signUp.form.password]: "Password *",
  [tokens.account.signUp.form.createAccountButton]: "Create account",
  [tokens.account.signUp.form.termsOfUseLink]: "Terms of use",
  [tokens.account.signUp.form.privacyPolicyLink]: "Privacy Policy",
  [tokens.account.signUp.form.andSeparator]: "and",
  [tokens.account.signUp.form.agreementText]: "I have read the",
  [tokens.account.signUp.form.signinLink]: "Sign in",
  [tokens.account.signUp.form.hasAccount]: "Already have an account?",

  [tokens.account.signIn.title]: "Sign in",
  [tokens.account.signIn.header]: "Sign in",
  [tokens.account.signIn.description]:
    "Provide your email address and we will send you a link to enter the platform.",
  [tokens.account.signIn.noAccount]: "Don't have an account?",
  [tokens.account.signIn.createAccountLink]: "Create account",
  [tokens.account.signIn.form.email]: "Email address *",
  [tokens.account.signIn.form.password]: "Password *",
  [tokens.account.signIn.form.signInButton]: "Sign in",
  [tokens.account.signIn.form.signInViaGoogleButton]: "Sign in via Google",
  [tokens.account.signIn.form.orSeparator]: "or",
  [tokens.account.signIn.form.forgotPasswordLink]: "Forgot password?",
  [tokens.account.signIn.form.termsOfUseLink]: "Terms of use",
  [tokens.account.signIn.form.privacyPolicyLink]: "Privacy Policy",
  [tokens.account.signIn.form.andSeparator]: "and",
  [tokens.account.signIn.form.agreementText]: "I have read the",

  [tokens.account.confirmSignIn.title]: "Confirm sign in",
  [tokens.account.confirmSignIn.expiredToken]:
    "The link has expired. Please sign in again.",

  [tokens.account.emailSent.title]: "Email sent",
  [tokens.account.emailSent.header]: "Email sent",
  [tokens.account.emailSent.message]:
    "The email with the sign in link has been sent. Please check your mailbox.",

  [tokens.account.resetPassword.title]: "Reset password",
  [tokens.account.resetPassword.header]: "Reset password",
  [tokens.account.resetPassword.signInLink]: "Sign in",
  [tokens.account.resetPassword.form.passwordChangedMessage]:
    "Your password has been successfully changed. Go to the sign in page.",
  [tokens.account.resetPassword.form.password]: "Password *",
  [tokens.account.resetPassword.form.confirmPassword]: "Confirm password *",
  [tokens.account.resetPassword.form.resetButton]: "Reset password",
  [tokens.account.resetPassword.form.linkInvalidError]:
    "Invalid link. Please try to reset your password again",
  [tokens.account.resetPassword.form.linkInvalidOrExpiredError]:
    "Restore password link has expired or invalid. Try to reset a password again",

  [tokens.account.forgotPassword.title]: "Forgot password",
  [tokens.account.forgotPassword.header]: "Forgot password",
  [tokens.account.forgotPassword.signInLink]: "Sign in",
  [tokens.account.forgotPassword.form.email]: "Email address *",
  [tokens.account.forgotPassword.form.emailSentMessage]:
    "The email with the link has been sent. Please check your mailbox.",
  [tokens.account.forgotPassword.form.resetButton]: "Send a reset link",

  [tokens.account.popover.settings]: "Settings",
  [tokens.account.popover.impersonate]: "Impersonate",
  [tokens.account.popover.stopImpersonation]: "Stop impersonation",
  [tokens.account.popover.signOut]: "Sign out",
  [tokens.account.popover.creator]: "Creator",
  [tokens.account.popover.organization]: "Organization",

  [tokens.account.colorScheme.light]: "Light",
  [tokens.account.colorScheme.dark]: "Dark",
  [tokens.account.colorScheme.scheme]: "Color scheme",

  [tokens.account.profile.title]: "Account",
  [tokens.account.profile.header]: "Account",
  [tokens.account.profile.passwordHeader]: "Password",
  [tokens.account.profile.accountDetailsHeader]: "Account details",
  [tokens.account.profile.accountProfileImage]: "Profile image",
  [tokens.account.profile.tab.general]: "General",
  [tokens.account.profile.form.firstName]: "First name *",
  [tokens.account.profile.form.lastName]: "Last name *",
  [tokens.account.profile.form.email]: "Email address *",
  [tokens.account.profile.form.currentPassword]: "Current password *",
  [tokens.account.profile.form.newPassword]: "New password *",
  [tokens.account.profile.form.confirmPassword]: "Confirm password *",
  [tokens.account.profile.form.passwordUpdatedMessage]:
    "Your password has been successfully updated.",
  [tokens.account.profile.form.accountUpdatedMessage]:
    "Your account has been successfully updated.",

  [tokens.account.impersonate.form.header]: "Impersonate as a user",
  [tokens.account.impersonate.form.searchForUser]: "User",
  [tokens.account.impersonate.form.searchForOrganization]: "Organization",
  [tokens.account.impersonate.form.buttons.impersonate]: "Impersonate",

  [tokens.payment.statuses.approved]: "Approved",
  [tokens.payment.statuses.pending]: "Pending",
  [tokens.payment.statuses.declined]: "Declined",
  [tokens.payment.statuses.unknown]: "Unknown",
  [tokens.payment.statuses.refunded]: "Refunded",
  [tokens.payment.statuses.noPayment]: "No payment",
  [tokens.payment.success.header]: "Payment details",
  [tokens.payment.success.title]: "Payment details",
  [tokens.payment.success.product]: "Product",
  [tokens.payment.success.email]: "Email",
  [tokens.payment.success.status]: "Status",
  [tokens.payment.success.orderReference]: "Order reference",
  [tokens.payment.success.messages.approved]:
    "The link to the product has been sent to your email.",
  [tokens.payment.success.messages.pending]:
    "Once the payment is approved, the link to the product will be sent to your email.",
  [tokens.payment.success.messages.declined]:
    "The payment has been declined. Please try again or contact support.",
  [tokens.payment.success.messages.unknown]:
    "The payment status is unknown. Please contact support.",

  [tokens.payment.error.header]: "Payment error",
  [tokens.payment.error.title]: "Payment error",
  [tokens.payment.error.message]:
    "Your payment has not been processed. Please try again.<br/><br/>If the problem persists, contact support.",
  [tokens.payment.error.orderReference]: "Order reference",

  [tokens.profile.title]: "Profile",

  [tokens.organization.notFound.title]: "Not found",
  [tokens.organization.notFound.header]: "Not found",
  [tokens.organization.notFound.message]:
    "The organization you are looking for does not exist or has been deleted.",

  [tokens.organization.select.title]: "Select organization",
  [tokens.organization.select.header]: "Select organization",
  [tokens.organization.select.newOrganization]: "New organization",
  [tokens.organization.select.errorMessage]:
    "Could not select the organization. Try to sign out and sign in again.",
  [tokens.organization.select.noOrganizations]: "No organizations found",

  [tokens.organization.create.title]: "New organization",
  [tokens.organization.create.header]: "New organization",
  [tokens.organization.create.form.name]: "Name *",
  [tokens.organization.create.form.domain]: "Domain *",
  [tokens.organization.create.form.logoImage.header]: "Logo",
  [tokens.organization.create.form.logoImage.description]:
    "Size recommendation: 200 (width) * 200 (height)",
  [tokens.organization.create.form.successMessage]:
    "Organization has been successfully created.",
  [tokens.organization.create.form.domainAlreadyUsed]:
    "The domain is already used.",

  [tokens.contact.title]: "Demo",
  [tokens.contact.header]: "Schedule FlitEdu demo",
  [tokens.contact.subHeader]:
    "Our friendly experts will show you FlitEdu and customize it to your needs.",
  [tokens.contact.form.firstName]: "First name *",
  [tokens.contact.form.lastName]: "Last name *",
  [tokens.contact.form.phone]: "Phone number *",
  [tokens.contact.form.email]: "Email address *",
  [tokens.contact.form.message]: "Message *",
  [tokens.contact.form.sendButton]: "Send message",
  [tokens.contact.form.agreementText]: "By submitting this, you agree to the",
  [tokens.contact.form.termsOfUseLink]: "Terms of use",
  [tokens.contact.form.privacyPolicyLink]: "Privacy Policy",
  [tokens.contact.form.andSeparator]: "and",
  [tokens.contact.form.successTitle]: "Your message has been sent",
  [tokens.contact.form.successMessage1]: "We will contact you soon.",
  [tokens.contact.form.successMessage2]:
    "Thank you for your interest in FlitEdu!",

  [tokens.errors.serverError.title]: "Service is unavailable",
  [tokens.errors.serverError.header]: "Service is unavailable",
  [tokens.errors.serverError.message]:
    "There might be ongoing maintenance or a temporary overload.",
  [tokens.errors.notFound.title]: "Not found",
  [tokens.errors.notFound.header]: "Not found",
  [tokens.errors.notFound.message]:
    "The page you are looking for does not exist or has been deleted.",
  [tokens.errors.notAuthorized.title]: "Not authorized",
  [tokens.errors.notAuthorized.header]: "Not authorized",
  [tokens.errors.notAuthorized.message]:
    "You are not authorized to access this page.",

  [tokens.general.languageChanged]: "Language changed",
  [tokens.general.language]: "Language",
  [tokens.general.newAppVersion.description]: "A new version is available.",
  [tokens.general.newAppVersion.action]: "Update",

  [tokens.general.userRoles.none]: "None",
  [tokens.general.userRoles.admin]: "Admin",
  [tokens.general.userRoles.user]: "User",

  [tokens.general.filters.emptyOptions]: "No options available",
  [tokens.general.paginationRowsPerPage]: "Items:",
  [tokens.general.formError]: "Something went wrong. Try Again!",
  [tokens.general.loading]: "Loading...",

  [tokens.general.validators.user.conflict]:
    "The user with such an email is already added",
  [tokens.general.validators.employee.conflict]:
    "The employee is already added",
  [tokens.general.validators.employee.accountConnected]:
    "The email is already connected to another employee",
  [tokens.general.validators.role.required]: "Role is required",
  [tokens.general.validators.role.min]: "At least one role is required",
  [tokens.general.validators.password.minLength]:
    "Password must be at least 6 characters",
  [tokens.general.validators.passwordConfirm.notMatch]:
    "Passwords do not match",
  [tokens.general.validators.policy.required]: "This field must be checked",
  [tokens.general.validators.invalidEmail]: "Invalid email format",
  [tokens.general.validators.roleRequired]: "At least one role is required",
  [tokens.general.validators.maxLength255]:
    "Field must be at most 255 characters",
  [tokens.general.validators.maxLength50]:
    "Field must be at most 50 characters",
  [tokens.general.validators.maxLength1000]:
    "Field must be at most 1000 characters",
  [tokens.general.validators.required]: "Required field",
  [tokens.general.validators.oneItemRequired]: "At least one item is required",

  [tokens.general.filepicker.titleHighlight]: "Click to upload",
  [tokens.general.filepicker.titleNormal]: " or drag and drop",
  [tokens.general.filepicker.imageExtensions]: "(JPG or PNG), 5MB max",
  [tokens.general.filepicker.excelExtensions]: "(XLS, or XLSX)",
  [tokens.general.filepicker.fileSizeError]: "File size must be less than 5MB",
  [tokens.general.filepicker.deleteImage]: "Delete",
  [tokens.general.filepicker.imageUploaded]:
    "Image has been successfully uploaded",
  [tokens.general.editor.tools.bold]: "Bold",
  [tokens.general.editor.tools.heading]: "Header",
  [tokens.general.editor.tools.italic]: "Italic",
  [tokens.general.editor.tools.list]: "List",
  [tokens.general.editor.tools.paragraph]: "Paragraph",
  [tokens.general.editor.tools.rawHtml]: "Raw HTML",
  [tokens.general.editor.tools.timeCode]: "Time code",
  [tokens.general.editor.blockTunes.delete]: "Delete",
  [tokens.general.editor.blockTunes.moveUp]: "Move up",
  [tokens.general.editor.blockTunes.moveDown]: "Move down",
  [tokens.general.editor.showTimeCodes]: "Show time codes",
  [tokens.general.buttons.ok]: "Ok",
  [tokens.general.buttons.delete]: "Delete",
  [tokens.general.buttons.edit]: "Edit",
  [tokens.general.buttons.add]: "Add",
  [tokens.general.buttons.copy]: "Copy",
  [tokens.general.buttons.invite]: "Invite",
  [tokens.general.buttons.send]: "Send",
  [tokens.general.buttons.update]: "Update",
  [tokens.general.buttons.archive]: "Archive",
  [tokens.general.buttons.create]: "Create",
  [tokens.general.buttons.cancel]: "Cancel",
  [tokens.general.buttons.close]: "Close",
  [tokens.general.buttons.loadMore]: "Load more",
  [tokens.general.buttons.tryAgain]: "Try again",
  [tokens.general.buttons.backToHome]: "Back to home page",
  [tokens.general.buttons.addItem]: "Add",
  [tokens.general.buttons.next]: "Next →",
  [tokens.general.buttons.generateAgain]: "Generate again",
  [tokens.general.buttons.generateStrategy]: "Generate strategy",
};
