import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import type { Theme } from '@mui/material';
import { Box, Container, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Footer } from '../components/footer';
import { useMobileNav } from '../components/use-mobile-nav';
import { TopNav } from '../components/top-nav';
import { SideNav } from '../components/side-nav';
const LayoutRoot = styled('div')(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    height: '100%'
  })
);

interface LayoutProps {
  children?: ReactNode;
}

export const Layout: FC<LayoutProps> = (props) => {
  const { children } = props;
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const mobileNav = useMobileNav();

  return (
    <>
      <TopNav onMobileNavOpen={mobileNav.handleOpen} />
      {!lgUp && (
        <SideNav
          onClose={mobileNav.handleClose}
          open={mobileNav.open}
        />
      )}

      <LayoutRoot>

        <Container
          maxWidth="lg"
          sx={{
            py: {
              xs: '80px',
              md: '120px'
            }
          }}
        >
          {children}
        </Container>
        <Footer />
      </LayoutRoot>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node
};
