import { useEffect, useMemo } from "react";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Link,
  Skeleton,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { Seo } from "src/components/seo";
import { usePageView } from "src/hooks/use-page-view";
import type { Page as PageType } from "src/types/page";
import { tokens } from "src/locales/tokens";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "src/store";
import { thunks } from "src/thunks/panel/module";
import { useParams } from "react-router";
import { RouterLink } from "src/components/router-link";
import { paths } from "src/paths";
import ArrowLeftIcon from "@untitled-ui/icons-react/build/esm/ArrowLeft";
import { createRoute } from "react-router-url-params";
import {
  OptionItem,
  OptionMenuButton,
} from "src/sections/common/option-menu-button";
import { ErrorMessage } from "src/sections/common/error-message";
import NotFound from "src/sections/errors/not-found";
import { ERRORS, ORG_ROLES } from "src/consts";
import ServerError from "src/sections/errors/server-error";
import EditorJsRenderer from "src/components/editor/EditorJsRenderer";
import { useAuth } from "src/hooks/use-auth";
import LinkIcon from "@mui/icons-material/Link";

const Page: PageType = () => {
  usePageView();

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const auth = useAuth();
  const { productId, moduleId } = useParams();
  const { item, error, isLoading } = useSelector(
    (state) => state.accountModule
  );

  const menuItems = useMemo<OptionItem[]>(() => {
    return [
      {
        text: t(tokens.panel.products.details.menu.edit),
        iconName: "edit",
        onClick: () => {
          window.open(
            createRoute(paths.admin.modules.details).link({
              productId: productId,
              moduleId: moduleId,
            }),
            "_blank"
          );
        },
      },
    ];
  }, [moduleId, productId, t]);

  useEffect(() => {
    const loadModule = async () => {
      if (productId && moduleId) {
        await dispatch(thunks.getModule(productId, moduleId));
      }
    };

    loadModule();
  }, [productId, moduleId, dispatch]);

  const data = useMemo(() => {
    if (item && item.content) {
      try {
        return JSON.parse(item.content);
      } catch (e) {
        return undefined;
      }
    }
    return undefined;
  }, [item]);

  if (error) {
    if (error.status === ERRORS.NOT_FOUND_ERROR.status) {
      return <NotFound showBackButton={false} />;
    }
    return <ServerError showBackButton={false} />;
  }

  if (!productId || !moduleId) {
    return null;
  }

  return (
    <>
      <Seo title={item?.title} />
      <Stack
        spacing={3}
        sx={{ mb: 3 }}
      >
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
        >
          <Link
            color="text.primary"
            component={RouterLink}
            href={createRoute(paths.panel.products.details).link({
              productId: productId,
            })}
            sx={{
              alignItems: "center",
              display: "inline-flex",
            }}
            underline="hover"
          >
            <SvgIcon sx={{ mr: 1 }}>
              <ArrowLeftIcon />
            </SvgIcon>
            <Typography variant="subtitle2">
              {t(tokens.panel.modules.details.backButton)}
            </Typography>
          </Link>
          {auth.getUserRole() === ORG_ROLES.Admin && (
            <Box>
              <OptionMenuButton items={menuItems} />
            </Box>
          )}
        </Stack>

        {error && (
          <>
            <Card>
              <CardContent>
                <ErrorMessage
                  text={t(tokens.panel.modules.details.loadingError)}
                />
              </CardContent>
            </Card>
          </>
        )}

        <Typography variant="h4">
          {isLoading ? <Skeleton /> : <>{item?.title}</>}
        </Typography>
        <div>
          {
            isLoading ? (
              <Skeleton height={50} />
            ) : (
              <>{data && <EditorJsRenderer data={data} />}</>
            )
            // <div dangerouslySetInnerHTML={{ __html: item?.content ?? "" }} />
          }
        </div>
        <Divider />
        <Stack spacing={1}>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              gap: 1,
              mb: 1,
            }}
          >
            <LinkIcon />
            <Typography variant="h5">
              {t(tokens.admin.modules.details.linkHeader)}
            </Typography>
          </Box>
          {item?.links.length === 0 && (
            <Typography variant="body2">
              {t(tokens.admin.modules.details.noLinks)}
            </Typography>
          )}

          {item?.links.map((link, index) => (
            <Box key={index}>
              <Stack
                direction={"row"}
                alignContent={"center"}
                alignItems={"center"}
                spacing={1}
              >
                <Box
                  sx={{
                    width: "10px",
                    height: "3px",
                    backgroundColor: "primary.main",
                  }}
                ></Box>
                <Link
                  href={link.url}
                  color="text.primary"  
                  target="_blank"
                  underline="always"
                >
                  {link.title}
                </Link>
              </Stack>
            </Box>
          ))}
        </Stack>
      </Stack>
    </>
  );
};

export default Page;
