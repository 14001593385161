import type { FC } from "react";
import { Card, CardContent, Stack } from "@mui/material";
import { useDispatch } from "src/store";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import { Organization } from "src/types/admin/organization";
import { Setting } from "src/types/setting";
import { ErrorMessage } from "src/sections/common/error-message";
import { OrganizationColorSettingsForm } from "./organization-color-settings-form";
import { OrganizationEmailSettingsForm } from "./organization-email-settings-form";
import { OrganizationWayForPaySettingsForm } from "./organization-way-for-pay-settings-form";
import { OrganizationEmailTemplateSettingsForm } from "./organization-email-templates-settings-form";

interface OrganizationSettingsFormProps {
  organization: Organization;
  settings: Setting[];
  isLoading: boolean;
  hasLoadingError: boolean;
}

export const OrganizationSettingsForm: FC<OrganizationSettingsFormProps> = (
  props
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { organization, settings, isLoading, hasLoadingError } = props;

  if (hasLoadingError) {
    return (
      <Card>
        <CardContent>
          <ErrorMessage
            text={t(
              tokens.admin.organization.details.settings.errorLoadingSettings
            )}
          />
        </CardContent>
      </Card>
    );
  }

  return (
    <>
      <Stack spacing={3}>
        <OrganizationColorSettingsForm
          organization={organization}
          settings={settings}
        />
        <OrganizationEmailSettingsForm
          organization={organization}
          settings={settings}
        />
        <OrganizationEmailTemplateSettingsForm
          organization={organization}
          settings={settings}
        />
        <OrganizationWayForPaySettingsForm
          organization={organization}
          settings={settings}
        />
      </Stack>
    </>
  );
};
