import type { AppThunk } from 'src/store';
import { fetchWrapper } from 'src/utils/fetch-wrapper';
import { api } from 'src/config';
import { slice as orgUsersSlice } from 'src/slices/admin/organization-users';
import { urlParams } from 'src/utils/url-params';
import { GetOrganizationUsersQueryParams } from 'src/types/admin/organization-users';

const getOrganizationUsers = (organizationId: string, params: GetOrganizationUsersQueryParams): AppThunk => async (dispatch): Promise<void> => {

  dispatch(orgUsersSlice.actions.setLoadingStatus(true));
  dispatch(orgUsersSlice.actions.setErrorStatus(false));
  try {
    var users = await fetchWrapper.get(`${api}/organizations/${organizationId}/users${urlParams(params)}`, {});
    dispatch(orgUsersSlice.actions.getOrganizationUsers(users));
  } catch (error) {
    dispatch(orgUsersSlice.actions.setErrorStatus(true));
  }
  dispatch(orgUsersSlice.actions.setLoadingStatus(false));
}

const addOrganizationUser = (organizationId: string, value: any, lang: string): AppThunk => async (dispatch): Promise<void> => {
  var user = await fetchWrapper.post(`${api}/organizations/${organizationId}/users`, value, undefined, lang);
  return user;
}

const updateOrganizationUser = (organizationId: string, userId: string, value: any, lang: string): AppThunk => async (dispatch): Promise<void> => {
  var user = await fetchWrapper.put(`${api}/organizations/${organizationId}/users/${userId}`, value, undefined, lang);
  return user;
}

const deleteOrganizationUser = (organizationId: string, userId: string): AppThunk => async (dispatch): Promise<void> => {
  await fetchWrapper.delete(`${api}/organizations/${organizationId}/users/${userId}`, {});
}

export const thunks = {
  getOrganizationUsers,
  deleteOrganizationUser,
  addOrganizationUser,
  updateOrganizationUser
};
