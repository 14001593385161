import {
  useState,
  type ChangeEvent,
  type FC,
  type MouseEvent,
  useCallback,
} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  SvgIcon,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { Scrollbar } from "src/components/scrollbar";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlined from "@mui/icons-material/EditOutlined";
import { useDispatch } from "src/store";
import { thunks } from "src/thunks/admin/product-plan";
import { toast } from "react-hot-toast";
import { TableLoadingRow } from "src/sections/common/table-loading-row";
import { Product } from "src/types/admin/product";
import { Currency, ProductPlan } from "src/types/product-plan";
import { useRouter } from "src/hooks/use-router";
import { UpdateProductPlanDrawer } from "./update-product-plan-drawer";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { SettingsProductPlanDrawer } from "./settings-product-plan-drawer";

interface ProductPlanListTableProps {
  product: Product;
  count: number | null;
  items: ProductPlan[] | [];
  onPageChange: (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  onRowsPerPageChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onReset: () => void;
  onUpdate: () => void;
  page: number;
  top: number;
  isLoading: boolean;
  hasError: boolean;
}

export const ProductPlanListTable: FC<ProductPlanListTableProps> = (props) => {
  const { t, i18n } = useTranslation();
  const {
    count = 0,
    product,
    items = null,
    onPageChange = () => {},
    onRowsPerPageChange,
    onReset = () => {},
    onUpdate = () => {},
    page = 0,
    top = 10,
    isLoading,
    hasError,
  } = props;
  const showPagination = !!page && !!top && !!count;

  const dispatch = useDispatch();

  const router = useRouter();

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [productPlan, setProductPlan] = useState<ProductPlan | undefined>(
    undefined
  );
  const [showUpdateProductPlanDrawer, setShowUpdateProductPlanDrawer] =
    useState<boolean>(false);
  const [showSettingsProductPlanDrawer, setShowSettingsProductPlanDrawer] =
    useState<boolean>(false);

  const handleDeleteClose = useCallback(() => {
    setProductPlan(undefined);
    setOpenDeleteDialog(false);
  }, []);

  const handleOpenDeleteDialog = useCallback((user: ProductPlan) => {
    setProductPlan(user);
    setOpenDeleteDialog(true);
  }, []);

  const deleteProductPlan = useCallback(async () => {
    try {
      if (productPlan) {
        handleDeleteClose();
        await dispatch(thunks.deleteProductPlan(product.id, productPlan.id));
        onReset();
      }
    } catch (err) {
      toast.error(t(tokens.general.formError));
    }
  }, [dispatch, handleDeleteClose, onReset, product.id, t, productPlan]);

  const getCurrency = useCallback((status: Currency) => {
    switch (status) {
      case Currency.NONE:
        return "";
      case Currency.EUR:
        return "EUR";
      case Currency.USD:
        return "USD";
      case Currency.UAH:
        return "UAH";
      case Currency.PLN:
        return "PLN";
      default:
        return "";
    }
  }, []);

  return (
    <Box sx={{ position: "relative" }}>
      <Scrollbar>
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              <TableCell>
                {
                  t(
                    tokens.admin.products.details.productPlans.table.name
                  ) as string
                }
              </TableCell>
              {/* <TableCell>
                {t(tokens.admin.products.details.users.table.phone) as string}
              </TableCell> */}
              <TableCell>
                {
                  t(
                    tokens.admin.products.details.productPlans.table.price
                  ) as string
                }
              </TableCell>
              <TableCell>
                {
                  t(
                    tokens.admin.products.details.productPlans.table.currency
                  ) as string
                }
              </TableCell>

              <TableCell
                align="right"
                sx={{
                  minWidth: 60,
                  pl: 3,
                }}
              >
                {
                  t(
                    tokens.admin.products.details.productPlans.table.actions
                  ) as string
                }
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableLoadingRow
              colSpan={4}
              isLoading={isLoading}
              hasError={hasError}
              itemsCount={items?.length ?? 0}
              noItemsText={
                t(
                  tokens.admin.products.details.productPlans.table
                    .noPaymentPlansFound
                ) as string
              }
              errorLoadingText={
                t(
                  tokens.admin.products.details.productPlans.table
                    .errorLoadingProductPlans
                ) as string
              }
            />
            {items?.map((plan) => {
              return (
                <TableRow
                  hover
                  key={plan.id}
                >
                  <TableCell>
                    <Typography variant="body1">{plan.name}</Typography>
                  </TableCell>

                  <TableCell>{plan.price}</TableCell>
                  <TableCell>{getCurrency(plan.currency)}</TableCell>
                  <TableCell align="right">
                    <Stack
                      direction="row"
                      spacing={1}
                      justifyContent="flex-end"
                    >
                      <IconButton
                        onClick={() => {
                          setProductPlan(plan);
                          setShowUpdateProductPlanDrawer(true);
                        }}
                      >
                        <SvgIcon>
                          <EditOutlined />
                        </SvgIcon>
                      </IconButton>

                      <IconButton
                        onClick={() => {
                          setProductPlan(plan);
                          setShowSettingsProductPlanDrawer(true);
                        }}
                      >
                        <SvgIcon>
                          <SettingsOutlinedIcon />
                        </SvgIcon>
                      </IconButton>
                      <IconButton onClick={() => handleOpenDeleteDialog(plan)}>
                        <SvgIcon>
                          <DeleteOutlineOutlinedIcon color="error" />
                        </SvgIcon>
                      </IconButton>
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
      {showPagination && (
        <TablePagination
          labelRowsPerPage={t(tokens.general.paginationRowsPerPage)}
          component="div"
          count={count}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          page={page - 1}
          rowsPerPage={top}
          rowsPerPageOptions={[5, 10, 25]}
        />
      )}

      <UpdateProductPlanDrawer
        isOpen={showUpdateProductPlanDrawer}
        onClose={() => setShowUpdateProductPlanDrawer(false)}
        onUpdate={() => {
          setShowUpdateProductPlanDrawer(false);
          onUpdate();
        }}
        productPlan={productPlan!}
        product={product}
      />

      <SettingsProductPlanDrawer
        isOpen={showSettingsProductPlanDrawer}
        onClose={() => setShowSettingsProductPlanDrawer(false)}
        onUpdate={() => {
          setShowSettingsProductPlanDrawer(false);
          onUpdate();
        }}
        productPlan={productPlan!}
        product={product}
      />

      <Dialog
        open={openDeleteDialog}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t(
            tokens.admin.products.details.productPlans
              .deleteProductPlanDialogTitle
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t(
              tokens.admin.products.details.productPlans
                .deleteProductPlanDialogDescription
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={deleteProductPlan}
            color="error"
          >
            {t(tokens.general.buttons.delete)}
          </Button>
          <Button onClick={handleDeleteClose}>
            {t(tokens.general.buttons.cancel)}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
