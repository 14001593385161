import type { AppThunk } from "src/store";
import { fetchWrapper } from "src/utils/fetch-wrapper";
import { api } from "src/config";
import { slice as productPlansSlice } from "src/slices/admin/product-plans";
import { GetProductPlansQueryParams } from "src/types/admin/product-plans";
import { urlParams } from "src/utils/url-params";

const getProductPlans =
  (productId: string, params: GetProductPlansQueryParams): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(productPlansSlice.actions.setLoadingStatus(true));
    dispatch(productPlansSlice.actions.setErrorStatus(false));
    try {
      var items = await fetchWrapper.get(
        `${api}/products/${productId}/product-plans${urlParams(params)}`,
        {}
      );
      dispatch(productPlansSlice.actions.getProductPlans(items));
    } catch (error) {
      dispatch(productPlansSlice.actions.setErrorStatus(true));
    }
    dispatch(productPlansSlice.actions.setLoadingStatus(false));
  };

export const thunks = {
  getProductPlans,
};
