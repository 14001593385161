import type { FC } from "react";
import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";
import { thunks } from "src/thunks/admin/product";
import { useDispatch } from "src/store";
import { useMounted } from "src/hooks/use-mounted";
import { RouterLink } from "src/components/router-link";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import { createRoute } from "react-router-url-params";
import LoadingButton from "@mui/lab/LoadingButton";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers";
import { Language, dateFormatLocales } from "src/language";
import { ImagePicker } from "src/sections/common/image-picker";
import { FileImage, FilePickerScenario } from "src/components/file-dropzone";

interface Values {
  title: string;
  description: string;
  isStandalone: boolean;
  accessMonthsCount: number;
  submit: null;
}

const initialValues: Values = {
  title: "",
  description: "",
  isStandalone: false,
  accessMonthsCount: 6,
  submit: null,
};

export const ProductCreateForm: FC = (props) => {
  const { t } = useTranslation();
  const isMounted = useMounted();
  const router = useRouter();
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState<Date | null>(moment().toDate());

  const { i18n } = useTranslation();
  const validationSchema = Yup.object({
    title: Yup.string()
      .max(255, t(tokens.general.validators.maxLength255) as string)
      .required(t(tokens.general.validators.required) as string),
    description: Yup.string().required(
      t(tokens.general.validators.required) as string
    ),
  });

  const [imageId, setImageId] = useState<string | undefined>(undefined);
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);

  const onImageUpload = useCallback(async (file: FileImage) => {
    setImageId(file.id);
    setImageUrl(file.resizedUrl);
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers): Promise<void> => {
      if (!startDate ?? values.isStandalone) return;

      const product = await dispatch(
        thunks.createProduct({
          title: values.title,
          description: values.description,
          imageId: imageId,
          isStandalone: values.isStandalone,
          accessMonthsCount: values.accessMonthsCount,
          startDate: startDate ? moment(startDate).valueOf() : undefined,
        })
      );
      if (product) {
        toast.success(t(tokens.admin.products.create.form.successMessage));
        router.push(
          createRoute(paths.admin.products.details).link({
            productId: product.id,
          })
        );
      } else {
        toast.error(t(tokens.general.formError));
        helpers.setStatus({ success: false });
        //helpers.setErrors({ submit: err.Message });
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      {...props}
    >
      <Stack spacing={4}>
        <Card>
          <CardContent>
            <Stack spacing={3}>
              <TextField
                error={!!(formik.touched.title && formik.errors.title)}
                fullWidth
                helperText={formik.touched.title && formik.errors.title}
                label={t(tokens.admin.products.create.form.title)}
                name="title"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.title}
              />

              <TextField
                error={
                  !!(formik.touched.description && formik.errors.description)
                }
                fullWidth
                multiline
                rows={5}
                helperText={
                  formik.touched.description && formik.errors.description
                }
                label={t(tokens.admin.products.create.form.description)}
                name="description"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.description}
              />

              <FormControlLabel
                control={
                  <Switch
                    name="isStandalone"
                    onChange={formik.handleChange}
                    checked={formik.values.isStandalone}
                  />
                }
                label={t(tokens.admin.products.create.form.isStandalone)}
              />

              <Stack
                alignItems="center"
                direction="row"
                spacing={2}
              >
                {!formik.values.isStandalone && (
                  <DatePicker
                    label={t(tokens.admin.products.details.form.startDate)}
                    sx={{}}
                    format={dateFormatLocales[i18n.language as Language]}
                    onChange={(date) => {
                      setStartDate(date);
                    }}
                    defaultValue={startDate}
                  />
                )}
                <TextField
                  error={
                    !!(
                      formik.touched.accessMonthsCount &&
                      formik.errors.accessMonthsCount
                    )
                  }
                  type="number"
                  rows={5}
                  helperText={
                    formik.touched.accessMonthsCount &&
                    formik.errors.accessMonthsCount
                  }
                  label={t(
                    tokens.admin.products.details.form.maxAccessMonthsCount
                  )}
                  name="accessMonthsCount"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.accessMonthsCount}
                />
              </Stack>

              <Box>
                <Typography
                  sx={{
                    mb: 2,
                  }}
                  variant="h6"
                >
                  {t(tokens.admin.products.create.form.backGroundImage.header)}
                </Typography>
                <Typography variant="body2">
                  {t(
                    tokens.admin.products.create.form.backGroundImage
                      .description
                  )}
                </Typography>
              </Box>
              <Stack spacing={3}>
                {imageUrl && (
                  <Stack spacing={3}>
                    <img
                      width={300}
                      src={imageUrl}
                    />
                    <Box>
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                          setImageId(undefined);
                          setImageUrl(undefined);
                        }}
                        size="small"
                      >
                        {t(tokens.general.buttons.delete)}
                      </Button>
                    </Box>
                  </Stack>
                )}
                <ImagePicker
                  scenario={FilePickerScenario.File}
                  onGenerate={onImageUpload}
                  onRemove={() => {
                    setImageId(undefined);
                    setImageUrl(undefined);
                  }}
                  //onUpload={onUpload}
                />
              </Stack>
            </Stack>
          </CardContent>
        </Card>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="flex-end"
          spacing={1}
        >
          <Button
            href={paths.admin.products.list}
            color="inherit"
            component={RouterLink}
          >
            {t(tokens.general.buttons.cancel)}
          </Button>
          <LoadingButton
            type="submit"
            disabled={formik.isSubmitting}
            loading={formik.isSubmitting}
            variant="contained"
          >
            {t(tokens.general.buttons.create)}
          </LoadingButton>
        </Stack>
      </Stack>
    </form>
  );
};
