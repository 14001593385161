import type { FC, ReactNode } from "react";
import { createContext, useCallback, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import type { Account } from "src/types/account";
import { Issuer } from "src/utils/auth";
import { useDispatch } from "src/store";
import { fetchWrapper } from "src/utils/fetch-wrapper";
import { api } from "src/config";
import { CONSTS } from "src/consts";
import { useTranslation } from "react-i18next";
//import { thunks as settingsThunks } from 'src/thunks/settings';
import { thunks as accountOrganization } from "src/thunks/account-organization";
import { Organization } from "src/types/account-organization";
import { jwtDecode } from "jwt-decode";
import { UserJwtToken } from "src/types/user";
import { identifyUser, initUser, userReset } from "src/hooks/use-mixpanel";

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  isImpersonated: boolean;
  hasError: boolean;
  account: Account | null;
  organization: Organization | undefined;
}

enum ActionType {
  INITIALIZE = "INITIALIZE",
  UDPATE_ACCOUNT = "UPDATE_ACCOUNT",
  ERROR = "ERROR",
  SIGN_IN = "SIGN_IN",
  IMPERSONATE = "IMPERSONATE",
  SIGN_UP = "SIGN_UP",
  SIGN_OUT = "SIGN_OUT",
  SELECT_ORGANIZATION = "SELECT_ORGANIZATION",
}

type InitializeAction = {
  type: ActionType.INITIALIZE;
  payload: {
    isAuthenticated: boolean;
    isImpersonated: boolean;
    account: Account | null;
    organization: Organization | undefined;
  };
};

type UpdateAccountAction = {
  type: ActionType.UDPATE_ACCOUNT;
  payload: {
    isAuthenticated: boolean;
    isImpersonated: boolean;
    account: Account | null;
  };
};

type ErrorAction = {
  type: ActionType.ERROR;
  payload: {
    hasError: boolean;
  };
};

type SignInAction = {
  type: ActionType.SIGN_IN;
  payload: {
    account: Account;
  };
};

type SelectOrganizationAction = {
  type: ActionType.SELECT_ORGANIZATION;
  payload: {
    account: Account;
  };
};

type ImpersonateAction = {
  type: ActionType.IMPERSONATE;
  payload: {
    account: Account;
  };
};

type SignUpAction = {
  type: ActionType.SIGN_UP;
  payload: {
    account: Account;
  };
};

type SignOutAction = {
  type: ActionType.SIGN_OUT;
};

type Action =
  | InitializeAction
  | ErrorAction
  | SignInAction
  | ImpersonateAction
  | SignUpAction
  | SelectOrganizationAction
  | UpdateAccountAction
  | SignOutAction;

type Handler = (state: State, action: any) => State;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  hasError: false,
  isImpersonated: false,
  account: null,
  organization: undefined,
};

const handlers: Record<ActionType, Handler> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, isImpersonated, account, organization } =
      action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      isImpersonated: isImpersonated,
      account: account,
      organization: organization,
    };
  },
  UPDATE_ACCOUNT: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, isImpersonated, account } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      isImpersonated: isImpersonated,
      account: account,
    };
  },
  ERROR: (state: State, action: ErrorAction): State => {
    const { hasError } = action.payload;

    return {
      ...state,
      hasError,
    };
  },
  SIGN_IN: (state: State, action: SignInAction): State => {
    const { account } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      account: account,
    };
  },
  SELECT_ORGANIZATION: (
    state: State,
    action: SelectOrganizationAction
  ): State => {
    const { account } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      account: account,
    };
  },
  IMPERSONATE: (state: State, action: ImpersonateAction): State => {
    const { account } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      isImpersonated: true,
      account: account,
    };
  },
  SIGN_UP: (state: State, action: SignUpAction): State => {
    const { account } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      account,
    };
  },
  SIGN_OUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    isImpersonated: false,
    account: null,
  }),
};

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export interface AuthContextType extends State {
  issuer: Issuer.JWT;
  initialize: () => Promise<void>;
  getUserRole: () => string | undefined;
  clearSettings: () => Promise<void>;
  confirmSignIn: (email: string, token: string) => Promise<void>;
  signIn: (email: string) => Promise<void>;
  selectOrganization: (organization: string) => Promise<void>;
  signInViaGoogle: (accessToken: string) => Promise<void>;
  impersonate: (userId: string, organizationId: string) => Promise<void>;
  stopImpersonation: () => Promise<void>;
  updateAccount: (
    firstName: string,
    lastName: string,
    profileImageId: string | undefined,
    profileImageUrl: string | undefined
  ) => Promise<void>;
  updatePassword: (
    oldPassword: string,
    newPassword: string,
    confirmationPassword: string
  ) => Promise<void>;
  forgotPassword: (email: string) => Promise<void>;
  changeLanguage: (language: string) => Promise<void>;
  resetPassword: (
    password: string,
    token: string,
    email: string
  ) => Promise<void>;
  signUp: (
    firstName: string,
    lastName: string,
    phone: string,
    email: string,
    password: string
  ) => Promise<void>;
  signOut: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextType>({
  ...initialState,
  issuer: Issuer.JWT,
  initialize: () => Promise.resolve(),
  getUserRole: () => undefined,
  clearSettings: () => Promise.resolve(),
  confirmSignIn: () => Promise.resolve(),
  signIn: () => Promise.resolve(),
  selectOrganization: () => Promise.resolve(),
  signInViaGoogle: () => Promise.resolve(),
  impersonate: () => Promise.resolve(),
  stopImpersonation: () => Promise.resolve(),
  updateAccount: () => Promise.resolve(),
  updatePassword: () => Promise.resolve(),
  forgotPassword: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  signUp: () => Promise.resolve(),
  changeLanguage: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const reduxDispatch = useDispatch();
  const { i18n } = useTranslation();

  const clearSettings = useCallback(async (): Promise<void> => {
    //localStorage.removeItem(CONSTS.PROFILE_ID_KEY);
  }, []);

  const initialize = useCallback(
    async (): Promise<void> => {
      try {
        var organization = await reduxDispatch(
          accountOrganization.getAccountOrganization()
        );
        //var profileId = localStorage.getItem(CONSTS.PROFILE_ID_KEY);
        //if (profileId) {
        //await reduxDispatch(settingsThunks.setProfileId(profileId));
        //await reduxDispatch(profileThunks.getProfile());
        //}

        var isImpersonated = true;
        var accountStr = localStorage.getItem(CONSTS.IMPERSONATED_USER_KEY);
        if (!accountStr) {
          accountStr = localStorage.getItem(CONSTS.USER_KEY);
          isImpersonated = false;
        }
        if (accountStr) {
          try {
            //var account = JSON.parse(accountStr) as Account;
            //account.organizationId
          } catch (err) {
            console.error(err);

            dispatch({
              type: ActionType.ERROR,
              payload: {
                hasError: true,
              },
            });
            return;
          }

          var account = JSON.parse(accountStr) as Account;

          if (!isImpersonated) {
            userReset();
            identifyUser(account.id);
            if (account.email) {
              initUser(
                account.email,
                account.firstName
                  ? account.firstName + " " + account.lastName
                  : account.email
              );
            }
          }

          dispatch({
            type: ActionType.INITIALIZE,
            payload: {
              isAuthenticated: true,
              isImpersonated: isImpersonated,
              organization: organization,
              account: account,
            },
          });
        } else {
          dispatch({
            type: ActionType.INITIALIZE,
            payload: {
              isAuthenticated: false,
              isImpersonated: false,
              organization: organization,
              account: null,
            },
          });
        }
      } catch (err) {
        console.error(err);

        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: false,
            isImpersonated: false,
            organization: undefined,
            account: null,
          },
        });

        dispatch({
          type: ActionType.ERROR,
          payload: {
            hasError: true,
          },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n.language, reduxDispatch]
  );

  useEffect(
    () => {
      initialize();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const confirmSignIn = useCallback(
    async (email: string, token: string): Promise<void> => {
      const account = await fetchWrapper.post(
        `${api}/account/confirm-signin`,
        { email, token },
        undefined,
        i18n.language
      );

      if (account) {
        localStorage.setItem(CONSTS.USER_KEY, JSON.stringify(account));
        if (account.langauge !== i18n.language) {
          i18n.changeLanguage(account.language);
        }
        await initialize();
        dispatch({
          type: ActionType.SIGN_IN,
          payload: {
            account: account,
          },
        });
      }
    },
    [i18n, initialize]
  );

  const selectOrganization = useCallback(
    async (organizationId: string): Promise<void> => {
      const account = await fetchWrapper.post(
        `${api}/account/organizations/${organizationId}/sign-in`,
        {},
        undefined,
        i18n.language
      );

      if (account) {
        localStorage.setItem(CONSTS.USER_KEY, JSON.stringify(account));
        if (account.langauge !== i18n.language) {
          i18n.changeLanguage(account.language);
        }
        await initialize();
        dispatch({
          type: ActionType.SELECT_ORGANIZATION,
          payload: {
            account: account,
          },
        });
      }
    },
    [i18n, initialize]
  );

  const signIn = useCallback(
    async (email: string): Promise<void> => {
      await fetchWrapper.post(
        `${api}/account/signin`,
        { email },
        undefined,
        i18n.language
      );
    },
    [i18n]
  );

  const signInViaGoogle = useCallback(
    async (accessToken: string): Promise<void> => {
      var account = await fetchWrapper.post(
        `${api}/account/google-signin`,
        { accessToken },
        undefined,
        i18n.language
      );
      if (account) {
        localStorage.setItem(CONSTS.USER_KEY, JSON.stringify(account));
        if (account.langauge !== i18n.language) {
          i18n.changeLanguage(account.language);
        }
        await initialize();
        dispatch({
          type: ActionType.SIGN_IN,
          payload: {
            account: account,
          },
        });
      }
    },
    [i18n, initialize]
  );

  const impersonate = useCallback(
    async (userId: string, organizationId: string): Promise<void> => {
      const account = await fetchWrapper.post(
        `${api}/host/impersonate`,
        { userId: userId, organizationId: organizationId },
        undefined,
        i18n.language
      );

      if (account) {
        localStorage.setItem(
          CONSTS.IMPERSONATED_USER_KEY,
          JSON.stringify(account)
        );

        await clearSettings();
        await initialize();

        dispatch({
          type: ActionType.IMPERSONATE,
          payload: {
            account: account,
          },
        });
      }
    },
    [clearSettings, i18n.language, initialize]
  );

  const updateAccount = useCallback(
    async (
      firstName: string,
      lastName: string,
      profileImageId: string | undefined,
      profileImageUrl: string | undefined
    ): Promise<void> => {
      await fetchWrapper.post(
        `${api}/account`,
        { firstName, lastName, profileImageId },
        undefined,
        i18n.language
      );

      var isImpersonated = true;
      var account = localStorage.getItem(CONSTS.IMPERSONATED_USER_KEY);
      if (!account) {
        account = localStorage.getItem(CONSTS.USER_KEY);
        isImpersonated = false;
      }

      var json = JSON.parse(account!);
      json.firstName = firstName;
      json.lastName = lastName;
      json.profileImageId = profileImageId;
      json.profileImageUrl = profileImageUrl;

      if (isImpersonated) {
        localStorage.setItem(
          CONSTS.IMPERSONATED_USER_KEY,
          JSON.stringify(json)
        );
      } else {
        localStorage.setItem(CONSTS.USER_KEY, JSON.stringify(json));
      }

      dispatch({
        type: ActionType.UDPATE_ACCOUNT,
        payload: {
          isAuthenticated: true,
          isImpersonated: isImpersonated,
          account: json,
        },
      });
    },
    [i18n.language]
  );

  const forgotPassword = useCallback(
    async (email: string): Promise<void> => {
      await fetchWrapper.post(
        `${api}/account/forgot-password`,
        { email },
        undefined,
        i18n.language
      );
    },
    [i18n.language]
  );

  const resetPassword = useCallback(
    async (password: string, token: string, email: string): Promise<void> => {
      await fetchWrapper.post(
        `${api}/account/reset-password`,
        { password, email, token },
        undefined,
        i18n.language
      );
    },
    [i18n.language]
  );

  const updatePassword = useCallback(
    async (
      currentPassword: string,
      newPassword: string,
      confirmationPassword: string
    ): Promise<void> => {
      await fetchWrapper.post(
        `${api}/account/change-password`,
        { currentPassword, newPassword, confirmationPassword },
        undefined,
        i18n.language
      );
    },
    [i18n.language]
  );

  const changeLanguage = useCallback(
    async (language: string): Promise<void> => {
      await fetchWrapper.post(`${api}/account/language`, { language });
    },
    []
  );

  const signUp = useCallback(
    async (
      firstName: string,
      lastName: string,
      phone: string,
      email: string,
      password: string
    ): Promise<void> => {
      const account = await fetchWrapper.post(
        `${api}/account/signup`,
        { firstName, lastName, phone, email, password },
        undefined,
        i18n.language
      );
      if (account) {
        localStorage.setItem(CONSTS.USER_KEY, JSON.stringify(account));
        dispatch({
          type: ActionType.SIGN_UP,
          payload: {
            account: account,
          },
        });
      }
    },
    [dispatch, i18n.language]
  );

  const signOut = useCallback(
    async (): Promise<void> => {
      userReset();
      localStorage.removeItem(CONSTS.USER_KEY);
      localStorage.removeItem(CONSTS.IMPERSONATED_USER_KEY);

      dispatch({ type: ActionType.SIGN_OUT });
      reduxDispatch({ type: "DESTROY_SESSION" });
      await clearSettings();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, reduxDispatch]
  );

  const stopImpersonation = useCallback(async (): Promise<void> => {
    localStorage.removeItem(CONSTS.IMPERSONATED_USER_KEY);
    await clearSettings();
    await initialize();
    //reduxDispatch({ type: 'DESTROY_SESSION' });
  }, [clearSettings, initialize]);

  const getUserRole = useCallback(() => {
    var accountStr = localStorage.getItem(CONSTS.IMPERSONATED_USER_KEY);
    if (!accountStr) {
      accountStr = localStorage.getItem(CONSTS.USER_KEY);
    }

    if (accountStr) {
      var account = JSON.parse(accountStr) as Account;
      if (account) {
        const token = account.accessToken;
        const decoded = jwtDecode<UserJwtToken>(token);
        if (decoded) {
          return decoded?.orgRole;
        }
      }
    }
    return "";
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        issuer: Issuer.JWT,
        initialize,
        getUserRole,
        clearSettings,
        confirmSignIn,
        signIn,
        selectOrganization,
        signInViaGoogle,
        impersonate,
        stopImpersonation,
        updateAccount,
        updatePassword,
        forgotPassword,
        resetPassword,
        signUp,
        changeLanguage,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AuthConsumer = AuthContext.Consumer;
