import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { Product } from 'src/types/panel/account-product';
import { ResultError } from 'src/types/error';

interface AccountProductState {
  isLoading: boolean;
  error: ResultError | undefined;
  item: Product | undefined;
}

type LoadProductAction = PayloadAction<Product>;
type ProductLoadingStatusAction = PayloadAction<boolean>;
type ProductErrorStatusAction = PayloadAction<ResultError | undefined>;

const initialState: AccountProductState = {
  isLoading: false,
  error: undefined,
  item: undefined
};

const reducers = {
  setLoadingStatus: (state: AccountProductState, action: ProductLoadingStatusAction): void => {
    state.isLoading = action.payload;
  },
  setLoadingErrorStatus: (state: AccountProductState, action: ProductErrorStatusAction): void => {
    state.error = action.payload;
  },
  getProduct: (state: AccountProductState, action: LoadProductAction): void => {
    state.item = action.payload;
  },

  cleanUpProduct: (state: AccountProductState): void => {
    state.item = undefined;
    state.isLoading = false;
    state.error = undefined;
  }
};

export const slice = createSlice({
  name: 'account-product',
  initialState,
  reducers
});

export const { reducer } = slice;
