import type { FC } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import { Product } from "src/types/admin/product";
import { api } from "src/config";
import { RouterLink } from "src/components/router-link";
import { createRoute } from "react-router-url-params";
import { paths } from "src/paths";
import { EmailTemplateType } from "src/types/admin/email-template";

interface EmailTemplateFormProps {
  product: Product;
}

export const EmailTemplateForm: FC<EmailTemplateFormProps> = (props) => {
  const { t, i18n } = useTranslation();

  const { product } = props;

  return (
    <Card>
      <CardHeader
        title={t(tokens.admin.products.details.settings.emailTemplate.header)}
      />
      <CardContent>
        <Stack spacing={3}>
          <Box>
            <Button
              variant="contained"
              component={RouterLink}
              href={createRoute(paths.admin.products.emailTemplate).link({
                productId: product.id,
                type: EmailTemplateType.ProductInvitation,
              })}
            >
              {t(
                tokens.admin.products.details.settings.emailTemplate
                  .editProductInvitationEmail
              )}
            </Button>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};
