export interface ClarityConfig {
  containerId?: string;
}

const warn = (...args: unknown[]) => {
  if (process.env.NODE_ENV !== 'development') {
    return;
  }

  console.warn(...args);
};

class Clarity {
  CONTAINER_ID?: string;

  initialized = false;

  configure(config: ClarityConfig) {
    if (!config.containerId) {
      warn('Clarity requires a Clarity ID to be loaded.');
      return;
    }

    this.CONTAINER_ID = config.containerId;
  }

  initialize(config: ClarityConfig) {
    if (this.initialized) {
      warn('Clarity can only be initialized once.');
      return;
    }

    // Maybe you want to load events from server side (in NextJS apps for example),
    // those can be queued.
    // SSR queued events can be loaded in the initialize script.
    // For the moment we do not implement it, but in future we might add it.

    if (!document) {
      warn('Clarity can be initialized only on client side.');
      return;
    }

    this.configure(config);

    if (!this.CONTAINER_ID) {
      return;
    }

    const script = document.createElement('script');

    script.innerHTML = `
        (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", '${this.CONTAINER_ID}');
    `;
    document.head.insertBefore(script, document.head.childNodes[0]);
  }

  // eslint-disable-next-line class-methods-use-this
  push(...args: any[]) {
    if (!window) {
      warn('Clarity push works only on client side.');
      return;
    }

    if (!(window as any).dataLayer) {
      (window as any).dataLayer = [];
    }

    (window as any).dataLayer.push(...args);
  }
}

// Singleton
export const clarity = new Clarity();
