import type { FC } from "react";
import { Card, CardContent, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Setting } from "src/types/setting";
import { ErrorMessage } from "src/sections/common/error-message";
import { tokens } from "src/locales/tokens";
import { Product } from "src/types/admin/product";
import { ProductWebHooksForm } from "./product-webhooks-form";
import { EmailTemplateForm } from "./email-template-form";

interface ProductSettingsFormProps {
  product: Product;
  organizationId: string;
  settings: Setting[];
  isLoading: boolean;
  hasLoadingError: boolean;
}

export const ProductSettingsForm: FC<ProductSettingsFormProps> = (props) => {
  const { t } = useTranslation();
  const { product, settings, hasLoadingError } = props;

  if (hasLoadingError) {
    return (
      <Card>
        <CardContent>
          <ErrorMessage
            text={t(
              tokens.admin.products.details.settings.errorLoadingSettings
            )}
          />
        </CardContent>
      </Card>
    );
  }

  return (
    <>
      <Stack spacing={3}>
        <EmailTemplateForm product={product} />
        <ProductWebHooksForm
          product={product}
          settings={settings}
        />
      </Stack>
    </>
  );
};
