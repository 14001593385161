import type { RouteObject } from "react-router";
import { Outlet } from "react-router-dom";
import { Layout as MarketingLayout } from "src/layouts/marketing";
import { Layout as AuthorizedLayout } from "src/layouts/authorized";
import { Layout as AuthorizedEmptyLayout } from "src/layouts/authorized-empty";
import HomePage from "src/pages";
import Error401Page from "src/pages/401";
import Error404Page from "src/pages/404";
import Error500Page from "src/pages/500";
import AdminProductsPage from "src/pages/admin/products/list";
import AdminProductPage from "src/pages/admin/products/details";
import AdminModulePage from "src/pages/admin/modules/details";
import AdminCreateModulePage from "src/pages/admin/modules/create";
import AdminCreateProductPage from "src/pages/admin/products/create";
import PanelProductsPage from "src/pages/panel/products/list";
import PanelProductPage from "src/pages/panel/products/details";
import PanelModulePage from "src/pages/panel/modules/details";
import PrivacyPolicyPage from "src/pages/privacy-policy";
import TermsOfUsePage from "src/pages/terms-of-use";
import PaymentSuccessPage from "src/pages/payment/success";
import PaymentErrorPage from "src/pages/payment/error";
import PaymentPage from "src/pages/payment";
import OrganizationNotFoundPage from "src/pages/organization/not-found";
import SelectOrganizationPage from "src/pages/organization/select";
import CreateOrganizationPage from "src/pages/organization/create";
import OrganizationPage from "src/pages/organization/details";
import EmailTemplatePage from "src/pages/admin/products/email-editor/index";
import { authRoutes } from "./auth";
import { lazy } from "react";

const SettingsPage = lazy(() => import("src/pages/account"));

export const routes: RouteObject[] = [
  {
    element: (
      <MarketingLayout>
        <Outlet />
      </MarketingLayout>
    ),
    children: [
      // {
      //   path: 'pricing',
      //   element: <PricingPage />
      // },
      {
        path: "payment/success",
        element: <PaymentSuccessPage />,
      },
      {
        path: "payment/error",
        element: <PaymentErrorPage />,
      },
      {
        path: "payment",
        element: <PaymentPage />,
      },
      {
        path: "privacy-policy",
        element: <PrivacyPolicyPage />,
      },
      {
        path: "terms-of-use",
        element: <TermsOfUsePage />,
      },
    ],
  },
  {
    element: (
      <AuthorizedLayout>
        <Outlet />
      </AuthorizedLayout>
    ),
    children: [
      {
        path: "/admin/products",
        element: <AdminProductsPage />,
      },
      {
        path: "/admin/products/:id",
        element: <AdminProductPage />,
      },
      {
        path: "/admin/products/create",
        element: <AdminCreateProductPage />,
      },
      {
        path: "/admin/products/:productId/modules/:moduleId",
        element: <AdminModulePage />,
      },
      {
        path: "/admin/products/:productId/modules/create",
        element: <AdminCreateModulePage />,
      },
    ],
  },
  {
    element: (
      <AuthorizedEmptyLayout>
        <Outlet />
      </AuthorizedEmptyLayout>
    ),
    children: [
      {
        path: "/admin/products/:productId/email-templates/:type",
        element: <EmailTemplatePage />,
      },
      {
        path: "/admin/organizations/:organizationId/email-templates/:type",
        element: <EmailTemplatePage />,
      },
    ],
  },
  {
    element: (
      <AuthorizedLayout>
        <Outlet />
      </AuthorizedLayout>
    ),
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: "/account/settings",
        element: <SettingsPage />,
      },
      {
        path: "/panel/products",
        element: <PanelProductsPage />,
      },
      {
        path: "/panel/products/:productId",
        element: <PanelProductPage />,
      },
      {
        path: "/panel/products/:productId/modules/:moduleId",
        element: <PanelModulePage />,
      },
      {
        path: "/panel/courses",
        element: <PanelProductsPage />,
      },
      {
        path: "/panel/courses/:productId",
        element: <PanelProductPage />,
      },
      {
        path: "/panel/courses/:productId/modules/:moduleId",
        element: <PanelModulePage />,
      },
    ],
  },
  {
    element: (
      <AuthorizedLayout>
        <Outlet />
      </AuthorizedLayout>
    ),
    children: [
      {
        path: "/admin/organizations/:organizationId",
        element: <OrganizationPage />,
      },
      {
        path: "/organization/select",
        element: <SelectOrganizationPage />,
      },
      {
        path: "/organization/create",
        element: <CreateOrganizationPage />,
      },
    ],
  },
  {
    element: (
      <MarketingLayout>
        <Outlet />
      </MarketingLayout>
    ),
    children: [
      {
        path: "/organization/not-found",
        element: <OrganizationNotFoundPage />,
      },
    ],
  },
  ...authRoutes,
  // {
  //   path: 'contact',
  //   element: <ContactPage />
  // },
  {
    path: "401",
    element: <Error401Page />,
  },
  {
    path: "404",
    element: <Error404Page />,
  },
  {
    path: "500",
    element: <Error500Page />,
  },
  {
    path: "*",
    element: <Error404Page />,
  },
];
