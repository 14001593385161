import type { PaletteColor, ThemeOptions } from '@mui/material';
import type { ColorPreset, Contrast } from '../../index';
import { createComponents } from '../../dark/create-components';
import { createPalette } from './create-palette';
import { createShadows } from '../../dark/create-shadows';

interface Config {
  paletteColor?: PaletteColor;
  contrast?: Contrast;
}

export const createOptions = (config: Config): ThemeOptions => {
  const { paletteColor, contrast } = config;
  const palette = createPalette({ paletteColor, contrast });
  const components = createComponents({ palette });
  const shadows = createShadows();

  return {
    components,
    palette,
    shadows
  };
};
