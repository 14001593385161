import {
  useState,
  type ChangeEvent,
  type FC,
  type MouseEvent,
  useCallback,
} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Link,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { RouterLink } from "src/components/router-link";
import { Scrollbar } from "src/components/scrollbar";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import { createRoute } from "react-router-url-params";
import { paths } from "src/paths";
import { Product } from "src/types/admin/product";
import moment from "moment";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useDispatch } from "src/store";
import { thunks } from "src/thunks/admin/product";
import { toast } from "react-hot-toast";
import { TableLoadingRow } from "src/sections/common/table-loading-row";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";

interface ProductListTableProps {
  count: number | null;
  items: Product[] | [];
  onPageChange: (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  onRowsPerPageChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onReset: () => void;
  page: number;
  top: number;
  isLoading: boolean;
  hasError: boolean;
}

export const ProductListTable: FC<ProductListTableProps> = (props) => {
  const { t } = useTranslation();
  const {
    count = 0,
    items = null,
    onPageChange = () => {},
    onRowsPerPageChange,
    onReset = () => {},
    page = 0,
    top = 10,
    isLoading,
    hasError,
  } = props;
  const showPagination = !!page && !!top && !!count;

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openArchiveDialog, setOpenArchiveDialog] = useState(false);
  const [productId, setProductId] = useState("");
  const dispatch = useDispatch();

  const handleCloseDeleteDialog = useCallback(() => {
    setProductId("");
    setOpenDeleteDialog(false);
  }, []);

  const handleCloseArchiveDialog = useCallback(() => {
    setProductId("");
    setOpenArchiveDialog(false);
  }, []);

  const handleOpenDeleteDialog = useCallback((id: string) => {
    setProductId(id);
    setOpenDeleteDialog(true);
  }, []);

  const handleOpenArchiveDialog = useCallback((id: string) => {
    setProductId(id);
    setOpenArchiveDialog(true);
  }, []);

  const archiveProduct = useCallback(async () => {
    try {
      if (productId) {
        handleCloseArchiveDialog();
        await dispatch(thunks.archiveProduct(productId));
        onReset();
      }
    } catch (err) {
      toast.error(t(tokens.general.formError));
    }
  }, [dispatch, handleCloseArchiveDialog, onReset, productId, t]);

  const deleteProduct = useCallback(async () => {
    try {
      if (productId) {
        handleCloseDeleteDialog();
        await dispatch(thunks.deleteProduct(productId));
        onReset();
      }
    } catch (err) {
      toast.error(t(tokens.general.formError));
    }
  }, [dispatch, handleCloseDeleteDialog, onReset, productId, t]);

  return (
    <Box sx={{ position: "relative" }}>
      <Scrollbar>
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              <TableCell>
                {t(tokens.admin.products.list.table.title) as string}
              </TableCell>
              <TableCell>
                {
                  t(
                    tokens.admin.products.list.table.accessMonthsCount
                  ) as string
                }
              </TableCell>
              <TableCell>
                {t(tokens.admin.products.list.table.usersCount) as string}
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  minWidth: 60,
                  pl: 3,
                }}
              >
                {t(tokens.admin.products.list.table.actions)}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableLoadingRow
              colSpan={4}
              isLoading={isLoading}
              hasError={hasError}
              itemsCount={items?.length ?? 0}
              noItemsText={
                t(tokens.admin.products.list.noProductsFound) as string
              }
              errorLoadingText={
                t(tokens.admin.products.list.errorLoadingProducts) as string
              }
            />
            {items?.map((product) => {
              return (
                <TableRow
                  hover
                  key={product.id}
                >
                  <TableCell>
                    <Link
                      color="inherit"
                      component={RouterLink}
                      href={createRoute(paths.admin.products.details).link({
                        productId: product.id,
                      })}
                      variant="subtitle2"
                    >
                      {product.title}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Box>
                      {product.accessMonthsCount}{" "}
                      {t(tokens.admin.products.list.table.months)}
                    </Box>
                    {!product.isStandalone && (
                      <Box>
                        <Typography variant="body2">
                          ({t(tokens.admin.products.list.table.start)} -{" "}
                          {moment(product.startDate).format("LL")})
                        </Typography>
                      </Box>
                    )}
                  </TableCell>
                  <TableCell>{product.usersCount}</TableCell>
                  <TableCell align="right">
                    <Tooltip
                      title="Archive"
                      placement="top"
                    >
                      <IconButton
                        onClick={() => handleOpenArchiveDialog(product.id)}
                      >
                        <SvgIcon>
                          <ArchiveOutlinedIcon />
                        </SvgIcon>
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title="Delete"
                      placement="top"
                    >
                      <IconButton
                        onClick={() => handleOpenDeleteDialog(product.id)}
                      >
                        <SvgIcon>
                          <DeleteOutlineOutlinedIcon color="error" />
                        </SvgIcon>
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
      {showPagination && (
        <TablePagination
          labelRowsPerPage={t(tokens.general.paginationRowsPerPage)}
          component="div"
          count={count}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          page={page - 1}
          rowsPerPage={top}
          rowsPerPageOptions={[5, 10, 25]}
        />
      )}
      <Dialog
        open={openArchiveDialog}
        onClose={handleCloseArchiveDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t(tokens.admin.products.list.archiveProductDialogTitle)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t(tokens.admin.products.list.archiveProductDialogDescription)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={archiveProduct}
            color="error"
          >
            {t(tokens.general.buttons.archive)}
          </Button>
          <Button onClick={handleCloseArchiveDialog}>
            {t(tokens.general.buttons.cancel)}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t(tokens.admin.products.list.deleteProductDialogTitle)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t(tokens.admin.products.list.deleteProductDialogDescription)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={deleteProduct}
            color="error"
          >
            {t(tokens.general.buttons.delete)}
          </Button>
          <Button onClick={handleCloseDeleteDialog}>
            {t(tokens.general.buttons.cancel)}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
