import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface VariousState {
  isLoading: boolean;
}

const initialState: VariousState = {
  isLoading: false
};

var activeLoaders = 0;

const reducers = {
  handleLoader(state: VariousState, action: PayloadAction<boolean>): void {
    action.payload ? activeLoaders++ : activeLoaders--;

    if (activeLoaders > 0 && !state.isLoading) {
      state.isLoading = true;
    }
    else if (activeLoaders === 0 && state.isLoading) {
      state.isLoading = false;
    }
  }
};

export const slice = createSlice({
  name: 'various',
  initialState,
  reducers
});

export const { reducer } = slice;
