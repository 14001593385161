import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { ProductPaymentsResponse } from 'src/types/admin/product-payments';

interface ProductPaymentsState {
  isLoading: boolean;
  hasLoadingError: boolean;
  items: ProductPaymentsResponse;
}

type GetProductPaymentsAction = PayloadAction<ProductPaymentsResponse>;
type ProductPaymentsLoadingStatusAction = PayloadAction<boolean>;
type ProductPaymentsErrorStatusAction = PayloadAction<boolean>;

const initialState: ProductPaymentsState = {
  isLoading: false,
  hasLoadingError: false,
  items: {
    items: [],
    totalCount: 0,
    top: 10,
    page: 1,
    lastItemDateTime: null
  }
};

const reducers = {
  setLoadingStatus: (state: ProductPaymentsState, action: ProductPaymentsLoadingStatusAction): void => {
    state.isLoading = action.payload;
  },
  setErrorStatus: (state: ProductPaymentsState, action: ProductPaymentsErrorStatusAction): void => {
    state.hasLoadingError = action.payload;
  },
  getProductPayments: (state: ProductPaymentsState, action: GetProductPaymentsAction): void => {
    state.items = action.payload;
  },
};

export const slice = createSlice({
  name: 'admin-product-payments',
  initialState,
  reducers
});

export const { reducer } = slice;
