import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { Organization } from 'src/types/account-organization';

interface AccountOrganizationState {
  isLoading: boolean;
  hasLoadingError: boolean;
  organization: Organization | undefined;
}

type GetAccountOrganizationAction = PayloadAction<Organization | undefined>;
type OrganizationLoadingStatusAction = PayloadAction<boolean>;
type OrganizationErrorStatusAction = PayloadAction<boolean>;

const initialState: AccountOrganizationState = {
  isLoading: false,
  hasLoadingError: false,
  organization: undefined
};

const reducers = {
  setLoadingStatus: (state: AccountOrganizationState, action: OrganizationLoadingStatusAction): void => {
    state.isLoading = action.payload;
  },
  setErrorStatus: (state: AccountOrganizationState, action: OrganizationErrorStatusAction): void => {
    state.hasLoadingError = action.payload;
  },
  getOrganization: (state: AccountOrganizationState, action: GetAccountOrganizationAction): void => {
    state.organization = action.payload;
  },
};

export const slice = createSlice({
  name: 'account-organization',
  initialState,
  reducers
});

export const { reducer } = slice;
