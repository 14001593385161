import type { AppThunk } from "src/store";
import { fetchWrapper } from "src/utils/fetch-wrapper";
import { api } from "src/config";
import { EmailTemplateType } from "src/types/admin/email-template";

const getProductEmailTemplate =
  (productId: string, type: EmailTemplateType): AppThunk =>
  async (dispatch): Promise<void> => {
    return await fetchWrapper.get(
      `${api}/products/${productId}/email-templates/${type}`,
      {}
    );
  };

const getOrganizationEmailTemplate =
  (type: EmailTemplateType): AppThunk =>
  async (dispatch): Promise<void> => {
    return await fetchWrapper.get(`${api}/email-templates/${type}`, {});
  };

const saveEmailTemplate =
  (type: EmailTemplateType, value: any): AppThunk =>
  async (dispatch): Promise<void> => {
    await fetchWrapper.put(`${api}/email-templates/${type}`, value);
  };

export const thunks = {
  getProductEmailTemplate,
  getOrganizationEmailTemplate,
  saveEmailTemplate,
};
