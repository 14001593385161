import { fetchWrapper } from "src/utils/fetch-wrapper";
import { api } from "src/config";

const getWayForPayConfiguration = (
  organizationId: string,
  productId: string,
  productPlanId: string,
  lang: string,
  userId?: string,
): Promise<any> =>
  fetchWrapper.post(
    `${api}/payment/way-for-pay/configuration`,
    {
      organizationId,
      productId,
      productPlanId,
      userId
    },
    undefined,
    lang
  );

export const thunks = {
  getConfiguration: getWayForPayConfiguration,
};
