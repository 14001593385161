import type { ChangeEvent, FC } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { Formik, Form, FieldArray, getIn } from "formik";
import {
  Button,
  Card,
  CardContent,
  Stack,
  TextField,
  FormControlLabel,
  Switch,
  IconButton,
  SvgIcon,
  Typography,
  Box,
  Divider,
  Tabs,
  Tab,
  Alert,
} from "@mui/material";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";
import { thunks } from "src/thunks/admin/module";
import { useDispatch } from "src/store";
import { RouterLink } from "src/components/router-link";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import { createRoute } from "react-router-url-params";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSearchParams } from "src/hooks/use-search-params";
import { Link } from "src/types/admin/module";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import React, { useCallback, useMemo, useState } from "react";
import { OutputData } from "@editorjs/editorjs";
import Editor from "src/components/editor/Editor";
import EditorJsRenderer from "src/components/editor/EditorJsRenderer";
import { FileImage, FilePickerScenario } from "src/components/file-dropzone";
import { ImagePicker } from "src/sections/common/image-picker";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers";
import { Language, dateFormatLocales } from "src/language";

interface Values {
  title: string;
  description: string;
  content: string;
  isActive: boolean;
  imageId: string | undefined;
  submit: null;
  links: Link[];
}

const initialValues: Values = {
  title: "",
  description: "",
  content: "",
  isActive: false,
  imageId: undefined,
  submit: null,
  links: [],
};

interface ModuleCreateFormProps {
  productId: string;
}

const TAB_DETAILS = "details";
const TAB_CONTENT = "content";

export const ModuleCreateForm: FC<ModuleCreateFormProps> = (props) => {
  const { t } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();
  const { productId } = props;
  const searchParams = useSearchParams();

  const [startDate, setStartDate] = useState<Date | null>(null);

  const tabs = useMemo(
    () => [
      {
        label: t(tokens.admin.modules.details.tabs.details) as string,
        value: TAB_DETAILS,
      },
      {
        label: t(tokens.admin.modules.details.tabs.content) as string,
        value: TAB_CONTENT,
      },
    ],
    [t]
  );

  const handleTabsChange = useCallback(
    (event: ChangeEvent<{}>, value: string): void => {
      setCurrentTab(value);
    },
    []
  );

  const [currentTab, setCurrentTab] = useState<string>(TAB_DETAILS);

  const [data, setData] = useState<OutputData | undefined>(undefined);

  const { i18n } = useTranslation();

  const [imageId, setImageId] = useState<string | undefined>(undefined);
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);

  const onImageUpload = useCallback(async (file: FileImage) => {
    setImageId(file.id);
    setImageUrl(file.resizedUrl);
  }, []);

  return (
    <Formik
      enableReinitialize={true}
      validateOnChange={false}
      initialValues={initialValues}
      onSubmit={async (values, helpers): Promise<void> => {
        if (productId) {
          const content = data ? JSON.stringify(data) : "";
          const module = await dispatch(
            thunks.createModule(productId, {
              title: values.title,
              description: values.description,
              content: content,
              imageId: imageId,
              orderRank: searchParams.get("orderRank") ?? 0,
              startDate: startDate ? moment(startDate).valueOf() : undefined,
              links: values.links,
              isActive: values.isActive,
            })
          );
          if (module) {
            toast.success(t(tokens.admin.modules.create.form.successMessage));
            router.push(
              createRoute(paths.admin.modules.details).link({
                productId: productId,
                moduleId: module.id,
              })
            );
          } else {
            toast.error(t(tokens.general.formError));
            helpers.setStatus({ success: false });
            helpers.setSubmitting(false);
          }
        }
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string()
          .max(255, t(tokens.general.validators.maxLength255) as string)
          .required(t(tokens.general.validators.required) as string),
        description: Yup.string().required(
          t(tokens.general.validators.required) as string
        ),
        links: Yup.array().of(
          Yup.object().shape({
            title: Yup.string()
              .max(255, t(tokens.general.validators.maxLength255) as string)
              .required(t(tokens.general.validators.required) as string),
            url: Yup.string()
              .max(255, t(tokens.general.validators.maxLength255) as string)
              .required(t(tokens.general.validators.required) as string),
          })
        ),
      })}
    >
      {(props) => (
        <Form>
          <Stack spacing={4}>
            <div>
              <Tabs
                indicatorColor="primary"
                onChange={handleTabsChange}
                scrollButtons="auto"
                textColor="primary"
                value={currentTab}
                variant="scrollable"
              >
                {tabs.map((tab) => (
                  <Tab
                    key={tab.value}
                    label={tab.label}
                    value={tab.value}
                  />
                ))}
              </Tabs>
              <Divider />
            </div>

            {currentTab === TAB_CONTENT && (
              <Stack spacing={3}>
                <Card
                  sx={{
                    overflow: "visible",
                  }}
                >
                  <CardContent>
                    <Stack spacing={3}>
                      <Typography variant="h6">
                        {t(tokens.admin.modules.details.form.content.editor)}
                      </Typography>
                      <Editor
                        data={data}
                        onChange={setData}
                        holder="editorjs-container"
                      />
                    </Stack>
                  </CardContent>
                </Card>
                <Card>
                  <CardContent>
                    <Stack spacing={3}>
                      <Typography variant="h6">
                        {t(tokens.admin.modules.details.form.content.preview)}
                      </Typography>

                      {data && <EditorJsRenderer data={data} />}
                      {!data && (
                        <Alert
                          variant="outlined"
                          severity="info"
                        >
                          <Typography>
                            {t(
                              tokens.admin.modules.details.form.content
                                .noPreviewContent
                            )}
                          </Typography>
                        </Alert>
                      )}
                    </Stack>
                  </CardContent>
                </Card>
              </Stack>
            )}

            {currentTab === TAB_DETAILS && (
              <Card>
                <CardContent>
                  <Stack spacing={3}>
                    <TextField
                      error={!!(props.touched.title && props.errors.title)}
                      fullWidth
                      helperText={props.touched.title && props.errors.title}
                      label={t(tokens.admin.modules.create.form.title)}
                      name="title"
                      onBlur={props.handleBlur}
                      onChange={props.handleChange}
                      value={props.values.title}
                    />

                    <TextField
                      error={
                        !!(
                          props.touched.description && props.errors.description
                        )
                      }
                      fullWidth
                      multiline
                      rows={10}
                      helperText={
                        props.touched.description && props.errors.description
                      }
                      label={t(tokens.admin.modules.create.form.description)}
                      name="description"
                      onBlur={props.handleBlur}
                      onChange={props.handleChange}
                      value={props.values.description}
                    />
                    <Stack
                      alignItems="center"
                      direction="row"
                      spacing={2}
                    >
                      <DatePicker
                        label={t(tokens.admin.modules.create.form.startDate)}
                        sx={{}}
                        format={dateFormatLocales[i18n.language as Language]}
                        onChange={(date) => {
                          setStartDate(date);
                        }}
                        defaultValue={startDate}
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            name="isActive"
                            onChange={props.handleChange}
                            checked={props.values.isActive}
                          />
                        }
                        label={t(tokens.admin.modules.create.form.active)}
                      />
                    </Stack>
                    <Box>
                      <Typography
                        sx={{
                          mb: 2,
                        }}
                        variant="h6"
                      >
                        {t(tokens.admin.modules.details.form.image.header)}
                      </Typography>
                      <Typography variant="body2">
                        {t(tokens.admin.modules.details.form.image.description)}
                      </Typography>
                    </Box>

                    <Stack spacing={3}>
                      {imageUrl && (
                        <Stack spacing={3}>
                          <img
                            width={300}
                            src={imageUrl}
                          />
                          <Box>
                            <Button
                              color="primary"
                              variant="outlined"
                              onClick={() => {
                                setImageId(undefined);
                                setImageUrl(undefined);
                              }}
                              size="small"
                            >
                              {t(tokens.general.buttons.delete)}
                            </Button>
                          </Box>
                        </Stack>
                      )}
                      <ImagePicker
                        scenario={FilePickerScenario.File}
                        onGenerate={onImageUpload}
                        onRemove={() => {
                          setImageId(undefined);
                          setImageUrl(undefined);
                        }}
                        //onUpload={onUpload}
                      />
                    </Stack>
                    <Divider />
                    <Typography variant="subtitle1">
                      {t(tokens.admin.modules.create.linkHeader)}
                    </Typography>

                    {props.values.links.length === 0 && (
                      <Typography variant="body2">
                        {t(tokens.admin.modules.create.noLinks)}
                      </Typography>
                    )}

                    <FieldArray
                      name="links"
                      render={(arrayHelpers) => (
                        <>
                          {props.values.links.map((link, index) => {
                            const title = `links[${index}].title`;
                            const touchedTitle = getIn(props.touched, title);
                            const errorTitle = getIn(props.errors, title);

                            const url = `links[${index}].url`;
                            const touchedUrl = getIn(props.touched, url);
                            const errorUrl = getIn(props.errors, url);
                            return (
                              <React.Fragment key={index}>
                                <Stack
                                  spacing={3}
                                  direction={{
                                    xs: "column",
                                    sm: "row",
                                  }}
                                  sx={{}}
                                >
                                  <TextField
                                    error={!!(touchedTitle && errorTitle)}
                                    fullWidth
                                    helperText={touchedTitle && errorTitle}
                                    label={t(
                                      tokens.admin.modules.create.form.link
                                        .title
                                    )}
                                    name={title}
                                    onBlur={props.handleBlur}
                                    onChange={props.handleChange}
                                    value={link.title}
                                  />
                                  <TextField
                                    error={!!(touchedUrl && errorUrl)}
                                    fullWidth
                                    helperText={touchedUrl && errorUrl}
                                    label={t(
                                      tokens.admin.modules.create.form.link.url
                                    )}
                                    name={url}
                                    onBlur={props.handleBlur}
                                    onChange={props.handleChange}
                                    value={link.url}
                                  />

                                  <Stack
                                    direction={"row"}
                                    alignContent={"center"}
                                    justifyContent={"center"}
                                    spacing={1}
                                  >
                                    <IconButton
                                      disabled={index === 0}
                                      onClick={() => {
                                        arrayHelpers.move(
                                          index,
                                          index !== 0 ? index - 1 : index
                                        );
                                      }}
                                    >
                                      <SvgIcon>
                                        <ArrowUpwardOutlinedIcon />
                                      </SvgIcon>
                                    </IconButton>

                                    <IconButton
                                      disabled={
                                        index + 1 === props.values.links.length
                                      }
                                      onClick={() => {
                                        arrayHelpers.move(index, index + 1);
                                      }}
                                    >
                                      <SvgIcon>
                                        <ArrowDownwardOutlinedIcon />
                                      </SvgIcon>
                                    </IconButton>

                                    <IconButton
                                      onClick={() => {
                                        arrayHelpers.remove(index);
                                      }}
                                    >
                                      <SvgIcon>
                                        <DeleteOutlinedIcon color="error" />
                                      </SvgIcon>
                                    </IconButton>
                                  </Stack>
                                </Stack>
                              </React.Fragment>
                            );
                          })}
                          <Box>
                            <Button
                              onClick={() =>
                                arrayHelpers.push({
                                  id: null,
                                  title: "",
                                  url: "",
                                })
                              }
                              variant="outlined"
                              color="primary"
                            >
                              {t(tokens.general.buttons.add)}
                            </Button>
                          </Box>
                        </>
                      )}
                    />
                  </Stack>
                </CardContent>
              </Card>
            )}
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="flex-end"
              spacing={1}
            >
              <Button
                href={createRoute(paths.admin.products.details).link({
                  productId: productId,
                })}
                color="inherit"
                component={RouterLink}
              >
                {t(tokens.general.buttons.cancel)}
              </Button>
              <LoadingButton
                type="submit"
                disabled={props.isSubmitting}
                loading={props.isSubmitting}
                variant="contained"
              >
                {t(tokens.general.buttons.create)}
              </LoadingButton>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
