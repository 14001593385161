import type { FC } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  TextField,
} from "@mui/material";
import { thunks } from "src/thunks/admin/organization-settings";
import { useDispatch } from "src/store";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import LoadingButton from "@mui/lab/LoadingButton";
import { ORGANIZATION_PAYMENT_SETTINGS } from "src/consts";
import { Organization } from "src/types/admin/organization";
import { Setting } from "src/types/setting";

interface OrganizationWayForPaySettingsFormProps {
  organization: Organization;
  settings: Setting[];
}

export const OrganizationWayForPaySettingsForm: FC<
  OrganizationWayForPaySettingsFormProps
> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { organization, settings } = props;

  const validationSchema = Yup.object({
    secretKey: Yup.string().required(
      t(tokens.general.validators.required) as string
    ),
  });

  const formik = useFormik({
    initialValues: {
      secretKey:
        settings.find(
          (s) => s.key === ORGANIZATION_PAYMENT_SETTINGS.WAY_FOR_PAY_SECRET_KEY
        )?.value || "",
      merchantAccount:
        settings.find(
          (s) =>
            s.key === ORGANIZATION_PAYMENT_SETTINGS.WAY_FOR_PAY_MERCHANT_ACCOUNT
        )?.value || "",
      merchantDomain:
        settings.find(
          (s) =>
            s.key === ORGANIZATION_PAYMENT_SETTINGS.WAY_FOR_PAY_MERCHANT_DOMAIN
        )?.value || "",
      submit: null,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, helpers): Promise<void> => {
      try {
        await dispatch(
          thunks.updateSetting(
            organization.id,
            ORGANIZATION_PAYMENT_SETTINGS.WAY_FOR_PAY_SECRET_KEY,
            values.secretKey
          )
        );
        await dispatch(
          thunks.updateSetting(
            organization.id,
            ORGANIZATION_PAYMENT_SETTINGS.WAY_FOR_PAY_MERCHANT_ACCOUNT,
            values.merchantAccount
          )
        );
        await dispatch(
          thunks.updateSetting(
            organization.id,
            ORGANIZATION_PAYMENT_SETTINGS.WAY_FOR_PAY_MERCHANT_DOMAIN,
            values.merchantDomain
          )
        );
        toast.success(
          t(tokens.admin.organization.details.settings.successMessage)
        );
      } catch (error) {
        toast.error(t(tokens.general.formError));
        helpers.setStatus({ success: false });
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      {...props}
    >
      <Stack spacing={4}>
        <Card>
          <CardHeader
            title={t(
              tokens.admin.organization.details.settings.payment.wayForPay
                .header
            )}
          />
          <CardContent>
            <Stack spacing={3}>
              <TextField
                error={!!(formik.touched.secretKey && formik.errors.secretKey)}
                fullWidth
                helperText={formik.touched.secretKey && formik.errors.secretKey}
                label={t(
                  tokens.admin.organization.details.settings.payment.wayForPay
                    .form.secretKey
                )}
                name="secretKey"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.secretKey}
              />

              <TextField
                error={
                  !!(
                    formik.touched.merchantAccount &&
                    formik.errors.merchantAccount
                  )
                }
                fullWidth
                helperText={
                  formik.touched.merchantAccount &&
                  formik.errors.merchantAccount
                }
                label={t(
                  tokens.admin.organization.details.settings.payment.wayForPay
                    .form.merchantAccount
                )}
                name="merchantAccount"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.merchantAccount}
              />

              <TextField
                error={
                  !!(
                    formik.touched.merchantDomain &&
                    formik.errors.merchantDomain
                  )
                }
                fullWidth
                helperText={
                  formik.touched.merchantDomain && formik.errors.merchantDomain
                }
                label={t(
                  tokens.admin.organization.details.settings.payment.wayForPay
                    .form.merchantDomain
                )}
                name="merchantDomain"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.merchantDomain}
              />

              <Box>
                <LoadingButton
                  type="submit"
                  disabled={formik.isSubmitting}
                  loading={formik.isSubmitting}
                  variant="contained"
                >
                  {t(tokens.general.buttons.update)}
                </LoadingButton>
              </Box>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </form>
  );
};
