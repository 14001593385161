import { useEffect, useMemo } from "react";
// import { subDays, subHours, subMinutes, subMonths } from 'date-fns';
import { Box, Link, Stack, SvgIcon, Typography } from "@mui/material";
import { Seo } from "src/components/seo";
import { usePageView } from "src/hooks/use-page-view";
// import { AccountBillingSettings } from 'src/sections/dashboard/account/account-billing-settings';
// import { AccountNotificationsSettings } from 'src/sections/dashboard/account/account-notifications-settings';
// import { AccountTeamSettings } from 'src/sections/dashboard/account/account-team-settings';
// import { AccountSecuritySettings } from 'src/sections/dashboard/account/account-security-settings';
import type { Page as PageType } from "src/types/page";
import { tokens } from "src/locales/tokens";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "src/store";
import { thunks } from "src/thunks/admin/module";
import { thunks as productThunks } from "src/thunks/admin/product";
import { useParams } from "react-router";
import { RouterLink } from "src/components/router-link";
import { paths } from "src/paths";
import ArrowLeftIcon from "@untitled-ui/icons-react/build/esm/ArrowLeft";
import { createRoute } from "react-router-url-params";
import { ModuleEditForm } from "src/sections/admin/modules/module-edit-form";
import { useRouter } from "src/hooks/use-router";
import {
  OptionItem,
  OptionMenuButton,
} from "src/sections/common/option-menu-button";
import NotFound from "src/sections/errors/not-found";
import { ERRORS } from "src/consts";
import ServerError from "src/sections/errors/server-error";
// const now = new Date();

const Page: PageType = () => {
  const { t, i18n } = useTranslation();
  const router = useRouter();
  usePageView();

  const dispatch = useDispatch();
  const { productId, moduleId } = useParams();
  const {
    item,
    error,
    isLoading: productIsLoading,
  } = useSelector((state) => state.adminModule);

  const { item: product } = useSelector((state) => state.adminProduct);

  const menuItems = useMemo<OptionItem[]>(() => {
    return [
      {
        text: t(tokens.admin.modules.details.menu.preview),
        iconName: "launch",
        onClick: () => {
          window.open(
            createRoute(paths.panel.modules.details).link({
              productId: productId,
              moduleId: moduleId,
            }),
            "_blank"
          );
        },
      },
    ];
  }, [moduleId, productId, t]);

  useEffect(() => {
    const loadModule = async () => {
      if (productId && moduleId) {
        await dispatch(thunks.getModule(productId, moduleId));
      }
    };

    const loadProduct = async () => {
      if (productId) {
        await dispatch(productThunks.getProduct(productId));
      }
    };

    loadModule();
    loadProduct();
  }, [productId, moduleId, dispatch]);

  if (error) {
    if (error.status === ERRORS.NOT_FOUND_ERROR.status) {
      return <NotFound showBackButton={false} />;
    }
    return <ServerError showBackButton={false} />;
  }

  if (!productId || !moduleId || !item || !product) {
    return null;
  }

  return (
    <>
      <Seo title={t(tokens.account.profile.title) as string} />
      <Stack
        spacing={3}
        sx={{ mb: 3 }}
      >
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
        >
          <Link
            color="text.primary"
            component={RouterLink}
            href={createRoute(paths.admin.products.details).link({
              productId: productId,
            })}
            sx={{
              alignItems: "center",
              display: "inline-flex",
            }}
            underline="hover"
          >
            <SvgIcon sx={{ mr: 1 }}>
              <ArrowLeftIcon />
            </SvgIcon>
            <Typography variant="subtitle2">
              {t(tokens.admin.modules.details.backButton)}
            </Typography>
          </Link>
          <Box>
            <OptionMenuButton items={menuItems} />
          </Box>
        </Stack>
        <Typography variant="h4">{item?.title}</Typography>
        <div>
          <ModuleEditForm
            key={item.id}
            product={product}
            module={item}
          />
        </div>
      </Stack>
    </>
  );
};

export default Page;
