import { tokens } from "../tokens";

export const uk = {
  [tokens.landing.nav.products]: "Продукти",
  [tokens.landing.nav.singIn]: "Увійти",
  [tokens.landing.nav.singUp]: "Зареєструватися",
  [tokens.landing.nav.privacyPolicy]: "Політика конфіденційності",
  [tokens.landing.nav.termsOfUse]: "Умови використання",

  [tokens.nav.dashboard]: "Панель",
  [tokens.nav.host]: "Хост",
  [tokens.nav.profile]: "Мій профіль",

  [tokens.admin.products.list.pageTitle]: "Продукти",
  [tokens.admin.products.list.pageHeader]: "Продукти",
  [tokens.admin.products.list.table.title]: "Назва",
  [tokens.admin.products.list.table.accessMonthsCount]: "Доступ",
  [tokens.admin.products.list.table.months]: "міс.",
  [tokens.admin.products.list.table.start]: "Початок",
  [tokens.admin.products.list.table.usersCount]: "Кількість користувачів",
  [tokens.admin.products.list.table.actions]: "Дії",
  [tokens.admin.products.list.archiveProductDialogTitle]: "Архівувати продукт",
  [tokens.admin.products.list.archiveProductDialogDescription]:
    "Ви впевнені, що хочете архівувати цей продукт?",
  [tokens.admin.products.list.deleteProductDialogTitle]: "Видалити продукт",
  [tokens.admin.products.list.deleteProductDialogDescription]:
    "Ви впевнені, що хочете видалити цей продукт?",
  [tokens.admin.products.list.buttons.add]: "Новий продукт",
  [tokens.admin.products.list.noProductsFound]: "Немає продуктів",
  [tokens.admin.products.list.errorLoadingProducts]:
    "Помилка завантаження продуктів",

  [tokens.admin.products.create.pageTitle]: "Новий продукт",
  [tokens.admin.products.create.pageHeader]: "Новий продукт",
  [tokens.admin.products.create.backButton]: "Повернутися до продуктів",
  [tokens.admin.products.create.form.title]: "Назва",
  [tokens.admin.products.create.form.description]: "Опис",
  [tokens.admin.products.create.form.startDate]: "Дата початку",
  [tokens.admin.products.create.form.endDate]: "Дата закінчення",
  [tokens.admin.products.create.form.isStandalone]: "Самостійний продукт",
  [tokens.admin.products.create.form.backGroundImage.header]:
    "Фонове зображення",
  [tokens.admin.products.create.form.backGroundImage.description]:
    "Рекомендації по розміру: 1120 (ширина) * 160 (висота)",
  [tokens.admin.products.create.form.successMessage]:
    "Продукт успішно створено.",

  [tokens.admin.products.details.pageTitle]: "Продукт",
  [tokens.admin.products.details.pageHeader]: "Продукт",
  [tokens.admin.products.details.backButton]: "Повернутися до продуктів",
  [tokens.admin.products.details.noModulesFound]: "Немає модулів",
  [tokens.admin.products.details.errorLoadingModules]:
    "Помилка завантаження модулів",
  [tokens.admin.products.details.buttons.addModule]: "Новий модуль",
  [tokens.admin.products.details.menu.preview]: "Попередній перегляд",
  [tokens.admin.products.details.table.title]: "Назва",
  [tokens.admin.products.details.table.active]: "Активний",
  [tokens.admin.products.details.table.productPlans]: "Плани продукту",
  [tokens.admin.products.details.table.actions]: "Дії",
  [tokens.admin.products.details.deleteModuleDialogTitle]: "Видалити модуль",
  [tokens.admin.products.details.deleteModuleDialogDescription]:
    "Ви впевнені, що хочете видалити цей модуль?",
  [tokens.admin.products.details.tabs.content]: "Контент",
  [tokens.admin.products.details.tabs.modules]: "Модулі",
  [tokens.admin.products.details.tabs.details]: "Деталі",
  [tokens.admin.products.details.tabs.users]: "Користувачі",
  [tokens.admin.products.details.tabs.payments]: "Платежі",
  [tokens.admin.products.details.tabs.settings]: "Налаштування",
  [tokens.admin.products.details.tabs.productPlans]: "Тарифні плани",
  [tokens.admin.products.details.form.title]: "Назва",
  [tokens.admin.products.details.form.description]: "Опис",
  [tokens.admin.products.details.form.startDate]: "Дата початку",
  [tokens.admin.products.details.form.maxAccessMonthsCount]: "Доступ (місяців)",
  [tokens.admin.products.details.form.content.editor]: "Редактор контенту",
  [tokens.admin.products.details.form.content.preview]:
    "Попередній перегляд контенту",
  [tokens.admin.products.details.form.content.noPreviewContent]:
    "Немає контенту для попереднього перегляду",
  [tokens.admin.products.details.form.backGroundImage.header]:
    "Фонове зображення",
  [tokens.admin.products.details.form.backGroundImage.description]:
    "Рекомендації по розміру: 1120 (ширина) * 160 (висота)",
  [tokens.admin.products.details.form.successMessage]:
    "Продукт успішно оновлено.",
  [tokens.admin.products.details.productPlans.buttons.newProductPlan]:
    "Новий план продукту",
  [tokens.admin.products.details.productPlans.table.name]: "Назва",
  [tokens.admin.products.details.productPlans.table.price]: "Ціна",
  [tokens.admin.products.details.productPlans.table.currency]: "Валюта",
  [tokens.admin.products.details.productPlans.table.actions]: "Дії",
  [tokens.admin.products.details.productPlans.table.noPaymentPlansFound]:
    "Немає планів продукту",
  [tokens.admin.products.details.productPlans.table.errorLoadingProductPlans]:
    "Помилка завантаження планів продукту",
  [tokens.admin.products.details.productPlans.deleteProductPlanDialogTitle]:
    "Видалити план продукту",
  [tokens.admin.products.details.productPlans
    .deleteProductPlanDialogDescription]:
    "Ви впевнені, що хочете видалити цей план продукту?",
  [tokens.admin.products.details.productPlans.form.name]: "Назва",
  [tokens.admin.products.details.productPlans.form.url]: "Посилання",
  [tokens.admin.products.details.productPlans.form.price]: "Ціна",
  [tokens.admin.products.details.productPlans.form.currency]: "Валюта",
  [tokens.admin.products.details.productPlans.form.params]: "Параметри",
  [tokens.admin.products.details.productPlans.form.successMessage]:
    "План продукту успішно збережено.",
  [tokens.admin.products.details.productPlans.form.updateTitle]: "Оновити план",
  [tokens.admin.products.details.productPlans.form.createTitle]: "Новий план",
  [tokens.admin.products.details.productPlans.form.settingsTitle]:
    "Налаштування",
  [tokens.admin.products.details.users.table.name]: "Ім'я",
  [tokens.admin.products.details.users.table.phone]: "Телефон",
  [tokens.admin.products.details.users.table.date]: "Дата",
  [tokens.admin.products.details.users.table.endDate]: "Дата закінчення",
  [tokens.admin.products.details.users.table.paymentStatus]: "Статус оплати",
  [tokens.admin.products.details.users.table.actions]: "Дії",
  [tokens.admin.products.details.users.table.type]: "Тип",
  [tokens.admin.products.details.users.table.productPlan]: "План продукту",
  [tokens.admin.products.details.users.table.manualType]: "Вручну",
  [tokens.admin.products.details.users.table.autoType]: "Автоматично",
  [tokens.admin.products.details.users.table.noUsersFound]:
    "Немає користувачів",
  [tokens.admin.products.details.users.table.errorLoadingUsers]:
    "Помилка завантаження користувачів",
  [tokens.admin.products.details.users.buttons.newUser]: "Новий користувач",
  [tokens.admin.products.details.users.form.firstName]: "Ім'я",
  [tokens.admin.products.details.users.form.lastName]: "Прізвище",
  [tokens.admin.products.details.users.form.email]: "Email",
  [tokens.admin.products.details.users.form.phone]: "Телефон",
  [tokens.admin.products.details.users.form.productPlan]: "План продукту",
  [tokens.admin.products.details.users.form.endDate]: "Дата закінчення",
  [tokens.admin.products.details.users.form.createTitle]: "Додати користувача",
  [tokens.admin.products.details.users.form.updateTitle]: "Оновити користувача",
  [tokens.admin.products.details.users.form.successMessage]:
    "Користувача успішно додано.",
  [tokens.admin.products.details.users.form.errors.userAlreadyExists]:
    "Користувач вже доданий до цього продукту.",
  [tokens.admin.products.details.users.deleteUserDialogTitle]:
    "Видалити користувача",
  [tokens.admin.products.details.users.deleteUserDialogDescription]:
    "Ви впевнені, що хочете видалити цього користувача?",
  [tokens.admin.products.details.users.sendInvitationDialogTitle]:
    "Надіслати запрошення",
  [tokens.admin.products.details.users.sendInvitationDialogDescription]:
    "Ви впевнені, що хочете надіслати запрошення цьому користувачу?",
  [tokens.admin.products.details.users.invitationSentSuccessMessage]:
    "Запрошення надіслано",
  [tokens.admin.products.details.payments.table.orderReference]:
    "Номер замовлення",
  [tokens.admin.products.details.payments.table.user]: "Користувач",
  [tokens.admin.products.details.payments.table.amount]: "Сума",
  [tokens.admin.products.details.payments.table.productPlan]: "План продукту",
  [tokens.admin.products.details.payments.table.date]: "Дата",
  [tokens.admin.products.details.payments.table.paymentStatus]: "Статус оплати",
  [tokens.admin.products.details.payments.table.noPaymentsFound]:
    "Немає платежів",
  [tokens.admin.products.details.payments.table.errorLoadingPayments]:
    "Помилка завантаження платежів",
  [tokens.admin.products.details.settings.successMessage]:
    "Налаштування успішно оновлені.",
  [tokens.admin.products.details.settings.errorLoadingSettings]:
    "Помилка завантаження налаштувань",
  [tokens.admin.products.details.settings.wayForPay.paymentButton.header]:
    "Кнопка оплати WayForPay",
  [tokens.admin.products.details.settings.wayForPay.paymentButton.serviceUrl]:
    "Service URL",
  [tokens.admin.products.details.settings.wayForPay.paymentButton.returnUrl]:
    "Return URL",
  [tokens.admin.products.details.settings.webHooks.header]: "Webhooks",
  [tokens.admin.products.details.settings.webHooks.form.productInvitationHook]:
    "Product invitation webhook",
  [tokens.admin.products.details.settings.webHooks.form.params]: "Параметри:",
  [tokens.admin.products.details.settings.webHooks.form.url]: "URL",
  [tokens.admin.products.details.settings.emailTemplate.header]:
    "Шаблон email із запрошенням до продукту",
  [tokens.admin.products.details.settings.emailTemplate
    .editProductInvitationEmail]: "Редагувати шаблон",
  [tokens.admin.products.details.settings.productPlans.header]:
    "Плани продукту",
  [tokens.admin.products.details.settings.productPlans.form.name]: "Назва",
  [tokens.admin.products.details.settings.productPlans.noPlans]: "Немає планів",
  [tokens.admin.products.details.settings.productPlans.form.successMessage]:
    "Плани успішно збережені.",

  [tokens.admin.modules.create.pageTitle]: "Новий модуль",
  [tokens.admin.modules.create.pageHeader]: "Новий модуль",
  [tokens.admin.modules.create.backButton]: "Повернутися до продукту",
  [tokens.admin.modules.create.form.title]: "Назва",
  [tokens.admin.modules.create.form.description]: "Опис",
  [tokens.admin.modules.create.form.active]: "Активний",
  [tokens.admin.modules.create.form.startDate]: "Дата відкриття",
  [tokens.admin.modules.create.form.link.title]: "Назва посилання",
  [tokens.admin.modules.create.form.link.url]: "URL посилання",
  [tokens.admin.modules.create.form.backGroundImage.header]: "Зображення",
  [tokens.admin.modules.create.form.backGroundImage.description]:
    "Рекомендації по розміру: 1120 (ширина) * 160 (висота)",
  [tokens.admin.modules.create.form.content]: "Контент",
  [tokens.admin.modules.create.form.successMessage]: "Модуль успішно створено.",
  [tokens.admin.modules.create.linkHeader]: "Посилання",
  [tokens.admin.modules.create.noLinks]: "Немає посилань",

  [tokens.admin.modules.details.pageTitle]: "Модуль",
  [tokens.admin.modules.details.pageHeader]: "Модуль",
  [tokens.admin.modules.details.backButton]: "Повернутися до модулів",
  [tokens.admin.modules.details.menu.preview]: "Попередній перегляд",
  [tokens.admin.modules.details.form.title]: "Назва",
  [tokens.admin.modules.details.form.description]: "Опис",
  [tokens.admin.modules.details.form.active]: "Активний",
  [tokens.admin.modules.details.form.startDate]: "Дата відкриття",
  [tokens.admin.modules.details.form.link.title]: "Назва посилання",
  [tokens.admin.modules.details.form.link.url]: "URL посилання",
  [tokens.admin.modules.details.form.productPlans.header]:
    "Доступний в планах продукту",
  [tokens.admin.modules.details.form.productPlans.name]: "Назва",
  [tokens.admin.modules.details.form.image.header]: "Зображення",
  [tokens.admin.modules.details.form.image.description]:
    "Рекомендації по розміру: 1120 (ширина) * 160 (висота)",
  [tokens.admin.modules.details.form.content.editor]: "Редактор контенту",
  [tokens.admin.modules.details.form.content.preview]:
    "Попередній перегляд контенту",
  [tokens.admin.modules.details.form.content.noPreviewContent]:
    "Немає контенту для попереднього перегляду",
  [tokens.admin.modules.details.form.successMessage]:
    "Модуль успішно оновлено.",
  [tokens.admin.modules.details.linkHeader]: "Додаткові файли",
  [tokens.admin.modules.details.noLinks]: "Немає посилань",
  [tokens.admin.modules.details.tabs.details]: "Деталі",
  [tokens.admin.modules.details.tabs.content]: "Контент",

  [tokens.admin.organization.details.pageTitle]: "Організація",
  [tokens.admin.organization.details.pageHeader]: "Організація",
  [tokens.admin.organization.details.tabs.details]: "Деталі",
  [tokens.admin.organization.details.tabs.users]: "Користувачі",
  [tokens.admin.organization.details.tabs.settings]: "Налаштування",
  [tokens.admin.organization.details.form.domain]: "Domain *",
  [tokens.admin.organization.details.form.name]: "Назва *",
  [tokens.admin.organization.details.form.logoImage.header]: "Логотип",
  [tokens.admin.organization.details.form.logoImage.description]:
    "Рекомендації по розміру: 200 (ширина) * 200 (висота)",
  [tokens.admin.organization.details.form.successMessage]:
    "Організацію успішно оновлено.",
  [tokens.admin.organization.details.form.domainAlreadyUsed]:
    "Цей домен вже використовується.",
  [tokens.admin.organization.details.users.table.name]: "Ім'я та прізвище",
  [tokens.admin.organization.details.users.table.email]: "Email",
  [tokens.admin.organization.details.users.table.role]: "Роль",
  [tokens.admin.organization.details.users.table.actions]: "Дії",
  [tokens.admin.organization.details.users.table.errorLoadingUsers]:
    "Помилка завантаження користувачів",
  [tokens.admin.organization.details.users.table.noUsersFound]:
    "Немає користувачів",
  [tokens.admin.organization.details.users.buttons.newUser]: "Новий користувач",
  [tokens.admin.organization.details.users.form.firstName]: "Ім'я",
  [tokens.admin.organization.details.users.form.lastName]: "Прізвище",
  [tokens.admin.organization.details.users.form.role]: "Роль",
  [tokens.admin.organization.details.users.form.email]: "Email",
  [tokens.admin.organization.details.users.form.createTitle]:
    "Додати користувача",
  [tokens.admin.organization.details.users.form.updateTitle]:
    "Оновити користувача",
  [tokens.admin.organization.details.users.form.updatedSuccessMessage]:
    "Користувач успішно оновлений.",
  [tokens.admin.organization.details.users.form.createdSuccessMessage]:
    "Користувач успішно доданий.",
  [tokens.admin.organization.details.users.form.errors.userAlreadyExists]:
    "Користувач вже доданий до цієї організації.",
  [tokens.admin.organization.details.users.deleteUserDialogTitle]:
    "Видалити користувача",
  [tokens.admin.organization.details.users.deleteUserDialogDescription]:
    "Ви впевнені, що хочете видалити цього користувача?",
  [tokens.admin.organization.details.settings.errorLoadingSettings]:
    "Помилка завантаження налаштувань",
  [tokens.admin.organization.details.settings.successMessage]:
    "Налаштування успішно оновлені.",
  [tokens.admin.organization.details.settings.colors.header]: "Кольори",
  [tokens.admin.organization.details.settings.colors.form.mainColor]:
    "Основний колір",
  [tokens.admin.organization.details.settings.colors.form.darkColor]:
    "Темний колір",
  [tokens.admin.organization.details.settings.colors.form.darkestColor]:
    "Найтемніший колір",
  [tokens.admin.organization.details.settings.colors.form.lightColor]:
    "Світлий колір",
  [tokens.admin.organization.details.settings.colors.form.lightestColor]:
    "Найсвітліший колір",
  [tokens.admin.organization.details.settings.colors.form.contrastTextColor]:
    "Контрастний колір тексту",

  [tokens.admin.organization.details.settings.email.header]:
    "Email Configuration",
  [tokens.admin.organization.details.settings.email.form.isEnabled]: "Включено",
  [tokens.admin.organization.details.settings.email.form.email]: "From email",
  [tokens.admin.organization.details.settings.email.form.name]: "From name",
  [tokens.admin.organization.details.settings.email.form.host]: "Host",
  [tokens.admin.organization.details.settings.email.form.port]: "Port",
  [tokens.admin.organization.details.settings.email.form.username]: "Username",
  [tokens.admin.organization.details.settings.email.form.password]: "Password",
  [tokens.admin.organization.details.settings.emailTemplates.header]:
    "Email шаблони",
  [tokens.admin.organization.details.settings.emailTemplates.signInEmail]:
    "Редагування email-у для входу",
  [tokens.admin.organization.details.settings.payment.wayForPay.header]:
    "WayForPay",
  [tokens.admin.organization.details.settings.payment.wayForPay.form.secretKey]:
    "Secret key",
  [tokens.admin.organization.details.settings.payment.wayForPay.form
    .merchantAccount]: "Merchant account",
  [tokens.admin.organization.details.settings.payment.wayForPay.form
    .merchantDomain]: "Merchant domain",

  [tokens.panel.products.details.backButton]: "Повернутися до продуктів",
  [tokens.panel.products.details.menu.edit]: "Редагувати",
  [tokens.panel.products.details.errorProductLoading]:
    "Помилка завантаження продукту",
  [tokens.panel.products.details.errorModulesLoading]:
    "Помилка завантаження модулів",
  [tokens.panel.products.details.noModulesFound]: "Немає модулів",
  [tokens.panel.products.details.startDate]: "Дата відкриття",

  [tokens.panel.products.list.pageTitle]: "Продукти",
  [tokens.panel.products.list.errorProductsLoading]:
    "Помилка завантаження продуктів",
  [tokens.panel.products.list.noProductsFound]: "Немає продуктів",
  [tokens.panel.products.list.status.open]: "Відкритий",
  [tokens.panel.products.list.status.closed]: "Закритий",

  [tokens.panel.modules.details.backButton]: "Повернутися до модулів",
  [tokens.panel.modules.details.linkHeader]: "Додаткові матеріали",
  [tokens.panel.modules.details.noLinks]: "Додаткові матеріали відсутні",
  [tokens.panel.modules.details.menu.edit]: "Редагувати",
  [tokens.panel.modules.details.loadingError]: "Помилка завантаження модуля",

  [tokens.account.signUp.title]: "Створити обліковий запис",
  [tokens.account.signUp.header]: "Створити обліковий запис",
  [tokens.account.signUp.form.firstName]: "Ім'я *",
  [tokens.account.signUp.form.lastName]: "Прізвище *",
  [tokens.account.signUp.form.phone]: "Номер телефону *",
  [tokens.account.signUp.form.email]: "Адреса електронної пошти *",
  [tokens.account.signUp.form.password]: "Пароль *",
  [tokens.account.signUp.form.createAccountButton]: "Створити обліковий запис",
  [tokens.account.signUp.form.termsOfUseLink]: "умови використання",
  [tokens.account.signUp.form.privacyPolicyLink]: "політику конфіденційності",
  [tokens.account.signUp.form.andSeparator]: "та",
  [tokens.account.signUp.form.agreementText]: "Я прочитав/ла",
  [tokens.account.signUp.form.signinLink]: "Увійти",
  [tokens.account.signUp.form.hasAccount]: "У вас вже є обліковий запис?",

  [tokens.account.signIn.title]: "Увійти",
  [tokens.account.signIn.header]: "Увійти",
  [tokens.account.signIn.description]:
    "Введіть ваш email і ми надішлемо вам посилання для входу.",
  [tokens.account.signIn.noAccount]: "Немає облікового запису?",
  [tokens.account.signIn.createAccountLink]: "Створити обліковий запис",
  [tokens.account.signIn.form.email]: "Адреса електронної пошти *",
  [tokens.account.signIn.form.password]: "Пароль *",
  [tokens.account.signIn.form.signInButton]: "Увійти",
  [tokens.account.signIn.form.signInViaGoogleButton]: "Увійти через Google",
  [tokens.account.signIn.form.orSeparator]: "або",
  [tokens.account.signIn.form.forgotPasswordLink]: "Забули пароль?",
  [tokens.account.signIn.form.termsOfUseLink]: "умови використання",
  [tokens.account.signIn.form.privacyPolicyLink]: "політику конфіденційності",
  [tokens.account.signIn.form.andSeparator]: "та",
  [tokens.account.signIn.form.agreementText]: "Я прочитав/ла",

  [tokens.account.emailSent.title]: "Лист надіслано",
  [tokens.account.emailSent.header]: "Лист надіслано",
  [tokens.account.emailSent.message]:
    "Лист з посиланням для входу надіслано на ваш e-mail. Будь ласка, перевірте свою поштову скриньку.",

  [tokens.account.confirmSignIn.title]: "Підтвердити вхід",
  [tokens.account.confirmSignIn.expiredToken]:
    "Термін дії посилання закінчився. Спробуйте увійти знову",

  [tokens.account.resetPassword.title]: "Скинути пароль",
  [tokens.account.resetPassword.header]: "Скинути пароль",
  [tokens.account.resetPassword.signInLink]: "Увійти",
  [tokens.account.resetPassword.form.passwordChangedMessage]:
    "Ваш пароль успішно змінено. Перейдіть на сторінку входу.",
  [tokens.account.resetPassword.form.password]: "Пароль *",
  [tokens.account.resetPassword.form.confirmPassword]: "Підтвердити пароль *",
  [tokens.account.resetPassword.form.resetButton]: "Скинути пароль",
  [tokens.account.resetPassword.form.linkInvalidError]:
    "Недійсне посилання. Спробуйте скинути пароль знову.",
  [tokens.account.resetPassword.form.linkInvalidOrExpiredError]:
    "Посилання для відновлення пароля минуло термін дії або недійсне. Спробуйте скинути пароль знову.",

  [tokens.account.forgotPassword.title]: "Забули пароль",
  [tokens.account.forgotPassword.header]: "Забули пароль",
  [tokens.account.forgotPassword.signInLink]: "Увійти",
  [tokens.account.forgotPassword.form.email]: "Адреса електронної пошти *",
  [tokens.account.forgotPassword.form.emailSentMessage]:
    "Лист з посиланням на відновлення паролю надісланий. Будь ласка, перевірте свою поштову скриньку.",
  [tokens.account.forgotPassword.form.resetButton]: "Відновити пароль",

  [tokens.account.popover.settings]: "Налаштування",
  [tokens.account.popover.impersonate]: "Увійти як користувач",
  [tokens.account.popover.stopImpersonation]: "Зупинити вхід під користувачем",
  [tokens.account.popover.signOut]: "Вийти",
  [tokens.account.popover.creator]: "Автор Продуктів",
  [tokens.account.popover.organization]: "Організація",

  [tokens.account.colorScheme.light]: "Світла",
  [tokens.account.colorScheme.dark]: "Темна",
  [tokens.account.colorScheme.scheme]: "Кольорова схема",

  [tokens.account.profile.title]: "Обліковий запис",
  [tokens.account.profile.header]: "Обліковий запис",
  [tokens.account.profile.passwordHeader]: "Пароль",
  [tokens.account.profile.accountDetailsHeader]: "Деталі облікового запису",
  [tokens.account.profile.accountProfileImage]: "Зображення профілю",
  [tokens.account.profile.tab.general]: "Деталі",
  [tokens.account.profile.form.firstName]: "Ім'я *",
  [tokens.account.profile.form.lastName]: "Прізвище *",
  [tokens.account.profile.form.email]: "Адреса електронної пошти *",
  [tokens.account.profile.form.currentPassword]: "Поточний пароль *",
  [tokens.account.profile.form.newPassword]: "Новий пароль *",
  [tokens.account.profile.form.confirmPassword]: "Підтвердити пароль *",
  [tokens.account.profile.form.passwordUpdatedMessage]: "Ваш пароль оновлено.",
  [tokens.account.profile.form.accountUpdatedMessage]:
    "Ваш обліковий запис оновлено.",

  [tokens.account.impersonate.form.header]: "Увійти як користувач",
  [tokens.account.impersonate.form.searchForUser]: "Користувач",
  [tokens.account.impersonate.form.searchForOrganization]: "Організація",
  [tokens.account.impersonate.form.buttons.impersonate]: "Увійти",

  [tokens.payment.statuses.approved]: "Сплачено",
  [tokens.payment.statuses.pending]: "Очікується підтвердження",
  [tokens.payment.statuses.declined]: "Відхилено",
  [tokens.payment.statuses.unknown]: "Невідомий",
  [tokens.payment.statuses.refunded]: "Повернено",
  [tokens.payment.statuses.noPayment]: "Оплата відсутня",
  [tokens.payment.success.header]: "Деталі платежу",
  [tokens.payment.success.title]: "Деталі платежу",
  [tokens.payment.success.product]: "Продукт",
  [tokens.payment.success.email]: "Email",
  [tokens.payment.success.status]: "Статус",
  [tokens.payment.success.orderReference]: "Номер замовлення",
  [tokens.payment.success.messages.approved]:
    "Посилання на продукт надіслано на ваш email.",
  [tokens.payment.success.messages.pending]:
    "Посилання на продукт буде надіслано на ваш email після підтвердження платежу.",
  [tokens.payment.success.messages.declined]:
    "Платіж відхилено. Спробуйте знову або зв'яжіться з нами",
  [tokens.payment.success.messages.unknown]:
    "Статус платежу невідомий. Зв’яжіться з нами.",

  [tokens.payment.error.header]: "Помилка платежу",
  [tokens.payment.error.title]: "Помилка платежу",
  [tokens.payment.error.message]:
    "Під час обробки вашого платежу сталася помилка. Спробуйте знову.<br/><br/>Якщо проблема не зникає, зв’яжіться з нами.",
  [tokens.payment.error.orderReference]: "Номер замовлення",

  [tokens.profile.title]: "Профіль",

  [tokens.organization.notFound.title]: "Організацію не знайдено",
  [tokens.organization.notFound.header]: "Організацію не знайдено",
  [tokens.organization.notFound.message]:
    "Організацію, яку ви шукаєте, не існує або була видалена.",

  [tokens.organization.select.title]: "Вибрати організацію",
  [tokens.organization.select.header]: "Вибрати організацію",
  [tokens.organization.select.newOrganization]: "Нова організація",
  [tokens.organization.select.errorMessage]:
    "Помилка вибору організації. Спробуйте вийти та увійти знову.",
  [tokens.organization.select.noOrganizations]: "Немає організацій",

  [tokens.organization.create.title]: "Нова організація",
  [tokens.organization.create.header]: "Нова організація",
  [tokens.organization.create.form.name]: "Назва *",
  [tokens.organization.create.form.domain]: "Domain *",
  [tokens.organization.create.form.logoImage.header]: "Логотип",
  [tokens.organization.create.form.logoImage.description]:
    "Рекомендації по розміру: 200 (ширина) * 200 (висота)",
  [tokens.organization.create.form.successMessage]:
    "Організацію успішно створено.",
  [tokens.organization.create.form.domainAlreadyUsed]:
    "Домен вже використовується",

  [tokens.contact.title]: "Контакти",
  [tokens.contact.header]: "Зв'яжіться з нами",
  [tokens.contact.subHeader]:
    "Наші дружелюбні експерти покажуть вам FlitEdu та налаштують його під ваші потреби.",
  [tokens.contact.form.firstName]: "Ім'я *",
  [tokens.contact.form.lastName]: "Прізвище *",
  [tokens.contact.form.phone]: "Номер телефону *",
  [tokens.contact.form.email]: "Адреса електронної пошти *",
  [tokens.contact.form.message]: "Повідомлення *",
  [tokens.contact.form.sendButton]: "Надіслати повідомлення",
  [tokens.contact.form.agreementText]: "Надсилаючи це, ви погоджуєтеся з",
  [tokens.contact.form.termsOfUseLink]: "Умовами використання",
  [tokens.contact.form.privacyPolicyLink]: "Політикою конфіденційності",
  [tokens.contact.form.andSeparator]: "та",
  [tokens.contact.form.successTitle]: "Запит надіслано",
  [tokens.contact.form.successMessage1]:
    "Ми зв'яжемося з вами найближчим часом.",
  [tokens.contact.form.successMessage2]: "Дякуємо за інтерес до FlitEdu!",

  [tokens.errors.serverError.title]: "Сервіс недоступний",
  [tokens.errors.serverError.header]: "Сервіс недоступний",
  [tokens.errors.serverError.message]:
    "Можливо, триває технічне обслуговування або тимчасове перевантаження.",
  [tokens.errors.notFound.title]: "Сторінку не знайдено",
  [tokens.errors.notFound.header]: "Сторінку не знайдено",
  [tokens.errors.notFound.message]:
    "Сторінка, яку ви шукаєте, не існує або була видалена.",
  [tokens.errors.notAuthorized.title]: "Неавторизований доступ",
  [tokens.errors.notAuthorized.header]: "Неавторизований доступ",
  [tokens.errors.notAuthorized.message]:
    "У вас недостатньо прав для доступу до цієї сторінки.",

  [tokens.general.languageChanged]: "Мову змінено",
  [tokens.general.language]: "Мова",
  [tokens.general.newAppVersion.description]: "Доступна нова версія додатку.",
  [tokens.general.newAppVersion.action]: "Оновити",

  [tokens.general.userRoles.none]: "Немає ролі",
  [tokens.general.userRoles.admin]: "Адміністратор",
  [tokens.general.userRoles.user]: "Користувач",

  [tokens.general.filters.emptyOptions]: "Немає опцій",
  [tokens.general.paginationRowsPerPage]: "Записів:",
  [tokens.general.formError]: "Щось пішло не так!",
  [tokens.general.loading]: "Завантаження...",

  [tokens.general.validators.user.conflict]:
    "Користувач із таким email вже доданий",
  [tokens.general.validators.employee.conflict]: "Співробітник вже доданий",
  [tokens.general.validators.employee.accountConnected]:
    "Даний email вже приєднано до іншого співробітника",
  [tokens.general.validators.role.required]: "Обов'язкове поле",
  [tokens.general.validators.role.min]: "Потрібна принаймні одна роль",
  [tokens.general.validators.password.minLength]:
    "Пароль має бути мінімум 6 символів",
  [tokens.general.validators.passwordConfirm.notMatch]: "Паролі не співпадають",
  [tokens.general.validators.policy.required]: "Обов'язкове поле",
  [tokens.general.validators.invalidEmail]: "Невірний формат email",
  [tokens.general.validators.roleRequired]: "Обов'язкове поле",
  [tokens.general.validators.maxLength255]:
    "Поле має містити не більше 255 символів",
  [tokens.general.validators.maxLength50]:
    "Поле має містити не більше 50 символів",
  [tokens.general.validators.required]: "Обов'язкове поле",
  [tokens.general.validators.oneItemRequired]:
    "Обов'язково потрібен хоча б один елемент",

  [tokens.general.filepicker.titleHighlight]: "Натисніть, щоб завантажити",
  [tokens.general.filepicker.titleNormal]: "або перетягніть",
  [tokens.general.filepicker.imageExtensions]: "(JPG or PNG), 5MB макс.",
  [tokens.general.filepicker.excelExtensions]: "(XLS або XLSX)",
  [tokens.general.filepicker.fileSizeError]: "Файл не може бути більше 5MB",
  [tokens.general.filepicker.deleteImage]: "Видалити",
  [tokens.general.filepicker.imageUploaded]: "Зображення завантажено",
  [tokens.general.editor.tools.bold]: "Жирний",
  [tokens.general.editor.tools.italic]: "Курсив",
  [tokens.general.editor.tools.paragraph]: "Параграф",
  [tokens.general.editor.tools.heading]: "Заголовок",
  [tokens.general.editor.tools.list]: "Список",
  [tokens.general.editor.tools.rawHtml]: "HTML",
  [tokens.general.editor.tools.link]: "Посилання",
  [tokens.general.editor.tools.timeCode]: "Таймкод",
  [tokens.general.editor.blockTunes.delete]: "Видалити",
  [tokens.general.editor.blockTunes.moveUp]: "Вгору",
  [tokens.general.editor.blockTunes.moveDown]: "Вниз",
  [tokens.general.editor.showTimeCodes]: "Показати таймкоди",
  [tokens.general.buttons.ok]: "Ok",
  [tokens.general.buttons.delete]: "Видалити",
  [tokens.general.buttons.edit]: "Редагувати",
  [tokens.general.buttons.add]: "Додати",
  [tokens.general.buttons.copy]: "Копіювати",
  [tokens.general.buttons.invite]: "Запросити",
  [tokens.general.buttons.send]: "Надіслати",
  [tokens.general.buttons.update]: "Оновити",
  [tokens.general.buttons.archive]: "Архівувати",
  [tokens.general.buttons.create]: "Створити",
  [tokens.general.buttons.cancel]: "Відміна",
  [tokens.general.buttons.close]: "Закрити",
  [tokens.general.buttons.loadMore]: "Завантажити більше",
  [tokens.general.buttons.tryAgain]: "Спробувати ще раз",
  [tokens.general.buttons.backToHome]: "Повернутися на головну",
  [tokens.general.buttons.addItem]: "Додати",
  [tokens.general.buttons.next]: "Далі →",
  [tokens.general.buttons.copy]: "Копіювати",
  [tokens.general.buttons.generateAgain]: "Згенерувати знову",
  [tokens.general.buttons.generateStrategy]: "Згенерувати стратегію",
};
