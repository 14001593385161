import type { ChangeEvent } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
// import { subDays, subHours, subMinutes, subMonths } from 'date-fns';
import {
  Box,
  Button,
  Card,
  Divider,
  Link,
  Stack,
  SvgIcon,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Seo } from "src/components/seo";
import { usePageView } from "src/hooks/use-page-view";
// import { AccountBillingSettings } from 'src/sections/dashboard/account/account-billing-settings';
// import { AccountNotificationsSettings } from 'src/sections/dashboard/account/account-notifications-settings';
// import { AccountTeamSettings } from 'src/sections/dashboard/account/account-team-settings';
// import { AccountSecuritySettings } from 'src/sections/dashboard/account/account-security-settings';
import type { Page as PageType } from "src/types/page";
import { tokens } from "src/locales/tokens";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "src/store";
import { thunks } from "src/thunks/admin/modules";
import { thunks as productThunks } from "src/thunks/admin/product";
import { thunks as userThunks } from "src/thunks/admin/product-users";
import { thunks as productPaymentsThunks } from "src/thunks/admin/product-payments";
import { thunks as productSettingsThunks } from "src/thunks/admin/product-settings";
import { thunks as productPlansThunks } from "src/thunks/admin/product-plans";
import { useParams } from "react-router";
import { ModuleListTable } from "src/sections/admin/modules/module-list-table";
import PlusIcon from "@untitled-ui/icons-react/build/esm/Plus";
import { paths } from "src/paths";
import { RouterLink } from "src/components/router-link";
import { createRoute } from "react-router-url-params";
import ArrowLeftIcon from "@untitled-ui/icons-react/build/esm/ArrowLeft";
import { useRouter } from "src/hooks/use-router";
import {
  OptionItem,
  OptionMenuButton,
} from "src/sections/common/option-menu-button";
import NotFound from "src/sections/errors/not-found";
import { ERRORS } from "src/consts";
import ServerError from "src/sections/errors/server-error";
import { UserListTable } from "src/sections/admin/products/user-list-table";
import { NewUsersDrawer } from "src/sections/admin/products/new-user-drawer";
import { PaymentsListTable } from "src/sections/admin/products/payments/payments-list-table";
import { ProductEditForm } from "src/sections/admin/products/product-edit-form";
import { ProductSettingsForm } from "src/sections/admin/products/settings/product-settings-form";
import { ProductContentEditForm } from "src/sections/admin/products/product-content-edit-form";
import { ProductPlanListTable } from "src/sections/admin/products/settings/product-plan-list-table";
import { NewProductPlanDrawer } from "src/sections/admin/products/settings/new-product-plan-drawer";
// const now = new Date();

const MODULES_TAB = "modules";
const CONTENT_TAB = "content";
const DETAILS_TAB = "details";
const PRODUCT_USERS_TAB = "users";
const PAYMENTS_TAB = "payments";
const SETTINGS_TAB = "settings";
const PRODUCT_PLANS_TAB = "product-plans";

const Page: PageType = () => {
  const { t, i18n } = useTranslation();

  usePageView();

  const dispatch = useDispatch();
  const { id } = useParams();
  const router = useRouter();

  const {
    items: modules,
    hasLoadingError,
    isLoading,
    hasOrderChanged,
  } = useSelector((state) => state.adminModules);
  const {
    items: productUsers,
    hasLoadingError: hasProductUsersLoadingError,
    isLoading: isProductUsersLoading,
  } = useSelector((state) => state.adminProductUsers);

  const {
    items: productPlans,
    hasLoadingError: hasProductPlansLoadingError,
    isLoading: isProductPlansLoading,
  } = useSelector((state) => state.adminProductPlans);

  const {
    items: payments,
    hasLoadingError: hasProductPaymentsLoadingError,
    isLoading: isProductPaymentsLoading,
  } = useSelector((state) => state.adminProductPayments);
  const {
    item: product,
    error: productError,
    isLoading: productIsLoading,
  } = useSelector((state) => state.adminProduct);
  const {
    settings: productSettings,
    hasLoadingError: hasProductSettingLoadingError,
    isLoading: isProductSettingsLoading,
  } = useSelector((state) => state.adminProductSettings);

  const { organization } = useSelector((state) => state.accountOrganization);

  const tabs = useMemo(
    () => [
      {
        label: t(
          product?.isStandalone
            ? tokens.admin.products.details.tabs.content
            : tokens.admin.products.details.tabs.modules
        ) as string,
        value: product?.isStandalone ? CONTENT_TAB : MODULES_TAB,
      },
      {
        label: t(tokens.admin.products.details.tabs.details) as string,
        value: DETAILS_TAB,
      },
      {
        label: t(tokens.admin.products.details.tabs.users) as string,
        value: PRODUCT_USERS_TAB,
      },
      {
        label: t(tokens.admin.products.details.tabs.payments) as string,
        value: PAYMENTS_TAB,
      },
      {
        label: t(tokens.admin.products.details.tabs.productPlans) as string,
        value: PRODUCT_PLANS_TAB,
      },
      {
        label: t(tokens.admin.products.details.tabs.settings) as string,
        value: SETTINGS_TAB,
      },
    ],
    [product?.isStandalone, t]
  );

  const menuItems = useMemo<OptionItem[]>(() => {
    return [
      {
        text: t(tokens.admin.products.details.menu.preview),
        iconName: "launch",
        onClick: () => {
          window.open(
            createRoute(paths.panel.products.details).link({ productId: id }),
            "_blank"
          );
        },
      },
    ];
  }, [id, t]);

  useEffect(() => {
    const updateModulesOrder = async () => {
      if (id && hasOrderChanged) {
        await dispatch(
          thunks.updateModulesOrderRanks(
            id!,
            modules.map((item) => item.id)
          )
        );
      }
    };

    updateModulesOrder();
  }, [dispatch, hasOrderChanged, id, modules]);

  const loadSettings = useCallback(async () => {
    if (id) {
      await dispatch(productSettingsThunks.getProductSettings(id));
    }
  }, [dispatch, id]);

  const loadModules = useCallback(async () => {
    if (id) {
      await dispatch(thunks.getModules(id));
    }
  }, [dispatch, id]);

  const loadProductPlans = useCallback(
    async (page: number, top: number) => {
      if (id) {
        await dispatch(
          productPlansThunks.getProductPlans(id, {
            page: page,
            top: top,
          })
        );
      }
    },
    [dispatch, id]
  );

  const loadProductUsers = useCallback(
    async (page: number, top: number) => {
      if (id) {
        await dispatch(
          userThunks.getProductUsers(id, {
            page: page,
            top: top,
          })
        );
      }
    },
    [dispatch, id]
  );

  const loadPayments = useCallback(
    async (page: number, top: number) => {
      if (id) {
        await dispatch(
          productPaymentsThunks.getProductPayments(id, {
            page: page,
            top: top,
          })
        );
      }
    },
    [dispatch, id]
  );

  const loadData = useCallback(async () => {
    if (id) {
      await dispatch(productThunks.getProduct(id));
      await loadModules();
      await loadSettings();
      await loadProductPlans(productPlans.page, productPlans.top);
      await loadProductUsers(productUsers.page, productUsers.top);
      await loadPayments(payments.page, payments.top);
    }
  }, [
    id,
    dispatch,
    loadModules,
    loadSettings,
    loadProductPlans,
    productPlans.page,
    productPlans.top,
    loadProductUsers,
    productUsers.page,
    productUsers.top,
    loadPayments,
    payments.page,
    payments.top,
  ]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleTabsChange = useCallback(
    (event: ChangeEvent<{}>, value: string): void => {
      setCurrentTab(value);
    },
    []
  );

  const [showNewProductUserDrawer, setShowNewProductUserDrawer] =
    useState<boolean>(false);
  const [showNewProductPlanDrawer, setShowNewProductPlanDrawer] =
    useState<boolean>(false);

  const [currentTab, setCurrentTab] = useState<string>("");

  useEffect(() => {
    if (product) {
      setCurrentTab(product.isStandalone ? CONTENT_TAB : MODULES_TAB);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product?.isStandalone]);

  if (productError) {
    if (productError.status === ERRORS.NOT_FOUND_ERROR.status) {
      return <NotFound showBackButton={false} />;
    }
    return <ServerError showBackButton={false} />;
  }

  if (!id || !product || !organization || !currentTab) {
    return;
  }

  return (
    <>
      <Seo title={product.title} />
      <Stack
        spacing={3}
        sx={{ mb: 3 }}
      >
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
        >
          <Link
            color="text.primary"
            component={RouterLink}
            href={paths.admin.products.list}
            sx={{
              alignItems: "center",
              display: "inline-flex",
            }}
            underline="hover"
          >
            <SvgIcon sx={{ mr: 1 }}>
              <ArrowLeftIcon />
            </SvgIcon>
            <Typography variant="subtitle2">
              {t(tokens.admin.products.details.backButton)}
            </Typography>
          </Link>
          <Box>
            <OptionMenuButton items={menuItems} />
          </Box>
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={4}
        >
          <Stack spacing={1}>
            <Typography variant="h4">{product?.title}</Typography>
          </Stack>
        </Stack>

        <div>
          <Tabs
            indicatorColor="primary"
            onChange={handleTabsChange}
            scrollButtons="auto"
            textColor="primary"
            value={currentTab}
            variant="scrollable"
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
              />
            ))}
          </Tabs>
          <Divider />
        </div>

        {currentTab === CONTENT_TAB && (
          <ProductContentEditForm product={product} />
        )}

        {currentTab === MODULES_TAB && (
          <Stack spacing={3}>
            <Card>
              <ModuleListTable
                items={modules}
                product={product}
                onReset={loadModules}
                onMoveUp={async (index: number) => {
                  const moduleId = modules[index].id;
                  await dispatch(thunks.moveModuleUp(id, moduleId));
                }}
                onMoveDown={async (index: number) => {
                  const moduleId = modules[index].id;
                  await dispatch(thunks.moveModuleDown(id, moduleId));
                }}
                isLoading={isLoading}
                hasError={hasLoadingError}
              />
            </Card>
            <Stack direction="row">
              <Button
                component={RouterLink}
                href={
                  createRoute(paths.admin.modules.create).link({
                    productId: id,
                  }) + `?orderRank=${modules.length + 1}`
                }
                startIcon={
                  <SvgIcon>
                    <PlusIcon />
                  </SvgIcon>
                }
                variant="contained"
              >
                {t(tokens.admin.products.details.buttons.addModule)}
              </Button>
            </Stack>
          </Stack>
        )}

        {currentTab === PRODUCT_USERS_TAB && (
          <Stack spacing={3}>
            <Card>
              <UserListTable
                product={product}
                count={productUsers.totalCount}
                items={productUsers.items}
                onPageChange={(event, newPage) =>
                  loadProductUsers(newPage + 1, productUsers.top)
                }
                onRowsPerPageChange={(event) =>
                  loadProductUsers(1, Number.parseInt(event.target.value))
                }
                onReset={() => loadProductUsers(1, productUsers.top)}
                onUpdate={() =>
                  loadProductUsers(productUsers.page, productUsers.top)
                }
                page={productUsers.page}
                top={productUsers.top}
                isLoading={isProductUsersLoading}
                hasError={hasProductUsersLoadingError}
              />
            </Card>
            <Stack direction="row">
              <Button
                component={RouterLink}
                onClick={() => setShowNewProductUserDrawer(true)}
                startIcon={
                  <SvgIcon>
                    <PlusIcon />
                  </SvgIcon>
                }
                variant="contained"
              >
                {t(tokens.admin.products.details.users.buttons.newUser)}
              </Button>
              <NewUsersDrawer
                product={product}
                isOpen={showNewProductUserDrawer}
                onReset={loadData}
                onClose={() => setShowNewProductUserDrawer(false)}
              />
            </Stack>
          </Stack>
        )}

        {currentTab === PRODUCT_PLANS_TAB && (
          <Stack spacing={3}>
            <Card>
              <ProductPlanListTable
                product={product}
                count={productPlans.totalCount}
                items={productPlans.items}
                onPageChange={(event, newPage) =>
                  loadProductPlans(newPage + 1, productPlans.top)
                }
                onRowsPerPageChange={(event) =>
                  loadProductPlans(1, Number.parseInt(event.target.value))
                }
                onReset={() => loadProductPlans(1, productPlans.top)}
                onUpdate={() =>
                  loadProductPlans(productPlans.page, productPlans.top)
                }
                page={productPlans.page}
                top={productPlans.top}
                isLoading={isProductPlansLoading}
                hasError={hasProductPlansLoadingError}
              />
            </Card>
            <Stack direction="row">
              <Button
                component={RouterLink}
                onClick={() => setShowNewProductPlanDrawer(true)}
                startIcon={
                  <SvgIcon>
                    <PlusIcon />
                  </SvgIcon>
                }
                variant="contained"
              >
                {t(
                  tokens.admin.products.details.productPlans.buttons
                    .newProductPlan
                )}
              </Button>
              <NewProductPlanDrawer
                product={product}
                isOpen={showNewProductPlanDrawer}
                onReset={loadData}
                onClose={() => setShowNewProductPlanDrawer(false)}
              />
            </Stack>
          </Stack>
        )}

        {currentTab === PAYMENTS_TAB && (
          <Stack spacing={3}>
            <Card>
              <PaymentsListTable
                product={product}
                count={payments.totalCount}
                items={payments.items}
                onPageChange={(event, newPage) =>
                  loadPayments(newPage + 1, payments.top)
                }
                onRowsPerPageChange={(event) =>
                  loadPayments(1, Number.parseInt(event.target.value))
                }
                onReset={() => loadPayments(1, payments.top)}
                onUpdate={() => loadPayments(payments.page, payments.top)}
                page={payments.page}
                top={payments.top}
                isLoading={isProductPaymentsLoading}
                hasError={hasProductPaymentsLoadingError}
              />
            </Card>
          </Stack>
        )}

        {currentTab === DETAILS_TAB && (
          <>
            <ProductEditForm product={product} />
          </>
        )}
        {currentTab === SETTINGS_TAB && (
          <>
            <ProductSettingsForm
              hasLoadingError={hasProductSettingLoadingError}
              isLoading={isProductSettingsLoading}
              settings={productSettings}
              organizationId={organization.id}
              product={product}
            />
          </>
        )}
      </Stack>
    </>
  );
};

export default Page;
