import type { FC } from 'react';
import {
    Box,
    CircularProgress,
    Stack,
    Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { tokens } from 'src/locales/tokens';

export const LoadIndicator: FC = (props) => {
    const { t } = useTranslation();
    return (
        <>
            <Stack
                alignItems="center"
                direction="row"
                spacing={1}>
                <CircularProgress
                    size='1.2em' />
                <Box>
                    <Typography variant="body2">
                        {t(tokens.general.loading)}
                    </Typography>
                </Box>
            </Stack>
        </>
    );
};