import { useMemo, type FC } from "react";
import {
  Button,
  Drawer,
  FormHelperText,
  IconButton,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import { useDispatch } from "src/store";
import { toast } from "react-hot-toast";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import XIcon from "@untitled-ui/icons-react/build/esm/X";
import { thunks } from "src/thunks/admin/product-plan";
import { Product } from "src/types/admin/product";
import { Currency, ProductPlan } from "src/types/product-plan";

interface UpdateProductPlanDrawerProps {
  product: Product;
  productPlan: ProductPlan | undefined;
  isOpen: boolean;
  onClose: () => void;
  onUpdate: () => void;
}

export const UpdateProductPlanDrawer: FC<UpdateProductPlanDrawerProps> = (
  props
) => {
  const { t } = useTranslation();
  const { isOpen, onClose, productPlan, onUpdate, product } = props;

  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const currencies = useMemo(() => {
    return [
      {
        value: Currency.NONE,
        label: "",
      },
      {
        value: Currency.USD,
        label: "USD",
      },
      {
        value: Currency.EUR,
        label: "EUR",
      },
      {
        value: Currency.UAH,
        label: "UAH",
      },
      {
        value: Currency.PLN,
        label: "PLN",
      },
    ];
  }, []);

  return (
    <Drawer
      anchor="right"
      variant="temporary"
      onClose={onClose}
      open={isOpen}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: 500,
          p: 3,
        },
      }}
    >
      <Formik
        initialValues={{
          name: productPlan?.name,
          price: productPlan?.price ?? "",
          currency: productPlan?.currency ?? "",
          params: productPlan?.params ?? "",
          submit: null,
        }}
        enableReinitialize
        onSubmit={async (values, helpers): Promise<void> => {
          try {
            await dispatch(
              thunks.updateProductPlan(product.id, productPlan!.id, {
                ...values,
                currency: Number.parseInt(values.currency.toString()),
              })
            );
            helpers.setStatus({ success: true });
            helpers.setSubmitting(false);
            toast.success(
              t(tokens.admin.products.details.productPlans.form.successMessage)
            );
            onClose();
            onUpdate();
          } catch (err) {
            console.error(err);
            toast.error(t(tokens.general.formError));
            helpers.setStatus({ success: false });
            helpers.setSubmitting(false);
          }
        }}
        validationSchema={Yup.object({
          name: Yup.string()
            .max(255, t(tokens.general.validators.maxLength255) as string)
            .required(t(tokens.general.validators.required) as string),
          price: Yup.number()
            .required(t(tokens.general.validators.maxLength255) as string)
            .required(t(tokens.general.validators.required) as string),
          currency: Yup.string().required(
            t(tokens.general.validators.required) as string
          ),
        })}
      >
        {(props) => (
          <Form>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              spacing={3}
              sx={{
                pb: 3,
              }}
            >
              <Typography variant="h6">
                {t(tokens.admin.products.details.productPlans.form.updateTitle)}
              </Typography>
              <Stack
                alignItems="center"
                direction="row"
                spacing={0.5}
              >
                <IconButton
                  color="inherit"
                  onClick={onClose}
                >
                  <SvgIcon>
                    <XIcon />
                  </SvgIcon>
                </IconButton>
              </Stack>
            </Stack>

            <Stack spacing={3}>
              <TextField
                error={!!(props.touched.name && props.errors.name)}
                fullWidth
                helperText={props.touched.name && props.errors.name}
                label={t(tokens.admin.products.details.productPlans.form.name)}
                name="name"
                inputProps={{
                  autoComplete: "name",
                }}
                onBlur={props.handleBlur}
                onChange={props.handleChange}
                value={props.values.name}
              />
              <TextField
                error={!!(props.touched.price && props.errors.price)}
                fullWidth
                helperText={props.touched.price && props.errors.price}
                label={t(tokens.admin.products.details.productPlans.form.price)}
                name="price"
                type="number"
                inputProps={{
                  autoComplete: "price",
                }}
                onBlur={props.handleBlur}
                onChange={props.handleChange}
                value={props.values.price}
              />

              <TextField
                select
                label={t(
                  tokens.admin.products.details.productPlans.form.currency
                )}
                defaultValue={props.values.currency}
                onBlur={props.handleBlur}
                onChange={props.handleChange}
                SelectProps={{
                  native: true,
                }}
                name="currency"
              >
                {currencies.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>

              <TextField
                error={!!(props.touched.params && props.errors.params)}
                fullWidth
                helperText={props.touched.params && props.errors.params}
                label={t(
                  tokens.admin.products.details.productPlans.form.params
                )}
                name="params"
                type="text"
                inputProps={{
                  autoComplete: "params",
                }}
                onBlur={props.handleBlur}
                onChange={props.handleChange}
                value={props.values.params}
              />

              {props.errors.submit && (
                <FormHelperText
                  error
                  sx={{ mt: 3 }}
                >
                  {props.errors.submit as string}
                </FormHelperText>
              )}

              <Stack
                alignItems="center"
                direction="row"
                justifyContent="flex-end"
                spacing={1}
                sx={{ pt: 3, pb: 3 }}
              >
                <Button
                  color="inherit"
                  onClick={onClose}
                  size="small"
                >
                  {t(tokens.general.buttons.cancel)}
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={props.isSubmitting}
                  disabled={props.isSubmitting}
                >
                  {t(tokens.general.buttons.update)}
                </LoadingButton>
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};
