import type { AppThunk } from 'src/store';
import { fetchWrapper } from 'src/utils/fetch-wrapper';
import { api } from 'src/config';
import { slice } from 'src/slices/admin/organization';
import { ERRORS } from 'src/consts';

const getOrganization = (organizationId: string): AppThunk => async (dispatch): Promise<void> => {

  dispatch(slice.actions.setLoadingStatus(true));
  dispatch(slice.actions.setErrorStatus(undefined));
  try {
    var item = await fetchWrapper.get(`${api}/organizations/${organizationId}`, {});
    dispatch(slice.actions.getOrganization(item));
  } catch (error) {
    if (error?.code === 'Organization.OrganizationNotFound') {
      dispatch(slice.actions.setErrorStatus(ERRORS.NOT_FOUND_ERROR));
    } else {
      dispatch(slice.actions.setErrorStatus(ERRORS.GENERAL_ERROR));
    }
    throw error;
  }
  dispatch(slice.actions.setLoadingStatus(false));
}


const createOrganization = (value: any): AppThunk => async (dispatch): Promise<void> => {
  var org = await fetchWrapper.post(`${api}/organizations`, value);
  dispatch(slice.actions.getOrganization(org));
  return org;
}



const updateOrganization = (organizationId: string, value: any): AppThunk => async (dispatch): Promise<void> => {
  var org = await fetchWrapper.put(`${api}/organizations/${organizationId}`, value);
  dispatch(slice.actions.getOrganization(org));
  return org;
}


export const thunks = {
  getOrganization,
  createOrganization,
  updateOrganization
};
