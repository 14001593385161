import type { FC } from 'react';
import { useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormHelperText,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useRouter } from 'src/hooks/use-router';
import { paths } from 'src/paths';
import { thunks } from 'src/thunks/admin/organization';
import { useDispatch } from 'src/store';
import { useMounted } from 'src/hooks/use-mounted';
import { RouterLink } from 'src/components/router-link';
import { useTranslation } from 'react-i18next';
import { tokens } from 'src/locales/tokens';
import LoadingButton from '@mui/lab/LoadingButton';
import { ImagePicker } from 'src/sections/common/image-picker';
import { FileImage, FilePickerScenario } from 'src/components/file-dropzone';
import { useAuth } from 'src/hooks/use-auth';
import { ORGANIZATION_ERRORS } from 'src/consts';

interface Values {
  name: string,
  domain: string,
  submit: null;
}

const initialValues: Values = {
  name: '',
  domain: '',
  submit: null
};

export const OrganizationCreateForm: FC = (props) => {
  const { t } = useTranslation();
  const isMounted = useMounted();
  const router = useRouter();
  const dispatch = useDispatch();
  const { account, selectOrganization } = useAuth();

  const { i18n } = useTranslation();
  const validationSchema = Yup.object({
    name: Yup
      .string()
      .max(255, t(tokens.general.validators.maxLength255) as string)
      .required(t(tokens.general.validators.required) as string),
    domain: Yup
      .string()
      .required(t(tokens.general.validators.required) as string),
  });

  const [logoId, setLogoId] = useState<string | undefined>(undefined);
  const [logoUrl, setLogoUrl] = useState<string | undefined>(undefined);

  const onLogoUpload = useCallback(async (file: FileImage) => {
    setLogoId(file.id);
    setLogoUrl(file.resizedUrl);
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers): Promise<void> => {
      try {
        const organization = await dispatch(thunks.createOrganization({
          name: values.name,
          domain: values.domain,
          logoId: logoId,
        }));
        if (organization) {
          toast.success(t(tokens.organization.create.form.successMessage));
          await selectOrganization(organization.id);
          router.push(paths.admin.products.list);
        }
        else {
          toast.error(t(tokens.general.formError));
          helpers.setStatus({ success: false });
          //helpers.setErrors({ submit: err.Message });
          helpers.setSubmitting(false);
        }
      } catch (err) {
        if (err.code === ORGANIZATION_ERRORS.DOMAIN_ALREADY_USED) {
          helpers.setErrors({ submit: t(tokens.organization.create.form.domainAlreadyUsed) as string });
        }
        else {
          toast.error(t(tokens.general.formError));
        }
        helpers.setStatus({ success: false });
        //helpers.setErrors({ submit: err.Message });
        helpers.setSubmitting(false);
      }
    }
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      {...props}
    >
      <Stack spacing={4}>
        <Card>
          <CardHeader title={t(tokens.organization.create.header)} />
          <CardContent>
            <Stack spacing={3}>
              <TextField
                error={!!(formik.touched.name && formik.errors.name)}
                fullWidth
                helperText={formik.touched.name && formik.errors.name}
                label={t(tokens.organization.create.form.name)}
                name="name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
              />

              <TextField
                error={!!(formik.touched.domain && formik.errors.domain)}
                fullWidth
                helperText={formik.touched.domain && formik.errors.domain}
                label={t(tokens.organization.create.form.domain)}
                name="domain"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.domain}
              />

              {formik.errors.submit && (
                <FormHelperText
                  error
                  sx={{ mt: 3 }}
                >
                  {formik.errors.submit as string}
                </FormHelperText>
              )}

              <Box>
                <Typography
                  sx={{
                    mb: 2
                  }}
                  variant="h6">
                  {t(tokens.organization.create.form.logoImage.header)}
                </Typography>
                <Typography
                  variant='body2'>
                  {t(tokens.organization.create.form.logoImage.description)}
                </Typography>
              </Box>
              <Stack
                spacing={3}
              >
                {logoUrl &&
                  <Stack
                    spacing={3}>
                    <img
                      width={100}
                      src={logoUrl} />
                    <Box>
                      <Button
                        color="primary"
                        variant='outlined'
                        onClick={() => {
                          setLogoId(undefined);
                          setLogoUrl(undefined);
                        }}
                        size="small"
                      >
                        {t(tokens.general.buttons.delete)}
                      </Button>
                    </Box>
                  </Stack>
                }
                <ImagePicker
                  scenario={FilePickerScenario.File}
                  onGenerate={onLogoUpload}
                  onRemove={() => {
                    setLogoId(undefined);
                    setLogoUrl(undefined);
                  }}
                //onUpload={onUpload}
                />

              </Stack>

            </Stack>
          </CardContent>
        </Card>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="flex-end"
          spacing={1}
        >
          <Button
            href={paths.admin.products.list}
            color="inherit"
            component={RouterLink}
          >
            {t(tokens.general.buttons.cancel)}
          </Button>
          <LoadingButton
            type="submit"
            disabled={formik.isSubmitting}
            loading={formik.isSubmitting}
            variant="contained"
          >
            {t(tokens.general.buttons.create)}
          </LoadingButton>
        </Stack>
      </Stack>
    </form>
  );
};
