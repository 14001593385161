
import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import { MixPanelConfig } from 'src/libs/mixpanel';

export function useMixPanel(config: MixPanelConfig) {

  useEffect(
    () => {
      if (config.token) {
        mixpanel.init(config.token, { debug: false, track_pageview: true, persistence: 'localStorage' });
      }
    },
    [config]
  );
}

export function trackAction(name: string, properties: any) {
  try {
    mixpanel.track(name, properties);
  }
  catch (e) {
    console.error('Error tracking action', e);
  }
}


export function identifyUser(id: string) {
  try {
    mixpanel.identify(id);
  }
  catch (e) {
    console.error('Mixpanel: inentification failed', e);
  }
}

export function userReset() {
  try {
    mixpanel.reset();
  }
  catch (e) {
    console.error('Mixpanel: reset failed', e);
  }
}

export function initUser(email: string, name: string) {
  try {
    mixpanel.people.set_once({
      "$name": name,
      "$email": email,
    });
  }
  catch (e) {
    console.error('Mixpanel: initUser failed', e);
  }
}

