import type { FC } from 'react';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField
} from '@mui/material';
import { thunks } from 'src/thunks/admin/organization-settings';
import { useDispatch } from 'src/store';
import { useTranslation } from 'react-i18next';
import { tokens } from 'src/locales/tokens';
import LoadingButton from '@mui/lab/LoadingButton';
import { ORGANIZATION_EMAIL_SETTINGS } from 'src/consts';
import { Organization } from 'src/types/admin/organization';
import { Setting } from 'src/types/setting';


interface OrganizationEmailSettingsFormProps {
  organization: Organization;
  settings: Setting[];
}

export const OrganizationEmailSettingsForm: FC<OrganizationEmailSettingsFormProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { organization, settings } = props;

  const validationSchema = Yup.object({
    isEnabled: Yup.boolean(),
    email: Yup
      .string()
      .email(t(tokens.general.validators.invalidEmail) as string)
      .when('isEnabled', {
        is: true,
        then: () => Yup.string().required(t(tokens.general.validators.required) as string),
      }),
    host: Yup
      .string()
      .when('isEnabled', {
        is: true,
        then: () => Yup.string().required(t(tokens.general.validators.required) as string),
      }),
    port: Yup.number()
      .when('isEnabled', {
        is: true,
        then: () => Yup.number().required(t(tokens.general.validators.required) as string),
      }),
    username: Yup
      .string()
      .when('isEnabled', {
        is: true,
        then: () => Yup.string().required(t(tokens.general.validators.required) as string),
      }),
    password: Yup
      .string()
      .when('isEnabled', {
        is: true,
        then: () => Yup.string().required(t(tokens.general.validators.required) as string),
      }),
    name: Yup
      .string()
      .when('isEnabled', {
        is: true,
        then: () => Yup.string().required(t(tokens.general.validators.required) as string),
      }),
  });

  const formik = useFormik({
    initialValues: {
      isEnabled: settings.find((s) => s.key === ORGANIZATION_EMAIL_SETTINGS.IS_ENABLED)?.value === 'true',
      host: settings.find((s) => s.key === ORGANIZATION_EMAIL_SETTINGS.HOST)?.value || '',
      port: settings.find((s) => s.key === ORGANIZATION_EMAIL_SETTINGS.PORT)?.value || '',
      username: settings.find((s) => s.key === ORGANIZATION_EMAIL_SETTINGS.USERNAME)?.value || '',
      password: settings.find((s) => s.key === ORGANIZATION_EMAIL_SETTINGS.PASSWORD)?.value || '',
      name: settings.find((s) => s.key === ORGANIZATION_EMAIL_SETTINGS.NAME)?.value || '',
      email: settings.find((s) => s.key === ORGANIZATION_EMAIL_SETTINGS.EMAIL)?.value || '',
      submit: null
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, helpers): Promise<void> => {
      try {
        await dispatch(thunks.updateSetting(organization.id, ORGANIZATION_EMAIL_SETTINGS.IS_ENABLED, values.isEnabled.toString()));
        if (values.isEnabled) {
          await dispatch(thunks.updateSetting(organization.id, ORGANIZATION_EMAIL_SETTINGS.HOST, values.host));
          await dispatch(thunks.updateSetting(organization.id, ORGANIZATION_EMAIL_SETTINGS.PORT, values.port));
          await dispatch(thunks.updateSetting(organization.id, ORGANIZATION_EMAIL_SETTINGS.USERNAME, values.username));
          await dispatch(thunks.updateSetting(organization.id, ORGANIZATION_EMAIL_SETTINGS.PASSWORD, values.password));
          await dispatch(thunks.updateSetting(organization.id, ORGANIZATION_EMAIL_SETTINGS.NAME, values.name));
          await dispatch(thunks.updateSetting(organization.id, ORGANIZATION_EMAIL_SETTINGS.EMAIL, values.email));
        }
        toast.success(t(tokens.admin.organization.details.settings.successMessage));
      } catch (error) {
        toast.error(t(tokens.general.formError));
        helpers.setStatus({ success: false });
        helpers.setSubmitting(false);
      }
    }
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      {...props}
    >
      <Stack spacing={4}>
        <Card>
          <CardHeader title={t(tokens.admin.organization.details.settings.email.header)} />
          <CardContent>
            <Stack spacing={3}>

              <FormControlLabel control={<Checkbox
                value={formik.values.isEnabled}
                checked={formik.values.isEnabled}
                name="isEnabled"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />} label={t(tokens.admin.organization.details.settings.email.form.isEnabled)} />


              {formik.values.isEnabled && (
                <>

                  <TextField
                    error={!!(formik.touched.name && formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    label={t(tokens.admin.organization.details.settings.email.form.name)}
                    name="name"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                  />

                  <TextField
                    error={!!(formik.touched.email && formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    label={t(tokens.admin.organization.details.settings.email.form.email)}
                    name="email"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />

                  <TextField
                    error={!!(formik.touched.host && formik.errors.host)}
                    helperText={formik.touched.host && formik.errors.host}
                    label={t(tokens.admin.organization.details.settings.email.form.host)}
                    name="host"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.host}
                  />

                  <TextField
                    error={!!(formik.touched.port && formik.errors.port)}
                    helperText={formik.touched.port && formik.errors.port}
                    label={t(tokens.admin.organization.details.settings.email.form.port)}
                    name="port"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.port}
                  />

                  <TextField
                    error={!!(formik.touched.username && formik.errors.username)}
                    helperText={formik.touched.username && formik.errors.username}
                    label={t(tokens.admin.organization.details.settings.email.form.username)}
                    name="username"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.username}
                  />

                  <TextField
                    error={!!(formik.touched.password && formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                    label={t(tokens.admin.organization.details.settings.email.form.password)}
                    name="password"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.password}
                  />

                </>
              )}
              <Box>
                <LoadingButton
                  type="submit"
                  disabled={formik.isSubmitting}
                  loading={formik.isSubmitting}
                  variant="contained"
                >
                  {t(tokens.general.buttons.update)}
                </LoadingButton>
              </Box>
            </Stack>
          </CardContent>
        </Card>

      </Stack>
    </form>
  );
};
