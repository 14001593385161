import { Box, Container, Link, Stack, SvgIcon, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { createRoute } from 'react-router-url-params';
import { RouterLink } from 'src/components/router-link';
import { Seo } from 'src/components/seo';
import { usePageView } from 'src/hooks/use-page-view';
import { useRouter } from 'src/hooks/use-router';
import { ModuleCreateForm } from 'src/sections/admin/modules/module-create-form';
import type { Page as PageType } from 'src/types/page';
import ArrowLeftIcon from '@untitled-ui/icons-react/build/esm/ArrowLeft';
import { paths } from 'src/paths';
import { tokens } from 'src/locales/tokens';

const Page: PageType = () => {

  const router = useRouter();
  const { t } = useTranslation();
  const { productId } = useParams();
  usePageView();

  if (!productId) {
    return null;
  }

  return (
    <>
      <Seo title={t(tokens.admin.modules.create.pageTitle) as string} />
      <Stack spacing={3}>
        <div>
          <Link
            color="text.primary"
            component={RouterLink}
            href={createRoute(paths.admin.products.details).link({ productId: productId })}
            sx={{
              alignItems: 'center',
              display: 'inline-flex',
            }}
            underline="hover"
          >
            <SvgIcon sx={{ mr: 1 }}>
              <ArrowLeftIcon />
            </SvgIcon>
            <Typography variant="subtitle2">
              {t(tokens.admin.modules.create.backButton)}
            </Typography>
          </Link>
        </div>
        <Stack spacing={1}>
          <Typography variant="h4">
            {t(tokens.admin.modules.create.pageTitle)}
          </Typography>
        </Stack>
        <ModuleCreateForm
          productId={productId ?? ""} />
      </Stack>
    </>
  );
};

export default Page;
