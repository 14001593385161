import {
  useState,
  type ChangeEvent,
  type FC,
  type MouseEvent,
  useCallback,
} from "react";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  SvgIcon,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { Scrollbar } from "src/components/scrollbar";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import moment from "moment";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import EditOutlined from "@mui/icons-material/EditOutlined";
import { useDispatch } from "src/store";
import { thunks } from "src/thunks/admin/product-users";
import { toast } from "react-hot-toast";
import { ProductUser } from "src/types/admin/product-users";
import { TableLoadingRow } from "src/sections/common/table-loading-row";
import { PaymentStatus } from "src/types/payment";
import { UpdateUserDrawer } from "./update-user-drawer";
import { Product } from "src/types/admin/product";

interface UserListTableProps {
  product: Product;
  count: number | null;
  items: ProductUser[] | [];
  onPageChange: (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  onRowsPerPageChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onReset: () => void;
  onUpdate: () => void;
  page: number;
  top: number;
  isLoading: boolean;
  hasError: boolean;
}

export const UserListTable: FC<UserListTableProps> = (props) => {
  const { t, i18n } = useTranslation();
  const {
    count = 0,
    product,
    items = null,
    onPageChange = () => {},
    onRowsPerPageChange,
    onReset = () => {},
    onUpdate = () => {},
    page = 0,
    top = 10,
    isLoading,
    hasError,
  } = props;
  const showPagination = !!page && !!top && !!count;

  const dispatch = useDispatch();

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openSendInvitationDialog, setOpenSendInvitationDialog] =
    useState<boolean>(false);

  const [user, setUser] = useState<ProductUser | undefined>(undefined);
  const [showUpdateUserDrawer, setShowUpdateUserDrawer] =
    useState<boolean>(false);

  const handleDeleteClose = useCallback(() => {
    setUser(undefined);
    setOpenDeleteDialog(false);
  }, []);

  const handleOpenDeleteDialog = useCallback((user: ProductUser) => {
    setUser(user);
    setOpenDeleteDialog(true);
  }, []);

  const handleSendInvitationClose = useCallback(() => {
    setUser(undefined);
    setOpenSendInvitationDialog(false);
  }, []);

  const deleteProductUser = useCallback(async () => {
    try {
      if (user) {
        handleDeleteClose();
        await dispatch(thunks.deleteProductUser(product.id, user.id));
        onReset();
      }
    } catch (err) {
      toast.error(t(tokens.general.formError));
    }
  }, [dispatch, handleDeleteClose, onReset, product.id, t, user]);

  const sendInvitation = useCallback(async () => {
    try {
      if (user) {
        handleSendInvitationClose();
        await dispatch(
          thunks.sendProductInvitation(product.id, user.id, i18n.language)
        );
        toast.success(
          t(tokens.admin.products.details.users.invitationSentSuccessMessage)
        );
      }
    } catch (err) {
      toast.error(t(tokens.general.formError));
    }
  }, [dispatch, handleSendInvitationClose, i18n.language, product.id, t, user]);

  const getStatus = useCallback(
    (status: PaymentStatus) => {
      switch (status) {
        case PaymentStatus.approved:
          return t(tokens.payment.statuses.approved);
        case PaymentStatus.pending:
          return t(tokens.payment.statuses.pending);
        case PaymentStatus.declined:
          return t(tokens.payment.statuses.declined);
        case PaymentStatus.refunded:
          return t(tokens.payment.statuses.refunded);
        default:
          return t(tokens.payment.statuses.noPayment);
      }
    },
    [t]
  );

  const getChipStatus = useCallback((status: PaymentStatus) => {
    switch (status) {
      case PaymentStatus.approved:
        return "success";
      case PaymentStatus.pending:
        return "info";
      case PaymentStatus.declined:
        return "error";
      case PaymentStatus.refunded:
        return "warning";
      default:
        return "default";
    }
  }, []);

  return (
    <Box sx={{ position: "relative" }}>
      <Scrollbar>
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              <TableCell>
                {t(tokens.admin.products.details.users.table.name) as string}
              </TableCell>
              {/* <TableCell>
                {t(tokens.admin.products.details.users.table.phone) as string}
              </TableCell> */}
              <TableCell>
                {t(tokens.admin.products.details.users.table.type) as string}
              </TableCell>
              <TableCell>
                {t(tokens.admin.products.details.users.table.date) as string}
              </TableCell>
              <TableCell>
                {t(tokens.admin.products.details.users.table.endDate) as string}
              </TableCell>
              <TableCell>
                {
                  t(
                    tokens.admin.products.details.users.table.paymentStatus
                  ) as string
                }
              </TableCell>
              {product.productPlans.length > 0 && (
                <TableCell>
                  {
                    t(
                      tokens.admin.products.details.users.table.productPlan
                    ) as string
                  }
                </TableCell>
              )}
              <TableCell
                align="right"
                sx={{
                  minWidth: 60,
                  pl: 3,
                }}
              >
                {t(tokens.admin.products.details.users.table.actions) as string}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableLoadingRow
              colSpan={4}
              isLoading={isLoading}
              hasError={hasError}
              itemsCount={items?.length ?? 0}
              noItemsText={
                t(
                  tokens.admin.products.details.users.table.noUsersFound
                ) as string
              }
              errorLoadingText={
                t(
                  tokens.admin.products.details.users.table.errorLoadingUsers
                ) as string
              }
            />
            {items?.map((user) => {
              return (
                <TableRow
                  hover
                  key={user.id}
                >
                  <TableCell>
                    <Typography variant="body1">
                      {user.firstName} {user.lastName}
                    </Typography>
                    <Typography variant="body2">{user.email}</Typography>
                    <Typography variant="body2">{user.phone}</Typography>
                  </TableCell>
                  {/* <TableCell>{user.phone}</TableCell> */}
                  <TableCell>
                    {user.isManuallyAdded ? (
                      <Chip
                        label={t(
                          tokens.admin.products.details.users.table.manualType
                        )}
                        variant="outlined"
                        color="success"
                      />
                    ) : (
                      <Chip
                        label={t(
                          tokens.admin.products.details.users.table.autoType
                        )}
                        variant="outlined"
                        color="primary"
                      />
                    )}
                  </TableCell>
                  <TableCell>{moment(user.date).format("LL")}</TableCell>
                  <TableCell>{moment(user.endDate).format("LL")}</TableCell>
                  <TableCell>
                    <Chip
                      label={getStatus(user.paymentStatus)}
                      variant="outlined"
                      color={getChipStatus(user.paymentStatus)}
                    />
                  </TableCell>
                  {product.productPlans.length > 0 && (
                    <TableCell>{user.productPlan?.name}</TableCell>
                  )}
                  <TableCell align="right">
                    <Stack
                      direction="row"
                      spacing={1}
                      justifyContent="flex-end"
                    >
                      <IconButton
                        onClick={() => {
                          setUser(user);
                          setOpenSendInvitationDialog(true);
                        }}
                      >
                        <SvgIcon>
                          <ForwardToInboxIcon />
                        </SvgIcon>
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setUser(user);
                          setShowUpdateUserDrawer(true);
                        }}
                      >
                        <SvgIcon>
                          <EditOutlined />
                        </SvgIcon>
                      </IconButton>

                      <IconButton onClick={() => handleOpenDeleteDialog(user)}>
                        <SvgIcon>
                          <DeleteOutlineOutlinedIcon color="error" />
                        </SvgIcon>
                      </IconButton>
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
      {showPagination && (
        <TablePagination
          labelRowsPerPage={t(tokens.general.paginationRowsPerPage)}
          component="div"
          count={count}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          page={page - 1}
          rowsPerPage={top}
          rowsPerPageOptions={[5, 10, 25]}
        />
      )}

      <UpdateUserDrawer
        isOpen={showUpdateUserDrawer}
        onClose={() => setShowUpdateUserDrawer(false)}
        onUpdate={() => {
          setShowUpdateUserDrawer(false);
          onUpdate();
        }}
        user={user}
        product={product}
      />
      <Dialog
        open={openDeleteDialog}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t(tokens.admin.products.details.users.deleteUserDialogTitle)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t(tokens.admin.products.details.users.deleteUserDialogDescription)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={deleteProductUser}
            color="error"
          >
            {t(tokens.general.buttons.delete)}
          </Button>
          <Button onClick={handleDeleteClose}>
            {t(tokens.general.buttons.cancel)}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openSendInvitationDialog}
        onClose={handleSendInvitationClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t(tokens.admin.products.details.users.sendInvitationDialogTitle)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t(
              tokens.admin.products.details.users
                .sendInvitationDialogDescription
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={sendInvitation}
            color="primary"
          >
            {t(tokens.general.buttons.send)}
          </Button>
          <Button onClick={handleSendInvitationClose}>
            {t(tokens.general.buttons.cancel)}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
