import type { FC } from "react";
import { useCallback, useState } from "react";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import Settings04Icon from "@untitled-ui/icons-react/build/esm/Settings04";
import {
  Box,
  Button,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  SvgIcon,
  Typography,
} from "@mui/material";
import { RouterLink } from "src/components/router-link";
import { useAuth } from "src/hooks/use-auth";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";
import { Issuer } from "src/utils/auth";
import { OptionsColorScheme } from "src/components/settings/settings-drawer/options-color-scheme";
import { useSettings } from "src/hooks/use-settings";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import KeyIcon from "@mui/icons-material/Key";
import SchoolIcon from "@mui/icons-material/School";
import { AccountImpersonationDrawer } from "../account-impersonation-drawer";
import { createRoute } from "react-router-url-params";
import BusinessIcon from "@mui/icons-material/Business";
import { useSelector } from "src/store";

interface AccountPopoverProps {
  anchorEl: null | Element;
  onClose?: () => void;
  open?: boolean;
}

export const AccountPopover: FC<AccountPopoverProps> = (props) => {
  const { anchorEl, onClose, open, ...other } = props;
  const router = useRouter();
  const auth = useAuth();
  const abilityToImpersonate = auth.account?.isHost;
  const [impersonateDrawerOpen, setImpersonateDrawerOpen] =
    useState<boolean>(false);
  //const organizationId = auth.account?.organizationId;
  const { organization } = useSelector((state) => state.accountOrganization);

  const settings = useSettings();
  const { t } = useTranslation();

  const handleLogout = useCallback(async (): Promise<void> => {
    try {
      onClose?.();

      switch (auth.issuer) {
        case Issuer.JWT: {
          await auth.signOut();
          break;
        }

        default: {
          console.warn("Using an unknown Auth Issuer, did not log out");
        }
      }

      router.push(paths.index);
    } catch (err) {
      console.error(err);
      toast.error(t(tokens.general.formError) as string);
    }
  }, [onClose, auth, router, t]);

  const handleSettings = useCallback(async (): Promise<void> => {
    onClose?.();
    setTimeout(() => {
      router.push(paths.account.settings);
    }, 400);
  }, [onClose, auth, router, t]);

  const handleStopImpersonation = useCallback(async (): Promise<void> => {
    try {
      onClose?.();

      switch (auth.issuer) {
        case Issuer.JWT: {
          await auth.stopImpersonation();
          break;
        }

        default: {
          console.warn("Using an unknown Auth Issuer, did not log out");
        }
      }

      router.refresh();
    } catch (err) {
      console.error(err);
      toast.error(t(tokens.general.formError) as string);
    }
  }, [onClose, auth, t]);

  return (
    <>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        disableScrollLock
        onClose={onClose}
        open={!!open}
        PaperProps={{ sx: { width: 250 } }}
        {...other}
      >
        <Box sx={{ p: 2 }}>
          {auth.account?.firstName && auth.account?.lastName && (
            <Typography
              variant="body1"
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {`${auth.account?.firstName ?? ""} ${auth.account?.lastName}` ??
                ""}
            </Typography>
          )}
          <Typography
            color="text.secondary"
            variant="body2"
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {auth.account?.email}
          </Typography>
        </Box>
        <Divider />

        {auth.isImpersonated && (
          <ListItemButton
            onClick={() => {
              handleStopImpersonation();
            }}
            sx={{
              borderRadius: 1,
              px: 1,
              py: 0.5,
            }}
          >
            <ListItemIcon>
              <SvgIcon fontSize="small">
                <KeyIcon />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body1">
                  {t(tokens.account.popover.stopImpersonation)}
                </Typography>
              }
            />
          </ListItemButton>
        )}

        <ListItemButton
          // component={RouterLink}
          // href={paths.account.settings}
          onClick={handleSettings}
          sx={{
            borderRadius: 1,
            px: 1,
            py: 0.5,
          }}
        >
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <Settings04Icon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">
                {t(tokens.account.popover.settings)}
              </Typography>
            }
          />
        </ListItemButton>

        {abilityToImpersonate && !auth.isImpersonated && (
          <ListItemButton
            onClick={async () => {
              if (onClose) {
                onClose();
              }
              setImpersonateDrawerOpen(true);
            }}
            sx={{
              borderRadius: 1,
              px: 1,
              py: 0.5,
            }}
          >
            <ListItemIcon>
              <SvgIcon fontSize="small">
                <KeyIcon />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body1">
                  {t(tokens.account.popover.impersonate)}
                </Typography>
              }
            />
          </ListItemButton>
        )}

        {auth.isAuthenticated && auth.account?.isHost && (
          <>
            <ListItemButton
              component={RouterLink}
              href={paths.admin.products.list}
              onClick={onClose}
              sx={{
                borderRadius: 1,
                px: 1,
                py: 0.5,
              }}
            >
              <ListItemIcon>
                <SvgIcon fontSize="small">
                  <SchoolIcon />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body1">
                    {t(tokens.account.popover.creator)}
                  </Typography>
                }
              />
            </ListItemButton>
            {organization && (
              <ListItemButton
                component={RouterLink}
                href={createRoute(paths.admin.organizations.details).link({
                  organizationId: organization.id,
                })}
                onClick={onClose}
                sx={{
                  borderRadius: 1,
                  px: 1,
                  py: 0.5,
                }}
              >
                <ListItemIcon>
                  <SvgIcon fontSize="small">
                    <BusinessIcon />
                  </SvgIcon>
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="body1">
                      {t(tokens.account.popover.organization)}
                    </Typography>
                  }
                />
              </ListItemButton>
            )}
          </>
        )}
        <Divider sx={{ my: "0 !important" }} />
        <Box
          sx={{
            display: "flex",
            p: 2,
          }}
        >
          <OptionsColorScheme
            onChange={(value) => {
              settings.handleUpdate({ paletteMode: value });
              //handleFieldUpdate('paletteMode', value)
            }}
            value={settings.paletteMode}
          />
        </Box>
        <Divider sx={{ my: "0 !important" }} />
        <Box
          sx={{
            display: "flex",
            p: 1,
            justifyContent: "center",
          }}
        >
          <Button
            color="inherit"
            onClick={handleLogout}
            size="small"
          >
            {t(tokens.account.popover.signOut)}
          </Button>
        </Box>
      </Popover>

      {abilityToImpersonate && (
        <AccountImpersonationDrawer
          isOpen={impersonateDrawerOpen}
          onClose={() => setImpersonateDrawerOpen(false)}
        />
      )}
    </>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
