import React from "react";

import { setTitle, useTitle } from "../documents/editor/EditorContext";

import { Box, TextField } from "@mui/material";

export default function GlobalPanel() {
  const title = useTitle();

  return (
    <>
      <Box p={2}>
        <TextField
          defaultValue={title}
          fullWidth
          multiline
          label="Email subject"
          variant="outlined"
          onChange={(e) => setTitle(e.target.value)}
        />
      </Box>
    </>
  );
}
