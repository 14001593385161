import type { AppThunk } from 'src/store';
import { fetchWrapper } from 'src/utils/fetch-wrapper';
import { api } from 'src/config';
import { slice as modulesSlice } from 'src/slices/panel/account-modules';

const getModules = (productId: string): AppThunk => async (dispatch): Promise<void> => {

  dispatch(modulesSlice.actions.setLoadingStatus(true));
  dispatch(modulesSlice.actions.setErrorStatus(false));
  try {
    var items = await fetchWrapper.get(`${api}/account/products/${productId}/modules`, {});
    dispatch(modulesSlice.actions.getModules(items));
  } catch (error) {
    dispatch(modulesSlice.actions.setErrorStatus(true));
  }
  dispatch(modulesSlice.actions.setLoadingStatus(false));
}

export const thunks = {
  getModules
};
