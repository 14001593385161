import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { ProductPlanResponse } from "src/types/admin/product-plans";
import { ProductPlan } from "src/types/product-plan";

interface ProductPlansState {
  isLoading: boolean;
  hasLoadingError: boolean;
  items: ProductPlanResponse;
}

type GetProductPlansAction = PayloadAction<ProductPlanResponse>;
type ProductPlansLoadingStatusAction = PayloadAction<boolean>;
type ProductPlansErrorStatusAction = PayloadAction<boolean>;

const initialState: ProductPlansState = {
  isLoading: false,
  hasLoadingError: false,
  items: {
    items: [],
    totalCount: 0,
    top: 10,
    page: 1,
    lastItemDateTime: null,
  },
};

const reducers = {
  setLoadingStatus: (
    state: ProductPlansState,
    action: ProductPlansLoadingStatusAction
  ): void => {
    state.isLoading = action.payload;
  },
  setErrorStatus: (
    state: ProductPlansState,
    action: ProductPlansErrorStatusAction
  ): void => {
    state.hasLoadingError = action.payload;
  },
  getProductPlans: (
    state: ProductPlansState,
    action: GetProductPlansAction
  ): void => {
    state.items = action.payload;
  },
};

export const slice = createSlice({
  name: "admin-product-plans",
  initialState,
  reducers,
});

export const { reducer } = slice;
