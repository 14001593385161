import { type FC } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { createRoute } from "react-router-url-params";
import { paths } from "src/paths";
import { Product } from "src/types/panel/account-product";
import { useRouter } from "src/hooks/use-router";
import { Avatar, Box, SvgIcon } from "@mui/material";
import User01Icon from "@untitled-ui/icons-react/build/esm/User01";
import { trackAction } from "src/hooks/use-mixpanel";

interface ProductListItemProps {
  item: Product | undefined;
}

export const ProductListItem: FC<ProductListItemProps> = (props) => {
  const { item } = props;
  const router = useRouter();

  return (
    <Card
      sx={{
        cursor: "pointer",
      }}
      onClick={() => {
        if (item) {
          trackAction("Product", {
            productId: item.id,
            productTitle: item.title,
          });
          router.push(
            createRoute(paths.panel.products.details).link({
              productId: item.id,
            })
          );
        }
      }}
    >
      <CardMedia
        sx={{
          height: 160,
          p: 3,
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-start",
          backgroundColor: "primary.main",
        }}
        image={item?.imageUrl ?? "/assets/empty.png"}
        title={item?.title ?? ""}
      ></CardMedia>
      <CardContent>
        <Stack spacing={2}>
          <Stack
            spacing={1}
            alignItems={"center"}
            direction={"row"}
            sx={{
              flex: "0 0 100%",
            }}
          >
            <Avatar
              sx={{
                height: 32,
                width: 32,
              }}
              src={item?.author.profileImageUrl}
            >
              <SvgIcon>
                <User01Icon />
              </SvgIcon>
            </Avatar>
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography>
                {item ? (
                  <>
                    {item.author.firstName} {item.author.lastName}
                  </>
                ) : (
                  <Skeleton width={"50%"} />
                )}
              </Typography>
            </Box>
          </Stack>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
          >
            {item ? item.title : <Skeleton />}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            noWrap
          >
            {item ? item.description : <Skeleton />}
          </Typography>
          {/* {!item?.isStandalone && (
            <Typography
              variant="body2"
              color="text.secondary"
            >
              {item ? (
                <>Доступ до {moment(item.endDate).format("LL")}</>
              ) : (
                <Skeleton />
              )}
            </Typography>
          )} */}
        </Stack>
      </CardContent>
    </Card>
  );
};
