import {
  IconListBulleted,
  IconListNumbered,
  IconCurlyBrackets,
} from "@codexteam/icons";

interface ListData {
  style: "ordered" | "unordered";
  items: string[];
}

interface ListConfig {
  defaultStyle?: "ordered" | "unordered";
}

interface ListTuneSettings {
  name: "ordered" | "unordered";
  label: string;
  icon: unknown;
  default: boolean;
}

interface EditorJsAPI {
  styles: {
    block: string;
  };
  blocks: {
    insert: () => void;
    getCurrentBlockIndex: () => number;
  };
  caret: {
    setToBlock: (index: number) => void;
  };
  i18n: {
    t: (key: string) => string;
  };
}

interface PasteEvent extends Event {
  detail: {
    data: HTMLElement;
  };
}

export default class TimeCode {
  private _elements: {
    wrapper: HTMLElement | null;
  };
  private _data: ListData;
  private api: EditorJsAPI;
  private readOnly: boolean;
  private settings: ListTuneSettings[];

  static get isReadOnlySupported(): boolean {
    return true;
  }

  static get enableLineBreaks(): boolean {
    return true;
  }

  static get toolbox() {
    return {
      icon: IconCurlyBrackets,
      title: "Time Code",
    };
  }

  constructor({
    data,
    config,
    api,
    readOnly,
  }: {
    data: ListData;
    config: ListConfig;
    api: EditorJsAPI;
    readOnly: boolean;
  }) {
    this._elements = {
      wrapper: null,
    };

    this.api = api;
    this.readOnly = readOnly;

    this.settings = [
      {
        name: "unordered",
        label: this.api.i18n.t("Unordered"),
        icon: IconListBulleted,
        default: config.defaultStyle === "unordered" || false,
      },
      {
        name: "ordered",
        label: this.api.i18n.t("Ordered"),
        icon: IconListNumbered,
        default: config.defaultStyle === "ordered" || true,
      },
    ];

    this._data = {
      style:
        this.settings.find((tune) => tune.default === true)?.name || "ordered",
      items: [],
    };

    this.data = data;
  }

  render(): HTMLElement {
    this._elements.wrapper = this.makeMainTag(this._data.style);

    if (this._data.items.length) {
      this._data.items.forEach((item) => {
        this._elements.wrapper!.appendChild(
          this._make("li", this.CSS.item, {
            innerHTML: item,
          })
        );
      });
    } else {
      this._elements.wrapper!.appendChild(this._make("li", this.CSS.item));
    }

    if (!this.readOnly) {
      this._elements.wrapper.addEventListener(
        "keydown",
        (event: KeyboardEvent) => {
          const [ENTER, BACKSPACE] = [13, 8];

          switch (event.keyCode) {
            case ENTER:
              this.getOutofList(event);
              break;
            case BACKSPACE:
              this.backspace(event);
              break;
          }
        },
        false
      );
    }

    return this._elements.wrapper;
  }

  save(): ListData {
    return this.data;
  }

  static get conversionConfig() {
    return {
      export: (data: ListData): string => {
        return data.items.join(". ");
      },
      import: (string: string): ListData => {
        return {
          items: [string],
          style: "unordered",
        };
      },
    };
  }

  static get sanitize() {
    return {
      style: {},
      items: {
        br: true,
      },
    };
  }

  onPaste(event: PasteEvent): void {
    const list = event.detail.data;
    this.data = this.pasteHandler(list);
  }

  static get pasteConfig() {
    return {
      tags: ["OL", "UL", "LI"],
    };
  }

  private makeMainTag(style: "ordered" | "unordered"): HTMLElement {
    const styleClass =
      style === "ordered" ? this.CSS.wrapperOrdered : this.CSS.wrapperUnordered;
    const tag = style === "ordered" ? "ol" : "ul";

    return this._make(
      tag,
      [this.CSS.baseBlock, this.CSS.wrapper, styleClass, "collapse"],
      {
        contentEditable: !this.readOnly,
      }
    );
  }

  private get CSS() {
    return {
      baseBlock: this.api.styles.block,
      wrapper: "cdx-list",
      wrapperOrdered: "cdx-list--ordered",
      wrapperUnordered: "cdx-list--unordered",
      item: "cdx-list__item",
    };
  }

  set data(listData: ListData) {
    if (!listData) {
      listData = {
        style:
          this.settings.find((tune) => tune.default === true)?.name ||
          "ordered",
        items: [],
      };
    }

    this._data.style =
      listData.style ||
      this.settings.find((tune) => tune.default === true)?.name ||
      "ordered";
    this._data.items = listData.items || [];

    const oldView = this._elements.wrapper;

    if (oldView) {
      oldView.parentNode?.replaceChild(this.render(), oldView);
    }
  }

  get data(): ListData {
    this._data.items = [];

    const items = this._elements.wrapper!.querySelectorAll(`.${this.CSS.item}`);

    for (let i = 0; i < items.length; i++) {
      const value = items[i].innerHTML.replace("<br>", " ").trim();

      if (value) {
        this._data.items.push(items[i].innerHTML);
      }
    }

    return this._data;
  }

  private _make(
    tagName: string,
    classNames: string | string[] | null = null,
    attributes: Record<string, any> = {}
  ): HTMLElement {
    const el = document.createElement(tagName);

    //add background color to the list
    el.style.backgroundColor = "#f9f9f9";
    //add radius to the list
    el.style.borderRadius = "10px";

    //add margin top
    el.style.marginBottom = "10px";

    if (Array.isArray(classNames)) {
      el.classList.add(...classNames);
    } else if (classNames) {
      el.classList.add(classNames);
    }

    for (const attrName in attributes) {
      if (Object.prototype.hasOwnProperty.call(attributes, attrName)) {
        (el as any)[attrName] = attributes[attrName];
      }
    }

    return el;
  }

  private get currentItem(): Element | null {
    let currentNode = window.getSelection()?.anchorNode;

    if (!currentNode) return null;

    if (currentNode.nodeType !== Node.ELEMENT_NODE) {
      currentNode = currentNode.parentNode;
    }

    return (currentNode as Element).closest(`.${this.CSS.item}`);
  }

  private getOutofList(event: KeyboardEvent): void {
    const items = this._elements.wrapper!.querySelectorAll("." + this.CSS.item);

    if (items.length < 2) {
      return;
    }

    const lastItem = items[items.length - 1];
    const currentItem = this.currentItem;

    if (currentItem === lastItem && !lastItem.textContent?.trim().length) {
      currentItem.parentElement?.removeChild(currentItem);
      this.api.blocks.insert();
      this.api.caret.setToBlock(this.api.blocks.getCurrentBlockIndex());
      event.preventDefault();
      event.stopPropagation();
    }
  }

  private backspace(event: KeyboardEvent): void {
    const items = this._elements.wrapper!.querySelectorAll("." + this.CSS.item);
    const firstItem = items[0];

    if (!firstItem) {
      return;
    }

    if (items.length < 2 && !firstItem.innerHTML.replace("<br>", " ").trim()) {
      event.preventDefault();
    }
  }

  private pasteHandler(element: HTMLElement): ListData {
    const tag = element.tagName;
    let style: "ordered" | "unordered";

    switch (tag) {
      case "OL":
        style = "ordered";
        break;
      case "UL":
      case "LI":
      default:
        style = "unordered";
    }

    const data: ListData = {
      style,
      items: [],
    };

    if (tag === "LI") {
      data.items = [element.innerHTML];
    } else {
      const items = Array.from(element.querySelectorAll("LI"));

      data.items = items
        .map((li) => li.innerHTML)
        .filter((item) => !!item.trim());
    }

    return data;
  }
}
