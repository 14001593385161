import React, { FC, useEffect } from "react";

import { MonitorOutlined, PhoneIphoneOutlined } from "@mui/icons-material";
import {
  Box,
  Stack,
  SxProps,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";

import EditorBlock from "../documents/editor/EditorBlock";
import {
  resetDocument,
  setSelectedScreenSize,
  setTitle,
  useDocument,
  useSelectedMainTab,
  useSelectedScreenSize,
  useTitle,
} from "../documents/editor/EditorContext";

import LoadingButton from "@mui/lab/LoadingButton";
import { Reader, renderToStaticMarkup } from "@usewaypoint/email-builder";

import ToggleInspectorPanelButton from "../InspectorDrawer/ToggleInspectorPanelButton";
import EMPTY_EMAIL_MESSAGE from "./empty-email-message";
import DownloadJson from "./DownloadJson";
import ImportJson from "./ImportJson";
import MainTabsGroup from "./MainTabsGroup";
import HtmlPanel from "./HtmlPanel";
import JsonPanel from "./JsonPanel";
import toast from "react-hot-toast";

interface TemplatePanelProps {
  content: string | undefined;
  emailTitle: string;
  onSave: (title: string, html: string, content: string) => void;
}

const TemplatePanel: FC<TemplatePanelProps> = (props) => {
  const document = useDocument();
  const title = useTitle();
  const selectedScreenSize = useSelectedScreenSize();
  const selectedMainTab = useSelectedMainTab();

  const { onSave, content, emailTitle } = props;

  const [isSaving, setIsSaving] = React.useState(false);

  useEffect(() => {
    const json = content ? JSON.parse(content) : EMPTY_EMAIL_MESSAGE;
    setTitle(emailTitle);
    resetDocument(json);
  }, [content, emailTitle]);

  let mainBoxSx: SxProps = {
    height: "100%",
  };
  if (selectedScreenSize === "mobile") {
    mainBoxSx = {
      ...mainBoxSx,
      margin: "32px auto",
      width: 370,
      height: 800,
      boxShadow:
        "rgba(33, 36, 67, 0.04) 0px 10px 20px, rgba(33, 36, 67, 0.04) 0px 2px 6px, rgba(33, 36, 67, 0.04) 0px 0px 1px",
    };
  }

  const handleScreenSizeChange = (_: unknown, value: unknown) => {
    switch (value) {
      case "mobile":
      case "desktop":
        setSelectedScreenSize(value);
        return;
      default:
        setSelectedScreenSize("desktop");
    }
  };

  const renderMainPanel = () => {
    switch (selectedMainTab) {
      case "editor":
        return (
          <Box sx={mainBoxSx}>
            <EditorBlock id="root" />
          </Box>
        );
      case "preview":
        return (
          <Box sx={mainBoxSx}>
            <Reader
              document={document}
              rootBlockId="root"
            />
          </Box>
        );
      case "html":
        return <HtmlPanel />;
      case "json":
        return <JsonPanel />;
    }
  };

  return (
    <>
      <Stack
        sx={{
          height: 49,
          borderBottom: 1,
          borderColor: "divider",
          backgroundColor: "white",
          position: "sticky",
          top: 0,
          zIndex: "appBar",
          px: 0,
        }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {/* <ToggleSamplesPanelButton /> */}
        <Stack
          px={2}
          direction="row"
          gap={2}
          width="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <LoadingButton
            variant="outlined"
            size="small"
            loading={isSaving}
            onClick={() => {
              const json = JSON.stringify(document, null, "  ");
              const html = renderToStaticMarkup(document, {
                rootBlockId: "root",
              });
              setIsSaving(true);
              try {
                onSave(title, html, json);
              } catch (e) {
                console.error(e);
                toast.error("Failed to save email template");
              } finally {
                setIsSaving(false);
              }
            }}
          >
            Finish
          </LoadingButton>
          <MainTabsGroup />

          <Stack
            direction="row"
            spacing={2}
          >
            {/* <DownloadJson />
             */}
            <ImportJson />
            <ToggleButtonGroup
              value={selectedScreenSize}
              exclusive
              size="small"
              onChange={handleScreenSizeChange}
            >
              <ToggleButton value="desktop">
                <Tooltip title="Desktop view">
                  <MonitorOutlined fontSize="small" />
                </Tooltip>
              </ToggleButton>
              <ToggleButton value="mobile">
                <Tooltip title="Mobile view">
                  <PhoneIphoneOutlined fontSize="small" />
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
            {/* <ShareButton /> */}
          </Stack>
        </Stack>
        <ToggleInspectorPanelButton />
      </Stack>
      <Box
        sx={{ height: "calc(100vh - 49px)", overflow: "auto", minWidth: 370 }}
      >
        {renderMainPanel()}
      </Box>
    </>
  );
};

export default TemplatePanel;
