import React from "react";

import {
  Box,
  Drawer,
  IconButton,
  Tab,
  Tabs,
  Theme,
  useMediaQuery,
  SvgIcon,
} from "@mui/material";
import XIcon from "@untitled-ui/icons-react/build/esm/X";
import {
  setSidebarTab,
  toggleInspectorDrawerOpen,
  useInspectorDrawerOpen,
  useSelectedSidebarTab,
} from "../documents/editor/EditorContext";

import ConfigurationPanel from "./ConfigurationPanel";
import StylesPanel from "./StylesPanel";
import { Stack } from "@mui/system";
import GlobalPanel from "./GlobalPanel";

export const INSPECTOR_DRAWER_WIDTH = 320;

export default function InspectorDrawer() {
  const selectedSidebarTab = useSelectedSidebarTab();
  const inspectorDrawerOpen = useInspectorDrawerOpen();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const renderCurrentSidebarPanel = () => {
    switch (selectedSidebarTab) {
      case "block-configuration":
        return <ConfigurationPanel />;
      case "styles":
        return <StylesPanel />;
      case "global":
        return <GlobalPanel />;
    }
  };

  return (
    <Drawer
      variant={mdUp ? "persistent" : "temporary"}
      anchor="right"
      open={inspectorDrawerOpen}
      onClose={() => {
        toggleInspectorDrawerOpen();
      }}
      sx={{
        width: inspectorDrawerOpen ? INSPECTOR_DRAWER_WIDTH : 0,
      }}
    >
      <Box
        sx={{
          width: INSPECTOR_DRAWER_WIDTH,
          height: 49,
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Box px={2}>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            spacing={3}
          >
            <Tabs
              value={selectedSidebarTab}
              onChange={(_, v) => setSidebarTab(v)}
            >
              <Tab
                value="styles"
                label="Styles"
              />
              <Tab
                value="block-configuration"
                label="Inspect"
              />
              <Tab
                value="global"
                label="Global"
              />
            </Tabs>
            {!mdUp && (
              <Stack
                alignItems="center"
                direction="row"
                spacing={0.5}
              >
                <IconButton
                  color="inherit"
                  onClick={() => {
                    toggleInspectorDrawerOpen();
                  }}
                >
                  <SvgIcon>
                    <XIcon />
                  </SvgIcon>
                </IconButton>
              </Stack>
            )}
          </Stack>
        </Box>
      </Box>
      <Box
        sx={{
          width: INSPECTOR_DRAWER_WIDTH,
          height: "calc(100% - 49px)",
          overflow: "auto",
        }}
      >
        {renderCurrentSidebarPanel()}
      </Box>
    </Drawer>
  );
}
