import { ResultError } from "./types/error";

export abstract class CONSTS {
  static readonly USER_KEY = "skillup_user";
  static readonly IMPERSONATED_USER_KEY = "wl_impersonated_user";
  static readonly DEFAULT_ORGANIZATION = "default";
}

export abstract class ERRORS {
  static readonly GENERAL_ERROR = {
    status: 501,
    message: "An error occurred. Please try again later.",
  } as ResultError;
  static readonly NOT_FOUND_ERROR = {
    status: 404,
    message: "Page not found error",
  } as ResultError;
  static readonly NOT_AUTHORIZED_ERROR = {
    status: 401,
    message: "Not authorized",
  } as ResultError;
}

export abstract class PRODUCT_ERRORS {
  static readonly USER_ALREADY_EXISTS = "Product.UserAlreadyExists";
}

export abstract class ORGANIZATION_ERRORS {
  static readonly USER_ALREADY_EXISTS = "Organization.UserAlreadyExists";
  static readonly DOMAIN_ALREADY_USED = "Organization.DomainAlreadyUsed";
}

export abstract class ORGANIZATION_PAYMENT_SETTINGS {
  static readonly WAY_FOR_PAY_SECRET_KEY = "way_for_pay_secret_key";
  static readonly WAY_FOR_PAY_MERCHANT_ACCOUNT = "way_for_pay_merchant_account";
  static readonly WAY_FOR_PAY_MERCHANT_DOMAIN = "way_for_pay_merchant_domain";
}

export abstract class ORGANIZATION_COLOR_SETTINGS {
  static readonly MAIN_COLOR = "main_color";
  static readonly DARK_COLOR = "dark_color";
  static readonly DARKEST_COLOR = "darkest_color";
  static readonly LIGHT_COLOR = "light_color";
  static readonly LIGHTEST_COLOR = "lightest_color";
  static readonly CONTRAST_TEXT_C0LOR = "contrast_text_color";
}

export abstract class ORGANIZATION_EMAIL_SETTINGS {
  static readonly IS_ENABLED = "email_sender_is_enabled";

  static readonly HOST = "email_sender_host";

  static readonly PORT = "email_sender_port";

  static readonly USERNAME = "email_sender_username";

  static readonly PASSWORD = "email_sender_password";

  static readonly NAME = "email_sender_from_name";

  static readonly EMAIL = "email_sender_from_email";
}

export abstract class ORG_ROLES {
  static readonly User = "User";

  static readonly Admin = "Admin";
}

export abstract class PRODUCT_SETTINGS_WEBHOOKS {
  static readonly INVITATION = "webhook_product_invitation";
}
