import type { FC } from 'react';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'src/store';

export const Logo: FC = () => {
  const theme = useTheme();
  const fillColor = theme.palette.primary.main;

  const { organization } = useSelector((state) => state.accountOrganization);

  if (organization?.logoUrl) {
    return <img src={organization.logoUrl} width={30} />
  }

  return (
    <svg
      fill="none"
      height="100%"
      viewBox="0 0 100 100"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M48.1815 13.3195C49.0679 12.8966 50.099 12.8934 50.988 13.3106L94.1315 33.5593C96.516 34.6784 96.6472 37.9991 94.3583 39.3003L86.3882 43.8312V73.4966C88.2642 73.9761 89.6505 75.6674 89.6505 77.6802C89.6505 80.066 87.703 82 85.3007 82C82.8984 82 80.951 80.066 80.951 77.6802C80.951 75.6674 82.3372 73.9761 84.2133 73.4966V45.0676L77.2021 49.0533C76.3434 49.5415 75.307 49.624 74.3714 49.2555C73.0507 48.7353 69.0701 47.2178 64.2802 45.863C59.4436 44.495 53.9872 43.3536 49.6465 43.465C45.7472 43.565 40.8779 44.7238 36.5617 46.042C32.2867 47.3476 28.7316 48.7534 27.5176 49.248C26.6166 49.615 25.6163 49.561 24.7683 49.1232L5.75771 39.3097C3.37227 38.0783 3.42636 34.6708 5.84971 33.5147L48.1815 13.3195ZM49.5979 17.4361L9.69385 36.4731L26.3339 45.0629C27.9105 44.4322 31.2909 43.1323 35.2832 41.9131C39.6894 40.5674 45.0217 39.2624 49.5341 39.1466C54.5591 39.0177 60.5369 40.3127 65.4717 41.7084C69.965 42.9793 73.764 44.3842 75.4883 45.0502L90.3877 36.5801L49.5979 17.4361Z" fill="#696BD8" />
      <path fillRule="evenodd" clipRule="evenodd" d="M28.7543 51.7616C28.1537 51.7616 27.6668 52.2451 27.6668 52.8416V62.561C27.6668 63.1575 28.1537 63.641 28.7543 63.641H73.339C73.9395 63.641 74.4264 63.1575 74.4264 62.561V52.8416C74.4264 52.2451 73.9395 51.7616 73.339 51.7616H28.7543ZM32.0166 56.0814V59.3212H70.0767V56.0814H32.0166Z" fill="#696BD8" />
      <path fillRule="evenodd" clipRule="evenodd" d="M27.6668 66.8808C27.6668 66.2844 28.1537 65.8009 28.7543 65.8009H73.339C73.9395 65.8009 74.4264 66.2844 74.4264 66.8808V76.6003C74.4264 77.1967 73.9395 77.6802 73.339 77.6802H28.7543C28.1537 77.6802 27.6668 77.1967 27.6668 76.6003V66.8808ZM32.0166 73.3605V70.1206H70.0767V73.3605H32.0166Z" fill="#696BD8" />
    </svg>
  );
};
