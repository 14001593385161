import type { AppThunk } from 'src/store';
import { fetchWrapper } from 'src/utils/fetch-wrapper';
import { api } from 'src/config';
import { slice as modulesSlice } from 'src/slices/admin/modules';

const getModules = (productId: string): AppThunk => async (dispatch): Promise<void> => {

  dispatch(modulesSlice.actions.setLoadingStatus(true));
  dispatch(modulesSlice.actions.setErrorStatus(false));
  try {
    var items = await fetchWrapper.get(`${api}/products/${productId}/modules`, {});
    dispatch(modulesSlice.actions.getModules(items));
  } catch (error) {
    dispatch(modulesSlice.actions.setErrorStatus(true));
  }
  dispatch(modulesSlice.actions.setLoadingStatus(false));
}

const updateModulesOrderRanks = (productId: string, moduleIds: string[]): AppThunk => async (dispatch): Promise<void> => {
  await fetchWrapper.put(`${api}/products/${productId}/modules/order-ranks`, { moduleIds: moduleIds });
}

const moveModuleUp = (productId: string, moduleId: string): AppThunk => async (dispatch): Promise<void> => {
  dispatch(modulesSlice.actions.moveModuleUp(moduleId));
}

const enableModule = (productId: string, moduleId: string): AppThunk => async (dispatch): Promise<void> => {
  await fetchWrapper.put(`${api}/products/${productId}/modules/${moduleId}/enable`, {});
  dispatch(modulesSlice.actions.enableModule(moduleId));
}

const disableModule = (productId: string, moduleId: string): AppThunk => async (dispatch): Promise<void> => {
  await fetchWrapper.put(`${api}/products/${productId}/modules/${moduleId}/disable`, {});
  dispatch(modulesSlice.actions.disableModule(moduleId));
}

const moveModuleDown = (productId: string, moduleId: string): AppThunk => async (dispatch): Promise<void> => {
  dispatch(modulesSlice.actions.moveModuleDown(moduleId));
}

export const thunks = {
  getModules,
  moveModuleUp,
  moveModuleDown,
  enableModule,
  disableModule,
  updateModulesOrderRanks
};
