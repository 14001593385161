import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { ProductUserResponse } from 'src/types/admin/product-users';

interface ProductsUsersState {
  isLoading: boolean;
  hasLoadingError: boolean;
  items: ProductUserResponse;
}

type GetProductUsersAction = PayloadAction<ProductUserResponse>;
type ProductUsersLoadingStatusAction = PayloadAction<boolean>;
type ProductUsersErrorStatusAction = PayloadAction<boolean>;

const initialState: ProductsUsersState = {
  isLoading: false,
  hasLoadingError: false,
  items: {
    items: [],
    totalCount: 0,
    top: 10,
    page: 1,
    lastItemDateTime: null
  }
};

const reducers = {
  setLoadingStatus: (state: ProductsUsersState, action: ProductUsersLoadingStatusAction): void => {
    state.isLoading = action.payload;
  },
  setErrorStatus: (state: ProductsUsersState, action: ProductUsersErrorStatusAction): void => {
    state.hasLoadingError = action.payload;
  },
  getUsers: (state: ProductsUsersState, action: GetProductUsersAction): void => {
    state.items = action.payload;
  },
};

export const slice = createSlice({
  name: 'admin-product-users',
  initialState,
  reducers
});

export const { reducer } = slice;
