import type { AppThunk } from 'src/store';
import { fetchWrapper } from 'src/utils/fetch-wrapper';
import { api } from 'src/config';
import { slice as productsSlice } from 'src/slices/panel/account-products';
import { GetProductsQueryParams } from 'src/types/panel/account-product';
import { urlParams } from 'src/utils/url-params';

const getProducts = (params: GetProductsQueryParams): AppThunk => async (dispatch): Promise<void> => {

  dispatch(productsSlice.actions.setLoadingStatus(true));
  dispatch(productsSlice.actions.setErrorStatus(false));
  try {
    var response = await fetchWrapper.get(`${api}/account/products${urlParams(params)}`, {});
    dispatch(productsSlice.actions.getProducts(response));
  } catch (error) {
    dispatch(productsSlice.actions.setErrorStatus(true));
  }
  dispatch(productsSlice.actions.setLoadingStatus(false));
}

export const thunks = {
  getProducts
};
