import { Stack } from '@mui/material';
import { Seo } from 'src/components/seo';
import { usePageView } from 'src/hooks/use-page-view';
import type { Page as PageType } from 'src/types/page';
import { tokens } from 'src/locales/tokens';
import { useTranslation } from 'react-i18next';
import { OrganizationCreateForm } from 'src/sections/admin/organizations/organization-create-form';
import { Container } from '@mui/system';

const Page: PageType = () => {
  const { t, i18n } = useTranslation();

  usePageView();


  return (
    <>
      <Seo title={t(tokens.organization.create.title) as string} />
      <Container maxWidth="sm">
        <Stack
          spacing={3}
          sx={{ mb: 3 }}
        >
          <OrganizationCreateForm />
        </Stack>
      </Container>
    </>
  );
};

export default Page;
