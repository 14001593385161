import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { Organization } from 'src/types/admin/organization';
import { ResultError } from 'src/types/error';

interface OrganizationState {
  isLoading: boolean;
  error: ResultError | undefined;
  organization: Organization | undefined;
}

type GetAccountOrganizationAction = PayloadAction<Organization | undefined>;
type OrganizationLoadingStatusAction = PayloadAction<boolean>;
type OrganizationErrorStatusAction = PayloadAction<ResultError | undefined>;

const initialState: OrganizationState = {
  isLoading: false,
  error: undefined,
  organization: undefined
};

const reducers = {
  setLoadingStatus: (state: OrganizationState, action: OrganizationLoadingStatusAction): void => {
    state.isLoading = action.payload;
  },
  setErrorStatus: (state: OrganizationState, action: OrganizationErrorStatusAction): void => {
    state.error = action.payload;
  },
  getOrganization: (state: OrganizationState, action: GetAccountOrganizationAction): void => {
    state.organization = action.payload;
  },
};

export const slice = createSlice({
  name: 'organization',
  initialState,
  reducers
});

export const { reducer } = slice;
