import type { FC } from 'react';
import {
  Box, SvgIcon
} from '@mui/material';
import { Stack } from '@mui/system';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';

interface ErrorMessageProps {
  text: string;
}

export const ErrorMessage: FC<ErrorMessageProps> = ({ text }) => {

  return (
    <>
      <Stack
        alignItems="center"
        direction="row"
        spacing={1}>
        <SvgIcon
          color='error'>
          <ReportOutlinedIcon />
        </SvgIcon>
        <Box>
          {text}
        </Box>
      </Stack>
    </>
  );
};