import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Icon, IconButton, Stack } from '@mui/material';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHorizOutlined';
import React from 'react';

export interface OptionItem {
  text: string;
  iconName: string;
  onClick: () => void;
}

interface OptionMenuButtonProps {
  items: OptionItem[];
}

export const OptionMenuButton: FC<OptionMenuButtonProps> = ({
  items,
}) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        // sx={{
        //   position: 'absolute',
        //   right: 0,
        //   top: -7,
        // }}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >

        {items.map((item, index) => (

          <Stack
            key={index}>
            <MenuItem onClick={() => {
              item.onClick();
              handleClose();
            }}
            >
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}>
                <Icon baseClassName={'material-icons-outlined'}
                  fontSize='medium'
                  sx={{
                    display: 'block'
                  }}
                  color='primary'>{item.iconName}</Icon>
                <Box>{item.text}</Box>
              </Stack>
            </MenuItem>
            {items.length - 1 !== index &&
              <Divider sx={{ my: 0.5 }} />
            }
          </Stack>
        ))}
      </Menu>
    </>
  )
}