import { Card, CardContent, Container, Stack, Typography } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Seo } from "src/components/seo";
import { usePageView } from "src/hooks/use-page-view";
import { useSearchParams } from "src/hooks/use-search-params";
import { tokens } from "src/locales/tokens";
import type { Page as PageType } from "src/types/page";
import { PaymentStatus } from "src/types/payment";

const Page: PageType = () => {
  usePageView();
  const { t } = useTranslation();

  const searchParams = useSearchParams();
  const email = searchParams.get("email") ?? "";
  const productTitle = searchParams.get("productTitle") ?? "";
  const status = searchParams.get("status") ?? "unknown";
  const orderReference = searchParams.get("orderReference") ?? "";

  const paymentStatus =
    PaymentStatus[status.toLowerCase() as keyof typeof PaymentStatus];

  const getStatus = useCallback(() => {
    switch (paymentStatus) {
      case PaymentStatus.approved:
        return t(tokens.payment.statuses.approved);
      case PaymentStatus.pending:
        return t(tokens.payment.statuses.pending);
      case PaymentStatus.declined:
        return t(tokens.payment.statuses.declined);
      default:
        return t(tokens.payment.statuses.unknown);
    }
  }, [paymentStatus, t]);

  const getStatusMessage = useCallback(() => {
    switch (paymentStatus) {
      case PaymentStatus.approved:
        return t(tokens.payment.success.messages.approved);
      case PaymentStatus.pending:
        return t(tokens.payment.success.messages.pending);
      case PaymentStatus.declined:
        return t(tokens.payment.success.messages.declined);
      default:
        return t(tokens.payment.success.messages.unknown);
    }
  }, [paymentStatus, t]);

  return (
    <>
      <Seo title={t(tokens.payment.success.title) as string} />
      <Container maxWidth="sm">
        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold" }}
              >
                {t(tokens.payment.success.header)}
              </Typography>
              <Typography variant="body1">
                <p>
                  {t(tokens.payment.success.product)}:{" "}
                  <strong>{productTitle}</strong>
                </p>
                <p>
                  {t(tokens.payment.success.orderReference)}:{" "}
                  <strong>{orderReference}</strong>
                </p>
                <p>
                  {t(tokens.payment.success.email)}: <strong>{email}</strong>
                </p>
                <p>
                  {t(tokens.payment.success.status)}:{" "}
                  <strong>{getStatus()}</strong>
                </p>
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold" }}
              >
                {getStatusMessage()}
              </Typography>
            </Stack>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default Page;
