import { api } from 'src/config';
import { slice } from 'src/slices/various';
import type { AppThunk } from 'src/store';
import { fetchWrapper } from 'src/utils/fetch-wrapper';

const handleLoader = (show: boolean): AppThunk => async (dispatch): Promise<any> => {
  await dispatch(slice.actions.handleLoader(show));
}

const uploadImage = (params: any): AppThunk => async (): Promise<void> =>
  await fetchWrapper.post(`${api}/images`, params);

export const thunks = {
  handleLoader,
  uploadImage
};
