import type { AppThunk } from 'src/store';
import { fetchWrapper } from 'src/utils/fetch-wrapper';
import { api } from 'src/config';
import { slice as productSettings } from 'src/slices/admin/product-settings';

const getProductSettings = (productId: string): AppThunk => async (dispatch): Promise<void> => {

  dispatch(productSettings.actions.setLoadingStatus(true));
  dispatch(productSettings.actions.setErrorStatus(false));
  try {
    var settings = await fetchWrapper.get(`${api}/products/${productId}/settings`, {});
    dispatch(productSettings.actions.getSettings(settings));
  } catch (error) {
    dispatch(productSettings.actions.setErrorStatus(true));
  }
  dispatch(productSettings.actions.setLoadingStatus(false));
}

const updateProductSetting = (productId: string, key: string, value: string): AppThunk => async (dispatch): Promise<void> => {
  var setting = await fetchWrapper.put(`${api}/products/${productId}/settings/${key}`, { value: value });
  return setting;
}

export const thunks = {
  getProductSettings,
  updateProductSetting,
};
