import { useCallback, useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import { Seo } from "src/components/seo";
import { usePageView } from "src/hooks/use-page-view";
import type { Page as PageType } from "src/types/page";
import { tokens } from "src/locales/tokens";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "src/store";
import { thunks } from "src/thunks/panel/products";
import { ProductList } from "src/sections/panel/products/product-list";

const Page: PageType = () => {
  const { t } = useTranslation();

  usePageView();

  const dispatch = useDispatch();
  const { items, hasLoadingError, isLoading } = useSelector(
    (state) => state.accountProducts
  );

  useEffect(() => {
    loadProducts(items.page, items.top);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadProducts = useCallback(
    async (page: number, top: number) => {
      await dispatch(
        thunks.getProducts({
          page: page,
          top: top,
        })
      );
    },
    [dispatch]
  );

  return (
    <>
      <Seo title={t(tokens.panel.products.list.pageTitle) as string} />
      <Stack
        spacing={3}
        sx={{ mb: 3 }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={4}
        >
          <Stack spacing={1}>
            <Typography variant="h4">
              {t(tokens.admin.products.list.pageHeader)}
            </Typography>
          </Stack>
        </Stack>

        <ProductList
          count={items.totalCount}
          items={items.items}
          onPageChange={(event, newPage) => {
            loadProducts(newPage + 1, items.top);
          }}
          onRowsPerPageChange={(input) => {
            loadProducts(1, Number.parseInt(input.target.value));
          }}
          onReset={() => loadProducts(1, items.top)}
          page={items.page}
          top={items.top}
          isLoading={isLoading}
          hasError={hasLoadingError}
        />
      </Stack>
    </>
  );
};

export default Page;
