import type { FC } from 'react';
import {
  Box,
  Container,
  Divider,
  Link,
  Stack,
} from '@mui/material';
import { RouterLink } from 'src/components/router-link';
import { useAuth } from 'src/hooks/use-auth';

interface Links {
  title: string;
  path: string;
  external: boolean;
}

const links: Links[] = [
  {
    title: 'Privacy Policy',
    path: '/privacy-policy',
    external: false
  },
  {
    title: 'Terms of Use',
    path: '/terms-of-use',
    external: false
  },
  {
    title: 'support@flitedu.net',
    path: 'mailto:support@flitedu.net',
    external: true
  }
];

export const Footer: FC = (props) => {
  const { organization } = useAuth();

  return (

    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.mode === 'dark'
          ? 'neutral.800'
          : 'neutral.50',
        borderTopColor: 'divider',
        borderTopStyle: 'solid',
        borderTopWidth: 1,
        pb: 6,
        pt: {
          md: 2,
          xs: 2
        }
      }}
      {...props}
    >
      <Container maxWidth="lg">
        <Stack
          alignItems="flex-start"
          direction={{ xs: 'column', sm: 'column', md: 'row' }}
          justifyContent="space-between"
          // display="inline-flex"
          spacing={3}
          sx={{ textDecoration: 'none' }}
        >

          <Box
            sx={{
              color: 'text.primary',
              // fontFamily: '\'Plus Jakarta Sans\', sans-serif',
              fontSize: 14,
              fontWeight: 500,
              // letterSpacing: '0.3px',
              lineHeight: 2.5,
              '& span': {
                color: 'primary.main'
              }
            }}
          >
            {organization ?
              <>
                <span>{organization.name}</span>
              </> :
              <>
                Flit<span>Edu</span>
              </>
            }

            © All Rights Reserved.
          </Box>
          <Stack
            spacing={3}
            direction={{ xs: 'column', sm: 'row', md: 'row' }}
          >

            {links.map((item) => {
              const linkProps = item.external
                ? {
                  component: 'a',
                  href: item.path,
                  target: '_blank'
                }
                : {
                  component: RouterLink,
                  href: item.path
                };

              return (
                <Stack
                  alignItems="center"
                  direction="row"
                  key={item.title}
                  spacing={2}
                >
                  <Link
                    color="text.primary"
                    variant="subtitle2"
                    {...linkProps}
                  >
                    {item.title}
                  </Link>

                </Stack>
              )
            })}
          </Stack>
        </Stack>

      </Container>
    </Box >
  );
}