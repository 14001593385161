import { useEffect } from 'react';
import { Seo } from 'src/components/seo';
import { usePageView } from 'src/hooks/use-page-view';
import { useRouter } from 'src/hooks/use-router';
import { paths } from 'src/paths';
import type { Page as PageType } from 'src/types/page';

const Page: PageType = () => {
  usePageView();

  const router = useRouter();

  useEffect(() => {
    router.replace(paths.panel.products.list);
  }, []);

  return (
    <>
      <Seo title='Landing' />

      {/* <HomeCreateCareerPath /> */}

      {/* <a href="https://secure.wayforpay.com/button/b312728d44645">Оплатити</a> */}
    </>
  );
};

export default Page;
