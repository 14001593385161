import { useState, type FC, useCallback } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Link,
  Stack,
  SvgIcon,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { RouterLink } from "src/components/router-link";
import { Scrollbar } from "src/components/scrollbar";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import { createRoute } from "react-router-url-params";
import { paths } from "src/paths";
import { Module } from "src/types/admin/module";
import { thunks } from "src/thunks/admin/module";
import { thunks as modulesThunks } from "src/thunks/admin/modules";
import { useDispatch } from "src/store";
import { toast } from "react-hot-toast";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import { TableLoadingRow } from "src/sections/common/table-loading-row";
import { Product } from "src/types/admin/product";

interface ModuleListTableProps {
  product: Product;
  items: Module[] | [];
  isLoading: boolean;
  hasError: boolean;
  onReset: () => void;
  onMoveUp: (index: number) => void;
  onMoveDown: (index: number) => void;
}

export const ModuleListTable: FC<ModuleListTableProps> = (props) => {
  const { t } = useTranslation();
  const {
    items = null,
    onReset = () => {},
    onMoveUp = () => {},
    onMoveDown = () => {},
    isLoading,
    product,
    hasError,
  } = props;

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [moduleId, setModuleId] = useState("");

  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    setModuleId("");
    setOpenDeleteDialog(false);
  }, []);

  const handleOpenDialog = useCallback((id: string) => {
    setModuleId(id);
    setOpenDeleteDialog(true);
  }, []);

  const deleteModule = useCallback(async () => {
    try {
      if (product) {
        await dispatch(thunks.deleteModule(product.id, moduleId));
        handleClose();
        onReset();
      }
    } catch (err) {
      toast.error(t(tokens.general.formError));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, moduleId]);

  return (
    <Box sx={{ position: "relative" }}>
      <Scrollbar>
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              <TableCell>
                {t(tokens.admin.products.details.table.title) as string}
              </TableCell>
              {product.productPlans.length > 0 && (
                <TableCell>
                  {
                    t(
                      tokens.admin.products.details.table.productPlans
                    ) as string
                  }
                </TableCell>
              )}
              <TableCell>
                {t(tokens.admin.products.details.table.active) as string}
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  minWidth: 60,
                  pl: 3,
                }}
              >
                {t(tokens.admin.products.details.table.actions)}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableLoadingRow
              colSpan={4}
              isLoading={isLoading}
              hasError={hasError}
              itemsCount={items?.length ?? 0}
              noItemsText={
                t(tokens.admin.products.details.noModulesFound) as string
              }
              errorLoadingText={
                t(tokens.admin.products.details.errorLoadingModules) as string
              }
            />
            {items?.map((module, index) => {
              return (
                <TableRow
                  hover
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  key={module.id}
                >
                  <TableCell>
                    <Link
                      color="inherit"
                      component={RouterLink}
                      href={createRoute(paths.admin.modules.details).link({
                        productId: product.id,
                        moduleId: module.id,
                      })}
                      variant="subtitle2"
                    >
                      {module.title}
                    </Link>
                  </TableCell>
                  {product.productPlans.length > 0 && (
                    <TableCell>
                      {module.productPlans.map((plan) => plan.name).join(", ")}
                    </TableCell>
                  )}
                  <TableCell>
                    <Switch
                      onChange={(e) => {
                        if (e.target.checked) {
                          dispatch(
                            modulesThunks.enableModule(product.id, module.id)
                          );
                        } else {
                          dispatch(
                            modulesThunks.disableModule(product.id, module.id)
                          );
                        }
                      }}
                      checked={module.isActive}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Stack
                      alignContent={"center"}
                      justifyContent={"right"}
                      direction={"row"}
                      spacing={1}
                    >
                      <IconButton
                        disabled={index === 0}
                        onClick={() => {
                          onMoveUp(index);
                        }}
                      >
                        <SvgIcon>
                          <ArrowUpwardOutlinedIcon />
                        </SvgIcon>
                      </IconButton>

                      <IconButton
                        disabled={index + 1 === items.length}
                        onClick={() => {
                          onMoveDown(index);
                        }}
                      >
                        <SvgIcon>
                          <ArrowDownwardOutlinedIcon />
                        </SvgIcon>
                      </IconButton>

                      <IconButton onClick={() => handleOpenDialog(module.id)}>
                        <SvgIcon>
                          <DeleteOutlineOutlinedIcon color="error" />
                        </SvgIcon>
                      </IconButton>
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
      <Dialog
        open={openDeleteDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t(tokens.admin.products.details.deleteModuleDialogTitle)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t(tokens.admin.products.details.deleteModuleDialogDescription)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={deleteModule}
            color="error"
          >
            {t(tokens.general.buttons.delete)}
          </Button>
          <Button onClick={handleClose}>
            {t(tokens.general.buttons.cancel)}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
