import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { Module } from 'src/types/admin/module';
import { ResultError } from 'src/types/error';

interface ModuleState {
  isLoading: boolean;
  error: ResultError | undefined;

  isSaving: boolean;
  hasSavingError: boolean;
  item: Module | undefined;
}

type LoadModuleAction = PayloadAction<Module | undefined>;
type ModuleLoadingAction = PayloadAction<boolean>;
type ModuleLoadingErrorAction = PayloadAction<ResultError | undefined>;
type ModuleSavingAction = PayloadAction<boolean>;
type ModuleSavingErrorAction = PayloadAction<boolean>;

const initialState: ModuleState = {
  isLoading: false,
  error: undefined,
  isSaving: false,
  hasSavingError: false,
  item: undefined
};

const reducers = {
  setLoadingStatus: (state: ModuleState, action: ModuleLoadingAction): void => {
    state.isLoading = action.payload;
  },
  setLoadingErrorStatus: (state: ModuleState, action: ModuleLoadingErrorAction): void => {
    state.error = action.payload;
  },
  setModule: (state: ModuleState, action: LoadModuleAction): void => {
    state.item = action.payload;
  },
  setSavingStatus: (state: ModuleState, action: ModuleSavingAction): void => {
    state.isSaving = action.payload;
  },
  setSavingErrorStatus: (state: ModuleState, action: ModuleSavingErrorAction): void => {
    state.hasSavingError = action.payload;
  },
};

export const slice = createSlice({
  name: 'admin-module',
  initialState,
  reducers
});

export const { reducer } = slice;
