import axios, { AxiosRequestHeaders, AxiosRequestConfig, CancelTokenSource, Method } from 'axios';
import { store } from 'src/store';
import { thunks } from 'src/thunks/various';
import { api } from 'src/config';
import { CONSTS, ERRORS } from 'src/consts';

export const fetchWrapper = {
  get: request('get'),
  post: request('post'),
  put: request('put'),
  delete: request('delete')
};

let isRefreshing = false;
let refreshSubscribers: any = [];

const subscribeTokenRefresh = (callback: Function) => refreshSubscribers.push(callback);
const onRefreshed = (token: string) => refreshSubscribers.map((callback: Function) => callback(token));

function request(method: Method) {
  return (url: string, body: any, cancelToken?: CancelTokenSource, language?: string) => {
    const params: AxiosRequestConfig = {
      method: method,
      data: body,
      headers: {
        ...authHeader(url),
        'Domain': window.location.hostname,
        'Content-Type': 'application/json'
      } as AxiosRequestHeaders
    };

    if (language) {
      params.headers!['Accept-Language'] = language;
    }

    if (cancelToken)
      params.cancelToken = cancelToken.token;
    if (!cancelToken || cancelToken === null) {
      store.dispatch(thunks.handleLoader(true));
    }

    return axios(url, params)
      .then(response => {
        store.dispatch(thunks.handleLoader(false));
        return response.data;
      })
      .catch((error) => {
        if (error.message === 'canceled')
          return null;

        const originalRequest = error.config;
        const originalResponse = error.response;

        if (error?.response?.status === 401) {
          const token = accessToken();
          const isLoggedIn = !!token;

          if (isLoggedIn) {
            //refresh tokens
            if (!isRefreshing) {
              isRefreshing = true;
              (async (): Promise<any> => {
                try {
                  const data = await fetchWrapper.post(`${api}/account/token/refresh`, {
                    accessToken: accessToken(),
                    refreshToken: refreshToken()
                  })

                  var isImpersonated = true;
                  var user = localStorage.getItem(CONSTS.IMPERSONATED_USER_KEY);
                  if (!user) {
                    user = localStorage.getItem(CONSTS.USER_KEY);
                    isImpersonated = false;
                  }

                  if (user) {
                    const parsedUser = JSON.parse(user);
                    parsedUser.accessToken = data.accessToken;
                    parsedUser.refreshToken = data.refreshToken;
                    if (isImpersonated) {
                      localStorage.setItem(CONSTS.IMPERSONATED_USER_KEY, JSON.stringify(parsedUser));
                    } else {
                      localStorage.setItem(CONSTS.USER_KEY, JSON.stringify(parsedUser));
                    }
                  }

                  isRefreshing = false;
                  onRefreshed(data.accessToken);
                } catch (authError) {
                  if (authError?.response?.status === 401) {
                    localStorage.removeItem(CONSTS.USER_KEY);
                    localStorage.removeItem(CONSTS.IMPERSONATED_USER_KEY);
                    //localStorage.removeItem(CONSTS.PROFILE_ID_KEY);
                    window.location.reload();
                  }
                }
              })();
            }
            else {
              if (originalRequest.url?.includes('refresh') && originalResponse.status === 401) {
                localStorage.removeItem(CONSTS.USER_KEY);
                localStorage.removeItem(CONSTS.IMPERSONATED_USER_KEY);
                window.location.reload();
              }
            }

            const retryOrigRequest = new Promise((resolve, reject) => {
              subscribeTokenRefresh((token: string) => {
                originalRequest.headers['Authorization'] = 'Bearer ' + token;
                resolve(axios(originalRequest).then(response => {
                  store.dispatch(thunks.handleLoader(false));
                  return response.data;
                }));
              });
            });
            return retryOrigRequest;
          }

          localStorage.removeItem(CONSTS.USER_KEY);
          localStorage.removeItem(CONSTS.IMPERSONATED_USER_KEY);
          window.location.reload();

          throw ERRORS.NOT_AUTHORIZED_ERROR;
        }

        store.dispatch(thunks.handleLoader(false));
        throw error.response.data;
      });
  }
}

function authHeader(url: string) {
  const token = accessToken();
  const isLoggedIn = !!token;
  const isApiUrl = url.includes('api');
  const isRefresh = isApiUrl && url.includes('refresh');

  if (isRefresh) {
    return {};
  }

  if (isLoggedIn && isApiUrl) {
    return { 'Authorization': `Bearer ${token}` };
  } else {
    return {};
  }
}

function accessToken() {
  var user = localStorage.getItem(CONSTS.IMPERSONATED_USER_KEY);
  if (!user) {
    user = localStorage.getItem(CONSTS.USER_KEY);
  }

  if (user)
    return JSON.parse(user).accessToken;
  return null;
}

function refreshToken() {
  var user = localStorage.getItem(CONSTS.IMPERSONATED_USER_KEY);
  if (!user) {
    user = localStorage.getItem(CONSTS.USER_KEY);
  }

  if (user)
    return JSON.parse(user).refreshToken;
  return null;
}