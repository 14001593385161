import { Avatar, Button, Card, CardActions, CardContent, CardHeader, Container, Divider, List, ListItem, ListItemAvatar, ListItemText, Stack, SvgIcon, Typography } from '@mui/material';
import { Seo } from 'src/components/seo';
import { usePageView } from 'src/hooks/use-page-view';
import type { Page as PageType } from 'src/types/page';
import { tokens } from 'src/locales/tokens';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'src/store';
import { useAuth } from 'src/hooks/use-auth';
import { RouterLink } from 'src/components/router-link';
import { paths } from 'src/paths';
import PlusIcon from '@untitled-ui/icons-react/build/esm/Plus';
import { toast } from 'react-hot-toast';
import { useRouter } from 'src/hooks/use-router';
import { EmptyMessage } from 'src/sections/common/empty-message';

const Page: PageType = () => {
  const { t, i18n } = useTranslation();

  usePageView();

  const dispatch = useDispatch();
  const { account, selectOrganization } = useAuth();
  const router = useRouter();

  return (
    <>
      <Seo title={t(tokens.organization.select.title) as string} />

      <Container maxWidth="sm">
        <Stack
          spacing={3}
          sx={{ mb: 3 }}
        >
          <Card>
            <CardHeader title={t(tokens.organization.select.header)}>
            </CardHeader>
            <Divider />
            {account?.organizations.length === 0 && (
              <>
                <CardContent>
                  <EmptyMessage text={t(tokens.organization.select.noOrganizations)} />
                </CardContent>
              </>
            )}
            <List disablePadding>
              {account?.organizations.map((organization, index) => (
                <ListItem
                  sx={{
                    '&:hover': {
                      backgroundColor: 'action.hover',
                      cursor: 'pointer',
                    },
                    padding: 2,
                  }}
                  onClick={async () => {
                    try {
                      await selectOrganization(organization.id);
                      router.replace(paths.panel.products.list);
                    }
                    catch (error) {
                      toast.error(t(tokens.organization.select.errorMessage));
                      console.error(error);
                    }
                  }}
                  divider={index !== account?.organizations.length - 1}
                  key={organization.id}
                >
                  <ListItemAvatar>
                    <Avatar
                      src={organization.logoUrl}
                      sx={{
                        color: 'common.white',
                        fontSize: 14,
                        fontWeight: 600,
                      }}
                    >
                      {organization.name.charAt(0)}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText>
                    <Stack
                      spacing={1}>
                      <Typography variant="h6">{organization.name}</Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary">
                        {organization.domain}
                      </Typography>
                    </Stack>
                  </ListItemText>
                </ListItem>
              ))}
              <Divider />
              <CardActions sx={{ justifyContent: 'center' }}>
                <Button
                  color="inherit"
                  size="small"
                  component={RouterLink}
                  href={paths.organizations.create}
                  startIcon={(
                    <SvgIcon>
                      <PlusIcon />
                    </SvgIcon>
                  )}
                >
                  {t(tokens.organization.select.newOrganization)}
                </Button>
              </CardActions>

            </List>
          </Card>
        </Stack>
      </Container>
    </>
  );
};

export default Page;
