import type { FC } from 'react';
import { FileDropzone, FileImage, FilePickerScenario } from 'src/components/file-dropzone';
import { useState, useCallback } from 'react';
import { useDispatch } from 'src/store';
import { tokens } from 'src/locales/tokens';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { thunks } from 'src/thunks/various';

interface ImagePickerProps {
  images?: FileImage[];
  onGenerate?: (...args: any[]) => void;
  onRemove?: (...args: any[]) => void;
  onUpload?: (...args: any[]) => Promise<any>;
  scenario?: FilePickerScenario;
  compact?: boolean;
  children?: JSX.Element;
}

export const ImagePicker: FC<ImagePickerProps> = ({ children, images, onGenerate, onRemove, onUpload, compact, scenario }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState<boolean>(false);
  const [imageGenerating, setImageGenerating] = useState<boolean>(false);
  const [files, setFiles] = useState<FileImage[]>(images || []);

  const handleFilesDrop = useCallback(
    async (newFiles: File[]) => {
      try {
        setImageGenerating(true);
        const formData = new FormData();
        await Promise.all(newFiles.map(async (newFile) => {
          formData.append('file', newFile, newFile.name)
          const newFileResponse: FileImage = await dispatch(thunks.uploadImage(formData));
          setFiles((prevState) => [...prevState, newFileResponse]);
          onGenerate && onGenerate(newFileResponse);
        }));
      }
      catch (err) {
        if (err.status === 422) {
          toast.error(t(tokens.general.filepicker.fileSizeError));
        }
        else {
          toast.error(t(tokens.general.formError));
        }
        console.error(err);
      }
      finally {
        setImageGenerating(false);
      }
    },
    [dispatch, onGenerate, t]
  );

  const handleFileRemove = useCallback(
    (_file: FileImage): void => {
      setFiles((prevState) => {
        return prevState.filter((fileImage) => {
          if (fileImage.id !== _file.id) {
            return true;
          }
          onRemove && onRemove(fileImage);
          return false;
        });
      });

    },
    [onRemove]
  );

  const handleFilesRemoveAll = useCallback(
    (): void => {
      setFiles([]);
    },
    []
  );

  const handleOnUpload = useCallback(
    async () => {
      try {
        setUploading(true);
        onUpload && await onUpload(files[0].id, files[0].resizedUrl);
        setFiles([]);
        toast.success(t(tokens.general.filepicker.imageUploaded));
      } catch (err) {
        console.error(err);
        toast.error(t(tokens.general.formError));
      }
      finally {
        setUploading(false);
      }
    },
    [onUpload, files, t]
  );

  return (
    <FileDropzone
      accept={{ 'image/*': ['.jpeg', '.jpg', '.png', '.jpe', '.jif', '.jfif'], }}
      multiple={false}
      scenario={scenario}
      compact={compact}
      caption={t(tokens.general.filepicker.imageExtensions) as string}
      uploading={uploading}
      fileProcessing={imageGenerating}
      files={files}
      onDrop={handleFilesDrop}
      onRemove={handleFileRemove}
      onRemoveAll={handleFilesRemoveAll}
      onUpload={onUpload && handleOnUpload}
      children={children}
    />
  )
}