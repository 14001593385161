import { type ChangeEvent, type FC, type MouseEvent, useCallback } from "react";
import {
  Box,
  Chip,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { Scrollbar } from "src/components/scrollbar";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import moment from "moment";
import { TableLoadingRow } from "src/sections/common/table-loading-row";
import { PaymentStatus } from "src/types/payment";
import { ProductPayment } from "src/types/admin/product-payments";
import { Product } from "src/types/admin/product";

interface PaymentsListTableProps {
  product: Product;
  count: number | null;
  items: ProductPayment[] | [];
  onPageChange: (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  onRowsPerPageChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onReset: () => void;
  onUpdate: () => void;
  page: number;
  top: number;
  isLoading: boolean;
  hasError: boolean;
}

export const PaymentsListTable: FC<PaymentsListTableProps> = (props) => {
  const { t } = useTranslation();
  const {
    count = 0,
    product,
    items = null,
    onPageChange = () => {},
    onRowsPerPageChange,
    onReset = () => {},
    onUpdate = () => {},
    page = 0,
    top = 10,
    isLoading,
    hasError,
  } = props;
  const showPagination = !!page && !!top && !!count;

  const getStatus = useCallback(
    (status: PaymentStatus) => {
      switch (status) {
        case PaymentStatus.approved:
          return t(tokens.payment.statuses.approved);
        case PaymentStatus.pending:
          return t(tokens.payment.statuses.pending);
        case PaymentStatus.declined:
          return t(tokens.payment.statuses.declined);
        case PaymentStatus.refunded:
          return t(tokens.payment.statuses.refunded);
        default:
          return t(tokens.payment.statuses.noPayment);
      }
    },
    [t]
  );

  const getChipStatus = useCallback((status: PaymentStatus) => {
    switch (status) {
      case PaymentStatus.approved:
        return "success";
      case PaymentStatus.pending:
        return "info";
      case PaymentStatus.declined:
        return "error";
      case PaymentStatus.refunded:
        return "warning";
      default:
        return "default";
    }
  }, []);

  return (
    <Box sx={{ position: "relative" }}>
      <Scrollbar>
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              <TableCell>
                {
                  t(
                    tokens.admin.products.details.payments.table.orderReference
                  ) as string
                }
              </TableCell>
              <TableCell>
                {t(tokens.admin.products.details.payments.table.user) as string}
              </TableCell>
              <TableCell>
                {t(tokens.admin.products.details.payments.table.date) as string}
              </TableCell>
              <TableCell>
                {
                  t(
                    tokens.admin.products.details.payments.table.amount
                  ) as string
                }
              </TableCell>

              <TableCell>
                {
                  t(
                    tokens.admin.products.details.payments.table.paymentStatus
                  ) as string
                }
              </TableCell>

              {product.productPlans.length > 0 && (
                <TableCell>
                  {
                    t(
                      tokens.admin.products.details.payments.table.productPlan
                    ) as string
                  }
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableLoadingRow
              colSpan={4}
              isLoading={isLoading}
              hasError={hasError}
              itemsCount={items?.length ?? 0}
              noItemsText={
                t(
                  tokens.admin.products.details.payments.table.noPaymentsFound
                ) as string
              }
              errorLoadingText={
                t(
                  tokens.admin.products.details.payments.table
                    .errorLoadingPayments
                ) as string
              }
            />
            {items?.map((payment) => {
              return (
                <TableRow
                  hover
                  key={payment.id}
                >
                  <TableCell>{payment.orderReference}</TableCell>
                  <TableCell>
                    <Stack>
                      <Typography>
                        {payment.user.firstName} {payment.user.lastName}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                      >
                        {payment.user.email}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell>{moment(payment.date).format("LLL")}</TableCell>
                  <TableCell>
                    {payment.amount} {payment.currency}
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={getStatus(payment.status)}
                      variant="outlined"
                      color={getChipStatus(payment.status)}
                    />
                  </TableCell>
                  {product.productPlans.length > 0 && (
                    <TableCell>
                      {payment.user.productPlan?.name ?? ""}
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
      {showPagination && (
        <TablePagination
          labelRowsPerPage={t(tokens.general.paginationRowsPerPage)}
          component="div"
          count={count}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          page={page - 1}
          rowsPerPage={top}
          rowsPerPageOptions={[5, 10, 25]}
        />
      )}
    </Box>
  );
};
