import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { ResultError } from 'src/types/error';
import { AccountModule } from 'src/types/panel/account-module';

interface ModuleState {
  isLoading: boolean;
  error: ResultError | undefined;
  item: AccountModule | undefined;
}

type LoadModuleAction = PayloadAction<AccountModule | undefined>;
type ModuleLoadingAction = PayloadAction<boolean>;
type ModuleLoadingErrorAction = PayloadAction<ResultError | undefined>;

const initialState: ModuleState = {
  isLoading: false,
  error: undefined,
  item: undefined
};

const reducers = {
  setLoadingStatus: (state: ModuleState, action: ModuleLoadingAction): void => {
    state.isLoading = action.payload;
  },
  setLoadingErrorStatus: (state: ModuleState, action: ModuleLoadingErrorAction): void => {
    state.error = action.payload;
  },
  getModule: (state: ModuleState, action: LoadModuleAction): void => {
    state.item = action.payload;
  },
};

export const slice = createSlice({
  name: 'account-module',
  initialState,
  reducers
});

export const { reducer } = slice;
