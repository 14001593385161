import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { Product } from "src/types/admin/product";
import { ResultError } from "src/types/error";
import { ProductPlan } from "src/types/product-plan";

interface ProductState {
  isLoading: boolean;
  error: ResultError | undefined;

  isSaving: boolean;
  hasSavingError: boolean;
  item: Product | undefined;
}

type LoadProductAction = PayloadAction<Product | undefined>;
type ProductLoadingAction = PayloadAction<boolean>;
type ProductLoadingErrorAction = PayloadAction<ResultError | undefined>;
type ProductSavingAction = PayloadAction<boolean>;
type ProductSavingErrorAction = PayloadAction<boolean>;

const initialState: ProductState = {
  isLoading: false,
  error: undefined,
  isSaving: false,
  hasSavingError: false,
  item: undefined,
};

const reducers = {
  setLoadingStatus: (
    state: ProductState,
    action: ProductLoadingAction
  ): void => {
    state.isLoading = action.payload;
  },
  setLoadingErrorStatus: (
    state: ProductState,
    action: ProductLoadingErrorAction
  ): void => {
    state.error = action.payload;
  },
  setProduct: (state: ProductState, action: LoadProductAction): void => {
    state.item = action.payload;
  },
  setSavingStatus: (state: ProductState, action: ProductSavingAction): void => {
    state.isSaving = action.payload;
  },
  setSavingErrorStatus: (
    state: ProductState,
    action: ProductSavingErrorAction
  ): void => {
    state.hasSavingError = action.payload;
  },
  setProductPlans: (
    state: ProductState,
    action: PayloadAction<ProductPlan[]>
  ): void => {
    if (state.item) {
      state.item.productPlans = action.payload;
    }
  },
};

export const slice = createSlice({
  name: "admin-product",
  initialState,
  reducers,
});

export const { reducer } = slice;
