import type { AppThunk } from 'src/store';
import { fetchWrapper } from 'src/utils/fetch-wrapper';
import { api } from 'src/config';
import { slice as productPaymentsSlice } from 'src/slices/admin/product-payments';
import { urlParams } from 'src/utils/url-params';
import { GetProductPaymentsQueryParams } from 'src/types/admin/product-payments';

const getProductPayments = (productId: string, params: GetProductPaymentsQueryParams): AppThunk => async (dispatch): Promise<void> => {

  dispatch(productPaymentsSlice.actions.setLoadingStatus(true));
  dispatch(productPaymentsSlice.actions.setErrorStatus(false));
  try {
    var users = await fetchWrapper.get(`${api}/products/${productId}/payments${urlParams(params)}`, {});
    dispatch(productPaymentsSlice.actions.getProductPayments(users));
  } catch (error) {
    dispatch(productPaymentsSlice.actions.setErrorStatus(true));
  }
  dispatch(productPaymentsSlice.actions.setLoadingStatus(false));
}

export const thunks = {
  getProductPayments
};
