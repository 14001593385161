export const gtmConfig = {
  containerId: process.env.REACT_APP_GTM_CONTAINER_ID
};

export const mixpanelConfig = {
  token: process.env.REACT_APP_MIXPANEL_TOKEN
};


export const clarityConfig = {
  containerId: process.env.REACT_APP_CLARITY_CONTAINER_ID
};

export const googleSignInConfig = {
  clientId: process.env.REACT_APP_GOOGLE_SIGIN_CLIENT_ID
};


export const mapboxConfig = {
  apiKey: process.env.REACT_APP_MAPBOX_API_KEY
};

export const api = process.env.REACT_APP_API_URL;