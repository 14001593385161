import type { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import { withAuthGuard } from "src/hocs/with-auth-guard";

interface LayoutProps {
  children?: ReactNode;
}

export const Layout: FC<LayoutProps> = withAuthGuard((props) => {
  const { children } = props;

  return <>{children}</>;
});

Layout.propTypes = {
  children: PropTypes.node,
};
