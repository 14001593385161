import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { Setting } from 'src/types/setting';

interface ProductSettingsState {
  isLoading: boolean;
  hasLoadingError: boolean;
  settings: Setting[];
}

type GetProductSettingsAction = PayloadAction<Setting[]>;
type ProductUsersLoadingStatusAction = PayloadAction<boolean>;
type ProductUsersErrorStatusAction = PayloadAction<boolean>;

const initialState: ProductSettingsState = {
  isLoading: false,
  hasLoadingError: false,
  settings: []
};

const reducers = {
  setLoadingStatus: (state: ProductSettingsState, action: ProductUsersLoadingStatusAction): void => {
    state.isLoading = action.payload;
  },
  setErrorStatus: (state: ProductSettingsState, action: ProductUsersErrorStatusAction): void => {
    state.hasLoadingError = action.payload;
  },
  getSettings: (state: ProductSettingsState, action: GetProductSettingsAction): void => {
    state.settings = action.payload;
  },
};

export const slice = createSlice({
  name: 'admin-product-settings',
  initialState,
  reducers
});

export const { reducer } = slice;
