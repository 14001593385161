import type { AppThunk } from "src/store";
import { fetchWrapper } from "src/utils/fetch-wrapper";
import { api } from "src/config";
import { slice as productSlice } from "src/slices/admin/product";
import { ERRORS } from "src/consts";

const createProduct =
  (value: any): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(productSlice.actions.setSavingErrorStatus(false));
    dispatch(productSlice.actions.setSavingStatus(true));
    try {
      var product = await fetchWrapper.post(`${api}/products`, value);
      dispatch(productSlice.actions.setProduct(product));
      return product;
    } catch (error) {
      dispatch(productSlice.actions.setSavingErrorStatus(true));
    }
    dispatch(productSlice.actions.setSavingStatus(false));
    return undefined;
  };

const updateProduct =
  (productId: string, value: any): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(productSlice.actions.setSavingErrorStatus(false));
    dispatch(productSlice.actions.setSavingStatus(true));
    try {
      var product = await fetchWrapper.put(
        `${api}/products/${productId}`,
        value
      );
      dispatch(productSlice.actions.setProduct(product));
      return product;
    } catch (error) {
      dispatch(productSlice.actions.setSavingErrorStatus(true));
    }
    dispatch(productSlice.actions.setSavingStatus(false));
    return undefined;
  };

const archiveProduct =
  (productId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    await fetchWrapper.post(`${api}/products/${productId}/archive`, {});
    dispatch(productSlice.actions.setProduct(undefined));
  };

const deleteProduct =
  (productId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    await fetchWrapper.delete(`${api}/products/${productId}`, {});
    dispatch(productSlice.actions.setProduct(undefined));
  };

const getProduct =
  (productId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(productSlice.actions.setLoadingErrorStatus(undefined));
    dispatch(productSlice.actions.setLoadingStatus(true));
    try {
      var product = await fetchWrapper.get(`${api}/products/${productId}`, {});
      dispatch(productSlice.actions.setProduct(product));
    } catch (error) {
      if (error?.code === "Product.ProductNotFound") {
        dispatch(
          productSlice.actions.setLoadingErrorStatus(ERRORS.NOT_FOUND_ERROR)
        );
      } else {
        dispatch(
          productSlice.actions.setLoadingErrorStatus(ERRORS.GENERAL_ERROR)
        );
      }
    }
    dispatch(productSlice.actions.setLoadingStatus(false));
  };

export const thunks = {
  createProduct,
  updateProduct,
  archiveProduct,
  deleteProduct,
  getProduct,
};
