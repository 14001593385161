import type { FC } from "react";
import { Button, Card, CardContent, CardHeader, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import { Organization } from "src/types/admin/organization";
import { Setting } from "src/types/setting";
import { RouterLink } from "src/components/router-link";
import { createRoute } from "react-router-url-params";
import { paths } from "src/paths";
import { EmailTemplateType } from "src/types/admin/email-template";

interface OrganizationEmailTemplateSettingsFormProps {
  organization: Organization;
  settings: Setting[];
}

export const OrganizationEmailTemplateSettingsForm: FC<
  OrganizationEmailTemplateSettingsFormProps
> = (props) => {
  const { t } = useTranslation();
  const { organization } = props;

  return (
    <Stack spacing={4}>
      <Card>
        <CardHeader
          title={t(
            tokens.admin.organization.details.settings.emailTemplates.header
          )}
        />
        <CardContent>
          <Button
            variant="contained"
            component={RouterLink}
            href={createRoute(paths.admin.organizations.emailTemplate).link({
              organizationId: organization.id,
              type: EmailTemplateType.SignIn,
            })}
          >
            {t(
              tokens.admin.organization.details.settings.emailTemplates
                .signInEmail
            )}
          </Button>
        </CardContent>
      </Card>
    </Stack>
  );
};
