export enum PaymentStatus {
  unknown,

  pending,

  approved,

  declined,

  refunded,
}