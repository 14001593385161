export enum OrganizationUserRole {
    none = 0,
    admin = 1,
    user = 2
}

export interface OrganizationUser {
    id: string;
    email: string;
    role: OrganizationUserRole;
    firstName: string | null;
    lastName: string | null;
}

export interface OrganizationUsersResponse {
    items: OrganizationUser[] | [];
    totalCount: number;
    top: number;
    page: number;
    lastItemDateTime: number | null;
}

export interface GetOrganizationUsersQueryParams {
    top?: number;
    page?: number;
    lastItemDateTime?: number;
}