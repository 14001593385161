import type { ChangeEvent } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
// import { subDays, subHours, subMinutes, subMonths } from 'date-fns';
import {
  Button,
  Card,
  Divider,
  Stack,
  SvgIcon,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Seo } from "src/components/seo";
import { usePageView } from "src/hooks/use-page-view";
// import { AccountBillingSettings } from 'src/sections/dashboard/account/account-billing-settings';
// import { AccountNotificationsSettings } from 'src/sections/dashboard/account/account-notifications-settings';
// import { AccountTeamSettings } from 'src/sections/dashboard/account/account-team-settings';
// import { AccountSecuritySettings } from 'src/sections/dashboard/account/account-security-settings';
import type { Page as PageType } from "src/types/page";
import { tokens } from "src/locales/tokens";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "src/store";
import { thunks as orgThunks } from "src/thunks/admin/organization";
import { thunks as usersThunks } from "src/thunks/admin/organization-users";
import { thunks as orgSettingsThunks } from "src/thunks/admin/organization-settings";
import { useParams } from "react-router";
import PlusIcon from "@untitled-ui/icons-react/build/esm/Plus";
import { RouterLink } from "src/components/router-link";
import NotFound from "src/sections/errors/not-found";
import { ERRORS } from "src/consts";
import ServerError from "src/sections/errors/server-error";
import { NewUserDrawer } from "src/sections/admin/organizations/new-user-drawer";
import { UserListTable } from "src/sections/admin/organizations/user-list-table";
import { OrganizationEditForm } from "src/sections/admin/organizations/organization-edit-form";
import { OrganizationSettingsForm } from "src/sections/admin/organizations/settings/organization-settings-form";

const DETAILS_TAB = "details";
const SETTINGS_TAB = "settings";
const USERS_TAB = "users";

const Page: PageType = () => {
  const { t } = useTranslation();

  const tabs = useMemo(
    () => [
      {
        label: t(tokens.admin.organization.details.tabs.details) as string,
        value: DETAILS_TAB,
      },
      {
        label: t(tokens.admin.organization.details.tabs.settings) as string,
        value: SETTINGS_TAB,
      },
      {
        label: t(tokens.admin.organization.details.tabs.users) as string,
        value: USERS_TAB,
      },
    ],
    [t]
  );

  usePageView();

  const dispatch = useDispatch();
  const { organizationId } = useParams();

  const {
    items: users,
    hasLoadingError: hasUsersLoadingError,
    isLoading: isUsersLoading,
  } = useSelector((state) => state.adminOrganizationUsers);
  const {
    organization,
    error: orgError,
    isLoading: orgIsLoading,
  } = useSelector((state) => state.adminOrganization);
  const {
    settings: productSettings,
    hasLoadingError: hasProductSettingLoadingError,
    isLoading: isProductSettingsLoading,
  } = useSelector((state) => state.adminProductSettings);

  const loadUsers = useCallback(
    async (page: number, top: number) => {
      if (organizationId) {
        await dispatch(
          usersThunks.getOrganizationUsers(organizationId, {
            page: page,
            top: top,
          })
        );
      }
    },
    [dispatch, organizationId]
  );



  const handleTabsChange = useCallback(
    (event: ChangeEvent<{}>, value: string): void => {
      setCurrentTab(value);
    },
    []
  );

  const loadSettings = useCallback(async () => {
    if (organizationId) {
      await dispatch(orgSettingsThunks.getSettings(organizationId));
    }
  }, [dispatch, organizationId]);

  const loadData = useCallback(async () => {
    if (organizationId) {
      await dispatch(orgThunks.getOrganization(organizationId));
      await loadSettings();
      await loadUsers(users.page, users.top);
    }
  }, [
    dispatch,
    loadSettings,
    loadUsers,
    organizationId,
    users.page,
    users.top,
  ]);

  useEffect(() => {
    loadData();
  }, [loadData, organizationId]);

  const [showNewUserDrawer, setShowNewUserDrawer] = useState<boolean>(false);

  const [currentTab, setCurrentTab] = useState<string>(DETAILS_TAB);

  if (orgError) {
    if (orgError.status === ERRORS.NOT_FOUND_ERROR.status) {
      return <NotFound showBackButton={false} />;
    }
    return <ServerError showBackButton={false} />;
  }

  if (!organizationId || !organization) {
    return;
  }

  return (
    <>
      <Seo title={t(tokens.admin.organization.details.pageTitle) as string} />
      <Stack
        spacing={3}
        sx={{ mb: 3 }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={4}
        >
          <Stack spacing={1}>
            <Typography variant="h4">{organization?.name}</Typography>
          </Stack>
        </Stack>

        <div>
          <Tabs
            indicatorColor="primary"
            onChange={handleTabsChange}
            scrollButtons="auto"
            textColor="primary"
            value={currentTab}
            variant="scrollable"
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
              />
            ))}
          </Tabs>
          <Divider />
        </div>

        {currentTab === USERS_TAB && (
          <Stack spacing={3}>
            <Card>
              <UserListTable
                organizationId={organizationId}
                count={users.totalCount}
                items={users.items}
                onPageChange={(event, newPage) =>
                  loadUsers(newPage + 1, users.top)
                }
                onRowsPerPageChange={(event) =>
                  loadUsers(1, Number.parseInt(event.target.value))
                }
                onReset={() => loadUsers(1, users.top)}
                onUpdate={() => loadUsers(users.page, users.top)}
                page={users.page}
                top={users.top}
                isLoading={isUsersLoading}
                hasError={hasUsersLoadingError}
              />
            </Card>
            <Stack direction="row">
              <Button
                component={RouterLink}
                onClick={() => setShowNewUserDrawer(true)}
                startIcon={
                  <SvgIcon>
                    <PlusIcon />
                  </SvgIcon>
                }
                variant="contained"
              >
                {t(tokens.admin.organization.details.users.buttons.newUser)}
              </Button>
              <NewUserDrawer
                organizationId={organizationId}
                isOpen={showNewUserDrawer}
                onReset={loadData}
                onClose={() => setShowNewUserDrawer(false)}
              />
            </Stack>
          </Stack>
        )}

       

        {currentTab === DETAILS_TAB && (
          <>
            <OrganizationEditForm organization={organization} />
          </>
        )}
        {currentTab === SETTINGS_TAB && (
          <>
            <OrganizationSettingsForm
              hasLoadingError={hasProductSettingLoadingError}
              isLoading={isProductSettingsLoading}
              settings={productSettings}
              organization={organization}
            />
          </>
        )}
      </Stack>
    </>
  );
};

export default Page;
