import type { AppThunk } from 'src/store';
import { fetchWrapper } from 'src/utils/fetch-wrapper';
import { api } from 'src/config';
import { slice as usersSlice } from 'src/slices/admin/product-users';
import { urlParams } from 'src/utils/url-params';
import { GetProductUsersQueryParams } from 'src/types/admin/product-users';

const getProductUsers = (productId: string, params: GetProductUsersQueryParams): AppThunk => async (dispatch): Promise<void> => {

  dispatch(usersSlice.actions.setLoadingStatus(true));
  dispatch(usersSlice.actions.setErrorStatus(false));
  try {
    var users = await fetchWrapper.get(`${api}/products/${productId}/users${urlParams(params)}`, {});
    dispatch(usersSlice.actions.getUsers(users));
  } catch (error) {
    dispatch(usersSlice.actions.setErrorStatus(true));
  }
  dispatch(usersSlice.actions.setLoadingStatus(false));
}

const addProductUser = (productId: string, value: any, lang: string): AppThunk => async (dispatch): Promise<void> => {
  var user = await fetchWrapper.post(`${api}/products/${productId}/users`, value, undefined, lang);
  return user;
}

const updateProductUser = (productId: string, userId: string, value: any, lang: string): AppThunk => async (dispatch): Promise<void> => {
  var user = await fetchWrapper.put(`${api}/products/${productId}/users/${userId}`, value, undefined, lang);
  return user;
}

const deleteProductUser = (productId: string, userId: string): AppThunk => async (dispatch): Promise<void> => {
  await fetchWrapper.delete(`${api}/products/${productId}/users/${userId}`, {});
}
const sendProductInvitation = (productId: string, userId: string, lang: string): AppThunk => async (dispatch): Promise<void> => {
  await fetchWrapper.post(`${api}/products/${productId}/users/${userId}/send-invitation`, {}, undefined, lang);
}

export const thunks = {
  getProductUsers,
  deleteProductUser,
  addProductUser,
  sendProductInvitation,
  updateProductUser
};
