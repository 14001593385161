import { AnyAction, combineReducers } from '@reduxjs/toolkit';
import { reducer as variousReducer } from 'src/slices/various';
import { reducer as settingsReducer } from 'src/slices/settings';
import { reducer as adminProductsReducer } from 'src/slices/admin/products';
import { reducer as adminProductReducer } from 'src/slices/admin/product';
import { reducer as adminModulesReducer } from 'src/slices/admin/modules';
import { reducer as adminModuleReducer } from 'src/slices/admin/module';
import { reducer as adminProductUsersReducer } from 'src/slices/admin/product-users';
import { reducer as adminProductPaymentsReducer } from 'src/slices/admin/product-payments';
import { reducer as adminProductSettingsReducer } from 'src/slices/admin/product-settings';
import { reducer as adminOrganizationSettingsReducer } from 'src/slices/admin/organization-settings';
import { reducer as adminOrganizationUsersReducer } from 'src/slices/admin/organization-users';
import { reducer as accountProductsReducer } from 'src/slices/panel/account-products';
import { reducer as accountProductReducer } from 'src/slices/panel/account-product';
import { reducer as accountModulesReducer } from 'src/slices/panel/account-modules';
import { reducer as accountModuleReducer } from 'src/slices/panel/account-module';
import { reducer as accountOrganizationReducer } from 'src/slices/account-organization';
import { reducer as adminOrganizationReducer } from 'src/slices/admin/organization';
import { reducer as adminProductPlansReducer } from "src/slices/admin/product-plans";

const appReducer = combineReducers({
  settings: settingsReducer,
  various: variousReducer,
  adminProducts: adminProductsReducer,
  adminModules: adminModulesReducer,
  adminModule: adminModuleReducer,
  adminProduct: adminProductReducer,
  adminProductUsers: adminProductUsersReducer,
  adminProductPayments: adminProductPaymentsReducer,
  adminProductSettings: adminProductSettingsReducer,
  accountProducts: accountProductsReducer,
  accountProduct: accountProductReducer,
  accountModules: accountModulesReducer,
  accountModule: accountModuleReducer,
  accountOrganization: accountOrganizationReducer,
  adminOrganization: adminOrganizationReducer,
  adminOrganizationSettings: adminOrganizationSettingsReducer,
  adminOrganizationUsers: adminOrganizationUsersReducer,
  adminProductPlans: adminProductPlansReducer,
});

export const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === 'DESTROY_SESSION')
    state = undefined;

  return appReducer(state, action);
};