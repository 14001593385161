import type { FC } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  TextField,
} from "@mui/material";
import { thunks } from "src/thunks/admin/organization-settings";
import { useDispatch } from "src/store";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import LoadingButton from "@mui/lab/LoadingButton";
import { Organization, getColor } from "src/types/admin/organization";
import { Setting } from "src/types/setting";
import { ErrorMessage } from "src/sections/common/error-message";
import { ORGANIZATION_COLOR_SETTINGS } from "src/consts";
import ColorInput from "src/sections/common/color-input";
import { indigo } from "src/theme/colors";

interface OrganizationColorSettingsFormProps {
  organization: Organization;
  settings: Setting[];
}

export const OrganizationColorSettingsForm: FC<
  OrganizationColorSettingsFormProps
> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { organization, settings } = props;

  const validationSchema = Yup.object({

  });

  const formik = useFormik({
    initialValues: {
      mainColor: getColor(
        organization,
        ORGANIZATION_COLOR_SETTINGS.MAIN_COLOR,
        indigo.main
      ),
      darkColor: getColor(
        organization,
        ORGANIZATION_COLOR_SETTINGS.DARK_COLOR,
        indigo.dark
      ),
      darkestColor: getColor(
        organization,
        ORGANIZATION_COLOR_SETTINGS.DARKEST_COLOR,
        indigo.darkest
      ),
      lightColor: getColor(
        organization,
        ORGANIZATION_COLOR_SETTINGS.LIGHT_COLOR,
        indigo.light
      ),
      lightestColor: getColor(
        organization,
        ORGANIZATION_COLOR_SETTINGS.LIGHTEST_COLOR,
        indigo.lightest
      ),
      contrastTextColor: getColor(
        organization,
        ORGANIZATION_COLOR_SETTINGS.CONTRAST_TEXT_C0LOR,
        indigo.contrastText
      ),
      submit: null,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, helpers): Promise<void> => {
      try {
        await dispatch(
          thunks.updateSetting(
            organization.id,
            ORGANIZATION_COLOR_SETTINGS.MAIN_COLOR,
            values.mainColor
          )
        );
        await dispatch(
          thunks.updateSetting(
            organization.id,
            ORGANIZATION_COLOR_SETTINGS.DARK_COLOR,
            values.darkColor
          )
        );
        await dispatch(
          thunks.updateSetting(
            organization.id,
            ORGANIZATION_COLOR_SETTINGS.DARKEST_COLOR,
            values.darkestColor
          )
        );
        await dispatch(
          thunks.updateSetting(
            organization.id,
            ORGANIZATION_COLOR_SETTINGS.LIGHT_COLOR,
            values.lightColor
          )
        );
        await dispatch(
          thunks.updateSetting(
            organization.id,
            ORGANIZATION_COLOR_SETTINGS.LIGHTEST_COLOR,
            values.lightestColor
          )
        );
        await dispatch(
          thunks.updateSetting(
            organization.id,
            ORGANIZATION_COLOR_SETTINGS.CONTRAST_TEXT_C0LOR,
            values.contrastTextColor
          )
        );
        toast.success(
          t(tokens.admin.organization.details.settings.successMessage)
        );
      } catch (error) {
        toast.error(t(tokens.general.formError));
        helpers.setStatus({ success: false });
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      {...props}
    >
      <Stack spacing={4}>
        <Card>
          <CardHeader
            title={t(tokens.admin.organization.details.settings.colors.header)}
          />
          <CardContent>
            <Stack spacing={3}>
              <ColorInput
                defaultValue={formik.values.darkestColor}
                onChange={(value) =>
                  formik.setFieldValue("darkestColor", value)
                }
                label={t(
                  tokens.admin.organization.details.settings.colors.form
                    .darkestColor
                )}
              />
              <ColorInput
                defaultValue={formik.values.darkColor}
                onChange={(value) => formik.setFieldValue("darkColor", value)}
                label={t(
                  tokens.admin.organization.details.settings.colors.form
                    .darkColor
                )}
              />

              <ColorInput
                defaultValue={formik.values.mainColor}
                onChange={(value) => formik.setFieldValue("mainColor", value)}
                label={t(
                  tokens.admin.organization.details.settings.colors.form
                    .mainColor
                )}
              />

              <ColorInput
                defaultValue={formik.values.lightColor}
                onChange={(value) => formik.setFieldValue("lightColor", value)}
                label={t(
                  tokens.admin.organization.details.settings.colors.form
                    .lightColor
                )}
              />

              <ColorInput
                defaultValue={formik.values.lightestColor}
                onChange={(value) =>
                  formik.setFieldValue("lightestColor", value)
                }
                label={t(
                  tokens.admin.organization.details.settings.colors.form
                    .lightestColor
                )}
              />

              <ColorInput
                defaultValue={formik.values.contrastTextColor}
                onChange={(value) =>
                  formik.setFieldValue("contrastTextColor", value)
                }
                label={t(
                  tokens.admin.organization.details.settings.colors.form
                    .contrastTextColor
                )}
              />

              <Box>
                <LoadingButton
                  type="submit"
                  disabled={formik.isSubmitting}
                  loading={formik.isSubmitting}
                  variant="contained"
                >
                  {t(tokens.general.buttons.update)}
                </LoadingButton>
              </Box>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </form>
  );
};
