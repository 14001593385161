import type { ChangeEvent } from "react";
import { useCallback, useEffect, useState } from "react";
// import { subDays, subHours, subMinutes, subMonths } from 'date-fns';
import { Button, Card, Stack, SvgIcon, Typography } from "@mui/material";
import { Seo } from "src/components/seo";
import { useAuth } from "src/hooks/use-auth";
import { usePageView } from "src/hooks/use-page-view";
import type { Page as PageType } from "src/types/page";
import { tokens } from "src/locales/tokens";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "src/store";
import { thunks } from "src/thunks/admin/products";
import { RouterLink } from "src/components/router-link";
import { paths } from "src/paths";
import PlusIcon from "@untitled-ui/icons-react/build/esm/Plus";
import { ProductListTable } from "src/sections/admin/products/product-list-table";

const Page: PageType = () => {
  const { t, i18n } = useTranslation();

  usePageView();

  const dispatch = useDispatch();

  const { items, hasLoadingError, isLoading } = useSelector(
    (state) => state.adminProducts
  );

  const loadProducts = useCallback(
    async (page: number, top: number) => {
      await dispatch(
        thunks.getProducts({
          page: page,
          top: top,
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    loadProducts(items.page, items.top);
  }, [items.page, items.top, loadProducts]);

  return (
    <>
      <Seo title={t(tokens.admin.products.list.pageTitle) as string} />
      <Stack
        spacing={3}
        sx={{ mb: 3 }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={4}
        >
          <Stack spacing={1}>
            <Typography variant="h4">
              {t(tokens.admin.products.list.pageHeader)}
            </Typography>
          </Stack>

          <Stack
            alignItems="center"
            direction="row"
            spacing={3}
          >
            <Button
              component={RouterLink}
              href={paths.admin.products.create}
              startIcon={
                <SvgIcon>
                  <PlusIcon />
                </SvgIcon>
              }
              variant="contained"
            >
              {t(tokens.admin.products.list.buttons.add)}
            </Button>
          </Stack>
        </Stack>
        <Card>
          <ProductListTable
            count={items.totalCount}
            items={items.items}
            onPageChange={(event, newPage) => {
              loadProducts(newPage + 1, items.top);
            }}
            onRowsPerPageChange={(input) => {
              loadProducts(1, Number.parseInt(input.target.value));
            }}
            onReset={() => loadProducts(1, items.top)}
            page={items.page}
            top={items.top}
            isLoading={isLoading}
            hasError={hasLoadingError}
          />
        </Card>
      </Stack>
    </>
  );
};

export default Page;
