import { TEditorConfiguration } from "../documents/editor/core";

const EMPTY_EMAIL_MESSAGE: TEditorConfiguration = {
  root: {
    type: "EmailLayout",
    data: {
      backdropColor: "#F5F5F5",
      canvasColor: "#FFFFFF",
      textColor: "#262626",
      fontFamily: "MODERN_SANS",
      childrenIds: [],
    },
  },
};

export default EMPTY_EMAIL_MESSAGE;
