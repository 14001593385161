import type { FC } from 'react';
import {
  TableCell,
  TableRow
} from '@mui/material';

import { LoadIndicator } from './load-indicator';
import { EmptyMessage } from './empty-message';
import { ErrorMessage } from './error-message';

interface TableLoadingRowProps {
  itemsCount: number;
  isLoading: boolean;
  hasError: boolean;
  noItemsText: string;
  errorLoadingText: string;
  colSpan: number;
}

export const TableLoadingRow: FC<TableLoadingRowProps> = (props) => {
  const {
    itemsCount,
    isLoading,
    hasError,
    noItemsText,
    errorLoadingText,
    colSpan
  } = props;

  return (
    <>
      {isLoading && itemsCount === 0 &&
        <TableRow>
          <TableCell colSpan={colSpan}
            sx={{
              border: 'none'
            }}>
            <LoadIndicator />
          </TableCell>
        </TableRow>
      }
      {hasError && !isLoading && itemsCount === 0 &&
        <TableRow>
          <TableCell colSpan={colSpan}
            sx={{
              border: 'none'
            }}>
            <ErrorMessage text={errorLoadingText} />
          </TableCell>
        </TableRow>
      }

      {!hasError && !isLoading && itemsCount === 0 &&
        <TableRow>
          <TableCell colSpan={colSpan}
            sx={{
              border: 'none'
            }}>
            <EmptyMessage text={noItemsText} />
          </TableCell>
        </TableRow>
      }
    </>
  );
};