import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { Setting } from 'src/types/setting';

interface OrganizationSettingsState {
  isLoading: boolean;
  hasLoadingError: boolean;
  settings: Setting[];
}

type GetSettingsAction = PayloadAction<Setting[]>;
type LoadingStatusAction = PayloadAction<boolean>;
type ErrorStatusAction = PayloadAction<boolean>;

const initialState: OrganizationSettingsState = {
  isLoading: false,
  hasLoadingError: false,
  settings: []
};

const reducers = {
  setLoadingStatus: (state: OrganizationSettingsState, action: LoadingStatusAction): void => {
    state.isLoading = action.payload;
  },
  setErrorStatus: (state: OrganizationSettingsState, action: ErrorStatusAction): void => {
    state.hasLoadingError = action.payload;
  },
  getSettings: (state: OrganizationSettingsState, action: GetSettingsAction): void => {
    state.settings = action.payload;
  },
};

export const slice = createSlice({
  name: 'admin-organization-settings',
  initialState,
  reducers
});

export const { reducer } = slice;
