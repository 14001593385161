import { tokens } from "../tokens";

export const pl = {
  [tokens.landing.nav.products]: "Produkty",
  [tokens.landing.nav.singIn]: "Zaloguj się",
  [tokens.landing.nav.singUp]: "Zarejestruj się",
  [tokens.landing.nav.privacyPolicy]: "Polityka prywatności",
  [tokens.landing.nav.termsOfUse]: "Warunki korzystania",

  [tokens.nav.dashboard]: "Panel",
  [tokens.nav.host]: "Host",
  [tokens.nav.profile]: "Mój profil",

  [tokens.admin.products.list.pageTitle]: "Produkty",
  [tokens.admin.products.list.pageHeader]: "Produkty",
  [tokens.admin.products.list.table.title]: "Tytuł",
  [tokens.admin.products.list.table.accessMonthsCount]: "Dostęp",
  [tokens.admin.products.list.table.months]: "mies.",
  [tokens.admin.products.list.table.start]: "Początek",
  [tokens.admin.products.list.table.usersCount]: "Liczba użytkowników",
  [tokens.admin.products.list.table.actions]: "Działania",
  [tokens.admin.products.list.archiveProductDialogTitle]: "Archiwizuj produkt",
  [tokens.admin.products.list.archiveProductDialogDescription]:
    "Czy na pewno chcesz zarchiwizować ten produkt?",
  [tokens.admin.products.list.deleteProductDialogTitle]: "Usuń produkt",
  [tokens.admin.products.list.deleteProductDialogDescription]:
    "Czy na pewno chcesz usunąć ten produkt?",
  [tokens.admin.products.list.buttons.add]: "Nowy produkt",
  [tokens.admin.products.list.noProductsFound]: "Brak produktów",
  [tokens.admin.products.list.errorLoadingProducts]:
    "Wystąpił błąd podczas ładowania produktów",

  [tokens.admin.products.create.pageTitle]: "Nowy produkt",
  [tokens.admin.products.create.pageHeader]: "Nowy produkt",
  [tokens.admin.products.create.backButton]: "Powrót do produktów",
  [tokens.admin.products.create.form.title]: "Tytuł",
  [tokens.admin.products.create.form.description]: "Opis",
  [tokens.admin.products.create.form.startDate]: "Data rozpoczęcia",
  [tokens.admin.products.create.form.endDate]: "Data zakończenia",
  [tokens.admin.products.create.form.isStandalone]: "Samodzielny",
  [tokens.admin.products.create.form.backGroundImage.header]: "Zdjęcie w tle",
  [tokens.admin.products.create.form.backGroundImage.description]:
    "Zalecenia dotyczące rozmiaru: 1120 (szerokość) * 160 (wysokość)",
  [tokens.admin.products.create.form.successMessage]:
    "Produkt został pomyślnie utworzony.",

  [tokens.admin.products.details.pageTitle]: "Produkt",
  [tokens.admin.products.details.pageHeader]: "Produkt",
  [tokens.admin.products.details.backButton]: "Powrót do produktów",
  [tokens.admin.products.details.noModulesFound]: "Brak modułów",
  [tokens.admin.products.details.errorLoadingModules]:
    "Wystąpił błąd podczas ładowania modułów",
  [tokens.admin.products.details.buttons.addModule]: "Nowy moduł",
  [tokens.admin.products.details.menu.preview]: "Podgląd produktu",
  [tokens.admin.products.details.table.title]: "Tytuł",
  [tokens.admin.products.details.table.active]: "Aktywny",
  [tokens.admin.products.details.table.productPlans]: "Plany produktu",
  [tokens.admin.products.details.table.actions]: "Działania",
  [tokens.admin.products.details.deleteModuleDialogTitle]: "Usuń moduł",
  [tokens.admin.products.details.deleteModuleDialogDescription]:
    "Czy na pewno chcesz usunąć ten moduł?",
  [tokens.admin.products.details.tabs.content]: "Treść",
  [tokens.admin.products.details.tabs.modules]: "Moduły",
  [tokens.admin.products.details.tabs.details]: "Szczegóły",
  [tokens.admin.products.details.tabs.users]: "Użytkownicy",
  [tokens.admin.products.details.tabs.payments]: "Płatności",
  [tokens.admin.products.details.tabs.settings]: "Ustawienia",
  [tokens.admin.products.details.tabs.productPlans]: "Plany produktu",
  [tokens.admin.products.details.form.title]: "Tytuł",
  [tokens.admin.products.details.form.description]: "Opis",
  [tokens.admin.products.details.form.startDate]: "Data rozpoczęcia",
  [tokens.admin.products.details.form.maxAccessMonthsCount]:
    "Dostęp (miesiące)",
  [tokens.admin.products.details.form.content.editor]: "Edytor treści",
  [tokens.admin.products.details.form.content.preview]: "Podgląd treści",
  [tokens.admin.products.details.form.content.noPreviewContent]:
    "Brak treści do wyświetlenia",
  [tokens.admin.products.details.form.backGroundImage.header]: "Zdjęcie w tle",
  [tokens.admin.products.details.form.backGroundImage.description]:
    "Zalecenia dotyczące rozmiaru: 1120 (szerokość) * 160 (wysokość)",
  [tokens.admin.products.details.form.successMessage]:
    "Produkt został pomyślnie zaktualizowany.",
  [tokens.admin.products.details.productPlans.buttons.newProductPlan]:
    "Nowy plan produktu",
  [tokens.admin.products.details.productPlans.table.name]: "Nazwa",
  [tokens.admin.products.details.productPlans.table.price]: "Cena",
  [tokens.admin.products.details.productPlans.table.currency]: "Waluta",
  [tokens.admin.products.details.productPlans.table.actions]: "Działania",
  [tokens.admin.products.details.productPlans.table.noPaymentPlansFound]:
    "Brak planów produktu",
  [tokens.admin.products.details.productPlans.table.errorLoadingProductPlans]:
    "Wystąpił błąd podczas ładowania planów produktu",
  [tokens.admin.products.details.productPlans.deleteProductPlanDialogTitle]:
    "Usuń plan produktu",
  [tokens.admin.products.details.productPlans
    .deleteProductPlanDialogDescription]:
    "Czy na pewno chcesz usunąć ten plan produktu?",
  [tokens.admin.products.details.productPlans.form.name]: "Nazwa",
  [tokens.admin.products.details.productPlans.form.url]: "Url",
  [tokens.admin.products.details.productPlans.form.price]: "Cena",
  [tokens.admin.products.details.productPlans.form.currency]: "Waluta",
  [tokens.admin.products.details.productPlans.form.params]: "Parametry",
  [tokens.admin.products.details.productPlans.form.successMessage]:
    "Plan produktu został pomyślnie zaktualizowany.",
  [tokens.admin.products.details.productPlans.form.updateTitle]: "Edytuj plan",
  [tokens.admin.products.details.productPlans.form.createTitle]: "Nowy plan",
  [tokens.admin.products.details.productPlans.form.settingsTitle]: "Ustawienia",

  [tokens.admin.products.details.users.table.name]: "Imię",
  [tokens.admin.products.details.users.table.phone]: "Telefon",
  [tokens.admin.products.details.users.table.date]: "Data",
  [tokens.admin.products.details.users.table.endDate]: "Data zakończenia",
  [tokens.admin.products.details.users.table.paymentStatus]: "Status płatności",
  [tokens.admin.products.details.users.table.actions]: "Działania",
  [tokens.admin.products.details.users.table.type]: "Typ",
  [tokens.admin.products.details.users.table.productPlan]: "Plan produktu",
  [tokens.admin.products.details.users.table.manualType]: "Ręcznie",
  [tokens.admin.products.details.users.table.autoType]: "Automatycznie",
  [tokens.admin.products.details.users.table.noUsersFound]: "Brak użytkowników",
  [tokens.admin.products.details.users.table.errorLoadingUsers]:
    "Wystąpił błąd podczas ładowania użytkowników",
  [tokens.admin.products.details.users.buttons.newUser]: "Nowy użytkownik",
  [tokens.admin.products.details.users.form.firstName]: "Imię",
  [tokens.admin.products.details.users.form.lastName]: "Nazwisko",
  [tokens.admin.products.details.users.form.email]: "Email",
  [tokens.admin.products.details.users.form.phone]: "Telefon",
  [tokens.admin.products.details.users.form.productPlan]: "Plan produktu",
  [tokens.admin.products.details.users.form.endDate]: "Data zakończenia",
  [tokens.admin.products.details.users.form.createTitle]: "Nowy użytkownik",
  [tokens.admin.products.details.users.form.updateTitle]: "Edytuj użytkownika",
  [tokens.admin.products.details.users.form.successMessage]:
    "Użytkownik został pomyślnie dodany.",
  [tokens.admin.products.details.users.form.errors.userAlreadyExists]:
    "Użytkownik z takim emailem jest już dodany",
  [tokens.admin.products.details.users.deleteUserDialogTitle]:
    "Usuń użytkownika",
  [tokens.admin.products.details.users.deleteUserDialogDescription]:
    "Czy na pewno chcesz usunąć tego użytkownika?",
  [tokens.admin.products.details.users.sendInvitationDialogTitle]:
    "Wyślij zaproszenie",
  [tokens.admin.products.details.users.sendInvitationDialogDescription]:
    "Czy na pewno chcesz usunąć tego użytkownika?",
  [tokens.admin.products.details.users.invitationSentSuccessMessage]:
    "Zaproszenie zostało wysłane",
  [tokens.admin.products.details.payments.table.orderReference]:
    "Numer zamówienia",
  [tokens.admin.products.details.payments.table.user]: "Użytkownik",
  [tokens.admin.products.details.payments.table.amount]: "Kwota",
  [tokens.admin.products.details.payments.table.productPlan]: "Plan produktu",
  [tokens.admin.products.details.payments.table.date]: "Data",
  [tokens.admin.products.details.payments.table.paymentStatus]:
    "Status płatności",
  [tokens.admin.products.details.payments.table.noPaymentsFound]:
    "Brak płatności",
  [tokens.admin.products.details.payments.table.errorLoadingPayments]:
    "Wystąpił błąd podczas ładowania płatności",
  [tokens.admin.products.details.settings.successMessage]:
    "Ustawienia zostały zaktualizowane.",
  [tokens.admin.products.details.settings.errorLoadingSettings]:
    "Wystąpił błąd podczas ładowania ustawień",
  [tokens.admin.products.details.settings.wayForPay.paymentButton.header]:
    "WayForPay przycisk płatności",
  [tokens.admin.products.details.settings.wayForPay.paymentButton.serviceUrl]:
    "Service URL",
  [tokens.admin.products.details.settings.wayForPay.paymentButton.returnUrl]:
    "Return URL",
  [tokens.admin.products.details.settings.webHooks.header]: "Webhooks",
  [tokens.admin.products.details.settings.webHooks.form.productInvitationHook]:
    "Product invitation",
  [tokens.admin.products.details.settings.webHooks.form.params]: "Parametry",
  [tokens.admin.products.details.settings.webHooks.form.url]: "URL",
  [tokens.admin.products.details.settings.emailTemplate.header]:
    "Szablon wiadomości e-mail z zaproszeniem do produktu",
  [tokens.admin.products.details.settings.emailTemplate
    .editProductInvitationEmail]: "Edytuj email zaproszenia",
  [tokens.admin.products.details.settings.productPlans.header]:
    "Plany produktu",
  [tokens.admin.products.details.settings.productPlans.form.name]: "Nazwa",
  [tokens.admin.products.details.settings.productPlans.noPlans]: "Brak planów",
  [tokens.admin.products.details.settings.productPlans.form.successMessage]:
    "Plany produktu zostały zaktualizowane.",

  [tokens.admin.modules.create.pageTitle]: "Nowy moduł",
  [tokens.admin.modules.create.pageHeader]: "Nowy moduł",
  [tokens.admin.modules.create.backButton]: "Powrót do produktów",
  [tokens.admin.modules.create.form.title]: "Tytuł",
  [tokens.admin.modules.create.form.description]: "Opis",
  [tokens.admin.modules.create.form.active]: "Aktywny",
  [tokens.admin.modules.create.form.link.title]: "Tytuł linku",
  [tokens.admin.modules.create.form.link.url]: "URL linku",
  [tokens.admin.modules.create.form.backGroundImage.header]: "Zdjęcie",
  [tokens.admin.modules.create.form.backGroundImage.description]:
    "Zalecenia dotyczące rozmiaru: 1120 (szerokość) * 160 (wysokość)",
  [tokens.admin.modules.create.form.content]: "Treść",
  [tokens.admin.modules.create.form.successMessage]:
    "Moduł został pomyślnie utworzony.",
  [tokens.admin.modules.create.linkHeader]: "Linki",
  [tokens.admin.modules.create.noLinks]: "Brak linków",

  [tokens.admin.modules.details.pageTitle]: "Moduł",
  [tokens.admin.modules.details.pageHeader]: "Moduł",
  [tokens.admin.modules.details.backButton]: "Powrót do modułów",
  [tokens.admin.modules.details.menu.preview]: "Podgląd modułu",
  [tokens.admin.modules.details.form.title]: "Tytuł",
  [tokens.admin.modules.details.form.description]: "Opis",
  [tokens.admin.modules.details.form.active]: "Aktywny",
  [tokens.admin.modules.details.form.startDate]: "Data rozpoczęcia",
  [tokens.admin.modules.details.form.link.title]: "Tytuł linku",
  [tokens.admin.modules.details.form.link.url]: "URL linku",
  [tokens.admin.modules.details.form.productPlans.header]:
    "Plany produktu dla modułu",
  [tokens.admin.modules.details.form.productPlans.name]: "Nazwa",
  [tokens.admin.modules.details.form.image.header]: "Zdjęcie",
  [tokens.admin.modules.details.form.image.description]:
    "Zalecenia dotyczące rozmiaru: 1120 (szerokość) * 160 (wysokość)",
  [tokens.admin.modules.details.form.content.editor]: "Edytor treści",
  [tokens.admin.modules.details.form.content.preview]: "Podgląd treści",
  [tokens.admin.modules.details.form.content.noPreviewContent]:
    "Brak treści do wyświetlenia",
  [tokens.admin.modules.details.form.successMessage]:
    "Moduł został pomyślnie zaktualizowany.",
  [tokens.admin.modules.details.linkHeader]: "Linki",
  [tokens.admin.modules.details.noLinks]: "Brak linków",
  [tokens.admin.modules.details.tabs.details]: "Szczegóły",
  [tokens.admin.modules.details.tabs.content]: "Treść",

  [tokens.admin.organization.details.pageTitle]: "Organizacja",
  [tokens.admin.organization.details.pageHeader]: "Organizacja",
  [tokens.admin.organization.details.tabs.details]: "Szczegóły",
  [tokens.admin.organization.details.tabs.users]: "Użytkownicy",
  [tokens.admin.organization.details.tabs.settings]: "Ustawienia",
  [tokens.admin.organization.details.form.domain]: "Domain *",
  [tokens.admin.organization.details.form.name]: "Nazwa *",
  [tokens.admin.organization.details.form.logoImage.header]: "Logo",
  [tokens.admin.organization.details.form.logoImage.description]:
    "Zalecenia dotyczące rozmiaru: 200 (szerokość) * 200 (wysokość)",
  [tokens.admin.organization.details.form.successMessage]:
    "Organizacja została pomyślnie zaktualizowana.",
  [tokens.admin.organization.details.form.domainAlreadyUsed]:
    "Ten domain jest już używany",
  [tokens.admin.organization.details.users.table.name]: "Imię i nazwisko",
  [tokens.admin.organization.details.users.table.email]: "Email",
  [tokens.admin.organization.details.users.table.role]: "Rola",
  [tokens.admin.organization.details.users.table.actions]: "Działania",
  [tokens.admin.organization.details.users.table.errorLoadingUsers]:
    "Wystąpił błąd podczas ładowania użytkowników",
  [tokens.admin.organization.details.users.table.noUsersFound]:
    "Brak użytkowników",
  [tokens.admin.organization.details.users.buttons.newUser]: "Nowy użytkownik",
  [tokens.admin.organization.details.users.form.firstName]: "Imię",
  [tokens.admin.organization.details.users.form.lastName]: "Nazwisko",
  [tokens.admin.organization.details.users.form.role]: "Rola",
  [tokens.admin.organization.details.users.form.email]: "Email",
  [tokens.admin.organization.details.users.form.createTitle]: "Nowy użytkownik",
  [tokens.admin.organization.details.users.form.updateTitle]:
    "Edytuj użytkownika",
  [tokens.admin.organization.details.users.form.updatedSuccessMessage]:
    "Użytkownik został pomyślnie zaktualizowany",
  [tokens.admin.organization.details.users.form.createdSuccessMessage]:
    "Użytkownik został pomyślnie dodany",
  [tokens.admin.organization.details.users.form.errors.userAlreadyExists]:
    "Użytkownik z takim emailem jest już dodany",
  [tokens.admin.organization.details.users.deleteUserDialogTitle]:
    "Usuń użytkownika",
  [tokens.admin.organization.details.users.deleteUserDialogDescription]:
    "Czy na pewno chcesz usunąć tego użytkownika?",
  [tokens.admin.organization.details.settings.errorLoadingSettings]:
    "Wystąpił błąd podczas ładowania ustawień",
  [tokens.admin.organization.details.settings.successMessage]:
    "Ustawienia zostały zaktualizowane.",
  [tokens.admin.organization.details.settings.colors.header]: "Kolorystyka",
  [tokens.admin.organization.details.settings.colors.form.mainColor]:
    "Główny kolor",
  [tokens.admin.organization.details.settings.colors.form.darkColor]:
    "Ciemny kolor",
  [tokens.admin.organization.details.settings.colors.form.darkestColor]:
    "Najciemniejszy kolor",
  [tokens.admin.organization.details.settings.colors.form.lightColor]:
    "Jasny kolor",
  [tokens.admin.organization.details.settings.colors.form.lightestColor]:
    "Najjaśniejszy kolor",
  [tokens.admin.organization.details.settings.colors.form.contrastTextColor]:
    "Kolor tekstu",

  [tokens.admin.organization.details.settings.email.header]:
    "Konfiguracja email",
  [tokens.admin.organization.details.settings.email.form.isEnabled]: "Włączony",
  [tokens.admin.organization.details.settings.email.form.email]: "From email",
  [tokens.admin.organization.details.settings.email.form.name]: "From name",
  [tokens.admin.organization.details.settings.email.form.host]: "Host",
  [tokens.admin.organization.details.settings.email.form.port]: "Port",
  [tokens.admin.organization.details.settings.email.form.username]: "Username",
  [tokens.admin.organization.details.settings.email.form.password]: "Password",
  [tokens.admin.organization.details.settings.payment.wayForPay.header]:
    "WayForPay",
  [tokens.admin.organization.details.settings.emailTemplates.header]:
    "Szablony email",
  [tokens.admin.organization.details.settings.emailTemplates.signInEmail]:
    "Edytuj email logowania",
  [tokens.admin.organization.details.settings.payment.wayForPay.form.secretKey]:
    "Secret key",
  [tokens.admin.organization.details.settings.payment.wayForPay.form
    .merchantAccount]: "Merchant account",
  [tokens.admin.organization.details.settings.payment.wayForPay.form
    .merchantDomain]: "Merchant domain",

  [tokens.panel.products.details.backButton]: "Powrót do produktów",
  [tokens.panel.products.details.menu.edit]: "Edytuj produkt",
  [tokens.panel.products.details.errorProductLoading]:
    "Wystąpił błąd podczas ładowania produktu",
  [tokens.panel.products.details.errorModulesLoading]:
    "Wystąpił błąd podczas ładowania modułów",
  [tokens.panel.products.details.noModulesFound]: "Brak modułów",
  [tokens.panel.products.details.startDate]: "Data otwarcia",

  [tokens.panel.products.list.pageTitle]: "Produkty",
  [tokens.panel.products.list.errorProductsLoading]:
    "Wystąpił błąd podczas ładowania produktów",
  [tokens.panel.products.list.noProductsFound]: "Brak produktów",
  [tokens.panel.products.list.status.open]: "Otwarty",
  [tokens.panel.products.list.status.closed]: "Zamknięty",

  [tokens.panel.modules.details.backButton]: "Powrót do modułów",
  [tokens.panel.modules.details.linkHeader]: "Dodatkowe materiały",
  [tokens.panel.modules.details.noLinks]: "Brak dodatkowych materiałów",
  [tokens.panel.modules.details.menu.edit]: "Edytuj moduł",
  [tokens.panel.modules.details.loadingError]:
    "Wystąpił błąd podczas ładowania modułu",

  [tokens.account.signUp.title]: "Utwórz konto",
  [tokens.account.signUp.header]: "Utwórz konto",
  [tokens.account.signUp.form.firstName]: "Imię *",
  [tokens.account.signUp.form.lastName]: "Nazwisko *",
  [tokens.account.signUp.form.phone]: "Numer telefonu *",
  [tokens.account.signUp.form.email]: "Adres email *",
  [tokens.account.signUp.form.password]: "Hasło *",
  [tokens.account.signUp.form.createAccountButton]: "Utwórz konto",
  [tokens.account.signUp.form.termsOfUseLink]: "Warunki korzystania",
  [tokens.account.signUp.form.privacyPolicyLink]: "Polityka prywatności",
  [tokens.account.signUp.form.andSeparator]: "i",
  [tokens.account.signUp.form.agreementText]: "Przeczytałem/am",
  [tokens.account.signUp.form.signinLink]: "Zaloguj się",
  [tokens.account.signUp.form.hasAccount]: "Masz już konto?",

  [tokens.account.signIn.title]: "Zaloguj się",
  [tokens.account.signIn.header]: "Zaloguj się",
  [tokens.account.signIn.description]:
    "Podaj swój adres e-mail, a my wyślemy Ci link do logowania.",
  [tokens.account.signIn.noAccount]: "Nie masz konta?",
  [tokens.account.signIn.createAccountLink]: "Utwórz konto",
  [tokens.account.signIn.form.email]: "Adres email *",
  [tokens.account.signIn.form.password]: "Hasło *",
  [tokens.account.signIn.form.signInButton]: "Zaloguj się",
  [tokens.account.signIn.form.signInViaGoogleButton]:
    "Zaloguj się przez Google",
  [tokens.account.signIn.form.orSeparator]: "lub",
  [tokens.account.signIn.form.forgotPasswordLink]: "Zapomniałeś hasła?",
  [tokens.account.signIn.form.termsOfUseLink]: "Warunki korzystania",
  [tokens.account.signIn.form.privacyPolicyLink]: "Polityka prywatności",
  [tokens.account.signIn.form.andSeparator]: "i",
  [tokens.account.signIn.form.agreementText]: "Przeczytałem/am",

  [tokens.account.confirmSignIn.title]: "Potwierdź logowanie",
  [tokens.account.confirmSignIn.expiredToken]:
    "Link wygasł. Spróbuj zalogować się ponownie.",

  [tokens.account.emailSent.title]: "Email wysłany",
  [tokens.account.emailSent.header]: "Email wysłany",
  [tokens.account.emailSent.message]:
    "Email z linkiem do potwierdzenia został wysłany. Proszę sprawdź swoją skrzynkę pocztową.",

  [tokens.account.resetPassword.title]: "Zresetuj hasło",
  [tokens.account.resetPassword.header]: "Zresetuj hasło",
  [tokens.account.resetPassword.signInLink]: "Zaloguj się",
  [tokens.account.resetPassword.form.passwordChangedMessage]:
    "Twoje hasło zostało pomyślnie zmienione. Przejdź do strony logowania.",
  [tokens.account.resetPassword.form.password]: "Hasło *",
  [tokens.account.resetPassword.form.confirmPassword]: "Potwierdź hasło *",
  [tokens.account.resetPassword.form.resetButton]: "Zresetuj hasło",
  [tokens.account.resetPassword.form.linkInvalidError]:
    "Nieprawidłowy link. Spróbuj zresetować hasło ponownie.",
  [tokens.account.resetPassword.form.linkInvalidOrExpiredError]:
    "Link do przywrócenia hasła wygasł lub jest nieprawidłowy. Spróbuj zresetować hasło ponownie.",

  [tokens.account.forgotPassword.title]: "Zapomniałeś hasła",
  [tokens.account.forgotPassword.header]: "Zapomniałeś hasła",
  [tokens.account.forgotPassword.signInLink]: "Zaloguj się",
  [tokens.account.forgotPassword.form.email]: "Adres email *",
  [tokens.account.forgotPassword.form.emailSentMessage]:
    "Email z linkiem został wysłany. Proszę sprawdź swoją skrzynkę pocztową.",
  [tokens.account.forgotPassword.form.resetButton]:
    "Wyślij link do resetowania",

  [tokens.account.popover.settings]: "Ustawienia",
  [tokens.account.popover.impersonate]: "Zaloguj się jako",
  [tokens.account.popover.stopImpersonation]: "Zakończ logowanie jako",
  [tokens.account.popover.signOut]: "Wyloguj się",
  [tokens.account.popover.creator]: "Twórca",
  [tokens.account.popover.organization]: "Organizacja",

  [tokens.account.colorScheme.light]: "Jasny",
  [tokens.account.colorScheme.dark]: "Ciemny",
  [tokens.account.colorScheme.scheme]: "Schemat kolorów",

  [tokens.account.profile.title]: "Konto",
  [tokens.account.profile.header]: "Konto",
  [tokens.account.profile.passwordHeader]: "Hasło",
  [tokens.account.profile.accountDetailsHeader]: "Szczegóły konta",
  [tokens.account.profile.accountProfileImage]: "Zdjęcie profilowe",
  [tokens.account.profile.tab.general]: "Ogólne",
  [tokens.account.profile.form.firstName]: "Imię *",
  [tokens.account.profile.form.lastName]: "Nazwisko *",
  [tokens.account.profile.form.email]: "Adres email *",
  [tokens.account.profile.form.currentPassword]: "Obecne hasło *",
  [tokens.account.profile.form.newPassword]: "Nowe hasło *",
  [tokens.account.profile.form.confirmPassword]: "Potwierdź hasło *",
  [tokens.account.profile.form.passwordUpdatedMessage]:
    "Twoje hasło zostało pomyślnie zmienione.",
  [tokens.account.profile.form.accountUpdatedMessage]:
    "Twój profil został pomyślnie zaktualizowany.",

  [tokens.account.impersonate.form.header]: "Zaloguj się jako",
  [tokens.account.impersonate.form.searchForUser]: "Użytkownik",
  [tokens.account.impersonate.form.searchForOrganization]: "Organizacja",
  [tokens.account.impersonate.form.buttons.impersonate]: "Zaloguj się",

  [tokens.payment.statuses.approved]: "Zatwierdzony",
  [tokens.payment.statuses.pending]: "Oczekujący",
  [tokens.payment.statuses.declined]: "Odrzucony",
  [tokens.payment.statuses.unknown]: "Nieznany",
  [tokens.payment.statuses.refunded]: "Zwrócony",
  [tokens.payment.statuses.noPayment]: "Brak płatności",
  [tokens.payment.success.header]: "Detale płatności",
  [tokens.payment.success.title]: "Detale płatności",
  [tokens.payment.success.product]: "Produkt",
  [tokens.payment.success.email]: "Email",
  [tokens.payment.success.status]: "Status",
  [tokens.payment.success.orderReference]: "Numer zamówienia",
  [tokens.payment.success.messages.approved]:
    "Link do produktu został wysłany na Twój adres email.",
  [tokens.payment.success.messages.pending]:
    "Płatność jest w trakcie przetwarzania. Link do produktu zostanie wysłany na Twój adres email po zakończeniu płatności.",
  [tokens.payment.success.messages.declined]:
    "Płatność została odrzucona. Spróbuj ponownie.",
  [tokens.payment.success.messages.unknown]:
    "Status płatności jest nieznany. Skontaktuj się z nami.",
  [tokens.payment.success.messages.declined]:
    "Opłata została odrzucona. Spróbuj ponownie.",
  [tokens.payment.success.messages.unknown]:
    "Status płatności jest nieznany. Skontaktuj się z nami.",

  [tokens.payment.error.header]: "Błąd płatności",
  [tokens.payment.error.title]: "Błąd płatności",
  [tokens.payment.error.message]:
    "Wystąpił błąd podczas przetwarzania płatności. Spróbuj ponownie.<br/><br/>Jeśli problem będzie się powtarzał, skontaktuj się z nami.",
  [tokens.payment.error.orderReference]: "Numer zamówienia",

  [tokens.profile.title]: "Profil",

  [tokens.organization.notFound.title]: "Nie znaleziono organizacji",
  [tokens.organization.notFound.header]: "Nie znaleziono organizacji",
  [tokens.organization.notFound.message]:
    "Organizacja, której szukasz, nie istnieje lub została przeniesiona.",

  [tokens.organization.select.title]: "Wybierz organizację",
  [tokens.organization.select.header]: "Wybierz organizację",
  [tokens.organization.select.newOrganization]: "Nowa organizacja",
  [tokens.organization.select.errorMessage]:
    "Wystąpił błąd podczas wyboru organizacji. Spróbuj ponownie.",
  [tokens.organization.select.noOrganizations]: "Nie znaleziono organizacji",

  [tokens.organization.create.title]: "Nowa organizacja",
  [tokens.organization.create.header]: "Nowa organizacja",
  [tokens.organization.create.form.name]: "Nazwa *",
  [tokens.organization.create.form.domain]: "Domain *",
  [tokens.organization.create.form.logoImage.header]: "Logo",
  [tokens.organization.create.form.logoImage.description]:
    "Zalecenia dotyczące rozmiaru: 200 (szerokość) * 200 (wysokość)",
  [tokens.organization.create.form.successMessage]:
    "Organizacja została pomyślnie utworzona.",
  [tokens.organization.create.form.domainAlreadyUsed]:
    "Ten domain jest już używany",

  [tokens.contact.title]: "Kontakt",
  [tokens.contact.header]: "Skontaktuj się z nami",
  [tokens.contact.subHeader]:
    "Nasi przyjaźni eksperci pokażą Ci FlitEdu i dostosują go do Twoich potrzeb.",
  [tokens.contact.form.firstName]: "Imię *",
  [tokens.contact.form.lastName]: "Nazwisko *",
  [tokens.contact.form.phone]: "Numer telefonu *",
  [tokens.contact.form.email]: "Adres e-mail *",
  [tokens.contact.form.message]: "Wiadomość *",
  [tokens.contact.form.sendButton]: "Wyślij wiadomość",
  [tokens.contact.form.agreementText]: "Przesyłając to, zgadzasz się na",
  [tokens.contact.form.termsOfUseLink]: "Warunki korzystania",
  [tokens.contact.form.privacyPolicyLink]: "Politykę prywatności",
  [tokens.contact.form.andSeparator]: "i",
  [tokens.contact.form.successTitle]: "Wiadomość została wysłana",
  [tokens.contact.form.successMessage1]: "Skontaktujemy się wkrótce.",
  [tokens.contact.form.successMessage2]:
    "Dziękujemy za zainteresowanie FlitEdu!",

  [tokens.errors.serverError.title]: "Usługa jest niedostępna",
  [tokens.errors.serverError.header]: "Usługa jest niedostępna",
  [tokens.errors.serverError.message]:
    "Możliwe, że trwa konserwacja lub chwilowe przeciążenie.",
  [tokens.errors.notFound.title]: "Nie znaleziono strony",
  [tokens.errors.notFound.header]: "Nie znaleziono strony",
  [tokens.errors.notFound.message]:
    "Strona, której szukasz, nie istnieje lub została przeniesiona.",
  [tokens.errors.notAuthorized.title]: "Nieautoryzowany dostęp",
  [tokens.errors.notAuthorized.header]: "Nieautoryzowany dostęp",
  [tokens.errors.notAuthorized.message]:
    "Nie masz uprawnień do wyświetlenia tej strony.",

  [tokens.general.languageChanged]: "Język został zmieniony",
  [tokens.general.language]: "Język",
  [tokens.general.newAppVersion.description]:
    "Dostępna jest nowa wersja aplikacji.",
  [tokens.general.newAppVersion.action]: "Aktualizacja",

  [tokens.general.userRoles.none]: "Brak roli",
  [tokens.general.userRoles.admin]: "Admin",
  [tokens.general.userRoles.user]: "Użytkownik",

  [tokens.general.filters.emptyOptions]: "Brak opcji",
  [tokens.general.formError]: "Coś poszło nie tak!",
  [tokens.general.paginationRowsPerPage]: "Zapisów na stronę:",
  [tokens.general.loading]: "Ładowanie...",

  [tokens.general.validators.user.conflict]:
    "Użytkownik z takim emailem jest już dodany",
  [tokens.general.validators.employee.conflict]: "Pracownik jest już dodany",
  [tokens.general.validators.employee.accountConnected]:
    "Adres email jest już połączony z innym pracownikiem",
  [tokens.general.validators.role.required]: "Rola jest wymagana",
  [tokens.general.validators.role.min]: "Wymagana jest co najmniej jedna rola",
  [tokens.general.validators.password.minLength]:
    "Hasło musi mieć co najmniej 6 znaków",
  [tokens.general.validators.passwordConfirm.notMatch]:
    "Hasła się nie zgadzają",
  [tokens.general.validators.policy.required]: "Pole wymagane",
  [tokens.general.validators.invalidEmail]:
    "Nieprawidłowy format wiadomości email",
  [tokens.general.validators.roleRequired]: "Rola jest wymagana",
  [tokens.general.validators.maxLength255]:
    "Pole może mieć maksymalnie 255 znaków",
  [tokens.general.validators.maxLength50]:
    "Pole może mieć maksymalnie 50 znaków",
  [tokens.general.validators.required]: "Pole wymagane",
  [tokens.general.validators.oneItemRequired]:
    "Wymagany jest co najmniej jeden element",

  [tokens.general.filepicker.titleHighlight]: "Kliknij, aby przesłać",
  [tokens.general.filepicker.titleNormal]: "lub przeciągnij i upuść",
  [tokens.general.filepicker.imageExtensions]: "(JPG or PNG), 5MB max",
  [tokens.general.filepicker.excelExtensions]: "(XLS lub XLSX)",
  [tokens.general.filepicker.fileSizeError]:
    "Rozmiar pliku musi być mniejszy niż 5 MB",
  [tokens.general.filepicker.deleteImage]: "Usuń",
  [tokens.general.filepicker.imageUploaded]: "Obraz został pomyślnie przesłany",
  [tokens.general.editor.tools.bold]: "Pogrubienie",
  [tokens.general.editor.tools.italic]: "Kursywa",
  [tokens.general.editor.tools.paragraph]: "Paragraf",
  [tokens.general.editor.tools.heading]: "Nagłówek",
  [tokens.general.editor.tools.list]: "Lista",
  [tokens.general.editor.tools.rawHtml]: "Raw HTML",
  [tokens.general.editor.blockTunes.delete]: "Usuń",
  [tokens.general.editor.blockTunes.moveUp]: "Przenieś w górę",
  [tokens.general.editor.blockTunes.moveDown]: "Przenieś w dół",
  [tokens.general.buttons.ok]: "Ok",
  [tokens.general.buttons.delete]: "Usuń",
  [tokens.general.buttons.edit]: "Edytuj",
  [tokens.general.buttons.add]: "Dodaj",
  [tokens.general.buttons.copy]: "Kopiuj",
  [tokens.general.buttons.invite]: "Zaproś",
  [tokens.general.buttons.send]: "Wyślij",
  [tokens.general.buttons.update]: "Aktualizuj",
  [tokens.general.buttons.archive]: "Archiwuj",
  [tokens.general.buttons.create]: "Stworz",
  [tokens.general.buttons.cancel]: "Anuluj",
  [tokens.general.buttons.close]: "Zamknij",
  [tokens.general.buttons.loadMore]: "Załaduj więcej",
  [tokens.general.buttons.tryAgain]: "Spróbuj ponownie",
  [tokens.general.buttons.backToHome]: "Wróć do strony głównej",
  [tokens.general.buttons.addItem]: "Dodaj",
  [tokens.general.buttons.next]: "Dalej →",
  [tokens.general.buttons.generateAgain]: "Generuj ponownie",
  [tokens.general.buttons.generateStrategy]: "Generuj strategię",
};
