import type { AppThunk } from 'src/store';
import { fetchWrapper } from 'src/utils/fetch-wrapper';
import { api } from 'src/config';
import { slice } from 'src/slices/account-organization';

const getAccountOrganization = (): AppThunk => async (dispatch): Promise<void> => {

  dispatch(slice.actions.setLoadingStatus(true));
  dispatch(slice.actions.setErrorStatus(false));
  try {
    var item = await fetchWrapper.get(`${api}/account/organization`, {});
    dispatch(slice.actions.getOrganization(item));
    return item;
  } catch (error) {
    dispatch(slice.actions.setErrorStatus(true));
  }
  finally {
    dispatch(slice.actions.setLoadingStatus(false));
  }
  return undefined;
}

export const thunks = {
  getAccountOrganization,
};
