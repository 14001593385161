import { Link, Stack, SvgIcon, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Seo } from 'src/components/seo';
import { usePageView } from 'src/hooks/use-page-view';
import type { Page as PageType } from 'src/types/page';
import ArrowLeftIcon from '@untitled-ui/icons-react/build/esm/ArrowLeft';
import { RouterLink } from 'src/components/router-link';
import { paths } from 'src/paths';
import { tokens } from 'src/locales/tokens';
import { ProductCreateForm } from 'src/sections/admin/products/product-create-form';

const Page: PageType = () => {

  const { t } = useTranslation();
  usePageView();

  return (
    <>
      <Seo title={t(tokens.admin.products.create.pageTitle) as string} />

      <Stack spacing={3}>
        <div>
          <Link
            color="text.primary"
            component={RouterLink}
            href={paths.admin.products.list}
            sx={{
              alignItems: 'center',
              display: 'inline-flex',
            }}
            underline="hover"
          >
            <SvgIcon sx={{ mr: 1 }}>
              <ArrowLeftIcon />
            </SvgIcon>
            <Typography variant="subtitle2">
              {t(tokens.admin.products.create.backButton)}
            </Typography>
          </Link>
        </div>
        <Stack spacing={1}>
          <Typography variant="h4">
            {t(tokens.admin.products.create.pageHeader)}
          </Typography>
        </Stack>
        <ProductCreateForm />
      </Stack>
    </>
  );
};

export default Page;
