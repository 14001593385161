import { FC, ReactNode, useEffect } from 'react';
import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Menu01Icon from '@untitled-ui/icons-react/build/esm/Menu01';
import type { Theme } from '@mui/material';
import {
  Box,
  Button,
  Container,
  IconButton,
  Stack,
  SvgIcon,
  useMediaQuery
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { Logo } from 'src/components/logo';
import { RouterLink } from 'src/components/router-link';
import { usePathname } from 'src/hooks/use-pathname';
import { useWindowScroll } from 'src/hooks/use-window-scroll';
import { paths } from 'src/paths';
import { TopNavItem } from './top-nav-item';
import { useAuth } from 'src/hooks/use-auth';
import { useDispatch, useSelector } from 'src/store';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { tokens } from 'src/locales/tokens';
import { LanguageSwitch } from './language-switch';
import { AccountButton } from './account-button';

interface Item {
  disabled?: boolean;
  external?: boolean;
  popover?: ReactNode;
  path?: string;
  title: string;
}

const TOP_NAV_HEIGHT: number = 64;

interface TopNavProps {
  onMobileNavOpen?: () => void;
}

export const TopNav: FC<TopNavProps> = (props) => {
  const { onMobileNavOpen } = props;
  const pathname = usePathname();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const [elevate, setElevate] = useState<boolean>(false);
  const offset = 64;
  const delay = 100;
  const { isAuthenticated } = useAuth();
  const [items, setItems] = useState<Item[]>([]);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { organization } = useAuth();

  useEffect(() => {
    (async () => {
      try {
        if (isAuthenticated) {

          setItems(() => {
            return [
              {
                title: t(tokens.landing.nav.products),
                path: paths.panel.products.list
              }
            ]
          });

        }
        else {
          setItems(() => {
            return [
              {
                title: t(tokens.landing.nav.singIn),
                path: paths.account.signIn
              },
              // {
              //   title: t(tokens.landing.nav.singUp),
              //   path: paths.account.signUp
              // }
            ]
          });
        }
      } catch (err) {
        console.error(err);
        toast.error(t(tokens.general.formError));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isAuthenticated, i18n.language]);

  const handleWindowScroll = useCallback(
    (): void => {
      if (window.scrollY > offset) {
        setElevate(true);
      } else {
        setElevate(false);
      }
    },
    []
  );

  useWindowScroll({
    handler: handleWindowScroll,
    delay
  });

  return (
    <Box
      component="header"
      sx={{
        left: 0,
        position: 'fixed',
        right: 0,
        top: 0,
        pt: 2,
        zIndex: (theme) => theme.zIndex.appBar
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          backdropFilter: 'blur(6px)',
          backgroundColor: 'transparent',
          borderRadius: 2.5,
          boxShadow: 'none',
          transition: (theme) => theme.transitions.create('box-shadow, background-color', {
            easing: theme.transitions.easing.easeInOut,
            duration: 200
          }),
          ...(elevate && {
            backgroundColor: (theme) => alpha(theme.palette.background.paper, 0.90),
            boxShadow: 8
          })
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          //justifyContent="space-between"
          sx={{ height: TOP_NAV_HEIGHT }}
        >
          <Stack
            alignItems="center"
            direction="row"
            spacing={1}
            sx={{ flexGrow: 1 }}
          >
            <Stack
              alignItems="center"
              component={RouterLink}
              direction="row"
              display="inline-flex"
              href={paths.index}
              spacing={1}
              sx={{ textDecoration: 'none' }}
            >
              <Box
                sx={{
                  display: 'inline-flex',
                  height: 24,
                  width: 24
                }}
              >
                <Logo />
              </Box>
              {/* {mdUp && ( */}
              <Box
                sx={{
                  color: 'text.primary',
                  fontFamily: '\'Plus Jakarta Sans\', sans-serif',
                  fontSize: 14,
                  fontWeight: 800,
                  letterSpacing: '0.3px',
                  lineHeight: 2.5,
                  '& span': {
                    color: 'primary.main'
                  }
                }}
              >
                {organization ?
                  <>
                    <span>{organization.name}</span>
                  </> :
                  <>
                    Flit<span>Edu</span>
                  </>
                }
              </Box>
              {/* )} */}
            </Stack>
          </Stack>
          {mdUp && (
            <Stack
              alignItems="center"
              direction="row"
              spacing={2}
            >

              <Box
                component="nav"
                sx={{ height: '100%' }}
              >
                <Stack
                  component="ul"
                  alignItems="center"
                  justifyContent="center"
                  direction="row"
                  spacing={1}
                  sx={{
                    height: '100%',
                    listStyle: 'none',
                    m: 0,
                    p: 0
                  }}
                >
                  <>
                    {items.map((item) => {
                      const checkPath = !!(item.path && pathname);
                      const partialMatch = checkPath ? pathname.includes(item.path!) : false;
                      const exactMatch = checkPath ? pathname === item.path : false;
                      const active = item.popover ? partialMatch : exactMatch;

                      return (
                        <TopNavItem
                          active={active}
                          external={item.external}
                          key={item.title}
                          path={item.path}
                          popover={item.popover}
                          title={item.title}
                        />
                      );
                    })}
                  </>

                  <LanguageSwitch />
                  {isAuthenticated && <AccountButton />}
                </Stack>

              </Box>
            </Stack>
          )}
          {!mdUp && (
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="flex-end"
              spacing={2}
              sx={{ flexGrow: 1 }}
            >
              <LanguageSwitch />
              {isAuthenticated && <AccountButton />}
              <IconButton onClick={onMobileNavOpen}>
                <SvgIcon fontSize="small">
                  <Menu01Icon />
                </SvgIcon>
              </IconButton>

            </Stack>
          )}

        </Stack>
      </Container>
    </Box>
  );
};

TopNav.propTypes = {
  onMobileNavOpen: PropTypes.func
};
