import React, { useEffect, useState } from "react";

import { Stack, Theme, useMediaQuery, useTheme } from "@mui/material";

import {
  useInspectorDrawerOpen,
  useSamplesDrawerOpen,
} from "../../../../sections/admin/email-template/documents/editor/EditorContext";

import InspectorDrawer, {
  INSPECTOR_DRAWER_WIDTH,
} from "../../../../sections/admin/email-template/InspectorDrawer";
import TemplatePanel from "../../../../sections/admin/email-template/TemplatePanel";
import { useParams } from "react-router";
import { Seo } from "src/components/seo";
import { thunks } from "src/thunks/admin/email-templates";
import {
  EmailTemplate,
  EmailTemplateType,
} from "src/types/admin/email-template";
import { number } from "prop-types";
import { useDispatch, useSelector } from "src/store";
import { useRouter } from "src/hooks/use-router";
import { createRoute } from "react-router-url-params";
import { paths } from "src/paths";

function useDrawerTransition(
  cssProperty: "margin-left" | "margin-right",
  open: boolean
) {
  const { transitions } = useTheme();
  return transitions.create(cssProperty, {
    easing: !open ? transitions.easing.sharp : transitions.easing.easeOut,
    duration: !open
      ? transitions.duration.leavingScreen
      : transitions.duration.enteringScreen,
  });
}

export default function App() {
  const inspectorDrawerOpen = useInspectorDrawerOpen();
  //const samplesDrawerOpen = useSamplesDrawerOpen();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const [openNav, setOpenNav] = React.useState<boolean>(false);
  const router = useRouter();

  const { organization } = useSelector((state) => state.accountOrganization);
  // const marginLeftTransition = useDrawerTransition(
  //   "margin-left",
  //   samplesDrawerOpen
  // );
  const marginRightTransition = useDrawerTransition(
    "margin-right",
    inspectorDrawerOpen
  );

  const { productId, type } = useParams();
  const [template, setTemplate] = useState<EmailTemplate | undefined>();
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      const typeValue = Number.parseInt(type!) as EmailTemplateType;
      if (productId) {
        const data = (await dispatch(
          thunks.getProductEmailTemplate(productId, typeValue)
        )) as EmailTemplate;
        setTemplate(data);
      } else {
        const data = (await dispatch(
          thunks.getOrganizationEmailTemplate(typeValue)
        )) as EmailTemplate;

        setTemplate(data);
      }
    }
    fetchData();
  }, [dispatch, productId, type]);

  return (
    <>
      <Seo title={"Email editor"} />
      <InspectorDrawer />
      <Stack
        sx={{
          marginRight:
            mdUp && inspectorDrawerOpen ? `${INSPECTOR_DRAWER_WIDTH}px` : 0,
          transition: [0, marginRightTransition].join(", "),
        }}
      >
        <TemplatePanel
          content={template?.content}
          emailTitle={template?.title ?? ""}
          onSave={async (title, html, content) => {
            const typeValue = Number.parseInt(type!) as EmailTemplateType;
            await dispatch(
              thunks.saveEmailTemplate(typeValue, {
                title,
                content,
                html,
                productId: productId,
              })
            );
            if (productId) {
              router.push(
                createRoute(paths.admin.products.details).link({
                  productId: productId,
                })
              );
            } else {
              router.push(
                createRoute(paths.admin.organizations.details).link({
                  organizationId: organization?.id,
                })
              );
            }
          }}
        />
      </Stack>
    </>
  );
}
