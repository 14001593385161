import type { FC } from "react";
import { useEffect } from "react";
import { useRoutes } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Provider as ReduxProvider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { RTL } from "./components/rtl";
import { SplashScreen } from "./components/splash-screen";
import { Toaster } from "./components/toaster";
import {
  SettingsConsumer,
  SettingsProvider,
} from "./contexts/settings-context";
import { AuthConsumer, AuthProvider } from "./contexts/auth/jwt-context";
import {
  clarityConfig,
  gtmConfig,
  googleSignInConfig,
  mixpanelConfig,
} from "./config";
import { useAnalytics } from "./hooks/use-analytics";
import { routes } from "./routes";
import { store, useSelector } from "./store";
import ServerErrorPage from "./pages/500";
import { createTheme, createCustomTheme } from "./theme";
// Remove if react-quill is not used
// import 'react-quill/dist/quill.snow.css';
// Remove if react-draft-wysiwyg is not used
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// Remove if simplebar is not used
import "simplebar-react/dist/simplebar.min.css";
// Remove if mapbox is not used
// import 'mapbox-gl/dist/mapbox-gl.css';
// Remove if locales are not used
import "./locales/i18n";
import toast from "react-hot-toast";
import { useServiceWorker } from "src/hooks/use-service-worker";
import { useTranslation } from "react-i18next";
import { tokens } from "./locales/tokens";
import { Language, datePickersUILocales, uiLocales } from "./language";
import { Button, Stack, Typography } from "@mui/material";
import { useClarity } from "./hooks/use-clarity";
import moment from "moment";
import "moment/locale/pl";
import "moment/locale/uk";
import "moment/locale/en-gb";
import { pl, uk, enUS } from "date-fns/locale";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useMixPanel } from "./hooks/use-mixpanel";
import { CONSTS, ORGANIZATION_COLOR_SETTINGS } from "./consts";
import { indigo, withAlphas } from "./theme/colors";
import { Favicon } from "./components/favicon";
import { getColor } from "./types/account-organization";

export const App: FC = () => {
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();
  const { t, i18n } = useTranslation();

  useAnalytics(gtmConfig);
  useMixPanel(mixpanelConfig);
  useClarity(clarityConfig);

  // useNprogress();
  useEffect(() => {
    if (showReload && waitingWorker) {
      toast.success(
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
        >
          <Typography>{t(tokens.general.newAppVersion.description)}</Typography>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={reloadPage}
          >
            {t(tokens.general.newAppVersion.action)}
          </Button>
        </Stack>,
        {
          id: "newVersionAvailable",
          duration: Infinity,
        }
      );
    }
  }, [waitingWorker, showReload, reloadPage, t]);

  const element = useRoutes(routes);

  const calendarLang = () => {
    switch (i18n.language as Language) {
      case "en":
        return enUS;
      case "uk":
        return uk;
      case "pl":
        return pl;
      default:
        return enUS;
    }
  };

  return (
    <GoogleOAuthProvider clientId={googleSignInConfig.clientId!}>
      <ReduxProvider store={store}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={calendarLang()}
        >
          <AuthProvider>
            <AuthConsumer>
              {(auth) => (
                <SettingsProvider>
                  <SettingsConsumer>
                    {(settings) => {
                      // Prevent theme flicker when restoring custom settings from browser storage
                      if (!settings.isInitialized) {
                        // return null;
                      }

                      var theme = createTheme(
                        {
                          colorPreset: settings.colorPreset,
                          contrast: settings.contrast,
                          direction: settings.direction,
                          paletteMode: settings.paletteMode,
                          responsiveFontSizes: settings.responsiveFontSizes,
                        },
                        uiLocales[i18n.language as Language],
                        datePickersUILocales[i18n.language as Language]
                      );

                      moment.locale(i18n.language);
                      // Prevent guards from redirecting
                      const showSlashScreen = !auth.isInitialized;
                      const hasError = auth.hasError;

                      if (
                        auth.organization &&
                        auth.organization.id !== CONSTS.DEFAULT_ORGANIZATION
                      ) {
                        var lightestColor = getColor(
                          auth.organization,
                          ORGANIZATION_COLOR_SETTINGS.LIGHTEST_COLOR,
                          indigo.lightest
                        );
                        var lightColor = getColor(
                          auth.organization,
                          ORGANIZATION_COLOR_SETTINGS.LIGHT_COLOR,
                          indigo.light
                        );
                        var mainColor = getColor(
                          auth.organization,
                          ORGANIZATION_COLOR_SETTINGS.MAIN_COLOR,
                          indigo.main
                        );
                        var darkColor = getColor(
                          auth.organization,
                          ORGANIZATION_COLOR_SETTINGS.DARK_COLOR,
                          indigo.dark
                        );
                        var darkestColor = getColor(
                          auth.organization,
                          ORGANIZATION_COLOR_SETTINGS.DARKEST_COLOR,
                          indigo.darkest
                        );

                        var contrastTextColor = getColor(
                          auth.organization,
                          ORGANIZATION_COLOR_SETTINGS.CONTRAST_TEXT_C0LOR,
                          indigo.contrastText
                        );

                        theme = createCustomTheme(
                          {
                            //colorPreset: settings.colorPreset,
                            paletteColor: withAlphas({
                              lightest: lightestColor,
                              light: lightColor,
                              main: mainColor,
                              dark: darkColor,
                              darkest: darkestColor,
                              contrastText: contrastTextColor,
                            }),
                            contrast: settings.contrast,
                            direction: settings.direction,
                            paletteMode: settings.paletteMode,
                            responsiveFontSizes: settings.responsiveFontSizes,
                          },
                          uiLocales[i18n.language as Language],
                          datePickersUILocales[i18n.language as Language]
                        );
                      }

                      return (
                        <ThemeProvider theme={theme}>
                          <Helmet>
                            <meta
                              name="color-scheme"
                              content={settings.paletteMode}
                            />
                            <meta
                              name="theme-color"
                              content={theme.palette.neutral[900]}
                            />
                          </Helmet>
                          <Favicon />
                          <RTL direction={settings.direction}>
                            <CssBaseline />
                            {hasError ? (
                              <>
                                <ServerErrorPage />
                              </>
                            ) : showSlashScreen ? (
                              <SplashScreen />
                            ) : (
                              <>
                                {element}
                                {/* <SettingsButton onClick={settings.handleDrawerOpen} />
                                  <SettingsDrawer
                                    canReset={settings.isCustom}
                                    onClose={settings.handleDrawerClose}
                                    onReset={settings.handleReset}
                                    onUpdate={settings.handleUpdate}
                                    open={settings.openDrawer}
                                    values={{
                                      colorPreset: settings.colorPreset,
                                      contrast: settings.contrast,
                                      direction: settings.direction,
                                      paletteMode: settings.paletteMode,
                                      responsiveFontSizes: settings.responsiveFontSizes,
                                      stretch: settings.stretch,
                                      layout: settings.layout,
                                      navColor: settings.navColor
                                    }}
                                  /> */}
                              </>
                            )}
                            <Toaster />
                          </RTL>
                        </ThemeProvider>
                      );
                    }}
                  </SettingsConsumer>
                </SettingsProvider>
              )}
            </AuthConsumer>
          </AuthProvider>
        </LocalizationProvider>
      </ReduxProvider>
    </GoogleOAuthProvider>
  );
};
