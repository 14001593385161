export const paths = {
  index: "/",
  privacyPolicy: "/privacy-policy",
  terms: "/terms-of-use",
  payment: {
    success: "/payment/success",
    error: "/payment/error",
    index: "/payment",
  },
  admin: {
    products: {
      list: "/admin/products",
      details: "/admin/products/:productId",
      create: "/admin/products/create",
      emailTemplate: "/admin/products/:productId/email-templates/:type",
    },
    modules: {
      details: "/admin/products/:productId/modules/:moduleId",
      create: "/admin/products/:productId/modules/create",
    },
    organizations: {
      details: "/admin/organizations/:organizationId",
      emailTemplate:
        "/admin/organizations/:organizationId/email-templates/:type",
    },
  },
  panel: {
    products: {
      list: "/panel/products",
      details: "/panel/products/:productId",
    },
    modules: {
      details: "/panel/products/:productId/modules/:moduleId",
    },
  },
  modules: {
    details: "/products/:productId/modules/:moduleId",
    create: "/products/:productId/modules/create",
  },
  createModules: "/products/:productId/modules/create",
  account: {
    signIn: "/account/sign-in",
    emailSent: "/account/email-sent",
    settings: "/account/settings",
    confirmSignin: "/account/confirm-signin",
  },
  organizations: {
    notFound: "/organization/not-found",
    create: "/organization/create",
    select: "/organization/select",
  },
  401: "/401",
  404: "/404",
  500: "/500",
};
