import type { FC } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import { Product } from "src/types/admin/product";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useFormik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch } from "src/store";
import { thunks } from "src/thunks/admin/product-settings";
import { Setting } from "src/types/setting";
import { PRODUCT_SETTINGS_WEBHOOKS } from "src/consts";

interface ProductWebHooksFormProps {
  product: Product;
  settings: Setting[];
}

export const ProductWebHooksForm: FC<ProductWebHooksFormProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { product, settings } = props;

  const validationSchema = Yup.object({});

  const formik = useFormik({
    initialValues: {
      productInvitationHook: settings.find(
        (setting) => setting.key === PRODUCT_SETTINGS_WEBHOOKS.INVITATION
      )?.value,
    },
    validationSchema,
    onSubmit: async (values, helpers): Promise<void> => {
      await dispatch(
        thunks.updateProductSetting(
          product.id,
          PRODUCT_SETTINGS_WEBHOOKS.INVITATION,
          values.productInvitationHook ?? ""
        )
      );
      if (product) {
        toast.success(t(tokens.admin.products.details.form.successMessage));
      } else {
        toast.error(t(tokens.general.formError));
        helpers.setStatus({ success: false });
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      {...props}
    >
      <Stack spacing={4}>
        <Card>
          <CardHeader
            title={t(tokens.admin.products.details.settings.webHooks.header)}
          />
          <CardContent>
            <Stack>
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: "bold",
                  mb: 1,
                }}
              >
                {t(
                  tokens.admin.products.details.settings.webHooks.form
                    .productInvitationHook
                )}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  mb: 1,
                }}
              >
                {t(tokens.admin.products.details.settings.webHooks.form.params)}{" "}
                <code>
                  UserEmail, UserFirstName, UserLastName, ProductTitle,
                  ProductDescription, ProductImageUrl
                </code>
              </Typography>
              <TextField
                error={
                  !!(
                    formik.touched.productInvitationHook &&
                    formik.errors.productInvitationHook
                  )
                }
                fullWidth
                helperText={
                  formik.touched.productInvitationHook &&
                  formik.errors.productInvitationHook
                }
                label={t(
                  tokens.admin.products.details.settings.webHooks.form.url
                )}
                name="productInvitationHook"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.productInvitationHook}
              />
              <Box sx={{ mt: 2 }}>
                <LoadingButton
                  type="submit"
                  disabled={formik.isSubmitting}
                  loading={formik.isSubmitting}
                  variant="contained"
                >
                  {t(tokens.general.buttons.update)}
                </LoadingButton>
              </Box>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </form>
  );
};
