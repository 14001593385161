import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Tooltip } from '@mui/material';
import { usePopover } from 'src/hooks/use-popover';
import { LanguagePopover } from './language-popover';
import { Language, languages } from 'src/language';
import { tokens } from 'src/locales/tokens';


//https://flagicons.lipis.dev/

export const LanguageSwitch: FC = () => {
  const { i18n, t } = useTranslation();
  const popover = usePopover<HTMLButtonElement>();

  const flag = languages[i18n.language as Language];

  return (
    <>
      <Tooltip title={t(tokens.general.language)}>
        <IconButton
          onClick={popover.handleOpen}
          ref={popover.anchorRef}
        >
          <Box
            sx={{
              width: 28,
              height: 21,
              '& img': {
                width: '100%',
                
              }
            }}
          >
            <img src={flag} />
          </Box>
        </IconButton>
      </Tooltip>
      <LanguagePopover
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
      />
    </>
  );
};
