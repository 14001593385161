import {
  Box,
  Container,
  Stack,
  Typography
} from '@mui/material';
import { Seo } from 'src/components/seo';
import { usePageView } from 'src/hooks/use-page-view';
import type { Page as PageType } from 'src/types/page';


const Page: PageType = () => {
  usePageView();

  return (
    <>
      <Seo title="Terms of Use" />
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.mode === 'dark'
            ? 'neutral.800'
            : 'neutral.50',
          py: '20px'
        }}
      >
        <Container maxWidth="lg">
          <Stack spacing={1}>
            <Typography variant="h1">
              Terms of Use
            </Typography>
            <Typography
              color="text.secondary"
              variant="body1"
            >
              Read before using our services
            </Typography>


          </Stack>
        </Container>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          pt: 2,
          pb: 8
        }}
      >
        <Container maxWidth="lg">
          <Stack

            spacing={2}
          >
            <h2 id="general">General</h2>
            <p>Please read these Terms and Conditions (“Terms“, “Terms and Conditions“) carefully before using the app (the “Service“) created by FlitEdu (“us“, “we“, or “our“).
              Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms.
              These Terms apply to all visitors, users and others who access or use the Service.</p>

            <p>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.</p>

            <h2 id="content">Content</h2>
            <p>Our Service allows you to post, link, store, share and otherwise use certain information, text, graphics, videos, or other material (“Content“).
              You are responsible for the Content that you post to the Service, including its legality, reliability, and appropriateness.</p>

            <p>By posting Content to the Service, you grant us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such
              Content on and through the Service. You retain any and all of your rights to any
              Content you submit, post or display on or through the Service and you are responsible for protecting those rights.</p>

            <p>You represent and warrant that: (i) the Content is yours (you own it) or you have the right to use it and grant us the rights and license as provided in these Terms,
              and (ii) the posting of your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights
              of any person.</p>

            <h2 id="accounts">Accounts</h2>
            <p>When you create an account with us, you must provide us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the
              Terms, which may result in immediate termination of your account on our Service.</p>

            <p>You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password,
              whether your password is with our Service or a third-party service.</p>

            <p>You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</p>

            <h2 id="intellectual-property">Intellectual Property</h2>
            <p>The original content of the Service, such as text, trademarks, graphics, logos, button icons, images and compilations thereof, all software used in the Service,
              and the contents of the offering, belong to FlitEdu, FlitEdu’s licensors or suppliers (“Intellectual Property“).
              The Intellectual Property Rights are protected by applicable laws, including, and without limitation,
              the Copyright Act and other laws protecting intellectual property and property rights. You undertake to comply with all such applicable laws and not to
              modify or remove any copyright or ownership notices in such Intellectual Property.</p>

            <p>Ownership of all Content belongs to you, or the third party who has the intellectual property rights to such Uploaded Material.
              You hereby grant us a worldwide, perpetual, non-exclusive, royalty-free and transferable right to dispose of, process, store, publish, distribute, stream, transmit,
              play, code, copy, present, display and otherwise use Content to provide the Service, or any of our existing or future products or other services.</p>

            <p>You warrant that you hold all necessary rights to view and upload Content, to use Content in other ways, and to grant us the license to Content as above.</p>

            <h2 id="links-to-other-web-sites">Links To Other Web Sites</h2>
            <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by FlitEdu.</p>

            <p>FlitEdu has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party web sites or services.
              You further acknowledge and agree that FlitEdu shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by
              or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>

            <p>We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.</p>

            <h2 id="termination">Termination</h2>
            <p>We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.</p>

            <p>Upon termination, your right to use the Service will immediately cease.</p>

            <p>If you wish to terminate your account, you may simply discontinue using the Service. If you want to be disconnected from our database, send us a message on
              support@flitedu.net We will take reasonable measures to ensure that you are you when you request to be disconnected.</p>

            <p>If you wish to end your personal subscription connected to your account, please use the appropriate steps in AppStore, GPlay or equivalent app stores (“Appstores”).
              We do not take any responsibility for issues or matters arising from the Appstores inability to comply with your request, not limited to but including,
              invoicing or payment matters.</p>

            <p>If you, when ending your subscription, want to be disconnected from our database, send us a message on support@flitedu.net We will take reasonable measures
              to ensure that you are you when you request to be disconnected.</p>

            <h2 id="network-access">Network Access</h2>
            <p>You are solely responsible for securing your access to the network needed to use the Service. There may be additional costs for e.g. data transfer and messaging services.
              We do not bear these costs. Furthermore, you are responsible for procuring and updating the hardware or software needed to access the Service.</p>

            <h2 id="personal-information">Personal Information</h2>
            <p>We process personal information that we receive in connection with the use of the Service. The personal data is processed in accordance with our Privacy Policy,
              available at https://flitedu.com/privacy-policy.</p>

            <h2 id="commitments-in-relation-to-apple-and-google">Commitments in Relation to Apple and Google</h2>
            <p>In accordance with the above, you are granted only a limited license to the App.
              FlitEdu, its licensors and / or suppliers retain ownership of the App (and any copy of the App). Standard data traffic rates may apply when using the App.</p>

            <h2 id="limitation-of-liability">Limitation of Liability</h2>
            <p>In no event shall FlitEdu, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental,
              special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i)
              your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (
              iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty,
              contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage,
              and even if a remedy set forth herein is found to have failed of its essential purpose.
              This includes but not limited to any issues connected to the subscription services used by you to access the Service.</p>

            <h2 id="indemnification">Indemnification</h2>
            <p>You are liable to us for any damages incurred by us, or any third party, for your breach of these Terms, including but not limited to the abuse of the App.
              You further agree to indemnify us in relation to all claims, costs (including reasonable legal costs), damages, expenses, damages and losses that
              we have incurred in any way due to your violations of these Terms of Use or other applicable law.</p>

            <p>In the event that any Content infringes any third party’s intellectual property, you agree to immediately remove all infringing parts of the Content and hold us harmless
              for all damages, costs and expenses we incur as a result of such infringement.</p>

            <h2 id="disclaimer">Disclaimer</h2>
            <p>The Service, including the App, is provided in its existing condition without any guarantees of any kind. Your use of the Service is solely your own responsibility
              and is done at your own risk. We make no warranties – direct, implied or otherwise – regarding the availability, quality, fitness of any particular purpose,
              suitability or accuracy of the App or the Service in general.</p>

            <p>FlitEdu, its subsidiaries, affiliates, and its licensors do not warrant that (i) the Service will function uninterrupted, secure or available at any particular time or location; (ii) any errors or defects will be corrected; (iii) the Service is free of viruses or other harmful components; or (iv) the results of using the Service will meet your requirements.</p>

            <h2 id="governing-law">Governing Law</h2>
            <p>These Terms shall be governed and construed in accordance with the laws of Ukraine, without regard to its conflict of law provisions.</p>

            <p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights.
              If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect.
              These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service.</p>

            <h2 id="changes">Changes</h2>
            <p>We reserve the right to unilaterally change the terms of these Terms of Use from time to time. The terms and conditions are available on our website,
              https://flitedu.com/terms-of-use. If your consent is required because of the changes, we will provide you with further notice as appropriate depending on the circumstances, and request your consent in accordance with the applicable data protection regulations.</p>

            <p>By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms.
              If you do not agree to the new terms, please stop using the Service.</p>

          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default Page;
