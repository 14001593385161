import { Box, Container, Theme, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Seo } from 'src/components/seo';
import { usePageView } from 'src/hooks/use-page-view';
import { tokens } from 'src/locales/tokens';
import NotFound from 'src/sections/errors/not-found';
import type { Page as PageType } from 'src/types/page';

const Page: PageType = () => {
  const { t } = useTranslation();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  usePageView();

  return (
    <>
      <Seo title={t(tokens.organization.notFound.title) as string} />
      <Box
        component="main"
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexGrow: 1,
          py: '80px'
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 6
            }}
          >
            <Box
              alt={t(tokens.organization.notFound.header) as string}
              component="img"
              src="/assets/errors/error-404.png"
              sx={{
                height: 'auto',
                maxWidth: '100%',
                width: 400
              }}
            />
          </Box>
          <Typography
            align="center"
            variant={mdUp ? 'h1' : 'h4'}
          >
            {t(tokens.organization.notFound.header)}
          </Typography>
          <Typography
            align="center"
            color="text.secondary"
            sx={{ mt: 2 }}
          >
            {t(tokens.organization.notFound.message)}
          </Typography>
          {/* {props.showBackButton &&
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 6
              }}
            >
              <Button
                component={RouterLink}
                href={paths.index}
              >
                {t(tokens.general.buttons.backToHome)}
              </Button>
            </Box>
          } */}
        </Container>
      </Box>
    </>
  );
};

export default Page;
