import type { AppThunk } from 'src/store';
import { fetchWrapper } from 'src/utils/fetch-wrapper';
import { api } from 'src/config';
import { slice as productSlice } from 'src/slices/panel/account-product';
import { ERRORS } from 'src/consts';

const getProduct = (productId: string): AppThunk => async (dispatch): Promise<void> => {

  dispatch(productSlice.actions.setLoadingErrorStatus(undefined));
  dispatch(productSlice.actions.setLoadingStatus(true));
  try {
    var product = await fetchWrapper.get(`${api}/account/products/${productId}`, {});
    dispatch(productSlice.actions.getProduct(product));
  } catch (error) {
    if (error?.code === 'Product.ProductNotFound') {
      dispatch(productSlice.actions.setLoadingErrorStatus(ERRORS.NOT_FOUND_ERROR));
    } else {
      dispatch(productSlice.actions.setLoadingErrorStatus(ERRORS.GENERAL_ERROR));
    }
  }
  dispatch(productSlice.actions.setLoadingStatus(false));
}

const cleanUpProduct = (): AppThunk => async (dispatch): Promise<void> => {
  dispatch(productSlice.actions.cleanUpProduct());
}

export const thunks = {
  getProduct,
  cleanUpProduct
};
