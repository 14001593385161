import type { AppThunk } from 'src/store';
import { fetchWrapper } from 'src/utils/fetch-wrapper';
import { api } from 'src/config';
import { slice as moduleSlice } from 'src/slices/panel/account-module';
import { ERRORS } from 'src/consts';

const getModule = (productId: string, moduleId: string): AppThunk => async (dispatch): Promise<void> => {

  dispatch(moduleSlice.actions.setLoadingErrorStatus(undefined));
  dispatch(moduleSlice.actions.setLoadingStatus(true));
  try {
    var module = await fetchWrapper.get(`${api}/account/products/${productId}/modules/${moduleId}`, {});
    dispatch(moduleSlice.actions.getModule(module));
  } catch (error) {
    if (error?.code === 'Module.ModuleNotFound') {
      dispatch(moduleSlice.actions.setLoadingErrorStatus(ERRORS.NOT_FOUND_ERROR));
    } else {
      dispatch(moduleSlice.actions.setLoadingErrorStatus(ERRORS.GENERAL_ERROR));
    }
  }
  dispatch(moduleSlice.actions.setLoadingStatus(false));
}

export const thunks = {
  getModule
};
