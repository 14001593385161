export enum Currency{
  NONE,
  USD,
  EUR,
  UAH,
  PLN
}

export interface ProductPlan {
  id: string;
  name: string;
  price: number;
  currency: Currency;
  params: string;
}
