import { useMemo, type FC } from "react";
import {
  Button,
  Drawer,
  FormHelperText,
  IconButton,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import { useDispatch } from "src/store";
import { toast } from "react-hot-toast";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import XIcon from "@untitled-ui/icons-react/build/esm/X";
import { thunks } from "src/thunks/admin/product-plan";
import { Product } from "src/types/admin/product";
import { Currency, ProductPlan } from "src/types/product-plan";
import { paths } from "src/paths";

interface SettingsProductPlanDrawerProps {
  product: Product;
  productPlan: ProductPlan | undefined;
  isOpen: boolean;
  onClose: () => void;
  onUpdate: () => void;
}

export const SettingsProductPlanDrawer: FC<SettingsProductPlanDrawerProps> = (
  props
) => {
  const { t } = useTranslation();
  const { isOpen, onClose, productPlan, product } = props;

  const wayforpayUrl = useMemo(() => {
    var url =
      window.location.origin +
      paths.payment.index +
      `?productId=${product.id}&productPlanId=${productPlan?.id}`;

    return url;
  }, [product, productPlan]);
  
  return (
    <Drawer
      anchor="right"
      variant="temporary"
      onClose={onClose}
      open={isOpen}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: 500,
          p: 3,
        },
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={3}
        sx={{
          pb: 3,
        }}
      >
        <Typography variant="h6">
          {t(tokens.admin.products.details.productPlans.form.settingsTitle)}
        </Typography>
        <Stack
          alignItems="center"
          direction="row"
          spacing={0.5}
        >
          <IconButton
            color="inherit"
            onClick={onClose}
          >
            <SvgIcon>
              <XIcon />
            </SvgIcon>
          </IconButton>
        </Stack>
      </Stack>

      <Stack spacing={3}>
        <TextField
          fullWidth
          label={t(tokens.admin.products.details.productPlans.form.url)}
          name="url"
          multiline
          inputProps={{
            autoComplete: "url",
          }}
          value={wayforpayUrl}
        />

        <Stack
          alignItems="center"
          direction="row"
          justifyContent="flex-end"
          spacing={1}
          sx={{ pt: 3, pb: 3 }}
        >
          <Button
            color="inherit"
            onClick={onClose}
            size="small"
          >
            {t(tokens.general.buttons.cancel)}
          </Button>
        </Stack>
      </Stack>
    </Drawer>
  );
};
