import { useCallback, useEffect, useMemo } from "react";
// import { subDays, subHours, subMinutes, subMonths } from 'date-fns';
import {
  Card,
  CardContent,
  Chip,
  Link,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { Seo } from "src/components/seo";
import { usePageView } from "src/hooks/use-page-view";
import type { Page as PageType } from "src/types/page";
import { tokens } from "src/locales/tokens";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "src/store";
import { thunks } from "src/thunks/panel/modules";
import { thunks as productThunks } from "src/thunks/panel/product";
import { useParams } from "react-router";
import { paths } from "src/paths";
import { RouterLink } from "src/components/router-link";
import { createRoute } from "react-router-url-params";
import ArrowLeftIcon from "@untitled-ui/icons-react/build/esm/ArrowLeft";
// const now = new Date();
import { useRouter } from "src/hooks/use-router";
import { Box } from "@mui/system";
import {
  OptionItem,
  OptionMenuButton,
} from "src/sections/common/option-menu-button";
import { EmptyMessage } from "src/sections/common/empty-message";
import { ErrorMessage } from "src/sections/common/error-message";
import NotFound from "src/sections/errors/not-found";
import { ERRORS, ORG_ROLES } from "src/consts";
import ServerError from "src/sections/errors/server-error";
import { useAuth } from "src/hooks/use-auth";
import EditorJsRenderer from "src/components/editor/EditorJsRenderer";
import { trackAction } from "src/hooks/use-mixpanel";
import Grid from "@mui/material/Unstable_Grid2";
import moment from "moment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const placeholderItems = 5;

const Page: PageType = () => {
  const { t } = useTranslation();

  usePageView();

  const dispatch = useDispatch();
  const { productId } = useParams();
  const auth = useAuth();

  const {
    items: modules,
    hasLoadingError: hasModulesLoadingError,
    isLoading: isModulesLoading,
  } = useSelector((state) => state.accountModules);
  const {
    item: product,
    error: productError,
    isLoading: isProductLoading,
  } = useSelector((state) => state.accountProduct);

  const menuItems = useMemo<OptionItem[]>(() => {
    return [
      {
        text: t(tokens.panel.products.details.menu.edit),
        iconName: "edit",
        onClick: () => {
          window.open(
            createRoute(paths.admin.products.details).link({
              productId: productId,
            }),
            "_blank"
          );
        },
      },
    ];
  }, [productId, t]);

  const data = useMemo(() => {
    if (product && product.content) {
      try {
        return JSON.parse(product.content);
      } catch (e) {
        return undefined;
      }
    }
    return undefined;
  }, [product]);

  const loadData = useCallback(async () => {
    if (productId) {
      await dispatch(productThunks.getProduct(productId));
    }
  }, [dispatch, productId]);

  const loadModules = useCallback(async () => {
    if (productId) {
      await dispatch(thunks.getModules(productId));
    }
  }, [dispatch, productId]);

  useEffect(() => {
    loadData();
    loadModules();

    return () => {
      (async () => {
        await dispatch(productThunks.cleanUpProduct());
      })();
    };
  }, [dispatch, loadData, loadModules, productId]);

  const router = useRouter();

  if (productError) {
    if (productError.status === ERRORS.NOT_FOUND_ERROR.status) {
      return <NotFound showBackButton={false} />;
    }
    return <ServerError showBackButton={false} />;
  }

  if (!productId) {
    return;
  }

  return (
    <>
      <Seo title={product?.title} />
      <Stack
        spacing={3}
        sx={{ mb: 3 }}
      >
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
        >
          <Link
            color="text.primary"
            component={RouterLink}
            href={paths.panel.products.list}
            sx={{
              alignItems: "center",
              display: "inline-flex",
            }}
            underline="hover"
          >
            <SvgIcon sx={{ mr: 1 }}>
              <ArrowLeftIcon />
            </SvgIcon>
            <Typography variant="subtitle2">
              {t(tokens.panel.products.details.backButton)}
            </Typography>
          </Link>
          {auth.getUserRole() === ORG_ROLES.Admin && (
            <Box>
              <OptionMenuButton items={menuItems} />
            </Box>
          )}
        </Stack>

        {productError && (
          <>
            <Card>
              <CardContent>
                <ErrorMessage
                  text={t(tokens.panel.products.details.errorProductLoading)}
                />
              </CardContent>
            </Card>
          </>
        )}

        {!productError && (
          <>
            <Typography variant="h4">
              {isProductLoading ? (
                <Skeleton width={"50%"} />
              ) : (
                <>{product?.title}</>
              )}
            </Typography>
          </>
        )}
        {product?.isStandalone && (
          <>{data && <EditorJsRenderer data={data} />}</>
        )}

        {!product?.isStandalone && (
          <>
            <Stack spacing={4}>
              <Grid
                container
                spacing={4}
              >
                <Grid
                  md={12}
                  xs={12}
                >
                  <Card>
                    {hasModulesLoadingError && (
                      <CardContent>
                        <ErrorMessage
                          text={t(
                            tokens.panel.products.details.errorModulesLoading
                          )}
                        />
                      </CardContent>
                    )}

                    {!hasModulesLoadingError && (
                      <>
                        <List disablePadding>
                          {modules.map((module, index) => (
                            <ListItem
                              sx={{
                                "&:hover": {
                                  backgroundColor: "action.hover",
                                  cursor: module.isActive
                                    ? "pointer"
                                    : "default",
                                },
                                padding: 2,
                              }}
                              onClick={() => {
                                trackAction("Module", {
                                  moduleId: module.id,
                                  moduleTitle: module.title,
                                });
                                if (module.isActive) {
                                  router.push(
                                    createRoute(
                                      paths.panel.modules.details
                                    ).link({
                                      productId: productId,
                                      moduleId: module.id,
                                    })
                                  );
                                }
                              }}
                              divider={index !== modules.length - 1}
                              key={module.id}
                            >
                              <Stack
                                sx={{
                                  width: "100%",
                                }}
                                direction={{
                                  xs: "column",
                                  md: "row",
                                }}
                                alignItems={{
                                  xs: "flex-start",
                                  md: "center",
                                }}
                                justifyContent={"space-between"}
                                alignContent={"space-between"}
                                spacing={2}
                              >
                                {module.imageUrl && (
                                  <Box
                                    sx={{
                                      backgroundImage: `url(${module.imageUrl})`,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "cover",
                                      minHeight: {
                                        xs: "240px",
                                        sm: "400px",
                                        md: "100px",
                                      },
                                      minWidth: {
                                        xs: "100%",
                                        sm: "100%",
                                        md: "180px",
                                      },
                                      borderRadius: 0.5,
                                    }}
                                  />
                                )}
                                <ListItemText
                                  primary={
                                    <Typography
                                      variant="h6"
                                      sx={{
                                        whiteSpace: "wrap",
                                      }}
                                    >
                                      {module.title}
                                    </Typography>
                                  }
                                  secondary={
                                    <>
                                      <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        sx={{
                                          whiteSpace: "wrap",
                                          mt: 1,
                                        }}
                                      >
                                        {module.description}
                                      </Typography>
                                      {module.startDate && (
                                        <Stack
                                          direction={"row"}
                                          alignItems={"center"}
                                          alignContent={"center"}
                                          spacing={1}
                                          sx={{
                                            mt: 1,
                                          }}
                                        >
                                          <CalendarMonthIcon />
                                          <Typography variant="body2">
                                            <Box
                                              sx={{
                                                mt: 0.5,
                                              }}
                                            >
                                              {t(tokens.panel.products.details.startDate)}{" "}
                                               -{" "}
                                              {moment(module.startDate).format(
                                                "LL"
                                              )}
                                            </Box>
                                          </Typography>
                                        </Stack>
                                      )}
                                    </>
                                  }
                                ></ListItemText>

                                <Box
                                  sx={{
                                    mt: 1,
                                  }}
                                >
                                  {module.isActive && (
                                    <>
                                      <Chip
                                        label={t(
                                          tokens.panel.products.list.status.open
                                        )}
                                        variant="outlined"
                                        color="success"
                                      />
                                    </>
                                  )}
                                  {!module.isActive && (
                                    <Chip
                                      label={t(
                                        tokens.panel.products.list.status.closed
                                      )}
                                      variant="outlined"
                                      color="primary"
                                    />
                                  )}
                                </Box>
                              </Stack>
                            </ListItem>
                          ))}
                          {!product?.isStandalone &&
                            isModulesLoading &&
                            modules.length === 0 && (
                              <>
                                {[...Array(placeholderItems)].map(
                                  (item, index) => (
                                    <ListItem
                                      divider={index !== placeholderItems - 1}
                                      key={index}
                                    >
                                      <ListItemText>
                                        <Typography variant="subtitle2">
                                          <Skeleton />
                                        </Typography>
                                      </ListItemText>
                                    </ListItem>
                                  )
                                )}
                              </>
                            )}
                        </List>
                      </>
                    )}
                  </Card>
                </Grid>
                {/* <Grid
                  md={4}
                  xs={12}
                >
                  <Card>
                    <CardContent>
                      <Stack
                        spacing={2}
                        alignItems={"center"}
                        alignContent={"center"}
                        direction={"column"}
                        sx={{
                          flex: "0 0 100%",
                        }}
                      >
                        <Avatar
                          sx={{
                            height: 96,
                            width: 96,
                          }}
                          src={product?.author.profileImageUrl}
                        >
                          <SvgIcon>
                            <User01Icon />
                          </SvgIcon>
                        </Avatar>
                        <Box
                          sx={{
                            width: "100%",
                          }}
                        >
                          <Typography
                            fontWeight={"bold"}
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            {product ? (
                              <>
                                {product.author.firstName}{" "}
                                {product.author.lastName}
                              </>
                            ) : (
                              <Skeleton />
                            )}
                          </Typography>
                        </Box>
                        <Typography variant="body1">
                          {isProductLoading ? (
                            <Skeleton />
                          ) : (
                            <>{product?.description}</>
                          )}
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid> */}
              </Grid>
            </Stack>
            {!isModulesLoading &&
              modules.length === 0 &&
              !hasModulesLoadingError && (
                <>
                  <Card>
                    <CardContent>
                      <EmptyMessage
                        text={t(tokens.panel.products.details.noModulesFound)}
                      />
                    </CardContent>
                  </Card>
                </>
              )}
          </>
        )}
      </Stack>
    </>
  );
};

export default Page;
