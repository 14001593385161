import { useEffect, useState, type FC } from "react";
import {
  Button,
  Drawer,
  FormHelperText,
  IconButton,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import { useDispatch } from "src/store";
import { toast } from "react-hot-toast";
import { ProductUser } from "src/types/admin/product-users";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import XIcon from "@untitled-ui/icons-react/build/esm/X";
import { thunks } from "src/thunks/admin/product-users";
import { DatePicker } from "@mui/x-date-pickers";
import { Language, dateFormatLocales } from "src/language";
import moment from "moment";
import { Product } from "src/types/admin/product";

interface UpdateUserDrawerProps {
  product: Product;
  user: ProductUser | undefined;
  isOpen: boolean;
  onClose: () => void;
  onUpdate: () => void;
}

export const UpdateUserDrawer: FC<UpdateUserDrawerProps> = (props) => {
  const { t } = useTranslation();
  const { isOpen, onClose, user, onUpdate, product } = props;

  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const [endDate, setEndDate] = useState<Date | null>(
    moment(user?.endDate).toDate()
  );

  useEffect(() => {
    setEndDate(moment(user?.endDate).toDate());
  }, [user]);

  return (
    <Drawer
      anchor="right"
      variant="temporary"
      onClose={onClose}
      open={isOpen}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: 500,
          p: 3,
        },
      }}
    >
      <Formik
        initialValues={{
          email: user?.email,
          firstName: user?.firstName ?? "",
          lastName: user?.lastName ?? "",
          phone: user?.phone ?? "",
          endDate: user?.endDate,
          productPlanId: user?.productPlan?.id,
          submit: null,
        }}
        enableReinitialize
        onSubmit={async (values, helpers): Promise<void> => {
          try {
            await dispatch(
              thunks.updateProductUser(
                product.id,
                user!.id,
                {
                  ...values,
                  endDate: moment(endDate).valueOf(),
                },
                i18n.language
              )
            );
            helpers.setStatus({ success: true });
            helpers.setSubmitting(false);
            onClose();
            onUpdate();
          } catch (err) {
            console.error(err);
            toast.error(t(tokens.general.formError));
            helpers.setStatus({ success: false });
            helpers.setSubmitting(false);
          }
        }}
        validationSchema={Yup.object({
          firstName: Yup.string().max(
            255,
            t(tokens.general.validators.maxLength255) as string
          ),
          // .required(t(tokens.general.validators.required) as string),
          lastName: Yup.string().max(
            255,
            t(tokens.general.validators.maxLength255) as string
          ),
          // .required(t(tokens.general.validators.required) as string),
          phone: Yup.string().max(
            50,
            t(tokens.general.validators.maxLength50) as string
          ),
        })}
      >
        {(props) => (
          <Form>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              spacing={3}
              sx={{
                pb: 3,
              }}
            >
              <Typography variant="h6">
                {t(tokens.admin.products.details.users.form.updateTitle)}
              </Typography>
              <Stack
                alignItems="center"
                direction="row"
                spacing={0.5}
              >
                <IconButton
                  color="inherit"
                  onClick={onClose}
                >
                  <SvgIcon>
                    <XIcon />
                  </SvgIcon>
                </IconButton>
              </Stack>
            </Stack>

            <Stack spacing={3}>
              <TextField
                error={!!(props.touched.firstName && props.errors.firstName)}
                fullWidth
                helperText={props.touched.firstName && props.errors.firstName}
                label={t(tokens.admin.products.details.users.form.firstName)}
                name="firstName"
                inputProps={{
                  autoComplete: "new-first-name",
                }}
                onBlur={props.handleBlur}
                onChange={props.handleChange}
                value={props.values.firstName}
              />
              <TextField
                error={!!(props.touched.lastName && props.errors.lastName)}
                fullWidth
                helperText={props.touched.lastName && props.errors.lastName}
                label={t(tokens.admin.products.details.users.form.lastName)}
                name="lastName"
                inputProps={{
                  autoComplete: "new-last-name",
                }}
                onBlur={props.handleBlur}
                onChange={props.handleChange}
                value={props.values.lastName}
              />

              <TextField
                error={!!(props.touched.phone && props.errors.phone)}
                fullWidth
                helperText={props.touched.phone && props.errors.phone}
                label={t(tokens.admin.products.details.users.form.phone)}
                name="phone"
                inputProps={{
                  autoComplete: "new-phone",
                }}
                onBlur={props.handleBlur}
                onChange={props.handleChange}
                value={props.values.phone}
              />
              {product.productPlans.length > 0 && (
                <TextField
                  select
                  label={t(
                    tokens.admin.products.details.users.form.productPlan
                  )}
                  defaultValue={props.values.productPlanId}
                  onBlur={props.handleBlur}
                  onChange={props.handleChange}
                  SelectProps={{
                    native: true,
                  }}
                  name="productPlanId"
                >
                  <option value={""}></option>

                  {product.productPlans.map((option) => (
                    <option
                      key={option.id}
                      value={option.id}
                    >
                      {option.name}
                    </option>
                  ))}
                </TextField>
              )}
              <DatePicker
                label={t(tokens.admin.products.details.users.form.endDate)}
                sx={{}}
                format={dateFormatLocales[i18n.language as Language]}
                onChange={(date) => {
                  setEndDate(date);
                }}
                value={endDate}
              />

              {props.errors.submit && (
                <FormHelperText
                  error
                  sx={{ mt: 3 }}
                >
                  {props.errors.submit as string}
                </FormHelperText>
              )}

              <Stack
                alignItems="center"
                direction="row"
                justifyContent="flex-end"
                spacing={1}
                sx={{ pt: 3, pb: 3 }}
              >
                <Button
                  color="inherit"
                  onClick={onClose}
                  size="small"
                >
                  {t(tokens.general.buttons.cancel)}
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={props.isSubmitting}
                  disabled={props.isSubmitting}
                >
                  {t(tokens.general.buttons.update)}
                </LoadingButton>
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};
