import type { ChangeEvent, FC } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { FieldArray, Form, Formik, getIn } from "formik";
import {
  Button,
  Card,
  CardContent,
  Stack,
  TextField,
  FormControlLabel,
  Switch,
  Divider,
  Typography,
  IconButton,
  SvgIcon,
  Box,
  Tabs,
  Tab,
  Alert,
  Select,
  OutlinedInput,
  Chip,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";
import { thunks } from "src/thunks/admin/module";
import { useDispatch } from "src/store";
import { useMounted } from "src/hooks/use-mounted";
import { RouterLink } from "src/components/router-link";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import { createRoute } from "react-router-url-params";
import LoadingButton from "@mui/lab/LoadingButton";
import { Link, Module } from "src/types/admin/module";
import React, { useCallback, useMemo, useState } from "react";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import Editor from "src/components/editor/Editor";
import { OutputData } from "@editorjs/editorjs";
import EditorJsRenderer from "src/components/editor/EditorJsRenderer";
import { FileImage, FilePickerScenario } from "src/components/file-dropzone";
import { ImagePicker } from "src/sections/common/image-picker";
import { DatePicker } from "@mui/x-date-pickers";
import { Language, dateFormatLocales } from "src/language";
import moment from "moment";
import { Product } from "src/types/admin/product";
import { ProductPlan } from "src/types/product-plan";

interface Values {
  title: string;
  description: string;
  content: string;
  isActive: boolean;
  orderRank: number;
  imageId: string | undefined;
  links: Link[];
  startDate: number | undefined;
  productPlans: ProductPlan[];
  submit: null;
}

interface ModuleEditFormProps {
  product: Product;
  module: Module;
}

const TAB_DETAILS = "details";
const TAB_CONTENT = "content";

export const ModuleEditForm: FC<ModuleEditFormProps> = (props) => {
  const { t } = useTranslation();
  const isMounted = useMounted();
  const router = useRouter();
  const dispatch = useDispatch();
  const { product, module } = props;

  const [startDate, setStartDate] = useState<Date | null>(
    module.startDate ? moment(module.startDate).toDate() : null
  );

  const tabs = useMemo(
    () => [
      {
        label: t(tokens.admin.modules.details.tabs.details) as string,
        value: TAB_DETAILS,
      },
      {
        label: t(tokens.admin.modules.details.tabs.content) as string,
        value: TAB_CONTENT,
      },
    ],
    [t]
  );

  const handleTabsChange = useCallback(
    (event: ChangeEvent<{}>, value: string): void => {
      setCurrentTab(value);
    },
    []
  );

  const [currentTab, setCurrentTab] = useState<string>(TAB_DETAILS);

  const getJson = useCallback(() => {
    if (module.content) {
      try {
        return JSON.parse(module.content);
      } catch (e) {
        return undefined;
      }
    }
    return undefined;
  }, [module.content]);

  const [data, setData] = useState<OutputData>(getJson());

  const { i18n } = useTranslation();

  const initialValues: Values = {
    title: module.title,
    description: module.description,
    content: module.content,
    isActive: module.isActive,
    links: module.links,
    orderRank: module.orderRank,
    imageId: module.imageId,
    startDate: module.startDate,
    productPlans: module.productPlans,
    submit: null,
  };

  const [imageId, setImageId] = useState<string | undefined>(module.imageId);
  const [imageUrl, setImageUrl] = useState<string | undefined>(module.imageUrl);

  const onImageUpload = useCallback(async (file: FileImage) => {
    setImageId(file.id);
    setImageUrl(file.resizedUrl);
  }, []);

  return (
    <Formik
      enableReinitialize={true}
      validateOnChange={false}
      initialValues={initialValues}
      onSubmit={async (values, helpers): Promise<void> => {
        if (product) {
          values.content = data ? JSON.stringify(data) : "";
          values.imageId = imageId;
          values.startDate = startDate
            ? moment(startDate).valueOf()
            : undefined;

          const updatedModule = await dispatch(
            thunks.updateModule(product.id, module.id, values)
          );
          if (updatedModule) {
            toast.success(t(tokens.admin.modules.details.form.successMessage));
          } else {
            toast.error(t(tokens.general.formError));
            helpers.setStatus({ success: false });
            helpers.setSubmitting(false);
          }
        }
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string()
          .max(255, t(tokens.general.validators.maxLength255) as string)
          .required(t(tokens.general.validators.required) as string),
        description: Yup.string().required(
          t(tokens.general.validators.required) as string
        ),
        links: Yup.array().of(
          Yup.object().shape({
            title: Yup.string()
              .max(255, t(tokens.general.validators.maxLength255) as string)
              .required(t(tokens.general.validators.required) as string),
            url: Yup.string()
              .max(255, t(tokens.general.validators.maxLength255) as string)
              .required(t(tokens.general.validators.required) as string),
          })
        ),
      })}
    >
      {(props) => (
        <>
          <Form>
            <Stack spacing={4}>
              <div>
                <Tabs
                  indicatorColor="primary"
                  onChange={handleTabsChange}
                  scrollButtons="auto"
                  textColor="primary"
                  value={currentTab}
                  variant="scrollable"
                >
                  {tabs.map((tab) => (
                    <Tab
                      key={tab.value}
                      label={tab.label}
                      value={tab.value}
                    />
                  ))}
                </Tabs>
                <Divider />
              </div>

              {currentTab === TAB_CONTENT && (
                <Stack spacing={3}>
                  <Card
                    sx={{
                      overflow: "visible",
                    }}
                  >
                    <CardContent>
                      <Stack spacing={3}>
                        <Typography variant="h6">
                          {t(tokens.admin.modules.details.form.content.editor)}
                        </Typography>
                        <Editor
                          data={data}
                          onChange={setData}
                          holder="editorjs-container"
                        />
                      </Stack>
                    </CardContent>
                  </Card>
                  <Card>
                    <CardContent>
                      <Stack spacing={3}>
                        <Typography variant="h6">
                          {t(tokens.admin.modules.details.form.content.preview)}
                        </Typography>

                        {data && <EditorJsRenderer data={data} />}
                        {!data && (
                          <Alert
                            variant="outlined"
                            severity="info"
                          >
                            <Typography>
                              {t(
                                tokens.admin.modules.details.form.content
                                  .noPreviewContent
                              )}
                            </Typography>
                          </Alert>
                        )}
                      </Stack>
                    </CardContent>
                  </Card>
                </Stack>
              )}
              {currentTab === TAB_DETAILS && (
                <Card>
                  <CardContent>
                    <Stack spacing={3}>
                      <TextField
                        error={!!(props.touched.title && props.errors.title)}
                        fullWidth
                        helperText={props.touched.title && props.errors.title}
                        label={t(tokens.admin.modules.details.form.title)}
                        name="title"
                        onBlur={props.handleBlur}
                        onChange={props.handleChange}
                        value={props.values.title}
                      />

                      <TextField
                        error={
                          !!(
                            props.touched.description &&
                            props.errors.description
                          )
                        }
                        fullWidth
                        multiline
                        rows={3}
                        helperText={
                          props.touched.description && props.errors.description
                        }
                        label={t(tokens.admin.modules.details.form.description)}
                        name="description"
                        onBlur={props.handleBlur}
                        onChange={props.handleChange}
                        value={props.values.description}
                      />
                      <Stack
                        alignItems="center"
                        direction="row"
                        spacing={2}
                      >
                        <DatePicker
                          label={t(tokens.admin.modules.details.form.startDate)}
                          sx={{}}
                          format={dateFormatLocales[i18n.language as Language]}
                          onChange={(date) => {
                            setStartDate(date);
                          }}
                          defaultValue={startDate}
                        />
                        <FormControlLabel
                          control={
                            <Switch
                              name="isActive"
                              onChange={props.handleChange}
                              checked={props.values.isActive}
                            />
                          }
                          label={t(tokens.admin.modules.details.form.active)}
                        />
                      </Stack>
                      {product.productPlans.length > 0 && (
                        <>
                          <Box>
                            <Typography
                              sx={{
                                mb: 0,
                                mt: 1,
                              }}
                              variant="h6"
                            >
                              {t(
                                tokens.admin.modules.details.form.productPlans
                                  .header
                              )}
                            </Typography>
                          </Box>

                          <Autocomplete
                            multiple
                            options={product.productPlans}
                            getOptionLabel={(option) => option.name}
                            value={props.values.productPlans}
                            filterSelectedOptions
                            isOptionEqualToValue={(option, value) => {
                              return option.id === value.id;
                            }}
                            onChange={(event, newValue) => {
                              props.setFieldValue("productPlans", newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="productPlans"
                                label={t(
                                  tokens.admin.modules.details.form.productPlans
                                    .name
                                )}
                              />
                            )}
                          />
                        </>
                      )}
                      <Box>
                        <Typography
                          sx={{
                            mb: 2,
                          }}
                          variant="h6"
                        >
                          {t(tokens.admin.modules.details.form.image.header)}
                        </Typography>
                        <Typography variant="body2">
                          {t(
                            tokens.admin.modules.details.form.image.description
                          )}
                        </Typography>
                      </Box>

                      <Stack spacing={3}>
                        {imageUrl && (
                          <Stack spacing={3}>
                            <img
                              width={300}
                              src={imageUrl}
                            />
                            <Box>
                              <Button
                                color="primary"
                                variant="outlined"
                                onClick={() => {
                                  setImageId(undefined);
                                  setImageUrl(undefined);
                                }}
                                size="small"
                              >
                                {t(tokens.general.buttons.delete)}
                              </Button>
                            </Box>
                          </Stack>
                        )}
                        <ImagePicker
                          scenario={FilePickerScenario.File}
                          onGenerate={onImageUpload}
                          onRemove={() => {
                            setImageId(undefined);
                            setImageUrl(undefined);
                          }}
                          //onUpload={onUpload}
                        />
                      </Stack>
                      <Divider />
                      <Typography variant="subtitle1">
                        {t(tokens.admin.modules.details.linkHeader)}
                      </Typography>

                      {props.values.links.length === 0 && (
                        <Typography variant="body2">
                          {t(tokens.admin.modules.details.noLinks)}
                        </Typography>
                      )}

                      <FieldArray
                        name="links"
                        render={(arrayHelpers) => (
                          <>
                            {props.values.links.map((link, index) => {
                              const title = `links[${index}].title`;
                              const touchedTitle = getIn(props.touched, title);
                              const errorTitle = getIn(props.errors, title);

                              const url = `links[${index}].url`;
                              const touchedUrl = getIn(props.touched, url);
                              const errorUrl = getIn(props.errors, url);
                              return (
                                <React.Fragment key={index}>
                                  <Stack
                                    spacing={3}
                                    direction={{
                                      xs: "column",
                                      sm: "row",
                                    }}
                                    sx={{}}
                                  >
                                    <TextField
                                      error={!!(touchedTitle && errorTitle)}
                                      fullWidth
                                      helperText={touchedTitle && errorTitle}
                                      label={t(
                                        tokens.admin.modules.details.form.link
                                          .title
                                      )}
                                      name={title}
                                      onBlur={props.handleBlur}
                                      onChange={props.handleChange}
                                      value={link.title}
                                    />
                                    <TextField
                                      error={!!(touchedUrl && errorUrl)}
                                      fullWidth
                                      helperText={touchedUrl && errorUrl}
                                      label={t(
                                        tokens.admin.modules.details.form.link
                                          .url
                                      )}
                                      name={url}
                                      onBlur={props.handleBlur}
                                      onChange={props.handleChange}
                                      value={link.url}
                                    />

                                    <Stack
                                      direction={"row"}
                                      alignContent={"center"}
                                      justifyContent={"center"}
                                      spacing={1}
                                    >
                                      <IconButton
                                        disabled={index === 0}
                                        onClick={() => {
                                          arrayHelpers.move(
                                            index,
                                            index !== 0 ? index - 1 : index
                                          );
                                        }}
                                      >
                                        <SvgIcon>
                                          <ArrowUpwardOutlinedIcon />
                                        </SvgIcon>
                                      </IconButton>

                                      <IconButton
                                        disabled={
                                          index + 1 ===
                                          props.values.links.length
                                        }
                                        onClick={() => {
                                          arrayHelpers.move(index, index + 1);
                                        }}
                                      >
                                        <SvgIcon>
                                          <ArrowDownwardOutlinedIcon />
                                        </SvgIcon>
                                      </IconButton>

                                      <IconButton
                                        onClick={() => {
                                          arrayHelpers.remove(index);
                                        }}
                                      >
                                        <SvgIcon>
                                          <DeleteOutlinedIcon color="error" />
                                        </SvgIcon>
                                      </IconButton>
                                    </Stack>
                                  </Stack>
                                </React.Fragment>
                              );
                            })}
                            <Box>
                              <Button
                                onClick={() =>
                                  arrayHelpers.push({
                                    id: null,
                                    title: "",
                                    url: "",
                                  })
                                }
                                variant="outlined"
                                color="primary"
                              >
                                {t(tokens.general.buttons.add)}
                              </Button>
                            </Box>
                          </>
                        )}
                      />
                    </Stack>
                  </CardContent>
                </Card>
              )}
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="flex-end"
                spacing={1}
              >
                <Button
                  href={createRoute(paths.admin.products.details).link({
                    productId: product.id,
                  })}
                  color="inherit"
                  component={RouterLink}
                >
                  {t(tokens.general.buttons.cancel)}
                </Button>
                <LoadingButton
                  type="submit"
                  disabled={props.isSubmitting}
                  loading={props.isSubmitting}
                  variant="contained"
                >
                  {t(tokens.general.buttons.update)}
                </LoadingButton>
              </Stack>
            </Stack>
          </Form>
        </>
      )}
    </Formik>
  );
};
