import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface SettingsState {
  // profileId: string | undefined;
  // strategyId: string | undefined;
}

const initialState: SettingsState = {
  // profileId: undefined,
  // strategyId: undefined
};

const reducers = {

};

export const slice = createSlice({
  name: 'settings',
  initialState,
  reducers
});

export const { reducer } = slice;
