import { type ChangeEvent, type FC, type MouseEvent } from "react";
import { Box, Card, CardContent } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import { Product } from "src/types/panel/account-product";
import Grid from "@mui/material/Unstable_Grid2";
import { useRouter } from "src/hooks/use-router";
import { ProductListItem } from "./product-list-item";
import { ErrorMessage } from "src/sections/common/error-message";
import { EmptyMessage } from "src/sections/common/empty-message";

interface ProductListProps {
  count: number | null;
  items: Product[] | [];
  onPageChange: (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  onRowsPerPageChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onReset: () => void;
  page: number;
  top: number;
  isLoading: boolean;
  hasError: boolean;
}

export const ProductList: FC<ProductListProps> = (props) => {
  const { t } = useTranslation();
  const {
    count = 0,
    items = [],
    onPageChange = () => {},
    onRowsPerPageChange,
    onReset = () => {},
    page = 0,
    top = 10,
    isLoading,
    hasError,
  } = props;
  const showPagination = count ?? 0 > top;
  const router = useRouter();

  return (
    <Box sx={{ position: "relative" }}>
      {!isLoading && items.length === 0 && !hasError && (
        <>
          <Card>
            <CardContent>
              <EmptyMessage
                text={t(tokens.panel.products.list.noProductsFound)}
              />
            </CardContent>
          </Card>
        </>
      )}

      {hasError && (
        <>
          <Card>
            <CardContent>
              <ErrorMessage
                text={t(tokens.panel.products.list.errorProductsLoading)}
              />
            </CardContent>
          </Card>
        </>
      )}
      {!hasError && (
        <Grid
          container
          spacing={4}
        >
          {items.map((strategy) => (
            <Grid
              key={strategy.id}
              xs={12}
              md={6}
            >
              <ProductListItem item={strategy} />
            </Grid>
          ))}
          {isLoading && items.length === 0 && (
            <>
              {[...Array(4)].map((item, index) => (
                <Grid
                  key={index}
                  xs={12}
                  md={6}
                >
                  <ProductListItem item={undefined} />
                </Grid>
              ))}
            </>
          )}
        </Grid>
      )}
    </Box>
  );
};
