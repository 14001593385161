import { Setting } from "./setting";

export interface Organization {
  id: string;
  name: string;
  logoUrl: string | undefined;
  settings: Setting[];
}

export function getColor(
  organization: Organization,
  key: string,
  defaultColor?: string
): string {
  return (
    organization.settings.find((s) => s.key === key)?.value ||
    defaultColor ||
    ""
  );
}
