import { Card, CardContent, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useTranslation } from "react-i18next";
import { Seo } from "src/components/seo";
import { usePageView } from "src/hooks/use-page-view";
import { useSearchParams } from "src/hooks/use-search-params";
import { tokens } from "src/locales/tokens";
import type { Page as PageType } from "src/types/page";

const Page: PageType = () => {
  usePageView();
  const { t } = useTranslation();
  const searchParams = useSearchParams();
  const orderReference = searchParams.get("orderReference") ?? "";

  return (
    <>
      <Seo title={t(tokens.payment.error.title) as string} />
      <Container maxWidth="sm">
        <Card>
          <CardContent>
            <>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold" }}
              >
                {t(tokens.payment.error.header)}
              </Typography>
              <Typography variant="body1">
                <p>
                  {t(tokens.payment.error.orderReference)}:{" "}
                  <strong>{orderReference}</strong>
                </p>
              </Typography>
              <Typography variant="body1">
                <div
                  dangerouslySetInnerHTML={{
                    __html: t(tokens.payment.error.message) ?? "",
                  }}
                />
              </Typography>
            </>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default Page;
