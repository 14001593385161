import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'src/store';

export const Favicon: FC = (props) => {
  const { organization } = useSelector((state) => state.accountOrganization);


  return (
    <Helmet>
      {organization?.logoUrl &&
        <link rel="icon" type="image/png" href={organization?.logoUrl} />
      }
    </Helmet>
  );
};