export enum EmailTemplateType {
  SignIn = 1,

  ForgotPassword = 2,

  ProductInvitation = 10,
}

export interface EmailTemplate {
  title: string;

  content: string;

  type: EmailTemplateType;
}
