import type { AppThunk } from "src/store";
import { fetchWrapper } from "src/utils/fetch-wrapper";
import { api } from "src/config";

const createProductPlan =
  (productId: string, value: any): AppThunk =>
  async (dispatch): Promise<void> => {
    var productPlan = await fetchWrapper.post(
      `${api}/products/${productId}/product-plans`,
      value
    );
    return productPlan;
  };

const updateProductPlan =
  (productId: string, productPlanId: string, value: any): AppThunk =>
  async (dispatch): Promise<void> => {
    var productPlan = await fetchWrapper.put(
      `${api}/products/${productId}/product-plans/${productPlanId}`,
      value
    );
    return productPlan;
  };

const deleteProductPlan =
  (productId: string, productPlanId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    await fetchWrapper.delete(
      `${api}/products/${productId}/product-plans/${productPlanId}`,
      {}
    );
  };

export const thunks = {
  createProductPlan,
  updateProductPlan,
  deleteProductPlan,
};
