import { useEffect, type FC, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, ButtonBase, Divider, Drawer, Stack, useMediaQuery } from '@mui/material';
import { Logo } from 'src/components/logo';
import { RouterLink } from 'src/components/router-link';
import { usePathname } from 'src/hooks/use-pathname';
import { paths } from 'src/paths';
import { SideNavItem } from './side-nav-item';
import { useAuth } from 'src/hooks/use-auth';
import { useTranslation } from 'react-i18next';
import { tokens } from 'src/locales/tokens';
import { useDispatch } from 'src/store';
import toast from 'react-hot-toast';
import { Theme } from '@mui/system';

interface Item {
  children?: {
    subheader?: string;
    items: {
      external?: boolean;
      path: string;
      title: string;
    }[];
  }[];
  disabled?: boolean;
  external?: boolean;
  path?: string;
  title: string;
}

const renderItems = ({
  depth = 0,
  items,
  pathname
}: {
  depth?: number;
  items: Item[];
  pathname?: string | null;
}): JSX.Element[] => items.reduce(
  (acc: JSX.Element[], item) => reduceChildRoutes({
    acc,
    depth,
    item,
    pathname
  }),
  []
);

const reduceChildRoutes = ({
  acc,
  depth,
  item,
  pathname
}: {
  acc: JSX.Element[];
  depth: number;
  item: Item;
  pathname?: string | null;
}): Array<JSX.Element> => {
  const checkPath = !!(item.path && pathname);
  const partialMatch = checkPath ? pathname.includes(item.path!) : false;
  const exactMatch = checkPath ? pathname === item.path : false;

  if (item.children) {
    acc.push(
      <SideNavItem
        active={partialMatch}
        depth={depth}
        disabled={item.disabled}
        key={item.title}
        open={partialMatch}
        title={item.title}
      >
        <Stack spacing={2}>
          {item.children.map((child, index) => (
            <Stack
              component="ul"
              key={index}
              spacing={0.5}
              sx={{
                listStyle: 'none',
                m: 0,
                p: 0
              }}
            >
              {child.subheader && (
                <Box
                  component="li"
                  sx={{
                    color: 'text.secondary',
                    fontSize: 12,
                    fontWeight: 500,
                    lineHeight: 1.66,
                    mb: 1,
                    pl: '24px',
                    textTransform: 'uppercase'
                  }}
                >
                  {child.subheader}
                </Box>
              )}
              {child.items.map((item) => {
                const checkPath = !!(item.path && pathname);
                const active = checkPath ? pathname === item.path : false;

                const linkProps = item.path
                  ? item.external
                    ? {
                      component: 'a',
                      href: item.path,
                      target: '_blank'
                    }
                    : {
                      component: RouterLink,
                      href: item.path
                    }
                  : {};

                return (
                  <li key={item.title}>
                    <ButtonBase
                      sx={{
                        alignItems: 'center',
                        borderRadius: 1,
                        display: 'flex',
                        justifyContent: 'flex-start',
                        pl: '24px',
                        pr: '16px',
                        py: '8px',
                        textAlign: 'left',
                        '&:hover': {
                          backgroundColor: 'action.hover'
                        },
                        ...(active && {
                          color: 'primary.main'
                        })
                      }}
                      {...linkProps}
                    >
                      <Box
                        component="span"
                        sx={{
                          height: 6,
                          mr: 2,
                          width: 6
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: 'neutral.400',
                            borderRadius: '50%',
                            height: 4,
                            opacity: 0, // remove this if you want it to be visible
                            width: 4,
                            ...(active && {
                              backgroundColor: 'primary.main',
                              height: 6,
                              opacity: 1,
                              width: 6
                            })
                          }}
                        />
                      </Box>
                      <Box
                        component="span"
                        sx={{
                          flexGrow: 1,
                          fontFamily: (theme) => theme.typography.fontFamily,
                          fontSize: 13,
                          fontWeight: 500,
                          lineHeight: '24px',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {item.title}
                      </Box>
                    </ButtonBase>
                  </li>
                );
              })}
            </Stack>
          ))}
        </Stack>
      </SideNavItem>
    );
  } else {
    acc.push(
      <SideNavItem
        active={exactMatch}
        depth={depth}
        disabled={item.disabled}
        external={item.external}
        key={item.title}
        path={item.path}
        title={item.title}
      />
    );
  }

  return acc;
};

interface SideNavProps {
  onClose?: () => void;
  open?: boolean;
}

export const SideNav: FC<SideNavProps> = (props) => {
  const { onClose, open = false } = props;
  const pathname = usePathname();
  const { isAuthenticated } = useAuth();
  const [items, setItems] = useState<Item[]>([]);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { organization } = useAuth();

  const sharedItems = useMemo(() => {
    return [{
      title: t(tokens.landing.nav.privacyPolicy),
      path: paths.privacyPolicy
    },
    {
      title: t(tokens.landing.nav.termsOfUse),
      path: paths.terms
    }] as Item[];
  }, [i18n.language]);

  useEffect(() => {
    (async () => {
      try {
        if (isAuthenticated) {

          setItems(() => {
            return [
              {
                title: t(tokens.landing.nav.products),
                path: paths.panel.products.list
              }
            ]
          });

        }
        else {
          setItems(() => {
            return [
              {
                title: t(tokens.landing.nav.singIn),
                path: paths.account.signIn
              },
              // {
              //   title: t(tokens.landing.nav.singUp),
              //   path: paths.account.signUp
              // }
            ]
          });
        }
      } catch (err) {
        console.error(err);
        toast.error(t(tokens.general.formError));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isAuthenticated, i18n.language]);


  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          maxWidth: '100%',
          width: 300
        }
      }}
      variant="temporary"
    >
      <Box
        sx={{
          pt: 2,
          px: 2
        }}
      >
        <Stack
          alignItems="center"
          component={RouterLink}
          direction="row"
          display="inline-flex"
          href={paths.index}
          spacing={1}
          sx={{ textDecoration: 'none' }}
        >
          <Box
            sx={{
              display: 'inline-flex',
              height: 24,
              width: 24
            }}
          >
            <Logo />
          </Box>
          <Box
            sx={{
              color: 'text.primary',
              fontFamily: '\'Plus Jakarta Sans\', sans-serif',
              fontSize: 14,
              fontWeight: 800,
              letterSpacing: '0.3px',
              lineHeight: 2.5,
              '& span': {
                color: 'primary.main'
              }
            }}
          >
            {organization ?
              <>
                <span>{organization.name}</span>
              </> :
              <>
                Flit<span>Edu</span>
              </>
            }
          </Box>
        </Stack>
      </Box>
      <Box
        component="nav"
        sx={{ p: 2 }}
      >

        <Stack
          component="ul"
          spacing={1}
          sx={{
            listStyle: 'none',
            m: 0,
            p: 0
          }}
        >
          {renderItems({ items, pathname })}
        </Stack>
        <Divider sx={{ my: 2 }} />
        <Stack
          component="ul"
          spacing={1}
          sx={{
            listStyle: 'none',
            m: 0,
            p: 0
          }}
        >
          {renderItems({ items: sharedItems, pathname })}
        </Stack>
      </Box>
    </Drawer>
  );
};

SideNav.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};
