import type { FC, ReactElement } from 'react';
import PropTypes from 'prop-types';
import SunIcon from '@untitled-ui/icons-react/build/esm/Sun';
import Moon01Icon from '@untitled-ui/icons-react/build/esm/Moon01';
import { Chip, PaletteMode, Stack, SvgIcon, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { tokens } from 'src/locales/tokens';

interface Option {
  label: string;
  value: PaletteMode;
  icon: ReactElement;
}


interface OptionsColorSchemeProps {
  onChange?: (value: PaletteMode) => void;
  value?: PaletteMode;
}

export const OptionsColorScheme: FC<OptionsColorSchemeProps> = (props) => {
  const { onChange, value } = props;
  const { t } = useTranslation();

  const options: Option[] = [
    {
      label: t(tokens.account.colorScheme.light),
      value: 'light',
      icon: (
        <SvgIcon fontSize="small">
          <SunIcon />
        </SvgIcon>
      )
    },
    {
      label: t(tokens.account.colorScheme.dark),
      value: 'dark',
      icon: (
        <SvgIcon fontSize="small">
          <Moon01Icon />
        </SvgIcon>
      )
    }
  ];
  return (
    <Stack spacing={1}>
      <Typography
        color="text.secondary"
        variant="overline"
      >
        {t(tokens.account.colorScheme.scheme)}
      </Typography>
      <Stack
        alignItems="center"
        direction="row"
        flexWrap="wrap"
        gap={2}
      >
        {options.map((option) => (
          <Chip
            icon={option.icon}
            key={option.value}
            label={option.label}
            onClick={() => onChange?.(option.value)}
            sx={{
              borderColor: 'transparent',
              borderRadius: 1.5,
              borderStyle: 'solid',
              borderWidth: 2,
              ...(option.value === value && {
                borderColor: 'primary.main'
              })
            }}
          />
        ))}
      </Stack>
    </Stack>
  );
};

OptionsColorScheme.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOf(['light', 'dark'])
};
