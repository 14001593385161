import { BlockToolData, OutputBlockData, OutputData } from "@editorjs/editorjs";
import React from "react";
import CodeRenderer from "./CodeRenderer";
import {
  AccordionDetails,
  AccordionSummary,
  Card,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";

const editorJsHtml = require("editorjs-html");

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion
    disableGutters
    elevation={0}
    square
    {...props}
  />
))(({ theme }) => ({
  padding: 0,
  marginTop: 1,
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {},
  "&::before": {
    display: "none",
  },
  "& ul": {
    padding: 0,
    margin: 0,
  },
  "& li": {
    listStyleType: "none",
    padding: "0.5rem",
  },
}));

//const { t } = useTranslation();

const EditorJsToHtml = editorJsHtml({
  code: (block: OutputBlockData<string>) => {
    return <CodeRenderer code={block.data.code} />;
  },
  timeCode: (block: OutputBlockData<BlockToolData>) => {
    const data = block.data;
    const listStyle = data.style === "unordered" ? "ul" : "ol";

    const recursor = (items: any, listStyle: string) => {
      const list = items.map((item: any) => {
        if (!item.content && !item.items) return `<li>${item}</li>`;

        let list = "";
        if (item.items) list = recursor(item.items, listStyle);
        if (item.content) return `<li> ${item.content} </li>` + list;
      });

      var listHtml = `<${listStyle}>${list.join("")}</${listStyle}>`;
      return listHtml;
    };

    var listResult = recursor(data.items, listStyle);

    return (
      <Accordion>
        <AccordionTitle />
        <AccordionDetails
          sx={{
            p: 1,
            borderTop: "1px solid #e0e0e0",
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: listResult }}></div>
        </AccordionDetails>
      </Accordion>
    );
  },
  raw: (block: OutputBlockData<string>) => {
    var html = block.data.html;
    if (!checkHTML(html)) {
      return "not valid html";
    }

    return block.data.html;
  },
});

var checkHTML = function (html: string) {
  var doc = document.createElement("div");
  doc.innerHTML = html;
  return doc.innerHTML === html;
};

type Props = {
  data: OutputData;
};
type ParsedContent = string | JSX.Element;

const EditorJsRenderer = ({ data }: Props) => {
  const html = EditorJsToHtml.parse(data) as ParsedContent[];
  return (
    <div className="prose max-w-full ">
      {html.map((item, index) => {
        if (typeof item === "string") {
          return (
            <div
              dangerouslySetInnerHTML={{ __html: item }}
              key={index}
            ></div>
          );
        }
        return item;
      })}
    </div>
  );
};

export default EditorJsRenderer;

const AccordionTitle = () => {
  const { t } = useTranslation();
  return (
    <AccordionSummary
      sx={{
        pl: 2,
        pr: 2,
        pb: 1,
        pt: 1,
      }}
      expandIcon={<ExpandMoreIcon />}
    >
      <Typography variant="subtitle1">
        {t(tokens.general.editor.showTimeCodes)}
      </Typography>
    </AccordionSummary>
  );
};
