import type { AppThunk } from 'src/store';
import { fetchWrapper } from 'src/utils/fetch-wrapper';
import { api } from 'src/config';
import { slice as moduleSlice } from 'src/slices/admin/module';
import { ERRORS } from 'src/consts';

const createModule = (productId: string, value: any): AppThunk => async (dispatch): Promise<void> => {

  dispatch(moduleSlice.actions.setSavingErrorStatus(false));
  dispatch(moduleSlice.actions.setSavingStatus(true));
  try {
    var module = await fetchWrapper.post(`${api}/products/${productId}/modules`, value);
    dispatch(moduleSlice.actions.setModule(module));
    return module;
  } catch (error) {
    dispatch(moduleSlice.actions.setSavingErrorStatus(true));
  }
  dispatch(moduleSlice.actions.setSavingStatus(false));
  return undefined;
}

const deleteModule = (productId: string, moduleId: string): AppThunk => async (dispatch): Promise<void> => {
  await fetchWrapper.delete(`${api}/products/${productId}/modules/${moduleId}`, {});
  dispatch(moduleSlice.actions.setModule(undefined));
}


const updateModule = (productId: string, moduleId: string, value: any): AppThunk => async (dispatch): Promise<void> => {

  dispatch(moduleSlice.actions.setSavingErrorStatus(false));
  dispatch(moduleSlice.actions.setSavingStatus(true));
  try {
    var module = await fetchWrapper.put(`${api}/products/${productId}/modules/${moduleId}`, value);
    dispatch(moduleSlice.actions.setModule(module));
    return module;
  } catch (error) {
    dispatch(moduleSlice.actions.setSavingErrorStatus(true));
  }
  dispatch(moduleSlice.actions.setSavingStatus(false));
  return undefined;
}

const getModule = (productId: string, moduleId: string): AppThunk => async (dispatch): Promise<void> => {

  dispatch(moduleSlice.actions.setLoadingErrorStatus(undefined));
  dispatch(moduleSlice.actions.setLoadingStatus(true));
  try {
    var module = await fetchWrapper.get(`${api}/products/${productId}/modules/${moduleId}`, {});
    dispatch(moduleSlice.actions.setModule(module));
  } catch (error) {
    if (error?.code === 'Module.ModuleNotFound') {
      dispatch(moduleSlice.actions.setLoadingErrorStatus(ERRORS.NOT_FOUND_ERROR));
    } else {
      dispatch(moduleSlice.actions.setLoadingErrorStatus(ERRORS.GENERAL_ERROR));
    }
  }
  dispatch(moduleSlice.actions.setLoadingStatus(false));
}

export const thunks = {
  createModule,
  updateModule,
  deleteModule,
  getModule
};
