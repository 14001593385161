import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { OrganizationUsersResponse } from 'src/types/admin/organization-users';

interface OrganizationUsersState {
  isLoading: boolean;
  hasLoadingError: boolean;
  items: OrganizationUsersResponse;
}

type GetOrganizationUsersAction = PayloadAction<OrganizationUsersResponse>;
type OrganizationUsersLoadingStatusAction = PayloadAction<boolean>;
type OrganizationUsersErrorStatusAction = PayloadAction<boolean>;

const initialState: OrganizationUsersState = {
  isLoading: false,
  hasLoadingError: false,
  items: {
    items: [],
    totalCount: 0,
    top: 10,
    page: 1,
    lastItemDateTime: null
  }
};

const reducers = {
  setLoadingStatus: (state: OrganizationUsersState, action: OrganizationUsersLoadingStatusAction): void => {
    state.isLoading = action.payload;
  },
  setErrorStatus: (state: OrganizationUsersState, action: OrganizationUsersErrorStatusAction): void => {
    state.hasLoadingError = action.payload;
  },
  getOrganizationUsers: (state: OrganizationUsersState, action: GetOrganizationUsersAction): void => {
    state.items = action.payload;
  },
};

export const slice = createSlice({
  name: 'admin-organization-users',
  initialState,
  reducers
});

export const { reducer } = slice;
