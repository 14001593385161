import { type FC } from "react";
import {
  Button,
  Drawer,
  FormHelperText,
  IconButton,
  MenuItem,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import { useDispatch } from "src/store";
import { toast } from "react-hot-toast";
import { ProductUser } from "src/types/admin/product-users";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import XIcon from "@untitled-ui/icons-react/build/esm/X";
import { thunks } from "src/thunks/admin/organization-users";
import {
  OrganizationUser,
  OrganizationUserRole,
} from "src/types/admin/organization-users";

interface UpdateUserDrawerProps {
  organizationId: string;
  user: OrganizationUser | undefined;
  isOpen: boolean;
  onClose: () => void;
  onUpdate: () => void;
}

export const UpdateUserDrawer: FC<UpdateUserDrawerProps> = (props) => {
  const { t } = useTranslation();
  const { isOpen, onClose, user, onUpdate, organizationId } = props;

  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  return (
    <Drawer
      anchor="right"
      variant="temporary"
      onClose={onClose}
      open={isOpen}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: 500,
          p: 3,
        },
      }}
    >
      <Formik
        initialValues={{
          email: user?.email,
          firstName: user?.firstName ?? "",
          lastName: user?.lastName ?? "",
          role: user?.role,
          submit: null,
        }}
        enableReinitialize
        onSubmit={async (values, helpers): Promise<void> => {
          try {
            await dispatch(
              thunks.updateOrganizationUser(
                organizationId,
                user!.id,
                values,
                i18n.language
              )
            );
            toast.success(
              t(
                tokens.admin.organization.details.users.form
                  .updatedSuccessMessage
              )
            );
            helpers.setStatus({ success: true });
            helpers.setSubmitting(false);
            onClose();
            onUpdate();
          } catch (err) {
            console.error(err);
            toast.error(t(tokens.general.formError));
            helpers.setStatus({ success: false });
            helpers.setSubmitting(false);
          }
        }}
        validationSchema={Yup.object({
          firstName: Yup.string()
            .max(255, t(tokens.general.validators.maxLength255) as string)
            .required(t(tokens.general.validators.required) as string),
          lastName: Yup.string()
            .max(255, t(tokens.general.validators.maxLength255) as string)
            .required(t(tokens.general.validators.required) as string),
        })}
      >
        {(props) => (
          <Form>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              spacing={3}
              sx={{
                pb: 3,
              }}
            >
              <Typography variant="h6">
                {t(tokens.admin.organization.details.users.form.updateTitle)}
              </Typography>
              <Stack
                alignItems="center"
                direction="row"
                spacing={0.5}
              >
                <IconButton
                  color="inherit"
                  onClick={onClose}
                >
                  <SvgIcon>
                    <XIcon />
                  </SvgIcon>
                </IconButton>
              </Stack>
            </Stack>

            <Stack spacing={3}>
              <TextField
                error={!!(props.touched.firstName && props.errors.firstName)}
                fullWidth
                helperText={props.touched.firstName && props.errors.firstName}
                label={t(
                  tokens.admin.organization.details.users.form.firstName
                )}
                name="firstName"
                inputProps={{
                  autoComplete: "new-first-name",
                }}
                onBlur={props.handleBlur}
                onChange={props.handleChange}
                value={props.values.firstName}
              />
              <TextField
                error={!!(props.touched.lastName && props.errors.lastName)}
                fullWidth
                helperText={props.touched.lastName && props.errors.lastName}
                label={t(tokens.admin.organization.details.users.form.lastName)}
                name="lastName"
                inputProps={{
                  autoComplete: "new-last-name",
                }}
                onBlur={props.handleBlur}
                onChange={props.handleChange}
                value={props.values.lastName}
              />

              <TextField
                error={!!(props.touched.role && props.errors.role)}
                fullWidth
                helperText={props.touched.role && props.errors.role}
                select
                label={t(tokens.admin.organization.details.users.form.role)}
                name="role"
                variant="filled"
                value={props.values.role}
                onBlur={props.handleBlur}
                onChange={props.handleChange}
              >
                <MenuItem
                  key={OrganizationUserRole.user}
                  value={OrganizationUserRole.user}
                >
                  {t(tokens.general.userRoles.user)}
                </MenuItem>
                <MenuItem
                  key={OrganizationUserRole.admin}
                  value={OrganizationUserRole.admin}
                >
                  {t(tokens.general.userRoles.admin)}
                </MenuItem>
              </TextField>

              {props.errors.submit && (
                <FormHelperText
                  error
                  sx={{ mt: 3 }}
                >
                  {props.errors.submit as string}
                </FormHelperText>
              )}

              <Stack
                alignItems="center"
                direction="row"
                justifyContent="flex-end"
                spacing={1}
                sx={{ pt: 3, pb: 3 }}
              >
                <Button
                  color="inherit"
                  onClick={onClose}
                  size="small"
                >
                  {t(tokens.general.buttons.cancel)}
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={props.isSubmitting}
                  disabled={props.isSubmitting}
                >
                  {t(tokens.general.buttons.update)}
                </LoadingButton>
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};
